import React from 'react';
const ReleaseNotes = React.lazy(() => import('./containers/ReleaseNotes'));

/*
 * We use code splitting here because we expect this to be infrequently used.
 */

export default [
  {
    path: '/releasenotes',
    exact: false,
    component: ReleaseNotes,
    module: 'admin'
  }
];
