import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';


const TextFieldSelect = styled(TextField)(() => ({
  margin: 10,
  width: 220,
}));

const fieldSort = (field) => (a, b) => {
  const aStr = a[field].toLowerCase();
  const bStr = b[field].toLowerCase();
  return aStr < bStr ? -1 : (aStr > bStr ? 1 : 0);
};

const nameSort = fieldSort('name');

class SchemaSettingsPanel extends React.Component {

  static propTypes = {
    client: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    schemas: PropTypes.array.isRequired,
  }

  state = {
    schema: 0,
    expanded: false,
  }

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.loadState();
    }
  }

  loadState = () => {
    const { client } = this.props;
    this.setState({
      schema: client.client_schema,
    });
  }

  schemaName = (schemaId) => {
    const schema = this.props.schemas.find(s => s.id === schemaId);
    if (schema) {
      return `${schema.name} (schema ${schema.id})`;
    }
  }

  render() {
    const {  schemas, client, onSave } = this.props;
    const { schema, expanded } = this.state;
    const options = schemas.filter(s => s.active).sort(nameSort);
    return (
      <SettingsPanel
        title="Client Schema"
        summary={this.schemaName(client.client_schema)}
        onSave={() => {
          onSave({schema});
          this.setState({expanded:false});
        }}
        onCancel={() => {
          this.loadState();
          this.setState({expanded:false});
        }}
        onExpand={(expanded) => this.setState({expanded})}
        expanded={expanded}
      >
        <TextFieldSelect
          select
          label="Schema"
          value={schema}
          onChange={(event) => this.setState({schema: event.target.value, modified: true})}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {options.map(v => <MenuItem key={v.id} value={v.id}>{v.name} (schema {v.id})</MenuItem>)}
        </TextFieldSelect>
      </SettingsPanel>
    );
  }
}

export default (SchemaSettingsPanel);
