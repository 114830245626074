import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Form, { Section } from './Form';
import papa from 'papaparse';

const DivGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledDropzone = styled(Dropzone)(() => ({
  width: 300,
  height: 280,
  border: '.2rem dashed #8088f9',
  alignSelf: 'center',
}));

const DivContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
}));

const StyledUploadIcon = styled(UploadIcon)(() => ({
  width: 150,
  height: 150,
  opacity: 0.5,
}));

const DivWarning = styled('div')(() => ({
  color: 'red',
  width: 600,
}));

const PWarning = styled('p')(() => ({
  color: 'red',
  width: 600,
}));

/*
 * Strip out any (explicit whitelist) CSV Prefixes that are found from the file data that has been uploaded.
 * We can optionally insert these into the downloaded CSV by decorating CSVLink
 * data e.g. <CSVLink data={this.csvFormat(vehicles)} filename={...}>
 */
const stripCSVPrefixes = (value) => {
  const re = /^(id::)/; // whitelist, if we add more later ... /^(id::|foo::)/
  return value.replace(re, '');
};

class UploadForm extends React.Component {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    warning: PropTypes.string,
    children: PropTypes.array.isRequired,
  };

  state = {
    file: null,
    objects: [],
  };

  fileDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transform: stripCSVPrefixes,
      complete: (results) => this.props.onUpload(file.name, results.data)
    });
  };

  render() {
    const {  onClose, title } = this.props;

    return (
      <Form>
        <div align="left">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h1>{title}</h1>
        <DivWarning align="center">
          <PWarning>{this.props.warning}</PWarning>
        </DivWarning>
        <DivGroup>
          <Section title="Select File">
            <StyledDropzone onDrop={this.fileDrop} multiple={false}>
              <DivContainer>
                <p>Drop your file here or click to select from your folders.</p>
                <StyledUploadIcon />
              </DivContainer>
            </StyledDropzone>
          </Section>

          <Section title="Instructions">{this.props.children}</Section>
        </DivGroup>
      </Form>
    );
  }
}

export default (UploadForm);