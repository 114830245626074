import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientIdControl from './ClientIdControl';
import logo from '../assets/logo-word-mark-negative.svg';
import logoBarton from 'assets/barton-negative.png';
import config from 'config/config';

const PREFIX = 'Footer';

const classes = {
  container: `${PREFIX}-container`,
  link: `${PREFIX}-link`,
  linkGroup: `${PREFIX}-linkGroup`,
  wordMark: `${PREFIX}-wordMark`,
  wordMarkBarton: `${PREFIX}-wordMarkBarton`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    paddingTop: config.isBarton ? '0' : '0.8rem',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    paddingBottom: 0,
    border: 'none',
    fontSize: '0.8rem',
    fontWeight: 400,
    color: '#ffffff',
    backgroundColor: config.isBarton ? '#053573' : '#202938',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 1001,
    [theme.breakpoints.down('md')]: {
      flexDirection: config.isBarton ? 'row' : 'column',
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'inherit',
    paddingLeft: '3rem',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },

  [`& .${classes.linkGroup}`]: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  [`& .${classes.wordMark}`]: {
    maxHeight: 11,
    marginRight: '1rem',
  },

  [`& .${classes.wordMarkBarton}`]: {
    paddingTop: 5,
    paddingBottom: 5,
    maxHeight: 40,
    objectFit: 'contain',
  },
}));

class Footer extends React.PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
  };

  render() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) return null;
    return (
      <Root className={classes.container}>
        {config.isBarton ? (
          <>
            <img
              src={logoBarton}
              alt="National Pilot Banner"
              className={classes.wordMarkBarton}
            />
          </>
        ) : (
          <img src={logo} alt="Prism Word Mark" className={classes.wordMark} />
        )}
        <ClientIdControl />
        <div className={classes.linkGroup}>
          <a className={classes.link} target="_blank" rel="noopener noreferrer" href={config.links.HELP_CENTRE}>
            Help
          </a>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Footer);
