import React, { useEffect, useState } from 'react';
import LeftNav from 'components/LeftNav';
import PropTypes from 'prop-types';
import { AnalyticsAPI } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStore } from 'ducks';
import moment from 'moment';
import ActivityDashboard from './content/ActivityDashboard';
import IdlingDashboard from './content/IdlingDashboard';
import DailyActivityCalendar from './content/DailyActivityCalendar';
import ActivityScatterplot from './content/ActivityScatterplot';
import CrossLocationStats from './content/CrossLocationStats';

const Analytics = ({ ...props }) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const [dateRange, setDateRange] = useState({min: null, max: null});
  const [filter, setFilter] = useState({ date: moment().startOf('month').subtract(1,'months').format('YYYY-MM-DD') });
  const onFilterChanged = f => setFilter({date: f});

  const getDateRange = async () => {
    dispatch(LoadingStore.loading(true));
    try {
      const response = await AnalyticsAPI.getDateRange(clientId);
      setDateRange(response.payload);
    } catch (err) {
      console.error(err);
      setDateRange({min: null, max: null});
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  const content = () => {

    const activityDashboard = {
      path: '/analytics/activitydashboard',
      label: 'Activity Dashboard',
      content: function renderIdlingDashboard(props) {
        return <ActivityDashboard dateRange={dateRange} {...props}/>;
      },
    };

    const idlingDashboard = {
      path: '/analytics/idlingdashboard',
      label: 'Idling Dashboard',
      content: function renderIdlingDashboard(props) {
        return <IdlingDashboard dateRange={dateRange} {...props}/>;
      },
    };

    const dailyVehicleActivityCalendar = {
      path: '/analytics/dailyactivitycalendar',
      label: 'Daily Activity Calendar',
      content: function renderDailyVehicleCalendar(props) {
        return <DailyActivityCalendar dateRange={dateRange} {...props}/>;
      },
    };

    const activityScatterplot = {
      path: '/analytics/activityscatterplot',
      label: 'Activity Scatterplot',
      content: function renderActivityScatterplot(props) {
        return <ActivityScatterplot dateRange={dateRange} {...props}/>;
      },
    };

    const crossLocationStats = {
      path: '/analytics/crosslocationstats',
      label: 'Cross location stats',
      content: function renderCrossLocationStats(props) {
        return <CrossLocationStats dateRange={dateRange} {...props}/>;
      },
    };

    return [
      activityDashboard,
      idlingDashboard,
      dailyVehicleActivityCalendar,
      activityScatterplot,
      crossLocationStats,
    ];
  };

  useEffect(() => {
    getDateRange();
  }, []); // eslint-disable-line

  return (
    <div style={{ height: '100%' }}>
      <LeftNav
        title='Analytics'
        pages={content()}
        filter={filter}
        onFilterChanged={onFilterChanged}
        {...props}
      />
    </div>
  );
};

Analytics.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default Analytics;
