import config from '../config/config';
let baseHeaders = {'Content-Type': 'application/json'};
let errorHandlers = {};

export function makePath(name, ...extra) {
  const parts = [
    config.services.HOST,
    name,
    config.services.VERSION
  ].concat(extra);
  return parts.join('/');
}

// Usage:
//   http.addErrorHandler(403, onAuthError)
//   http.addErrorHandler([401,403], onAuthError)
// error handlers get an error as a param and return:
//   false to cause the exception to be thrown
//   undefined to cause the request to be retried
//   anything else to return that value from the handler
export function addErrorHandler(status, handler) {
  // eslint-disable-next-line
  if (typeof status === 'object') {
    // eslint-disable-next-line
    for (let s of status) {
      errorHandlers[s] = handler;
    }
  } else {
    errorHandlers[status] = handler;
  }
}

export function formatQuery(params) {
  let esc = encodeURIComponent;
  let query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  return query;
}

export function formatFormBody(args) {
  const params = new URLSearchParams();
  // eslint-disable-next-line
  for (const key in args) {
    params.append(key, args[key]);
  }
  return params;
}

export function formatJsonBody(args) {
  return JSON.stringify(args);
}

export function setHeaders(newHeaders) {
  Object.assign(baseHeaders, newHeaders);
}

export function clearHeader(name) {
  delete baseHeaders[name];
}

export async function request(method, url, args={}, headers ={}) {
  const maxAttempts = 10;
  for (let attempt=0; attempt < maxAttempts; attempt++) {
    try {
      return await rawRequest(method, url, args, headers);
    } catch (err) {
      // if there is no status code or no handler for the status code, throw
      // if the handler returns false throw
      // if the handler returns true retry
      // if the handler returns something else, return that value
      if (!err.status) throw err;
      const handler = errorHandlers[err.status];
      const handled = handler ? await handler(err) : false;
      if (handled === false) throw err;
      if (handled !== true) return handled;
    }
  }
}

export async function rawRequest(method, url, args={}, additional_headers = {}) {
  const {body, params, clientId} = args;
  const headers = clientId ? {'prism-client-id':clientId,...additional_headers} : {...additional_headers};
  const opts = {
    method: method,
    mode: 'cors',
    headers: Object.assign({}, baseHeaders, headers),
    body: formatJsonBody(body)
  };
  if (params !== undefined) {
    url += '?' + formatQuery(params);
  }
  let response = await fetch(url, opts);
  if (response.ok) {
    if (response.status === 204) { // no content
      return null;
    }
    return await response.json();
  }
  let err = Error(response.status);
  err.status = response.status;
  err.url = url;
  err.opts = opts;
  err.response = response;
  try {
    // some api errors have a JSON body, typically formatted like this:
    // {status_code: 400, message: 'invalid operation'}
    err.body = await response.json();
  } catch (e) {
    // body must not be valid.
  }
  throw err;
}
