import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Gravatar from '../../components/Gravatar';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import HelpIcon from '@mui/icons-material/Help';
import SuperUserIcon from '@mui/icons-material/Whatshot';
import PasswordIcon from '@mui/icons-material/Security';
import { AuthStore, ClientStore } from '../../ducks';
import { connect } from 'react-redux';
import config from 'config/config';

const PREFIX = 'SessionControl';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  avatar: `${PREFIX}-avatar`,
  button: `${PREFIX}-button`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  menuIcon: `${PREFIX}-menuIcon`,
  bigIcon: `${PREFIX}-bigIcon`
};

const StyledToolbar = styled(Toolbar)((
  {
    theme
  }
) => ({
  [`&.${classes.toolbar}`]: {
    minHeight: 52,
  },

  [`& .${classes.avatar}`]: {
    height: 35,
    width: 35,
    marginRight: 10,
    color: '#fff',
    backgroundColor: '#333333',
  },

  [`& .${classes.button}`]: {
    color: '#fff',
    textTransform: 'none',
    fontWeight: 900,
  },

  [`& .${classes.menu}`]: {
    marginTop: '10px',
  },

  [`& .${classes.menuItem}`]: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: theme.palette.gray['100'],
    },
  },

  [`& .${classes.menuIcon}`]: {
    fontSize: '1.3rem',
    marginRight: '0.5rem',
  },

  [`& .${classes.bigIcon}`]: {
    fontSize: 25,
    marginRight: 10,
    borderRadius: 17.5,
    backgroundColor: '#333333',
  }
}));

class SessionControl extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    profile: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    isSuperUser: PropTypes.bool,
    clients: PropTypes.array,
    selectedClient: PropTypes.object,
  };

  state = {
    isOpen: false,
    anchorEl: null,
  };

  onOpen = (event) => {
    this.setState({
      isOpen: true,
      anchorEl: event.target,
    });
  };

  onClose = () => {
    this.setState({isOpen: false});
  };

  onLogout = () => {
    const { history } = this.props;
    history.push('/logout');
  };

  onChangePassword = () => {
    const { history } = this.props;
    history.push('/changepassword');
    this.setState({isOpen: false});
  };

  onSuperUser = () => {
    const { isSuperUser, profile, clients, selectedClient } = this.props;
    if (isSuperUser) {
      // as SuperUser we load additional clients from clients.all.items and append onto clients.items,
      // so we need to check against profile.roles to either retain selectedClient or select a new one.
      if (profile) {
        if (selectedClient && (selectedClient.client_id in profile.roles === false)) {
          this.props.dispatch(ClientStore.select(profile.default_client));
        } // else do nothing i.e. retain selectedClient
      } else {
        this.props.dispatch(ClientStore.select(clients[0].client_id));
      }
      this.props.history.push('/prism');
    }
    this.setState({isOpen: false});
    this.props.dispatch(ClientStore.list(true)); // fix pollution caused by loadAdditionalClient
    this.props.dispatch(AuthStore.setSuperUser(!isSuperUser));
  };

  renderAvatar = () => {
    const { profile,  isSuperUser } = this.props;
    if (isSuperUser) {
      return (
        <SuperUserIcon
          className={classes.bigIcon}
          fontSize="inherit"
          color="secondary"
        />
      );
    }
    return (
      <Gravatar user={profile}/>
    );
  };

  render() {
    const { profile,  isSuperUser } = this.props;
    const suText = isSuperUser ? 'Leave SuperAdmin' : 'Become SuperAdmin';
    if (!profile) return null;
    return (
      <StyledToolbar classes={{root:classes.toolbar}}>
        <Button
          className={classes.button}
          aria-owns={this.state.anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.onOpen}
          color="inherit"
        >
          {this.renderAvatar()}
          {profile.nickname}
          <ArrowDropDown/>
        </Button>
        <Menu
          open={this.state.isOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.menu}
        >
          {profile.sysadmin &&
            <MenuItem
              key={'superuser'}
              onClick={this.onSuperUser}
              className={classes.menuItem}
            >
              <SuperUserIcon className={classes.menuIcon}/>
              {suText}
            </MenuItem>
          }
          <MenuItem
            key={'changepassword'}
            onClick={this.onChangePassword}
            className={classes.menuItem}
          >
            <PasswordIcon className={classes.menuIcon}/>
            Change Password
          </MenuItem>
          <MenuItem
            key={'help'}
            className={classes.menuItem}
            onClick={() => {
              window.open(config.links.HELP_CENTRE, '_blank');
              this.setState({ isOpen: false });
            }}
          >
            <HelpIcon className={classes.menuIcon} />
            Help
          </MenuItem>
          <MenuItem
            key={'logout'}
            onClick={this.onLogout}
            className={classes.menuItem}
          >
            <LogoutIcon className={classes.menuIcon}/>
            Sign Out
          </MenuItem>
        </Menu>
      </StyledToolbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isSuperUser: state.auth.isSuperUser,
    clients: state.clients.items,
    selectedClient: state.clients.selectedItem,
  };
};

export default connect(mapStateToProps)(
  (SessionControl)
);
