import { AuthForm, AuthFormGroup } from '../components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProgressBar from '../../../components/ProgressBar';
import PropTypes from 'prop-types';
import React from 'react';

const passwordRule = {
  minLength: 8,
  minGroups: 3,
  groups: [
    { re: /[a-z]+/, hint: 'lower case letters' },
    { re: /[A-Z]+/, hint: 'upper case letters' },
    { re: /[0-9]+/, hint: 'digits' },
    { re: /[^a-zA-Z0-9]+/, hint: 'special characters' },
  ]
};

const passwordScore = (password) => {
  const groups = passwordRule.groups.reduce((count, g) => g.re.test(password) ? count+1 : count, 0);
  const score =
   Math.min(groups, passwordRule.minGroups) / passwordRule.minGroups * 50 +
   Math.min(password.length, passwordRule.minLength) / passwordRule.minLength * 50;
  return score;
};

const passwordMessage = (password) => {
  if (password.length < passwordRule.minLength) {
    return `Must be at least ${passwordRule.minLength} characters.`;
  }
  const hints = passwordRule.groups.reduce((hints, g) => g.re.test(password) ? hints : [...hints, g.hint], []);
  if (hints.length) return 'Too weak, add ' + joinHints(hints) + '.';
  return undefined;
};

const joinHints = (hints) => {
  return hints.length === 1 ? hints[0] : [hints.slice(0,-1).join(', '), hints[hints.length-1]].join(' or ');
};

class ResetPasswordForm extends React.Component
{
  static propTypes = {
    title: PropTypes.node.isRequired,
    header: PropTypes.node,
    buttonText: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    username: PropTypes.string,
    onReset: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    homeLink: PropTypes.bool,
    alertText: PropTypes.string,
    requireOldPassword: PropTypes.bool.isRequired,
  }

  state = {
    loading: false,
    oldPassword: '',
    password1: '',
    password2: '',
    focus: 'oldPassword',
  }

  onActivate = async () => {
    this.setState({ loading: true });
    await this.props.onReset(this.state.password1, this.state.oldPassword);
    this.setState({ loading: false });
  }

  onFormFieldChange = (field, value) => {
    this.setState({[field]: value});
  }

  renderErrorDialog = () => {
    const errorText = this.props.errorText;
    return (
      <Dialog
        open={errorText !== undefined}
        onClose={this.props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Error Resetting Password'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const { oldPassword, password1, password2, loading, focus } = this.state;
    const { title, buttonText, header, username, homeLink, alertText, requireOldPassword } = this.props;
    const score1 = passwordScore(password1);
    const score2 = score1 < 100 || password2.length===0 ? 0 : (password1!==password2 ? 99 : 100);
    const valid = score1===100 && score2===100;
    const message1 = password1.length > 0 && score1 < 100 ? passwordMessage(password1) : undefined;
    const message2 = password2.length > 0 && score1===100 && password1!==password2 ? 'Passwords must match' : undefined;
    return (
      <AuthForm
        loading={loading}
        alertText={alertText}
        title={title}
        header={header}
        buttonText={buttonText}
        buttonAction={this.onActivate}
        buttonDisabled={!valid}
        homeLink={homeLink}>

        <AuthFormGroup
          label="Username"
          type="email"
          name="username"
          value={username}
          readOnly={true}
        />

        {requireOldPassword && 
          <AuthFormGroup
            label="Old Password"
            autoComplete="current-password"
            type="password"
            exposable={true}
            name="oldPassword"
            glyph="password"
            value={oldPassword}
            onChange={this.onFormFieldChange}
            focus={focus==='oldPassword'}
            onEnter={() => this.setState({focus: 'password1'})}
          />
        }

        <AuthFormGroup
          label="New Password"
          autoComplete="new-password"
          type="password"
          exposable={true}
          name="password1"
          glyph="password"
          value={password1}
          onChange={this.onFormFieldChange}
          focus={focus==='password1'}
          onEnter={() => this.setState({focus: 'password2'})}
        />
        <ProgressBar value={score1} complete={score1===100} message={message1}/>

        <AuthFormGroup
          label="Repeat Password"
          autoComplete="new-password"
          type="password"
          exposable={true}
          name="password2"
          glyph="password"
          value={password2}
          onChange={this.onFormFieldChange}
          focus={focus==='password2'}
          onEnter={valid ? this.onActivate : null}
        />
        <ProgressBar value={score2} complete={score2===100} message={message2}/>
        {this.renderErrorDialog()}
      </AuthForm>
    );
  }
}

export default ResetPasswordForm;
