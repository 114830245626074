import React from 'react';
import PropTypes from 'prop-types';
import {LoadingStore} from '../../../ducks';
import {AreasAPI} from '../../../api';
import {useDispatch,useSelector} from 'react-redux';
import Confirmation from '../../vehicles/components/Confirmation';

const AreasDeleteDialog = ({open, setOpen, refreshCustomAreasList, id}) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const handleDelete = async () => {
    dispatch(LoadingStore.loading(true    ));
    try {
      await AreasAPI.deleteCustomArea(clientId, id);
    } catch (error) {
      console.log(error);
    } finally {
      refreshCustomAreasList();
      dispatch(LoadingStore.loading(false));
      handleClose();

    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Confirmation
      show={open}
      title="Delete this custom area?"
      text="Deleting a custom area is a permanent operation and it cannot be undone!"
      onCancel={handleClose}
      onAction={handleDelete}
    />
  );
};


AreasDeleteDialog.propTypes = {
  open :  PropTypes.bool,
  setOpen: PropTypes.func,
  id: PropTypes.string,
  refreshCustomAreasList: PropTypes.func
};


export default AreasDeleteDialog;