import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import TextField from '@mui/material/TextField';

const PREFIX = 'TripInclusionSettingsPanel';

const classes = {
  root: `${PREFIX}-root`,
  block: `${PREFIX}-block`,
  subtitle: `${PREFIX}-subtitle`,
  row: `${PREFIX}-row`,
  label: `${PREFIX}-label`,
  select: `${PREFIX}-select`
};

const StyledSettingsPanel = styled(SettingsPanel)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  [`& .${classes.block}`]: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.subtitle}`]: {
    margin: '-0.5rem 0 1rem 0',
    padding: 0,
    fontSize: '0.9rem',
    fontWeight: 600,
  },

  [`& .${classes.row}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1rem',
    borderTop: `1px solid ${theme.palette.gray[100]}`,
  },

  [`& .${classes.label}`]: {
    fontSize: '0.9rem',
  },

  [`& .${classes.select}`]: {
    minWidth: '6rem',
  }
}));

const TripInclusionSettingsPanel = ({ client, onSave }) => {


  const initialData = {
    'trip_inclusion_max_idle_mins': client.trip_inclusion_max_idle_mins,
    'trip_inclusion_max_idle_percentage': client.trip_inclusion_max_idle_percentage,
    'trip_inclusion_min_total_mins': client.trip_inclusion_min_total_mins,
    'trip_inclusion_min_km': client.trip_inclusion_min_km,
    'trip_inclusion_max_speed_kmh': client.trip_inclusion_max_speed_kmh,
    'trip_inclusion_min_speed_kmh': client.trip_inclusion_min_speed_kmh,
  };
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState(initialData);

  const toggleExpanded = () => setExpanded(!expanded);

  const onValueChange = (field, value) => {
    const newValue = value === '' ? null : value;
    setData({...data, [field]: newValue});
  };

  const summary = () => {
    const totalItems = Object.keys(data).length;
    const setItems = Object.values(data).filter(value => value !== null).length;
    return setItems + ' items set of ' + totalItems + ' available';
  };

  return (
    <StyledSettingsPanel
      className={classes.root}
      title="Trip Inclusion Threshold Settings"
      summary={summary()}
      onSave={() => {
        onSave(data);
        setExpanded(false);
      }}
      onCancel={() => {
        setData(initialData);
        setExpanded(false);
      }}
      onExpand={toggleExpanded}
      expanded={expanded}
    >
      <div className={classes.block}>
        <h2 className={classes.subtitle}>
          These constraints limit which trip records are included in Prism calculations. If left blank, no contraint is applied.
        </h2>
        <div className={classes.row}>
          <p className={classes.label}>1. Maximum total idle time, in minutes, allowed during a trip to include it. This can be used to remove exclude any trips with excessive idling.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_inclusion_max_idle_mins || ''}
            onChange={(e)=>onValueChange('trip_inclusion_max_idle_mins', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>2. Maximum idle time, as a percentage of total time, allowed during a trip to include it. This can be used to exclude any trips with disproportionate idling.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Numeric value between 0 and 100"
            value={data.trip_inclusion_max_idle_percentage || ''}
            onChange={(e)=>onValueChange('trip_inclusion_max_idle_percentage', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>3. Minimum total time (idle plus travel), in minutes, required during a trip to include it. This can be used to exclude very short trips.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_inclusion_min_total_mins || ''}
            onChange={(e)=>onValueChange('trip_inclusion_min_total_mins', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>4. Minimum total distance, in KM, required during a trip to include it. This can be used to exclude very short trips.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_inclusion_min_km || ''}
            onChange={(e)=>onValueChange('trip_inclusion_min_km', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>5. Maximum speed recorded during a trip, in KM/H, to include it.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_inclusion_max_speed_kmh || ''}
            onChange={(e)=>onValueChange('trip_inclusion_max_speed_kmh', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>6. Minimum speed recorded during a trip, in KM/H, to include it. Setting this to any value greater that 0 excludes all idle-only trips.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_inclusion_min_speed_kmh || ''}
            onChange={(e)=>onValueChange('trip_inclusion_min_speed_kmh', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
    </StyledSettingsPanel>
  );
};

TripInclusionSettingsPanel.propTypes = {
  client: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TripInclusionSettingsPanel;
