import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import { industryMap } from './Industries';
import PropTypes from 'prop-types';

export default function IndustrySelector({ industry, onChange }) {
  const sep = '////';

  const [firstAndSecondLevel, setFirstAndSecondLevel] = useState(
    industry ? `${industry.anzsic?.level_1}${sep}${industry.anzsic?.level_2}` : ''
  );
  const [thirdLevel, setThirdLevel] = useState(
    industry?.anzsic?.level_3 ?? ''
  );
  const [open, setOpen] = React.useState(false);

  const getSelectedFirstLevel = () => firstAndSecondLevel.split(sep)[0];

  const getSelectedSecondLevel = () => firstAndSecondLevel.split(sep)[1];

  const filteredIndustries = () =>
    industryMap[getSelectedFirstLevel()]?.[getSelectedSecondLevel()] ?? [];

  const renderGroup = (firstLevel) => {
    const items = Object.keys(industryMap[firstLevel]).map((secondLevel) => (
      <MenuItem
        key={`${firstLevel}-${secondLevel}`}
        value={`${firstLevel}${sep}${secondLevel}`}
      >
        {secondLevel}
      </MenuItem>
    ));
    return [
      <ListSubheader key={firstLevel}>{firstLevel}</ListSubheader>,
      items,
    ];
  };

  const onIndustryGroupChange = (e) => {
    setFirstAndSecondLevel(e.target.value);
    setThirdLevel('');
    setTimeout(() => setOpen(true), 50);
  };

  const onIndustryChange = (e) => {
    setThirdLevel(e.target.value);
    onChange({
      anzsic: {
        level_1: getSelectedFirstLevel(),
        level_2: getSelectedSecondLevel(),
        level_3: e.target.value,
      },
    });
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 250 }}>
        <InputLabel htmlFor="grouped-industry">Industry group</InputLabel>
        <Select
          id="grouped-industry"
          label="Industry group"
          onChange={onIndustryGroupChange}
          value={firstAndSecondLevel}
        >
          {Object.keys(industryMap).map((firstLevel) =>
            renderGroup(firstLevel)
          )}
        </Select>
      </FormControl>
      {filteredIndustries().length > 0 && (
        <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 250 }} focused={open}>
          <InputLabel htmlFor="industry">Industry*</InputLabel>
          <Select
            id="industry"
            label="Industry*"
            value={thirdLevel}
            onChange={onIndustryChange}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            error={!thirdLevel}
          >
            {filteredIndustries().map((industry) => (
              <MenuItem key={industry} value={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
IndustrySelector.propTypes = {
  onChange: PropTypes.func,
  industry: PropTypes.object,
};
