import React, { Suspense } from 'react';
import { Route } from 'react-router';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/header';
import Footer from './components/Footer';
import Analytics from './components/Analytics';
import Page from './components/Page';
import { Provider } from 'react-redux';
import store from './ducks';
import { persistor } from './ducks';
import { PersistGate } from 'redux-persist/integration/react';
import modules from './modules';
import Loading from './components/Loading';
import * as Sentry from '@sentry/react';
import SentryUserManager from './components/SentryUserManager';
import { LicenseInfo } from '@mui/x-license-pro';
import config from 'config/config';
import { addErrorHandler } from 'api/http';
import { createBrowserHistory } from 'history';

import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import './App.css';
import './themes/DefaultTheme.css';
import './themes/PublicTheme.css';
import './themes/AdminTheme.css';

LicenseInfo.setLicenseKey(config.settings.MUI_LICENCE_KEY);
// Log the user out on 403 unauthorized
addErrorHandler(403, () => {
  createBrowserHistory().push('/logout');
  window.location.reload();
  return true;
});

class App extends React.Component {

  render() {
    return ( <Route render={({location, history}) => {
      return (
        <Provider store={store}>
          <Analytics history={history}>
            <PersistGate loading={<div>Wait</div>} persistor={persistor}>
              <ErrorBoundary history={history}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                  <Suspense fallback={<Loading force={true}/>}>
                    <Header location={location} history={history} />
                    <Page>
                      {modules.map((module) => (
                        <Route
                          key={module.path}
                          path={module.path}
                          exact={module.exact}
                          render={(routerProps) => {
                            const Component = module.component;
                            return (
                              <Component key={module.key} {...this.props} {...routerProps} module={module} />
                            );
                          }}
                        />
                      ))}
                    </Page>
                    <Footer />
                    <Loading/>
                  </Suspense>
                </LocalizationProvider>
              </ErrorBoundary>
            </PersistGate>
          </Analytics>
          <SentryUserManager />
        </Provider>
      );}}/>);
  }
}

export default Sentry.withProfiler(App);
