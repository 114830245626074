import { request, makePath } from './http';
const API_NAME = 'sources';

export function getExternalFiles(params) {
  return request('GET', makePath(API_NAME, 'external_files'), {params});
}

export function getSystems() {
  return request('GET', makePath(API_NAME, 'system'));
}

export function getSubscriptions(clientId) {
  return request('GET', makePath(API_NAME, 'client', clientId, 'subscription'));
}

export function findJobs(params) {
  return request('GET', makePath(API_NAME, 'jobs'), {params});
}

export function getJobs(clientId, subscriptionId) {
  return request('GET', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId));
}

export function restartJob(clientId, subscriptionId, jobId) {
  return request('POST', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId, 'job', jobId, 'restart'));
}

export function patchSubscription(clientId, subscriptionId, patch) {
  return request('PATCH', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId), {body: patch});
}

export function getSubscription(clientId, subscriptionId, params) {
  return request('GET', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId), {params});
}

export function postSubscription(clientId, subscription) {
  return request('POST', makePath(API_NAME, 'client', clientId, 'subscription'), {body: subscription});
}

export function deleteSubscription(clientId, subscriptionId) {
  return request('DELETE', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId));
}

export function getCredentials(clientId, subscriptionId) {
  return request('GET', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId, 'credentials'));
}

export function putCredentials(clientId, subscriptionId, credentials) {
  return request('PUT', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId, 'credentials'), {body: credentials});
}

export function testSubscription(clientId, subscriptionId) {
  return request('POST', makePath(API_NAME, 'client', clientId, 'subscription', subscriptionId, 'verify'));
}
