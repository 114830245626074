/*
 * derived from https://raw.githubusercontent.com/TeamWertarbyte/material-ui-search-bar/master/src/components/SearchBar/SearchBar.js
 * Primarily modified styling.
 */

import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';

const PREFIX = 'SearchBar';

const classes = {
  root: `${PREFIX}-root`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonHidden: `${PREFIX}-iconButtonHidden`,
  iconButtonDisabled: `${PREFIX}-iconButtonDisabled`,
  icon: `${PREFIX}-icon`,
  input: `${PREFIX}-input`,
  searchContainer: `${PREFIX}-searchContainer`
};

const StyledPaper = styled(Paper)({
  [`&.${classes.root}`]: {
    height: 36,
    marginRight: 16,
    display: 'flex',
    justifyContent: 'space-between'
  },
  [`& .${classes.iconButton}`]: {
    padding: 0,
    width: 36,
    height: 36,
    opacity: 0.54,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  [`& .${classes.iconButtonHidden}`]: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0
    }
  },
  [`& .${classes.iconButtonDisabled}`]: {
    opacity: 0.38
  },
  [`& .${classes.icon}`]: {
    opacity: 0.54,
    transition: 'opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  [`& .${classes.input}`]: {
    width: '100%'
  },
  [`& .${classes.searchContainer}`]: {
    margin: 'auto 16px',
    width: '100%'
  }
});

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/guidelines/patterns/search.html)
 */
class SearchBar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      focus: false,
      value: this.props.value,
      active: false
    };
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({...this.state, value: nextProps.value});
    }
  }

  handleFocus = (e) => {
    this.setState({focus: true});
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleBlur = (e) => {
    this.setState({focus: false});
    if (this.state.value.trim().length === 0) {
      this.setState({value: ''});
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleInput = (e) => {
    this.setState({value: e.target.value});
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  handleCancel = () => {
    this.setState({active: false, value: ''});
    if (this.props.onCancelSearch) {
      this.props.onCancelSearch();
    }
    if (this.props.onChange) {
      this.props.onChange('');
    }
  }

  handleKeyUp = (e) => {
    if (this.props.onRequestSearch && (e.charCode === 13 || e.key === 'Enter')) {
      this.props.onRequestSearch(this.state.value);
    } else if (this.props.cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
      this.handleCancel();
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  renderButton() {
    const { value } = this.state;
    const {
      disabled,
      onRequestSearch,
      searchIcon,
      closeIcon,
    } = this.props;

    let handler;
    let icon;

    if (value === '') {
      handler = onRequestSearch;
      icon = searchIcon;
    } else {
      handler = this.handleCancel;
      icon = closeIcon;
    }

    return (
      <IconButton
        size="small"
        onClick={handler}
        classes={{
          root: classNames(classes.iconButton),
          disabled: classes.iconButtonDisabled
        }}
        disabled={disabled}
      >
        {React.cloneElement(icon, {
          classes: { root: classes.icon }
        })}
      </IconButton>
    );
  }

  render () {
    const { value } = this.state;
    const {
      disabled,
      style,
      /* avoid sending these into the inputProps */
      onRequestSearch,  // eslint-disable-line no-unused-vars
      searchIcon,  // eslint-disable-line no-unused-vars
      closeIcon,  // eslint-disable-line no-unused-vars
      ...inputProps
    } = this.props;

    return (
      <StyledPaper
        className={classes.root}
        style={style}
      >
        <div className={classes.searchContainer}>
          <Input
            {...inputProps}
            onBlur={this.handleBlur}
            value={value}
            onChange={this.handleInput}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleFocus}
            fullWidth
            className={classes.input}
            disableUnderline
            disabled={disabled}
          />
        </div>
        {this.renderButton()}

      </StyledPaper>
    );
  }
}

SearchBar.defaultProps = {
  className: '',
  closeIcon: <ClearIcon style={{ color: grey[500] }} />,
  disabled: false,
  placeholder: 'Search',
  searchIcon: <SearchIcon style={{ color: grey[500] }} />,
  style: null,
  value: ''
};

SearchBar.propTypes = {
  /** Whether to clear search on escape */
  cancelOnEscape: PropTypes.bool,
  /** Custom top-level class */
  className: PropTypes.string,
  /** Override the close icon. */
  closeIcon: PropTypes.node,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Fired when the search is cancelled. */
  onCancelSearch: PropTypes.func,
  /** Fired when the text value changes. */
  onChange: PropTypes.func,
  /** Fired when text box gets focus. */
  onFocus: PropTypes.func,
  /** Fired when text box loses focus. */
  onBlur: PropTypes.func,
  /** Fired on key up. */
  onKeyUp: PropTypes.func,
  /** Fired when the search icon is clicked. */
  onRequestSearch: PropTypes.func,
  /** Sets placeholder text for the embedded text field. */
  placeholder: PropTypes.string,
  /** Override the search icon. */
  searchIcon: PropTypes.node,
  /** Override the inline-styles of the root element. */
  style: PropTypes.object,
  /** The value of the text field. */
  value: PropTypes.string
};

export default (SearchBar);
