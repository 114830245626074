import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

const PREFIX = 'ActivityBarChart';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '35rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  }
}));

const colors = ['#6366F1', '#10B981', '#FBBF24', '#EF4444', '#6B7280'];

const ActivityBarChart = ({ data, indexKey, barKeys, yAxisUnits }) => {

  if (data === null || data === undefined) return null;
  return (
    <Root className={classes.root}>
      <ResponsiveBar
        data={data}
        keys={barKeys}
        indexBy={indexKey}
        margin={{ top: 50, right: 130, bottom: 100, left: 80 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={colors}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisUnits,
          legendPosition: 'middle',
          legendOffset: -60
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 20,
            itemWidth: 120,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        role="application"
        // ariaLabel="Nivo bar chart demo"
        // barAriaLabel={function(e){return e.id+': '+e.formattedValue+' in country: '+e.indexValue;}}
      />
    </Root>
  );
};

ActivityBarChart.propTypes = {
  data: PropTypes.array,
  indexKey: PropTypes.string.isRequired,
  barKeys: PropTypes.array.isRequired,
  yAxisUnits: PropTypes.string.isRequired,
};

export default ActivityBarChart;
