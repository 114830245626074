import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FuelAPI } from '../../../api';
import FuelInput from '../components/FuelInput';
import moment from 'moment';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

const PREFIX = 'FuelRecords';

const classes = {
  container: `${PREFIX}-container`,
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  menu: `${PREFIX}-menu`,
  menuClosed: `${PREFIX}-menuClosed`,
  menuItem: `${PREFIX}-menuItem`,
  content: `${PREFIX}-content`,
  tooltip: `${PREFIX}-tooltip`,
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    paddingTop: '1px',
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#333333',
    height: '100%',
  },

  [`& .${classes.title}`]: {
    marginLeft: '1rem',
    fontSize: '1rem',
  },

  [`& .${classes.tooltip}`]: {
    marginTop : '0.3rem',
  },

  [`& .${classes.drawerPaper}`]: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 'auto',
  },

  [`& .${classes.drawerPaperClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerClosedWidth-drawerOpenWidth,
  },

  [`& .${classes.menu}`]: {
    padding: 0,
    marginLeft: 0,
    height: '100%',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuClosed}`]: {
    marginLeft: drawerClosedWidth-drawerOpenWidth,
    padding: '50px',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.menuItem}`]: {
    paddingTop: '12px',
    paddingBottom: '12px',
    fontSize: '0.75rem',
  },

  [`&.${classes.content}`]: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: 0,
  }
}));

class FuelRecords extends React.Component {

  static propTypes = {
    clientId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    fuelSummary: undefined,
    fuelData: undefined,
    fuelMonthStartDate: undefined,
    selectedMenuItem: 0,
    drawerOpen: true,
  };

  componentDidMount() {
    this.reload();
  }

  render() {
    const {  clientId } = this.props;
    const { fuelMonthStartDate, fuelSummary, fuelData } = this.state;
    return (
      <StyledContainer className={classes.container}>
        {this.menu()}
        {(fuelMonthStartDate !== undefined && fuelSummary !== undefined && fuelData !== undefined) ? (
          <div className={classes.content}>
            <FuelInput clientId={clientId} fuelMonthStartDate={fuelMonthStartDate}
              fuelSummary={fuelSummary} fuelData={fuelData} reload={this.reload}
            />
          </div>
        ) : (
          // TODO: show a Loading animation rather than <div>&nbsp;</div>
          <div className={classes.content}>&nbsp;</div>
        )}
      </StyledContainer>
    );
  }

  selectMenuItem(event, index) {
    this.setState({selectedMenuItem: index, fuelMonthStartDate: this.state.fuelSummary.summary[index].month_start_date});
    this.reload();
  }

  // TODO: extract out Menu logic into its own Component for easy and consistent re-use / inject children into LeftNav
  menu() {
    const { fuelSummary, fuelMonthStartDate, drawerOpen } = this.state;

    if ((fuelSummary === undefined) || (fuelMonthStartDate === undefined)) {
      // TODO: show a Loading animation rather than <div>&nbsp;</div>
      return (
        <div className={classes.menu}>&nbsp;</div>
      );
    }

    return (
      <Paper className={classes.menu}>
        <Drawer variant="permanent" open={drawerOpen}
          classes={{paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)}}
        >

          <div className={classes.toolbar}>
            <span className={classes.title}>Monthly Fuel Records</span>

            {drawerOpen ? (
              <IconButton onClick={this.closeDrawer} size="large">
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={this.openDrawer} size="large">
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>

          <List component="nav" alignitems="flex-start" disablePadding={true} className={classNames(classes.menu, !drawerOpen && classes.menuClosed)}>
            <Divider />
            {this.listItems()}
          </List>
        </Drawer>
      </Paper>
    );

  }

  listItems() {
    const { fuelSummary } = this.state;
    return fuelSummary.summary.map((month, index) => {
      const activeVehiclesWithoutFuel = month.count_active - month.count_client_fuel;
      const tooltip = 'Active Vehicles without Fuel = ' + activeVehiclesWithoutFuel;

      if (activeVehiclesWithoutFuel > 0) {
        return (
          <ListItemButton
            alignItems="flex-start"
            divider={true}
            key={month.month_start_date}
            className={classes.tooltip}
            onClick={event => this.selectMenuItem(event, index)}
          >
            <Tooltip title={tooltip} >
              <Badge color="secondary"  badgeContent={activeVehiclesWithoutFuel}>
                <ListItemText primary={moment(month.month_start_date).format('MMM YYYY')}/>
              </Badge>
            </Tooltip>
          </ListItemButton>
        );

      } else {
        return (
          <ListItemButton
            alignItems="flex-start"
            divider={true}
            key={month.month_start_date}
            onClick={event => this.selectMenuItem(event, index)}
          >
            <ListItemText primary={moment(month.month_start_date).format('MMM YYYY')}/>
          </ListItemButton>
        );
      }

    });
  }

  openDrawer = () => {
    this.setState({ drawerOpen: true });
  };

  closeDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  reload = () => {
    const { clientId } = this.props;
    FuelAPI.summary(clientId).then(
      response => {this.setState({fuelSummary: response.payload, fuelMonthStartDate: response.payload.summary[this.state.selectedMenuItem].month_start_date});
        FuelAPI.get(clientId, response.payload.summary[this.state.selectedMenuItem].month_start_date).then((response) => {
          this.setState({ fuelData: response.payload });}
        );
      });
  };

}

const drawerOpenWidth = 250;
const drawerClosedWidth = 50;

export default ((FuelRecords));
