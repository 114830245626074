import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import moment from 'moment';
import config from 'config/config';

const PREFIX = 'Title';

const classes = {
  form: `${PREFIX}-form`,
  section: `${PREFIX}-section`
};

const Root = styled('form')(() => ({
  [`&.${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    color: config.isBarton ? '#053573' : '#42489e',
    maxWidth: 1200,
  },

  [`& .${classes.section}`]: {
    display: 'flex',
    flexDirection: 'column',
  }
}));

export const Title = ({children, level=1}) => {
  const Tag = `h${level}`;
  return <Tag>{children}</Tag>;
};
Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
};

const _Section = ({title, children}) => (
  <section className={classes.section}>
    <Title level={2}>{title}</Title>
    {children}
  </section>
);
_Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export const Section = (_Section);

// nullTransform does not transforming and all values are valid.
const nullTransform = {
  toString: (nativeValue) => (nativeValue === null || nativeValue === undefined) ? '' : nativeValue,
  fromString: (strValue) => strValue,
};

const dateTransform = {
  toString: (nativeValue) => (nativeValue === '' || nativeValue === null || nativeValue === undefined) ? '' : moment(nativeValue).format('YYYY-MM-DD'),
  fromString: (strValue) =>  strValue ? strValue + 'T00:00:00+00:00' : '',
};

const textFieldStyle = {
  margin: 10,
  width: 220,
};

export const spanTextFieldStyle = (span) => {
  const { margin, width } = textFieldStyle;
  // span == 1 is just width.  add width + 2 x margin for each additional span.
  const newWidth = width + (span - 1) * (width + 2 * margin);
  return { margin, width: newWidth };
};

export const TextInput = ({label, value, field, readOnly, units, required, onChange,disabled=false, transform=nullTransform, span=1, rows=1, ...formControlProps}) => (
  <TextField
    {...formControlProps}
    style={spanTextFieldStyle(span)}
    label={label}
    disabled={disabled}
    multiline={rows>1}
    value={transform.toString(value[field])}
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      disabled: readOnly,
      endAdornment: units ? (<InputAdornment position="end">{units}</InputAdornment>) : undefined
    }}
    required={required}
    onChange={onChange ? (event) => onChange(field, event.target.value) : null}
    rows={rows}
  />
);
TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  field: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  units: PropTypes.string,
  disabled:PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  transform: PropTypes.object,
  span: PropTypes.number,
  rows: PropTypes.number,
};

export class ComboInput extends React.Component {

  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.object,
    values: PropTypes.array.isRequired,
    field: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    units: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    transform: PropTypes.object,
  };

  state = {
    menuOpen: false,
  }

  anchorRef = React.createRef()

  toggleMenu = () => {
    this.setState(state => ({
      menuOpen: !state.MenuOpen
    }));
  }

  render() {
    // NB - be careful not to let onSelect pass through to the formControlProps.
    // If you do, it will be called unexpectedly.
    const { label, value, values, field, readOnly, required, onChange, onSelect, transform=nullTransform, ...formControlProps } = this.props;
    const { menuOpen } = this.state;
    const onClick = (item) => () => {
      onSelect(item);
      this.setState({ menuOpen: false});
    };
    return (
      <div>
        <TextField
          {...formControlProps}
          style={textFieldStyle}
          label={label}
          value={transform.toString(value[field])}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disabled: readOnly,
            endAdornment: (
              <ArrowDropDownIcon
                onClick={this.toggleMenu}
                style={{color: 'rgba(0,0,0,0.54)'}}
              />)
          }}
          required={required}
          onChange={onChange ? (event) => onChange(field, event.target.value) : null}
          inputRef={this.anchorRef}
        />
        <Menu
          open={menuOpen}
          anchorEl={this.anchorRef.current}
          onClose={() => this.setState({menuOpen: false})}
        >
          {values.map((v,i) => <MenuItem key={i} onClick={onClick(v)}>{v.label}</MenuItem>)}
        </Menu>
      </div>
    );
  }
}

export const DateInput = ({label, value, field, readOnly, required, onChange,span =1,disabled=false, transform=dateTransform, ...formControlProps}) => (
  <TextField
    {...formControlProps}
    type="date"
    style={spanTextFieldStyle(span)}
    label={label}
    value={transform.toString(value[field])}
    readOnly={readOnly}
    disabled={disabled}
    required={required}
    onChange={onChange ? (event) => onChange(field, transform.fromString(event.target.value)) : null}
    InputLabelProps={{
      shrink: true,
    }}
  />
);
DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  span: PropTypes.number,
  value: PropTypes.object,
  field: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  transform: PropTypes.object,
};

export const SelectInput = ({label, value, field, values, readOnly, required, onChange, disabled=false,span=1, transform=nullTransform, ...formControlProps}) => (
  <TextField
    select
    {...formControlProps}
    style={spanTextFieldStyle(span)}
    label={label}
    value={transform.toString(value[field])}
    readOnly={readOnly}
    disabled={disabled}
    required={required}
    onChange={onChange ? (event) => onChange(field, event.target.value) : null}
    InputLabelProps={{
      shrink: true,
    }}
  >
    {values.map(v => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
  </TextField>
);

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  values: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  span: PropTypes.number,
  onChange: PropTypes.func,
  transform: PropTypes.object,
};

export const CheckboxInput = ({label, checked=false, value, readOnly=false, onChange}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        onChange={(event) => onChange(value, event.target.checked)}
        disabled={readOnly}
      />
    }
    label={label}
  />
);

CheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const Form = ({children}) => (
  <Root className={classes.form}>
    {children}
  </Root>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

export default (Form);
