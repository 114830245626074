import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import ReloadIcon from '@mui/icons-material/Autorenew';
import { FabActionButton } from '../../../components/ActionButton';

const PREFIX = 'CreateToolbar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarGroup: `${PREFIX}-toolbarGroup`,
  titleText: `${PREFIX}-titleText`,
  messageText: `${PREFIX}-messageText`,
  button: `${PREFIX}-button`
};

const Root = styled('div')(() => ({
  [`&.${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '40px',
    paddingRight: '40px',
    minHeight: '80px',
    backgroundColor: '#ffffff',
  },

  [`& .${classes.toolbarGroup}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.titleText}`]: {
    color: 'rgba(0,0,0,0.8)',
    fontSize: '1rem',
    fontWeight: 800,
  },

  [`& .${classes.messageText}`]: {
    color: 'rgba(0,0,0,0.54)',
    fontSize: '0.75rem',
    fontWeight: 700,
  },

  [`& .${classes.button}`]: {
    float: 'right',
    marginLeft: '5px',
    fontWeight: 900,
  }
}));

const CreateToolbar = ({  title, message, onNew, onRefresh, toolTip }) => (
  <Root className={classes.toolbar}>
    <div className={classes.toolbarGroup}>
      <span className={classes.titleText}>{title}</span>
    </div>
    <div className={classes.toolbarGroup}>
      <span className={classes.messageText}>{message}</span>
    </div>
    <div className={classes.toolbarGroup}>
      {onRefresh && (
        <FabActionButton
          size="small"
          onClick={onRefresh}
          tooltip="Refresh"
          className={classes.button}
          color="default"
          Icon={ReloadIcon}
        />
      )}
      {onNew && (
        <FabActionButton
          size="small"
          onClick={onNew}
          tooltip={toolTip}
          className={classes.button}
          color="secondary"
          Icon={AddIcon}
        />
      )}
    </div>
  </Root>
);

CreateToolbar.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onNew: PropTypes.func,
  onRefresh: PropTypes.func,
  toolTip: PropTypes.string.isRequired,
};

export default (CreateToolbar);
