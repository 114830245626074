import AuditReports from './containers/AuditReports';
import withRole from '../../hocs/withRole';

export default [
  {
    key: 1,
    path: '/auditreports',
    exact: false,
    component: withRole(['Vehicle Admin', 'Client Admin', 'Client View'], AuditReports),
    module: 'reports'
  }
];
