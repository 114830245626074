import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';


const  SentryUserManager = () => {
  const profile = useSelector((state) => state.auth.profile);
  const clientId = useSelector((state) => state.clients.selectedItemId);
  if (profile) {
    Sentry.setUser({
      email: profile.email ?? undefined,
      username: profile.nickname ?? undefined,
      id: profile.user_id ?? undefined,
    });
  } else {
    Sentry.setUser(null);
  }

  if (clientId) {
    Sentry.setContext('client',{
      'clientId': clientId
    });}
};

SentryUserManager.propTypes = {
  profile: PropTypes.object
};

export default SentryUserManager;
