import { AuthHeader, PublicPage, ResetPasswordForm } from '../components';

import { LoadingStore } from '../../../ducks';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserAPI } from '../../../api';
import { connect } from 'react-redux';
import queryString from 'qs';
import { withRouter } from 'react-router';

const validateString = (s, length) =>
  (typeof(s) === 'string' || s instanceof String) && s.length === length;

const errorMessages = {
  'Token has expired': 'This link has expired.  Please request a new activation token.',
  'Invalid token': 'This link is not valid.  Please request a new activation token.',
  'Token already used': 'This link has already been used.',
};
const defaultErrorText = 'An unexpected error has occurred processing your request.';


class ResetPassword extends React.Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    token: undefined,
    userId: undefined,
    errorText: undefined,
    username: undefined,
    redirect: false,
  }

  componentDidMount() {
    const args = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});
    const { user, token } = args;
    const pathName = this.props.location.pathname;
    const valid = (
      validateString(user, 8) &&
      validateString(token, 32)
    );
    if (!valid) {
      this.setState({errorText: 'Invalid activation link.'});
      return;
    }

    this.setState({userId: user, token, pathName});

    UserAPI.validateToken(user, token)
      .then((response) => {
        this.setState({username: response.payload.email});
      })
      .catch((err) => {
        const errorType = err.body ? err.body.message : 'Unknown error';
        const errorText = errorMessages[errorType] || defaultErrorText;
        this.setState({errorText});
      });
  }

  onReset = (password) => {
    const { userId, token } = this.state;
    this.props.dispatch(LoadingStore.loading(true));
    UserAPI.resetPassword(userId, token, password)
      .then(() => {
        this.props.dispatch(LoadingStore.loading(false));
        this.setState({redirect: true});
      })
      .catch((err) => {
        this.props.dispatch(LoadingStore.loading(false));
        this.showError(err.body ? err.body.message : 'Unknown error');
      });
  }

  onCancel = () => {
    this.setState({redirect: true});
  }

  render() {
    const { redirect, username, errorText, pathName } = this.state;
    const titleText = pathName === '/activate' ? 'activate_title' : 'password_reset_title';
    const buttonText = pathName === '/activate' ? 'activate_button' : 'password_reset_button';
    const title = <AuthHeader title={titleText}/>;
    if (redirect) return <Redirect to="/login"/>;

    return (
      <PublicPage>
        <ResetPasswordForm
          title={title}
          buttonText={buttonText}
          username={username}
          errorText={errorText}
          onReset={this.onReset}
          onCancel={this.onCancel}
          requireOldPassword={false}
        />
      </PublicPage>
    );
  }
}

export default connect()(withRouter(ResetPassword));
