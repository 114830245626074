import Auth from './auth';
import Menu from './menu';
import TripViewer from './tripviewer';
import Analytics from './analytics';
import Vehicles from './vehicles';
import AuditReports from './auditreports/reports';
import FTCReports from './ftcreports/reports';
import ClientAdmin from './clientadmin';
import SuperAdmin from './superadmin';
import Fuel from './fuel';
import ReleaseNotes from './releasenotes';
import Signup from './signup';
import Areas from './areas';
import AccountHealth from './accounthealth';
import VehicleConfiguration from './vehicleconfiguration';
import Invoices from './invoices';
import Reports from './reports';

export default [
  ...Vehicles,
  ...AuditReports,
  ...FTCReports,
  ...TripViewer,
  ...Analytics,
  ...Menu,
  ...Auth,
  ...ClientAdmin,
  ...SuperAdmin,
  ...Fuel,
  ...ReleaseNotes,
  ...Signup,
  ...Areas,
  ...AccountHealth,
  ...VehicleConfiguration,
  ...Invoices,
  ...Reports,
];
