import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames'; import {styled} from '@mui/material/styles';

const PREFIX = 'ActionButton';

const classes = {
  button: `${PREFIX}-button`,
  iconWithText: `${PREFIX}-iconWithText`,
  lightTooltip: `${PREFIX}-lightTooltip`,
  iconWithoutText: `${PREFIX}-iconWithoutText`,
};


const StyledTooltip = styled(Tooltip)((  {theme}) => ({
  [`& .${classes.button}`]: {
    float: 'right',
    marginLeft: '0.5rem',
    fontWeight: 600,
    fontSize: '0.8rem',
    textTransform: 'capitalize',
  },
  [`& .${classes.iconWithText}`]: {
    marginLeft: theme.spacing(),
  },
  [`& .${classes.iconWithoutText}`]: {
  },
  [`& .${classes.lightTooltip}`]: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  }
}));



const ActionButton = ({Component=Button, Icon=null, label=null, tooltip, className, ...buttonProps}) =>
  (
    <StyledTooltip title={tooltip} classes={{ tooltip: classes.lightTooltip }}>
      <Component
        className={classNames(classes.button, className)}
        {...buttonProps}
      >
        {label}
        <Icon className={label ? classes.iconWithText : classes.iconWithoutText}/>
      </Component>
    </StyledTooltip>
  );

const FabActionButtonComponent = (props) =>
  (
    <ActionButton Component={Fab} {...props}/>
  );

ActionButton.propTypes = {
  Icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  label: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  Component: PropTypes.any,
  className: PropTypes.string,
};

const FabActionButton = FabActionButtonComponent;
export {
  FabActionButton
};
export default ActionButton;
