import React from 'react';
import { styled } from '@mui/material/styles';
import StreetviewIcon from '@mui/icons-material/Streetview';
import SatelliteIcon from '@mui/icons-material/Satellite';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
const PREFIX = 'MapViewToggle';

const classes = {
  viewToggleContainer: `${PREFIX}-viewToggleContainer`
};

const Root = styled('div')(() => ({
  [`&.${classes.viewToggleContainer}`]: {
    float:'right',
    background: '#fff',
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.15)',
    margin: '12px',
    // padding: '6px 12px',
    position: 'absolute',
    right: 0,
  }
}));

// eslint-disable-next-line react/prop-types
const MapViewToggle = ({mapView, setMapView}) =>{

  const handleView = (event, newView) => {
    setMapView(newView);
  };

  return (
    <Root
      className={classes.viewToggleContainer}
    >
      <ToggleButtonGroup
        value={mapView}
        exclusive
        onChange={handleView}
        aria-label="map view"
      >
        <ToggleButton color="primary" value="street" aria-label="street view">
          <StreetviewIcon color="primary" />
        </ToggleButton>
        <ToggleButton value="satellite" aria-label="satellite view">
          <SatelliteIcon color="primary"/>
        </ToggleButton>
      </ToggleButtonGroup>
    </Root>
  );
};
export default MapViewToggle;