import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React, { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const Alert = forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StatusSnackbar = ({ message, severity, open, onClose }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);
StatusSnackbar.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default StatusSnackbar;
