import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PREFIX = 'PercentageTicker';

const classes = {
  root: `${PREFIX}-root`,
  greenValue: `${PREFIX}-greenValue`,
  redValue: `${PREFIX}-redValue`,
  grayValue: `${PREFIX}-grayValue`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  [`& .${classes.greenValue}`]: {
    fontSize: '0.7rem',
    color: `${green}`,
  },

  [`& .${classes.redValue}`]: {
    fontSize: '0.7rem',
    color: `${red}`,
  },

  [`& .${classes.grayValue}`]: {
    fontSize: '0.7rem',
    color: `${gray}`,
  }
}));

const red = '#DC2626';
const green = '#059669';
const gray = '#9CA3AF';

// Conventionally a positive percentage is green and negative is
// red, but for some metrics like idling time the inverse is
// true, since a reduction is an improvement
const PercentageTicker = ({ value, invert=false }) => {


  if (value === null || value === undefined) {
    value = 0;
  }

  const percentageValue = `${(value * 100).toFixed(1)}%`;

  const renderConventional = (value) => (
    <Fragment>
      {value > 0 && (
        <Fragment>
          <ArrowDropUpIcon htmlColor={green} />
          <Typography className={classes.greenValue}>{percentageValue}</Typography>
        </Fragment>
      )}
      {value < 0 && (
        <Fragment>
          <ArrowDropDownIcon htmlColor={red} />
          <Typography className={classes.redValue}>{percentageValue}</Typography>
        </Fragment>
      )}
      {value === 0 && (
        <Fragment>
          <ArrowDropDownIcon htmlColor={gray} />
          <Typography className={classes.grayValue}>{percentageValue}</Typography>
        </Fragment>
      )}
    </Fragment>
  );

  const renderInverse = (value) => (
    <Fragment>
      {value > 0 && (
        <Fragment>
          <ArrowDropUpIcon htmlColor={red} />
          <Typography className={classes.redValue}>{percentageValue}</Typography>
        </Fragment>
      )}
      {value < 0 && (
        <Fragment>
          <ArrowDropDownIcon htmlColor={green} />
          <Typography className={classes.greenValue}>{percentageValue}</Typography>
        </Fragment>
      )}
      {value === 0 && (
        <Fragment>
          <ArrowDropDownIcon htmlColor={gray} />
          <Typography className={classes.grayValue}>{percentageValue}</Typography>
        </Fragment>
      )}
    </Fragment>
  );

  return (
    <Root className={classes.root}>
      {invert ? renderInverse(value) : renderConventional(value)}
    </Root>
  );
};

PercentageTicker.propTypes = {
  value: PropTypes.number,
  invert: PropTypes.bool,
};

export default PercentageTicker;
