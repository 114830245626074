import React from 'react';
import { CheckboxInput } from '../Form';

const updateRoles = (roles, value, checked) => {
  let newRoles;
  if (checked) {
    newRoles = roles.slice();
    newRoles.push(value);
  } else {
    newRoles = roles.filter(role => role !== value);
  }
  return newRoles;
};

const Roles = ({roles, userRoles, readOnlyRoles=[], onChange}) => {
  return roles.map(role => (
    <CheckboxInput
      key={role.id}
      label={role.description}
      value={role.name}
      checked={userRoles.includes(role.name)}
      onChange={(value, checked) => onChange(updateRoles(userRoles, value, checked))}
      readOnly={readOnlyRoles.includes(role.name)}
    />
  ));
};

export default Roles;
