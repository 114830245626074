import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const SettingsPanelRoot = styled(SettingsPanel)({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
});

const DivBlock = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
});

const DivRow = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: '1rem',
});

const TextFieldSelect = styled(TextField)({
  minWidth: '12rem',
  marginRight: '2rem',
});

const BillingSettingsPanel = ({ client, dataProviders, servicePlans, dataPlans, onSave }) => {

  const initialBillingData = {
    'billing_service_plan': client.billing_service_plan,
    'billing_service_fee_amount': client.billing_service_fee_amount,
    'billing_data_provider': client.billing_data_provider,
    'billing_data_plan': client.billing_data_plan,
    'billing_data_fee_amount': client.billing_data_fee_amount,
  };
  const [expanded, setExpanded] = useState(false);
  const [billingData, setBillingData] = useState(initialBillingData);

  const toggleExpanded = () => setExpanded(!expanded);

  const { billing_service_plan, billing_service_fee_amount, billing_data_provider, billing_data_plan, billing_data_fee_amount } = billingData;

  const summary = () => {
    let serviceSummary = 'Service settings not configured';
    if (billing_service_plan !== null && billing_service_fee_amount !== null) {
      serviceSummary = 'Service: ' + billing_service_plan + ' (' + billing_service_fee_amount + ')';
    }
    let dataSummary = 'Data settings not configured';
    if (billing_data_provider !== null && billing_data_plan !== null && billing_data_fee_amount !== null) {
      dataSummary = 'Data: ' + billing_data_provider + ', ' + billing_data_plan + ' (' + billing_data_fee_amount + ')';
    }
    return serviceSummary + ' ' + dataSummary;
  };

  return (
    <SettingsPanelRoot
      title="Billing Settings"
      summary={summary()}
      onSave={() => {
        onSave(billingData);
        setExpanded(false);
      }}
      onCancel={() => {
        setBillingData(initialBillingData);
        setExpanded(false);
      }}
      onExpand={toggleExpanded}
      expanded={expanded}
    >
      <DivBlock>
        <DivRow>
          <TextFieldSelect
            select
            label="Service Plan"
            value={billing_service_plan || ''}
            onChange={(e) =>
              setBillingData({
                ...billingData,
                billing_service_plan: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          >
            {servicePlans.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </TextFieldSelect>
          <TextFieldSelect
            type="number"
            label="Service Fee Amount"
            helperText="Must be a number ($ or %)"
            value={billing_service_fee_amount || ''}
            onChange={(e) =>
              setBillingData({
                ...billingData,
                billing_service_fee_amount: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </DivRow>
        <DivRow>
          <TextFieldSelect
            select
            label="Data Provider"
            value={billing_data_provider || ''}
            onChange={(e) =>
              setBillingData({
                ...billingData,
                billing_data_provider: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          >
            {dataProviders.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </TextFieldSelect>
          <TextFieldSelect
            select
            label="Data Plan"
            value={billing_data_plan || ''}
            onChange={(e) =>
              setBillingData({ ...billingData, billing_data_plan: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          >
            {dataPlans.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </TextFieldSelect>
          <TextFieldSelect
            type="number"
            helperText="Must be a number ($ or %)"
            label="Data Fee Amount"
            value={billing_data_fee_amount || ''}
            onChange={(e) =>
              setBillingData({
                ...billingData,
                billing_data_fee_amount: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </DivRow>
      </DivBlock>
    </SettingsPanelRoot>
  );
};

BillingSettingsPanel.propTypes = {
  client: PropTypes.object.isRequired,
  dataProviders: PropTypes.array.isRequired,
  servicePlans: PropTypes.array.isRequired,
  dataPlans: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BillingSettingsPanel;
