import React, { useEffect, useState } from 'react';

import DownloadReports from './DownloadReports';
import LeftNav from '../../../components/LeftNav';
import PropTypes from 'prop-types';
import Report from './Report';
import ReportTableView from '../components/ReportTableView';
import { ReportsAPI } from '../../../api';
import moment from 'moment';
import { useSelector } from 'react-redux';

const FTCReports = ({ ...props }) => {
  const client = useSelector((state) => state.clients.selectedItem);
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const [reports, setReports] = useState([]);
  const [dateRange, setDateRange] = useState({min: null, max: null});
  const [filter, setFilter] = useState({ date: moment().startOf('month').subtract(1,'months').format('YYYY-MM-DD') });
  const version = client && client.features.includes('ftc_reports_v2') ? 2 : 1;
  const endpointFunction = version === 2 ? ReportsAPI.getFTCReports : ReportsAPI.getReports;
  const v2Exclusions = ['vehicle_register', 'client_fuel']; // Don't show these reports for V2

  const onFilterChanged = f => setFilter({date: f});

  const makeReportList = (reportList) => {
    return reportList.map(r => {
      return {
        path: '/ftcreports/' + r.name,
        label: version === 2 ? r.short_title : r.description,
        content: function renderReport(props) {
          return (
            <Report
              report={r}
              filter={filter}
              onFilterChanged={onFilterChanged}
              ViewComponent={ReportTableView}
              version={version}
              {...props}
            />
          );
        },
      };
    });
  };

  const staticReports = () => {
    /*
     * we use the date range from the ftc_fleet report to set the
     * date range for creating new PDF and XLS reports.
     */
    const ftcReports = {
      path: '/ftcreports/download',
      label: 'Download Reports',
      content: function renderDownloads(props) {
        return <DownloadReports dateRange={dateRange} {...props}/>;
      },
    };
    return [ ftcReports ];
  };

  const setReportList = (reportList) => {
    const newReportList = makeReportList(reportList);
    const ftcFleetReports = ['ftc_fleet', 'fleet_fuel_ftc']; // v1 and v2 reports used to get date range
    const ftcFleet = reportList.find(report => ftcFleetReports.includes(report.name));
    const newDateRange = ftcFleet ? ftcFleet.date_range : undefined;
    setReports(newReportList);
    setDateRange(newDateRange);
  };

  const getReportList = async () => {
    const response = await endpointFunction(clientId);
    let result = response.payload;
    if (version === 2) {
      result = response.payload.filter(r => !v2Exclusions.includes(r.name));
    }
    setReportList(result);
  };

  useEffect(() => {
    getReportList();
  }, []); // eslint-disable-line

  return (
    <div style={{ height: '100%' }}>
      <LeftNav
        title='FTC Reports'
        pages={reports.concat(staticReports())}
        filter={filter}
        onFilterChanged={onFilterChanged}
        {...props}
      />
    </div>
  );
};

FTCReports.propTypes = {
  clientId: PropTypes.string,
  profile: PropTypes.object.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
};

export default FTCReports;
