
const locale = (_nav_) =>
{
  const DEFAULT_LOCALE = 'en';

  if ((typeof _nav_ === 'undefined') &&
      (navigator && typeof navigator !== 'undefined'))
    _nav_ = navigator;

  let current = (
    _nav_.languages &&
                  Object.prototype.toString.call( _nav_.languages ) === '[object Array]' &&
                  _nav_.languages[0]
  ) ||
                (_nav_.languages) ||
                (_nav_.language) ||
                (_nav_.userLanguage) ||
                DEFAULT_LOCALE;

  if (current.indexOf('en-') > -1) current = DEFAULT_LOCALE;

  return current;
};

export default locale;
