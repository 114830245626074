import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import ReloadIcon from '@mui/icons-material/Autorenew';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { FabActionButton } from '../ActionButton';

const PREFIX = 'Toolbar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarGroup: `${PREFIX}-toolbarGroup`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
    marginRight: '10px',
    minHeight: '80px',
  },

  [`& .${classes.toolbarGroup}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.title}`]: {
    display: 'inline-block',
    margin: '1rem',
    color: '#333333',
    fontSize: '1.1rem',
  },

  [`& .${classes.subtitle}`]: {
    display: 'inline-block',
    margin: '1rem',
    color: '#333333',
    fontSize: '1rem',
  },

  [`& .${classes.button}`]: {
    float: 'right',
    marginLeft: '10px',
    fontWeight: 900,
  },

  [`& .${classes.buttonIcon}`]: {
    marginLeft: theme.spacing(),
  }
}));

const ucFirst = (word) => word ? word[0].toUpperCase() + word.substring(1) : word;

const Toolbar = ({
  showing,
  onNew,
  onRefresh,
  onGroupAdd,
  refreshDisabled,
  itemName,
  total,
  children,
  title,
}) => (

  <Root className={classes.toolbar}>
    <div className={classes.toolbarGroup}>
      <h1 className={classes.title}>{title ? title : (ucFirst(itemName) + ' Management')}</h1>
      <h2 className={classes.subtitle}>{showing}{total && showing < total && '/'+total} {showing && itemName+'s'}</h2>
    </div>
    <div className={classes.toolbarGroup}>

    </div>
    <div className={classes.toolbarGroup}>
      {children}
      {onRefresh && (
        <FabActionButton
          size="small"
          onClick={onRefresh}
          tooltip={'Refresh'}
          className={classes.button}
          disabled={refreshDisabled}
          color="primary"
          Icon={ReloadIcon}
        />
      )}
      {onGroupAdd && (
        <FabActionButton
          size="small"
          onClick={onGroupAdd}
          tooltip={`Add ${itemName} list`}
          className={classes.button}
          color="primary"
          Icon={GroupAddIcon}
        />
      )}
      {onNew && (
        <FabActionButton
          size="small"
          onClick={onNew}
          tooltip={`Add new ${itemName}`}
          className={classes.button}
          color="secondary"
          Icon={AddIcon}
        />
      )}
    </div>
  </Root>
);

Toolbar.propTypes = {
  onNew: PropTypes.func,
  onRefresh: PropTypes.func,
  onGroupAdd: PropTypes.func,
  onDownload: PropTypes.func,
  refreshDisabled: PropTypes.bool,
  showing: PropTypes.number,
  itemName: PropTypes.string.isRequired,
  title: PropTypes.string,
  total: PropTypes.number,
  children: PropTypes.node,
};

export default (Toolbar);
