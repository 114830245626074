import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import {DateInput,Section, SelectInput, TextInput} from '../../../components/Form';
import PropTypes from 'prop-types';


const PREFIX = 'NewAreaForm';

const classes = {
  dateContainer: `${PREFIX}-dateContainer`,
  columnContainer: `${PREFIX}-columnContainer`
};

const StyledSection = styled(Section)(() => ({
  [`& .${classes.dateContainer}`]: {
    display:'flex',
  },

  [`& .${classes.columnContainer}`]: {
    width:'50%'
  }
}));


const ftcClassificationOpt = [
  {'value': 'Public', 'label': 'Public'},
  {'value': 'Non-Public', 'label': 'Non-Public'},
];


const NewAreaForm = ({newArea, setNewArea,readOnly, disabled}) =>{



  const onNewAreaChange = (field,value) =>{
    setNewArea({
      ...newArea,[field]:value});
  };
  return (
    <StyledSection>
      <FormGroup row >
        <TextInput disabled={disabled} readOnly={readOnly}  required={true} label="Name of Area" value={newArea} span={6}  field='name' onChange={onNewAreaChange} variant="outlined"/>
      </FormGroup>
      <FormGroup row>
        <SelectInput disabled={disabled} readOnly={readOnly}  required={true} label="FTC Road Classification"  span={6} value={newArea} values={ftcClassificationOpt} field="ftc_road_classification" variant="outlined" onChange={onNewAreaChange} />
      </FormGroup>
      <FormGroup row className={classes.dateContainer}>
        <FormGroup className={classes.columnContainer} >
          <DateInput disabled={disabled} readOnly={readOnly} className={classes.dateForm} label="Start Date" value={newArea} field="start_time"  onChange={onNewAreaChange} variant="outlined"/>
        </FormGroup>
        <FormGroup className={classes.columnContainer}>
          <DateInput  disabled={disabled} readOnly={readOnly} label="End Date" value={newArea} field="end_time"  onChange={onNewAreaChange} variant="outlined"/>
        </FormGroup>
      </FormGroup>
      <FormGroup row>
        <TextInput disabled={disabled} readOnly={readOnly} label="Description" value={newArea} span={6} field='description'variant = "outlined" onChange={onNewAreaChange}/>
      </FormGroup>
    </StyledSection>
  );
};

NewAreaForm.propTypes = {
  newArea :  PropTypes.object,
  setNewArea: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool

};

export default NewAreaForm;