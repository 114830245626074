import moment from 'moment';

/*
  Convert a date range in YYYY-MM-DD format into a list of 
  months spanning that date range in YYYY-MM-01 format
  in chronological order.

  makeDateList("2021-08-30","2021-10-17") =>
    [
      "2021-08-01",
      "2021-09-01",
      "2021-10-01"
    ]
*/
const makeDateList = (from, to) => {
  const m1 = moment(from).startOf('month');
  const m2 = moment(to).endOf('month');
  let dates = [];
  for (let m = m1; m.isBefore(m2); m = m.add(1, 'months')) {
    dates.push(m.format('YYYY-MM-DD'));
  }
  return dates.reverse();
};

/*
  Given a date in YYYY-MM-DD format, returns date range spanning the containing month.

  makeMonthFilter("2021-10-10") => 
    {
        "start_date": "2021-09-01T00:00:00+10:00",
        "end_date: "2021-10-01T00:00:00+10:00",
    }
*/
const makeMonthFilter = (date) => {
  const d = moment(date);
  const start_date = d.startOf('month');
  const end_date = moment(start_date).add(1, 'M');
  // note that endOf('month') returns 23:59:59 of the last day, but
  // we want 00:00:00 of the first day of the next month.
  return {
    start_date: start_date.format(),
    end_date: end_date.format()
  };
};

const isBeforeNow = (date) => moment(date).isBefore();

export {
  makeDateList,
  makeMonthFilter,
  isBeforeNow,
};