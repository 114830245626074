import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { VehicleAPI, AnalyticsAPI } from '../../api';
import { LoadingStore } from '../../ducks';
import { prettyLocalDatetime } from '../../helpers/date';
import { useSelector, useDispatch } from 'react-redux';
import { Toolbar, TripTable, ProfileChart } from './components';
import MapView from './components/MapView';
import dayjs from 'dayjs';

const PREFIX = 'TripView';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    padding: '1rem',
    width: '100%',
  },
});

const formatTripList = (tripList) => {
  if (tripList === undefined) return null;
  return tripList.map((trip) => {
    return {
      Started: prettyLocalDatetime(trip['start_time']),
      Ended: prettyLocalDatetime(trip['end_time']),
      From: trip['start_suburb'],
      To: trip['end_suburb'],
      'Distance (KM)': trip['distance_km'],
      'Time (Mins)': trip['duration_mins'],
      'Driving (Mins)': trip['driving_mins'],
      'Idling (Mins)': trip['idling_mins'],
      'Auxiliary (Mins)': trip['auxiliary_mins'],
    };
  });
};

const TripView = () => {
  const dispatch = useDispatch();
  const createInitialDateRange = () => [dayjs().subtract(1, 'day'), dayjs()];
  const clientId = useSelector((state) => state.clients.selectedItemId);

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleId, setVehicleId] = useState();
  const [availableDateRange, setAvailableDateRange] = useState(
    createInitialDateRange
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    createInitialDateRange
  );
  const [tripList, setTripList] = useState([]);
  const [tripRow, setTripRow] = useState();
  const [tripId, setTripId] = useState();
  const [tripPoints, setTripPoints] = useState([]);
  const [noDataText, setNoDataText] = useState('');

  const onVehicleChanged = (value) => {
    setSelectedDateRange(createInitialDateRange);
    setVehicleId(value);
    setTripPoints([]);
  };

  const onDateRangeChanged = (range) => {
    const [min, max] = range;
    // sometimes the date picker returns null values for min or max date
    if (min != null && max != null) {
      setSelectedDateRange(range);
    }
  };

  const onTripClick = (row, idx) => {
    setTripRow(idx.dataIndex);
    setTripId(tripList[idx.dataIndex]['trip_id']);
  };

  useEffect(() => {
    const formatVehicleList = (vehicleList) => {
      return vehicleList.map((obj) => {
        const description = `${obj['client_vehicle_id']}/${
          obj['registration']
        }/${obj['make']} ${obj['model']} ${obj['description'] ?? ''}`;
        return { value: obj['vehicle_id'], label: description };
      });
    };

    dispatch(LoadingStore.loading(true));
    const params = { disabled: false };
    VehicleAPI.list(clientId, params)
      .then(
        (response) => setVehicleList(formatVehicleList(response.payload)),
        (err) => console.log(err)
      )
      .finally(() => dispatch(LoadingStore.loading(false)));
  }, [clientId, setVehicleList, dispatch]);

  useEffect(() => {
    if (vehicleList.length > 0) {
      setVehicleId(vehicleList[0].value);
    } else {
      setVehicleId();
    }
  }, [vehicleList, setVehicleId]);

  useEffect(() => {
    dispatch(LoadingStore.loading(true));
    const params = { vehicle_id: vehicleId };
    AnalyticsAPI.getVehicleDateRange(clientId, params)
      .then(
        (response) => {
          const minDate = dayjs(response.payload[0].min);
          const maxDate = dayjs(response.payload[0].max);
          if (minDate.isValid() && maxDate.isValid()) {
            setAvailableDateRange([minDate, maxDate]);
          } else {
            setAvailableDateRange(null);
          }
        },
        (err) => console.log(err)
      )
      .finally(() => dispatch(LoadingStore.loading(false)));
  }, [vehicleId, clientId, dispatch]);

  useEffect(() => {
    if (availableDateRange != null && availableDateRange.length === 2) {
      const [min, max] = availableDateRange;
      const startDate = max.toDate();
      const endDate = max.toDate();
      startDate.setDate(endDate.getDate() - 1);
      setSelectedDateRange([dayjs(startDate), dayjs(endDate)]);
      setNoDataText(
        `This vehicle has trips between ${min.format(
          'DD-MM-YYYY'
        )} and ${max.format('DD-MM-YYYY')}`
      );
    } else {
      setSelectedDateRange(createInitialDateRange);
      setNoDataText('There are no trips for this vehicle recorded in Prism.');
    }
  }, [availableDateRange, setSelectedDateRange, setNoDataText]);

  useEffect(() => {
    const getVehicleTripList = () => {
      dispatch(LoadingStore.loading(true));
      const [startDate, endDate] = selectedDateRange;
      const params = {
        vehicle_id: vehicleId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      };
      AnalyticsAPI.getVehicleTripList(clientId, params)
        .then(
          (response) => setTripList(response.payload),
          (err) => {
            console.log(err);
            setTripList([]);
          }
        )
        .finally(() => dispatch(LoadingStore.loading(false)));
    };

    if (selectedDateRange != null && selectedDateRange.length === 2) {
      getVehicleTripList();
    } else {
      setTripList([]);
    }
  }, [selectedDateRange, clientId, vehicleId, setTripList, dispatch]);

  useEffect(() => {
    if (tripList && tripList.length > 0) {
      setTripId(tripList[0].trip_id);
      setTripRow(0);
    } else {
      setTripId();
      setTripRow();
    }
  }, [tripList, setTripId, setTripRow]);

  useEffect(() => {
    const getTripPoints = () => {
      dispatch(LoadingStore.loading(true));
      if (tripId === undefined) return null;
      const params = { vehicle_id: vehicleId, trip_id: tripId };
      AnalyticsAPI.getTripPoints(clientId, params)
        .then(
          (response) => setTripPoints(response.payload),
          (err) => console.log(err)
        )
        .finally(() => dispatch(LoadingStore.loading(false)));
    };

    if (vehicleId != null && tripId != null) getTripPoints();
  }, [clientId, tripId, vehicleId, dispatch]);

  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Toolbar
            clientId={clientId}
            items={vehicleList}
            selectedItem={vehicleId}
            onChanged={onVehicleChanged}
            onDateRangeChanged={onDateRangeChanged}
            selectedDateRange={selectedDateRange}
          />
        </Grid>
        <Grid item md={6}>
          <TripTable
            data={formatTripList(tripList)}
            onRowClick={onTripClick}
            rowsSelected={tripRow != null ? [tripRow] : []}
            noDataText={noDataText}
          />
        </Grid>
        <Grid item md={6}>
          <MapView
            geojson={tripPoints.length === 0 ? null : tripPoints}
            style={{ height: '47vh' }}
          />
          <ProfileChart data={tripPoints.length === 0 ? null : tripPoints} />
        </Grid>
      </Grid>
    </Root>
  );
};

export default TripView;
