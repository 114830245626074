import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OkayIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';

const PREFIX = 'VehicleTable';

const classes = {
  okayIcon: `${PREFIX}-okayIcon`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledTable = styled(Table)((
  {
    theme
  }
) => ({
  [`& .${classes.okayIcon}`]: {
    color: theme.palette.common.success,
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.common.error,
  }
}));

const renderBoolean = (value) => value ? <CheckIcon/> : null;

const columns = [
  {field: 'client_vehicle_id', label: 'Client Vehicle Id'},
  {field: 'make', label: 'Make'},
  {field: 'model', label: 'Model'},
  {field: 'fuel_type', label: 'Fuel Type'},
  {field: 'registration', label: 'Registration'},
  {field: 'category', label: 'Category'},
  {field: 'ftc_on_road', label: 'FTC On Road', render: renderBoolean},
];

class VehicleTable extends React.Component {
  static propTypes = {
    vehicles: PropTypes.array,
  }

  emptyState = () => (
    <div>No Vehicles</div>
  )

  statusIcon = (status) => {
    if (status === true) return <OkayIcon className={classes.okayIcon}/>;
    if (status === false) return <ErrorIcon className={classes.errorIcon}/>;
    return null;
  }

  tableRow = (v, i) => (
    <TableRow key={i}>
      <TableCell key="n">{i+1}</TableCell>
      {columns.map((col, j) => (
        <TableCell key={j}>
          {col.render ? col.render(v[col.field]) : v[col.field]}
        </TableCell>
      ))}
      <TableCell key="ok">
        {this.statusIcon(v.status)}
      </TableCell>
    </TableRow>
  )

  render() {
    const { vehicles } = this.props;
    if (vehicles === undefined || vehicles.length === 0) return this.emptyState();
    return (
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell key="n"></TableCell>
            {columns.map((col, i) => (<TableCell key={i}>{col.label}</TableCell>))}
            <TableCell key="ok"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((v, i) => this.tableRow(v, i))}
        </TableBody>
      </StyledTable>
    );
  }
}


export default (VehicleTable);
