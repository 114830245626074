import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Toolbar from '../components/Toolbar';
import VehicleCard from '../components/VehicleCard';
import Drawer from '@mui/material/Drawer';
import UploadDrawer from '../components/UploadDrawer';
import EditForm from '../components/EditForm';
import ErrorDialog from '../../../components/ErrorDialog';
import { VehicleStore, RefsStore } from '../../../ducks';
import { VehicleAPI } from '../../../api';
import { connect } from 'react-redux';
import { isBeforeNow } from 'lib/date';
import config from '../../../config/config';
import VehicleDatagrid from '../components/VehicleDatagrid';

const PREFIX = 'Register';

const classes = {
  container: `${PREFIX}-container`,
  cardContainer: `${PREFIX}-cardContainer`,
  listContainer: `${PREFIX}-listContainer`,
  listHolder: `${PREFIX}-listHolder`,
  cardHolder: `${PREFIX}-cardHolder`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    height: `calc(100vh - ${netReduction}px)`,
    display: 'flex',
    flexDirection: 'column',
  },

  [`&.${classes.cardContainer}`]: {
    flex: 1,
    overflow: 'hidden',
  },

  [`& .${classes.listContainer}`]: {
    flex: 1,
    overflow: 'hidden',
  },

  [`& .${classes.listHolder}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'left',
    height: '100%',
    alignContent: 'flex-start',
    padding: '0 1rem 1rem 1rem',
  },

  [`& .${classes.cardHolder}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    alignContent: 'flex-start',
  }
}));

const stringMatch = (value, query) =>
  value !== null && value.toUpperCase().includes(query);

const errorMessage = err => (err.body && err.body.message) || 'An error occurred processing this request.';

const filterQuery = (data, query) => {
  let filteredData = data.filter(v => {
    query = query.toUpperCase();
    if (
      stringMatch(v.make, query) ||
      stringMatch(v.model, query) ||
      stringMatch(v.variant, query) ||
      stringMatch(v.category, query) ||
      stringMatch(v.fuel_type, query) ||
      stringMatch(v.description, query) ||
      stringMatch(v.registration, query) ||
      stringMatch(v.vehicle_id, query) ||
      stringMatch(v.client_vehicle_id, query)
    ) { return v; }
    return null;
  });
  return filteredData;
};

const filterIncomplete = (data) => {
  let filteredData = data.filter(v => {
    let nullValues = [null, ''];
    if (
      nullValues.includes(v.make) ||
          nullValues.includes(v.model)  ||
          nullValues.includes(v.variant)  ||
          nullValues.includes(v.fuel_type)  ||
          nullValues.includes(v.client_vehicle_id)  ||
          nullValues.includes(v.registration)
    ) { return v; }
    return null;
  });
  return filteredData;
};

// This filters for inactive vehicles, which are those where disabled is True or where a disposal date is set to a date in the past
// We don't do any time zone conversion, so the disposal date is treated as YYYY-MM-DD 00:00:00 UTC.
const filterDisabled = (data) => data.filter(v => !(v.disabled || (v.disposed_date !== null && isBeforeNow(v.disposed_date))));

const normalize = (value) => (value === undefined || value === null) ? '' : (value.toLowerCase ? value.toLowerCase() : value);
const cmp = (a, b) => a < b ? -1 : (a > b ? 1 : 0);
const regoSort = (a, b) => cmp(normalize(a.registration), normalize(b.registration)) || cmp(a.vehicle_id, b.vehicle_id);
const categorySort = (a, b) => cmp(normalize(a.category), normalize(b.category)) || cmp(a.vehicle_id, b.vehicle_id);
const ivmsIdSort = (a, b) => cmp(normalize(a.client_vehicle_id), normalize(b.client_vehicle_id)) || cmp(a.vehicle_id, b.vehicle_id);
const makeModelSort = (a, b) => cmp(normalize(a.make), normalize(b.make)) || cmp(normalize(a.model),normalize(b.model)) || cmp(a.vehicle_id, b.vehicle_id);
const modelSort = (a, b) => cmp(normalize(a.model),normalize(b.model)) || cmp(a.vehicle_id, b.vehicle_id);

const sortOptions = {
  'Registration': regoSort,
  'IVMS ID': ivmsIdSort,
  'Make/Model': makeModelSort,
  'Model': modelSort,
  'Category': categorySort,
};

const filterData = (data, filter) => {
  let filteredData = data.slice(); // copy before sorting
  if (filter.query !== '') {
    filteredData = filterQuery(filteredData, filter.query);
  }
  if (filter.incomplete === true) {
    filteredData = filterIncomplete(filteredData);
  }
  if (filter.disabled !== true) {
    filteredData = filterDisabled(filteredData);
  }
  filteredData.sort(filter.sort);
  return filteredData;
};

class Register extends React.Component
{
  static propTypes = {
    clientId: PropTypes.string,
    vehicles: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.object,
    isSuperUser: PropTypes.bool.isRequired,
  }

  state = {
    ready: false,
    vehicles: [],  // after filters
    query: '',
    showDetail: false,
    showUpload: false,
    detailContent: 'Edit',
    showCreate: false,
    createContent: 'Create',
    selectedVehicle: {},
    incompleteChecked: false,
    disabledChecked: false,
    error: undefined,
    selectedVehicleId: undefined,
    sortName: 'Registration',
    sortFunction: regoSort,
    view: 'list',
  }

  onViewChanged = (view) => {
    this.setState({view});
  }

  openUploadDrawer = () => {
    this.setState({ showUpload: true});
  }

  closeUploadDrawer = () => {
    this.setState({ showUpload: false});
  }

  openVehicleDetails = (vehicle) => {
    this.setState({ showDetail: true, selectedVehicle: vehicle, selectedVehicleId: vehicle.vehicle_id });
  }

  setDetailContent = (content) => {
    this.setState({ detailContent: content });
  }

  openCreateVehicle = () => {
    this.setState({ showDetail: true, selectedVehicle: {}});
  }

  setCreateContent = (content) => {
    this.setState({ createContent: content });
  }

  applyDataFilters = (filter) => {
    const f = {
      query: this.state.query,
      incomplete: this.state.incompleteChecked,
      disabled: this.state.disabledChecked,
      sort: this.state.sortFunction,
      ...filter,
    };
    return filterData(this.props.vehicles, f);
  }

  searchChange = (query) => {
    const filteredData = this.applyDataFilters({query});
    this.setState({ vehicles: filteredData, query: query});
  }

  onSortChanged = (sortName) => {
    const sort = sortOptions[sortName];
    const filteredData = this.applyDataFilters({sort});
    this.setState({ vehicles: filteredData, sortName: sortName, sortFunction: sort});
  }

  incompleteCheckboxChange = (value) => {
    const filteredData = this.applyDataFilters({incomplete:value});
    this.setState({ vehicles: filteredData, incompleteChecked: value});
  }

  disabledCheckboxChange = (value) => {
    const filteredData = this.applyDataFilters({disabled:value});
    this.setState({ vehicles: filteredData, disabledChecked: value});
  }

  refreshData = (refresh=false) => {
    this.props.dispatch(VehicleStore.list(refresh));
  }

  setError = (title, message) => {
    this.setState({ error: {title, message} });
  }

  /* create or update */
  updateVehicle = (vehicle) => {
    const clientId = this.props.clientId;
    const vehicleId = vehicle.vehicle_id;
    this.setState({ ready: false });

    if (vehicleId === undefined) {
      VehicleAPI.create(clientId, vehicle)
        .then(response => {
          if (response && response.status_code === 200) {
            this.setState({
              selectedVehicle: response.payload,
              selectedVehicleId: response.payload.vehicle_id,
              showDetail: false,
            });
            this.refreshData();
          }
        })
        .catch(err => {
          this.setError('Unable to Create Vehicle', errorMessage(err));
        });
    } else {
      VehicleAPI.update(clientId, vehicleId, vehicle)
        .then(response => {
          if (response && response.status_code === 200) {
            this.setState({
              selectedVehicle: response.payload,
              showDetail: false,
            });
            this.refreshData();
          }
        })
        .catch(err => {
          this.setError('Unable to Update Vehicle', errorMessage(err));
        });
    }
  }

  matchFuel = async (vehicle) => {
    const clientId = this.props.clientId;
    const { payload } = await VehicleAPI.matchVehicle(clientId, vehicle);
    if (!payload.matched) {
      this.setError('Unable to Determine Fuel Rate', 'No reference vehicle matched.  Ensure a category is specified.');
    }
    return payload.matched ? payload.vehicle : undefined;
  }

  deleteVehicle = (vehicle) => {
    const clientId = this.props.clientId;
    const vehicleId = vehicle.vehicle_id;
    if (vehicleId) {
      this.setState({ showDetail: false, ready: false });
      VehicleAPI.remove(clientId, vehicleId)
        .then(() => {
          this.refreshData();
        });
    } else {
      // deleting a never-saved vehicle.
      this.setState({ showDetail: false });
    }
  }

  componentDidMount() {
    this.refreshData();
    this.props.dispatch(RefsStore.getAuxFuelUses());
    this.props.dispatch(RefsStore.getAuxFuelMethods());
    this.props.dispatch(VehicleStore.getCategories());
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.refreshData();
    } else if (this.props.vehicles !== prevProps.vehicles) {
      const filteredData = this.applyDataFilters(null, null, null);
      this.setState({ vehicles: filteredData });
    }
  }

  renderCardView = () => {
    const {
      selectedVehicleId,
      vehicles,
    } = this.state;

    return (
      <div className={classes.cardContainer}>
        {this.props.vehicles ? (
          <div className={classes.cardHolder}>
            {vehicles.map((v, i) =>
              <VehicleCard
                key={i}
                selected={v.vehicle_id === selectedVehicleId}
                onClick={() => this.openVehicleDetails(v)}
                data={v}
              />)}
          </div>
        ) : null}
      </div>
    );
  }

  renderListView = () => {
    const { vehicles } = this.state;
    return (
      <VehicleDatagrid
        vehicles={vehicles}
        openVehicleDetails={this.openVehicleDetails}
      />
    );
  };

  render() {
    const {  clientId, profile, isSuperUser } = this.props;
    const {
      error,
      showUpload,
      showDetail,
      selectedVehicle,
      incompleteChecked,
      disabledChecked,
      vehicles,
      sortName,
      view,
    } = this.state;

    return (
      <Root className={classes.container}>
        <Drawer
          anchor="right"
          open={showDetail}
          onClose={() => this.setState({showDetail: false})}
        >
          <EditForm
            selectedVehicle={selectedVehicle}
            onSave={this.updateVehicle}
            onMatchFuel={this.matchFuel}
            onDelete={this.deleteVehicle}
            onClose={() => this.setState({showDetail: false})}
          />
        </Drawer>
        <UploadDrawer
          isOpen={showUpload}
          onClose={() => this.setState({showUpload: false})}
          refresh={() => this.refreshData()}
          vehicles={this.props.vehicles}
        />
        <Toolbar
          showCount={vehicles.length}
          totalCount={this.props.vehicles.length}
          sortSelected={sortName}
          sortOptions={Object.keys(sortOptions)}
          onSortChanged={this.onSortChanged}
          sortEnabled={view === 'card'}
          disabledChecked={disabledChecked}
          onDisabledChanged={this.disabledCheckboxChange}
          incompleteChecked={incompleteChecked}
          onIncompleteChanged={this.incompleteCheckboxChange}
          onSearchChanged={this.searchChange}
          onNewVehicle={this.openCreateVehicle}
          onUploadVehicle={this.openUploadDrawer}
          data={this.props.vehicles}
          fileName={config.isBarton ? 'National-Pilot-Vehicle-Register' : 'Prism-Vehicle-Register'}
          view={view}
          onViewChanged={this.onViewChanged}
          clientId={clientId}
          profile={profile}
          isSuperUser={isSuperUser}
        />

        {view === 'card' ? this.renderCardView() : this.renderListView()}

        {error && <ErrorDialog
          show={true}
          title={error.title}
          text={error.message}
          onClose={() => this.setState({error: undefined})}/>
        }
      </Root>
    );
  }
}

const headerHeight = 52;
const footerHeight = 40;
const netReduction = headerHeight + footerHeight;

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isSuperUser: state.auth.isSuperUser,
    clientId: state.clients.selectedItemId,
    vehicles: state.vehicles.items,
  };
};

export default connect(mapStateToProps)((Register));
