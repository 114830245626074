import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MonthlySelectionDrawer from '../components/MonthlySelectionDrawer';
import VehicleList from '../components/VehicleList';
import { dateToShortMonth } from 'helpers/date';

const PREFIX = 'VehicleConfiguration';

const classes = {
  container: `${PREFIX}-container`,
};

const StyledContainer = styled('div')(() => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    paddingTop: '1px',
  },
}));

export default function VehicleConfiguration() {
  const getCurrentMonthAndYear = () => {
    const currentDate = new Date();
    const month = dateToShortMonth(currentDate);
    const year = currentDate.getFullYear();
    const formattedDate = `${month} ${year}`;
    return formattedDate;
  };

  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthAndYear());
  return (
    <StyledContainer className={classes.container}>
      <MonthlySelectionDrawer setSelectedMonth={setSelectedMonth}></MonthlySelectionDrawer>
      <VehicleList selectedMonth={selectedMonth}></VehicleList>
    </StyledContainer>
  );
}
