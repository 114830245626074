const nf = new Intl.NumberFormat('en-AU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const safePercent = (a, b) => {
  const percent = (a / b * 100);
  return isFinite(percent) ? nf.format(percent.toFixed(2)) : '0.00';
};

export const safeFixed = (value, dp=2) => {
  if (value === 0 || value === null) return '0.00';
  return nf.format(value.toFixed(dp));
};
