import React ,{useEffect, useState}from 'react';
import {styled} from '@mui/material/styles';
import { TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {FabActionButton} from '../../../components/ActionButton';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const PREFIX = 'RegistrationDetail';

const classes = {
  errorText: `${PREFIX}-errorText`,
  container: `${PREFIX}-container`,
  fieldName: `${PREFIX}-fieldName`,
  fieldValue: `${PREFIX}-fieldValue`,
};

const Container = styled('div')(({theme})=>({

  [`& .${classes.container}`]: {
    display: 'flex',
    margin: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.errorText}`]: {
    marginLeft: 60,
    color: theme.palette.error.main
  },
  [`& .${classes.fieldName}`]: {
    width: '75%',
    marginLeft:'10px'
  },
  [`& .${classes.fieldValue}`]: {
    width: '15%',
    marginLeft:'auto'
  },

}));

const RegistrationComponent = ({ field, value , count, onChange, handleDelete }) => {
  const [valueError, setValueError] = useState(false);
  const [valueHelperText, setValueHelperText] = useState('');
  const [inputValue, setInputValue] = useState(value?.toFixed(2) ?? 0);

  const validateValue = (inputValue) => {
    const decimalRegex = /^-?\d+(\.\d{0,2})?$/; // Regex to match numbers with up to two decimal places

    if (!decimalRegex.test(inputValue)) {
      setValueError(true);
      setValueHelperText('Please enter a value with up to 2 decimal places.');
      return false;
    } else {
      setValueError(false);
      setValueHelperText('');
      return true;
    }
  };

  const onValueBlur = (event) => {
    let inputValue = event.target.value;

    if (inputValue === '' || isNaN(inputValue)) {
      inputValue = '0.00'; // If value is empty or NaN, default it to 0.00
    }

    setInputValue(inputValue);

    const isValid = validateValue(inputValue);

    if (isValid) {
      const floatValue = parseFloat(parseFloat(inputValue).toFixed(2));
      onChange(count, field, floatValue);
    }
  };

  const onValueChange = (event) => {
    setInputValue(event.target.value);
  };

  const onFieldChange = (event) => {
    onChange(count, event.target.value, value);
  };

  const onDeleteClick = () =>{
    handleDelete(count);
  };

  useEffect(() => {
    setInputValue(value?.toFixed(2) ?? 0);
  }, [value]);

  return (
    <Container>
      <div className={classes.container}>
        <FabActionButton
          size="small"
          onClick={onDeleteClick}
          tooltip="Delete Registration Detail"
          Icon={DeleteIcon}
        />
        <TextField className={classes.fieldName} variant="outlined" value={field} onChange={onFieldChange} />
        <TextField
          className={classes.fieldValue}
          variant="outlined"
          value={inputValue}
          onChange={onValueChange}
          onBlur={onValueBlur}
          inputProps={{ step: '0.01' ,style: { textAlign: 'right' }}}
        />
      </div>
      {valueError && (
        <Typography className={classes.errorText}>
          {valueHelperText}
        </Typography>
      )}
    </Container>
  );
};

RegistrationComponent.propTypes = {
  field: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  count: PropTypes.number,
  handleDelete: PropTypes.func
};

export default RegistrationComponent;
