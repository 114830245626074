import React from 'react';
import PropTypes from 'prop-types';
import { AuthStore } from '../../ducks';
import { AuthAPI } from '../../api';
import { connect } from 'react-redux';

const alarmMarginMs = 60 * 1000;  // fire alarm this many seconds before expiry.
const inactivityTimeoutMs = 5 * 60 * 1000; // 5 minutes

const events = ['click', 'mousemove', 'keydown'];

class SessionRefresh extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    tokenExpiresAt: PropTypes.number,
    refreshToken: PropTypes.string,
    history: PropTypes.object.isRequired,
  };

  lastActive = Date.now();
  timer = undefined;

  onActive = () => {
    this.lastActive = Date.now();
  };

  componentDidMount() {
    // eslint-disable-next-line
    for (let e of events) document.addEventListener(e, this.onActive);
    this.startTimer();
  }

  componentWillUnmount() {
    // eslint-disable-next-line
    for (let e of events) document.removeEventListener(e, this.onActive);
    this.cancelTimer();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tokenExpiresAt !== prevProps.tokenExpiresAt) {
      this.startTimer();
    }
  }

  onTimeout = () => {
    const { history } = this.props;
    if (Date.now() - this.lastActive < inactivityTimeoutMs) {
      this.refreshToken();
    } else {
      history.push('/logout');
    }
  }

  startTimer = () => {
    if (this.timer !== undefined) {
      this.cancelTimer();
    }
    const delay = this.props.tokenExpiresAt - Date.now() - alarmMarginMs;
    this.timer = setInterval(this.onTimeout, delay);
  }

  cancelTimer = () => {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  refreshToken = async () => {
    this.setState({isOpen: false});
    const { refreshToken } = this.props;
    const response = await AuthAPI.refresh(refreshToken);
    const { token, expires_in, refresh_token, profile } = response.payload;
    this.props.dispatch(AuthStore.setToken(profile, token, Date.now() + 1000 * expires_in, refresh_token));
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    tokenExpiresAt: state.auth.tokenExpiresAt,
    refreshToken: state.auth.refreshToken,
  };
};

export default connect(mapStateToProps)(SessionRefresh);
