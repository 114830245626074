import React, { useEffect, useState } from 'react';
import LeftNav from '../../../components/LeftNav';
import PropTypes from 'prop-types';
import ReportPage from './ReportPage';
import { ReportsAPI } from 'api';
import { useSelector } from 'react-redux';

export default function Reports({ location, history }) {
  const [pages, setPages] = useState([]);
  const clientId = useSelector((state) => state.clients.selectedItemId);

  useEffect(() => {
    ReportsAPI.getNewReports(clientId).then((res) => {
      const reports = res.payload;
      const pages = [
        ...reports.map((r) => {
          const Page = () => <ReportPage reportId={r.id} name={r.name} />;

          return {
            label: r.name,
            path: `/reports/${r.path}`,
            content: Page,
          };
        }),
      ];
      setPages(pages);
    });
  }, [setPages, clientId]);

  return (
    <LeftNav
      title="Reports"
      pages={pages}
      location={location}
      history={history}
    />
  );
}
Reports.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
