import moment from 'moment';

export const colours = ['#4F46E5', '#6ee7b7', '#38bdf8', '#fdba74', '#EFA120'];

export const isTimestamp = (value) => typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/);
export const isDate = (value) => moment(value, moment.ISO_8601).isValid();
export const isNumber = (value) => !value.toString().includes('-') && !isNaN(parseFloat(value));
export const formatDate = (value) => moment(value).format('D MMM YY');
export const formatNumber = (value) => value.toLocaleString();
export const formatMonthYear = (date) => moment(date).format('MMM YYYY');
export const formatTime = (value) => value.substring(0, 8);

export const formatTimestamp = (date) => moment(date).format('Do hh:mm:ss a');

export const formatValue = (value) => {
  if (value === null || value === undefined) {
    return '';
  }
  if (isNumber(value)) {
    return formatNumber(value);
  }
  if (isTimestamp(value)) {
    return formatTimestamp(value);
  }
  if (isDate(value)) {
    return formatDate(value);
  }
  return value;
};

// removes null/undefined items
export const filterArray = (arr) => arr.filter(e => e === 0 || e);

export const filterArrayForObjectsWithKey = (arr, key) => arr.filter(e => key in e);

export const filterObjectByKeys = (data, keys) => {
  let filtered = {};
  Object.keys(data).forEach(function(k) {
    if (keys.indexOf(k) > -1) {
      filtered[k] = data[k];
    }
  });
  return filtered;
};

export const parseData = (data) => {
  return (data === null | typeof(data) === 'undefined') ? [] : data;
};

export const fieldNameToLabel = (value) => {
  if (typeof(value) !== 'undefined') {
    let elements = value.split('_');
    elements.forEach((o, i, a) => a[i] = a[i].replace(/\b\w/g, l => l.toUpperCase()));
    return elements.join(' ');
  }
};

export const titleWithUnits = (title, units) => {
  return typeof(units) === 'undefined' ? title : `${title} (${units})`;
};

export const filterData = (data) => {
  const subset = data.map(d => ({
    name: d.datakey,
    value: d.value
  }));
  return filterArray(subset);
};

// Splits an array into an array of objects by a ctageory key. From [{..}, {...}] to [{name: '', data:[{..}, {..}]}, ...]
export const groupData = (data, key) => {
  const values = data.map(d => d[key]);
  const uniqueValues = values.filter((x, i, a) => a.indexOf(x) === i);
  const groups = uniqueValues.map(k => {
    let items = data.filter(d => d[key] === k);
    return {
      name: k,
      data: items
    };
  });
  return groups;
};

// Formatter for when raw fields are used in selection filter controls
export const formatFieldName = (value) => {
  let formatted = value.replace(/_/g, ' ');
  formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
  return formatted;
};

// Generates a list of months to populate select control from start/end inputs
export const makeDateList = (from, to) => {
  const m1 = moment(from).startOf('month');
  const m2 = moment(to).endOf('month');
  let dates = [];
  for (let m=m1; m.isBefore(m2); m=m.add(1, 'months')) {
    dates.push(m.format('YYYY-MM-DD'));
  }
  return dates.reverse();
};

// Generate date filter object for the month from a date
export const makeMonthFilter = (date) => {
  const d = moment(date);
  return {
    start_date: d.startOf('month').format('YYYY-MM-DD'),
    end_date: d.endOf('month').format('YYYY-MM-DD')
  };
};
