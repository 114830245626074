import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const PREFIX = 'Confirmation';

const classes = {
  button: `${PREFIX}-button`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.button}`]: {
    fontWeight: 900,
  }
}));

const Confirmation = ({show, title, text, onAction, onCancel, color='primary', cancelLabel='Cancel'}) => {
  if (!show) return null;
  return (
    <StyledDialog
      open={show}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel} className={classes.button} autoFocus>
          {cancelLabel}
        </Button>
        {onAction &&
          <Button variant="contained" color={color} onClick={onAction} className={classes.button}>
            Continue
          </Button>
        }
      </DialogActions>
    </StyledDialog>
  );
};

Confirmation.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  color: PropTypes.string,
};

export default (Confirmation);
