import { makePath, request } from './http';
const API_NAME = 'users';

export function list(clientId, params={}) {
  return request('GET', makePath(API_NAME), {clientId, params});
}

export function listAll(params={}) {
  return request('GET', makePath(API_NAME, 'all'), {params});
}

export function update(clientId, userId, data) {
  return request('PATCH', makePath(API_NAME, userId), {clientId, body:data});
}

export function updateAdmin(clientId, userId, data) {
  return request('PATCH', makePath(API_NAME, userId, 'admin'), {clientId, body:data});
}

export function create(clientId, data) {
  return request('POST', makePath(API_NAME), {clientId, body:data});
}

export function remove(clientId, userId) {
  return request('DELETE', makePath(API_NAME, userId), {clientId});
}

export function removeAdmin(clientId, userId) {
  return request('DELETE', makePath(API_NAME, userId, 'admin'), {clientId});
}

export function profile(clientId) {
  return request('GET', makePath(API_NAME, 'profile'), {clientId});
}

export function triggerPasswordReset(email) {
  return request('POST', makePath(API_NAME, 'passwordreset'), {body:{email}});
}

export function resetPassword(userId, token, password) {
  return request('POST', makePath(API_NAME, userId, 'activation'), {body:{token, password}});
}

export function setPassword(clientId, oldPassword, newPassword) {
  return request('POST', makePath(API_NAME, 'password'), {clientId, body:{oldPassword, newPassword}});
}

export function validateToken(userId, token) {
  return request('GET', makePath(API_NAME, userId, 'activation'), {params: {token}});
}

export function acceptTsAndCs(clientId, version) {
  return request('POST', makePath(API_NAME, 'accepttsandcs'), {clientId, params:{version}});
}
