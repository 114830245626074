import React from 'react';
import PropTypes from 'prop-types';
import FatalError from './FatalError';

class ErrorBoundary extends React.Component
{
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
  }

  constructor(props)
  {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo)
  {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      hasError: true
    });
  }

  render()
  {
    if (this.state.hasError) {
      return (
        <FatalError
          title="An error has occurred"
          message={this.state.error.message}
          {...this.props}
        />
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
