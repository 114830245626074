import React from 'react';
import withRole from '../../hocs/withRole';
const SuperAdmin = React.lazy(() => import('./containers/SuperAdmin'));

/*
 * We use code splitting here mostly to test the pattern - it isn't
 * hugely valuable because the depdendent code for this module is still small.
 */

export default [
  {
    path: '/superadmin',
    exact: false,
    component: withRole(['Super Admin'], SuperAdmin),
    module: 'admin'
  }
];
