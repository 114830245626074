import React from 'react';
import PropTypes from 'prop-types';
import FilterSelect from './FilterSelect';
import { connect } from 'react-redux';
import { ClientStore } from '../ducks';

class ClientSelect extends React.Component {

  static propTypes = {
    clients: PropTypes.array.isRequired,
    allClients: PropTypes.array,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    isSuperUser: PropTypes.bool,
  };


  componentDidMount() {
    this.getClients();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.isAuthenticated && !this.props.isAuthenticated) || prevProps.profile !== this.props.profile) {
      this.getClients();
    }
  }

  getClients = (refresh=false) => {
    this.props.dispatch(ClientStore.list(refresh));
  };

  filterSelected = (clientList, selected, onChange, allowEmpty) => {
    const { profile } = this.props;
    if (clientList && clientList.length > 0) {
      if (!allowEmpty && !clientList.find(c => c.client_id === selected)) {
        if (profile && clientList.find(c => c.client_id === profile.default_client)) {
          selected = profile.default_client;
        } else {
          selected = clientList[0].client_id;
        }
        onChange(selected);
      }
    }
    return selected;
  }

  render() {
    const { clients, allClients, value, onChange, isSuperUser, allowEmpty=true } = this.props;
    const clientList = isSuperUser ? allClients : clients;
    const suggestions = clientList.map(c => ({label: `${c.nickname} (${c.client_id})`, value: c.client_id}));
    let selected = this.filterSelected(clientList, value, onChange, allowEmpty);

    return (
      <FilterSelect
        options={suggestions}
        value={selected}
        onChange={value => onChange(value)}
        placeholder="Client"
        maxItems={300}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isSuperUser: state.auth.isSuperUser,
    clients: state.clients.items,
    allClients: state.clients.all.items,
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(ClientSelect);
