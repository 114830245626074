import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const defaultMaxItems = 10;
const defaultWidth = 300;
const PREFIX = 'FilterSelect';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  singleValue: `${PREFIX}-singleValue`,
  placeholder: `${PREFIX}-placeholder`,
  paper: `${PREFIX}-paper`,
};


const Root = styled('div')(( )=>({
  [`&.${classes.root}`]: {
    zIndex: 103,
  },
  [`& .${classes.input}`]: {
    display: 'flex',
    width: 300,
    padding: 0,
  },
  [`& .${classes.valueContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
  },
  [`& .${classes.singleValue}`]: {
    fontSize: '0.8rem!important',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 10px)',
  },
  [`& .${classes.placeholder}`]: {
    position: 'absolute',
    fontSize: '0.8rem',
  },
  [`& .${classes.paper}`]: {
    position: 'absolute',
    margin: 0,
    left: 0,
    right: 0,
  },
}));

const inputComponent = React.forwardRef((props, ref) =>(
  <div ref={ref} {...props} />
));

inputComponent.displayName = 'inputComponent';

inputComponent.propTypes = {
  inputRef: PropTypes.func
};

function Control(props) {
  const inputStyle =  {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0.5rem',
    margin: 0,
    height: '20px',
    borderRadius: '4px',
    backgroundColor:'white',
    width: props.selectProps.width || defaultWidth
  };
  return (
    <TextField
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          style: inputStyle,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
          width: props.selectProps.width,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

Control.propTypes = {
  selectProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 900 : 400,
        fontSize: '0.8rem',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerRef: PropTypes.func,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

function SingleValue(props) {
  return (
    <Typography className={classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

function ValueContainer(props) {
  return <div className={classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

function Menu(props) {
  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

const MenuList = props => {
  const { maxItems } = props.selectProps;
  return (
    <components.MenuList {...props}>
      { Array.isArray(props.children) ? props.children.slice(0, maxItems) : props.children }
    </components.MenuList>
  );
};

MenuList.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

const customComponents = {
  Control,
  Menu,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MenuList,
};

class FilterSelectComponent extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array,
    loadOptions: PropTypes.func,
    maxItems: PropTypes.number,
    width: PropTypes.number,
  }

  render() {
    const { value, onChange, placeholder, options, loadOptions, maxItems, width } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit',
        },
      }),
      indicatorsContainer: base => ({
        ...base,
        marginTop: -8,
        marginBottom: -8,
      }),
    };

    const selected = options.find(c => c.value === value);
    if (!loadOptions) {
      return (
        <Root>
          <Select
            width={width}
            classes={classes}
            styles={selectStyles}
            className={classes.select}
            options={options}
            components={customComponents}
            value={selected}
            onChange={data => onChange(data ? data.value : null)}
            placeholder={placeholder}
            maxItems={maxItems || defaultMaxItems}
            isClearable
          />
        </Root>
      );
    } else {
      return (
        <Root>
          <AsyncSelect
            width={width}
            classes={classes}
            styles={selectStyles}
            value={selected}
            defaultOptions={options}
            loadOptions={loadOptions}
            components={customComponents}
            onChange={data => onChange(data ? data.value : null)}
            placeholder={placeholder}
            maxItems={maxItems || defaultMaxItems}
            isClearable
          />
        </Root>
      );
    }
  }
}

export default FilterSelectComponent;
