import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { colours } from '../../../components/analytics/helpers.js';
import ChartAxisTick from '../../../components/analytics/ChartAxisTick.js';
import ProfileTooltip from './ProfileTooltip.js';
import ContentItem from './ContentItem';
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const PREFIX = 'ProfileChart';

const classes = {
  container: `${PREFIX}-container`
};

const StyledContentItem = styled(ContentItem)({
  [`& .${classes.container}`]: {
    padding: '1rem 0.5rem',
  },
});

const xAxisKey = 'timestamp';
const yAxisKey = 'speed_section_kmh';
const yAxisLabel = 'km/h';

const ProfileChart = ({ data, onBarClick }) => {

  const [profileData, setProfileData] = useState([]);

  const updateProfileData = (data) => {
    if (data !== null && 'features' in data) {
      const formattedData = data.features.map((d) => {
        return d.properties;
      });
      setProfileData(formattedData);
    } else {
      setProfileData([]);
    }
  };

  const renderChart = () => (
    <ResponsiveContainer
      height={180}
      width='100%'
      margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
      <AreaChart data={profileData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} tick={<ChartAxisTick axis="x" />} />
        <YAxis tick={<ChartAxisTick axis="y" />} type="number" domain={[0, 120]} allowDataOverflow={true} label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
        <Tooltip content={<ProfileTooltip />}/>
        <Area dataKey={yAxisKey} fill={colours[0]} onClick={onBarClick} />
      </AreaChart>
    </ResponsiveContainer>
  );

  useEffect(() => {
    updateProfileData(data);
  }, [data]); // eslint-disable-line

  return (
    <StyledContentItem title="Trip speed profile">
      <div className={classes.container}>
        {renderChart()}
      </div>
    </StyledContentItem>
  );
};

ProfileChart.propTypes = {
  data: PropTypes.object,
  onBarClick: PropTypes.func,
};


export default ProfileChart;
