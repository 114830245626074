import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import ErrorIcon from '@mui/icons-material/Error';

const PREFIX = 'ErrorDialog';

const classes = {
  button: `${PREFIX}-button`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.button}`]: {
    fontWeight: 900,
  },

  [`& .${classes.errorIcon}`]: {
    height: 50,
    width: 50,
    marginTop: -5,
    verticalAlign: 'middle',
    marginRight: '0.6rem',
  }
}));

function Transition(props) {
  return <Slide direction="up" {...props}/>;
}

class ErrorDialog extends React.Component {

  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  render() {
    const { show=true, title, text, onClose, } = this.props;
    if (!show) return null;
    return (
      <StyledDialog
        TransitionComponent={Transition}
        open={show}
        onClose={this.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <ErrorIcon color="error" className={classes.errorIcon}/>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }

}

export default (ErrorDialog);
