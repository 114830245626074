import React, {useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import SettingsPanel from './SettingsPanel';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';


const TextFieldSelect = styled(TextField)(() => ({
  margin: 10,
  width: 220,
}));

const ConOpsSettingsPanel = ({onSave,client}) =>{
  const [isExpanded, setIsExpanded] = useState(false);
  const [conOps, setConOps] = useState(client.con_ops ?? '');
  const message =  client.con_ops !== null ? `Conops ${client.con_ops}` : 'Conops not set for account';
  const menuItems = [0,1,2,3,4,5];
  return (
    <SettingsPanel
      title="Client Conops"
      summary={message}
      onSave={() => {
        onSave({'con_ops': conOps});
        setIsExpanded(false);
      }}
      onCancel={() => {
        setConOps(client.con_ops);
        setIsExpanded(false);
      }}
      onExpand={(expanded) => setIsExpanded(expanded)}
      expanded={isExpanded}
    >
      <TextFieldSelect
        select
        label="Conops"
        value={conOps}
        onChange={(event) => setConOps(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {menuItems.map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextFieldSelect>
    </SettingsPanel>
  );
};

ConOpsSettingsPanel.propTypes = {
  client: PropTypes.object,
  onSave: PropTypes.func.isRequired,

};

export default ConOpsSettingsPanel;