import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import logo from '../../../assets/logo-full-pos.svg';
import bartonLogo from '../../../assets/national-pilot-banner.png';
import { FormattedMessage as Intl } from 'react-intl';
import config from 'config/config';

const PREFIX = 'AuthHeader';

const classes = {
  header: `${PREFIX}-header`,
  brand: `${PREFIX}-brand`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
  logoBarton: `${PREFIX}-logoBarton`,
};

const Root = styled('div')(() => ({
  [`&.${classes.header}`]: {
    display: 'flex',
    flexDirection: 'column',
    color: '#58595b',
    fontSize: '1.0rem',
    alignItems: 'center',
  },

  [`& .${classes.brand}`]: {
    display: 'flex',
    flexDirection: 'row',
    color: '#ffffff',
    fontSize: '1rem',
    alignItems: 'center',
    fontFamily: 'Barlow, sans-serif',
  },

  [`& .${classes.title}`]: {
    fontSize: '1.2rem',
  },

  [`& .${classes.logo}`]: {
    height: '4rem',
  },

  [`& .${classes.logoBarton}`]: {
    width: '420px',
  },
}));

const AuthHeader = ({title}) => (
  <Root className={classes.header}>
    <div className={classes.brand}>
      {
        config.isBarton 
          ? <img src={bartonLogo} alt={title} className={classes.logoBarton} /> 
          : <img src={logo} alt={title} className={classes.logo} />
      }
      
    </div>
    {!config.isBarton && <h2 className={classes.title}><Intl id={title} /></h2>}
  </Root>
);
AuthHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
export default (AuthHeader);
