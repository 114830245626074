import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import SettingsPanel from '../components/SettingsPanel';
const PREFIX = 'FTCReportSettingsPanel';

const classes = {
  switchRow: `${PREFIX}-switchRow`
};

const StyledSettingsPanel = styled(SettingsPanel)(() => ({
  [`& .${classes.switchRow}`]: {
    display: 'flex',
    alignItems: 'center',
  }
}));

class FTCReportSettingsPanel extends React.PureComponent {

  static propTypes = {
    client: PropTypes.object,
    onSave: PropTypes.func.isRequired,
  }

  state = {
    expanded: false,
    enabled: false,
  };

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.loadState();
    }
  }

  loadState = () => {
    this.setState({enabled: this.props.client.ftc_report_auto});
  }

  render() {
    const { onSave, client, } = this.props;
    const { expanded, enabled } = this.state;
    return (
      <StyledSettingsPanel
        title="Report Generation"
        summary={client.ftc_report_auto ? 'Generation enabled' : 'Generation disabled'}
        onSave={() => {
          onSave({ftc_report_auto: enabled});
          this.setState({expanded: false});
        }}
        onCancel={() => {
          this.loadState();
          this.setState({expanded: false});
        }}
        expanded={expanded}
        onExpand={(expanded) => this.setState({expanded})}
      >
        <div className={classes.switchRow}>
          <Switch
            checked={enabled}
            color="primary"
            onChange={(event) => this.setState({enabled: event.target.checked})}
          />
          Generate monthly reports automatically?
        </div>
      </StyledSettingsPanel>
    );
  }
}

export default (FTCReportSettingsPanel);
