import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { colours } from './helpers.js';
import ChartAxisTick from './ChartAxisTick.js';
import ChartTooltip from './ChartTooltip.js';
import ContentTitle from './ContentTitle.js';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';


const PREFIX = 'TimeSeriesBarChart';

const classes = {
  container: `${PREFIX}-container`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    flex: 1,
    width: '100%',
    height: '100%',
    padding: '8px',
  }
}));


// Assumes numeric values only
const CustomTooltip = ({ active, payload, dataKey, keyLabel }) => {
  if (!active || payload === null) {
    return null;
  }
  const label = payload[0].payload.date;
  const data = [
    {name: keyLabel, value: Math.round(payload[0].payload[dataKey]).toLocaleString()},
  ];
  return (<ChartTooltip heading={label} data={data} />);
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  dataKey: PropTypes.string,
  keyLabel: PropTypes.string,
};


class TimeSeriesBarChart extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.array.isRequired,
    xAxisKey: PropTypes.string.isRequired,
    yAxisKey: PropTypes.string.isRequired,
    yAxisLabel: PropTypes.string.isRequired,
    onBarClick: PropTypes.func,
    colourChoice: PropTypes.number,
  }

  render() {
    const { title = null, data, xAxisKey, yAxisKey, yAxisLabel, onBarClick,  colourChoice = 0 } = this.props;
    return (
      <Root className={classes.container}>
        {title !== null && <ContentTitle title={title} />}
        <ResponsiveContainer
          height={180}
          width='100%'
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} tick={<ChartAxisTick axis="x" />} />
            <YAxis tick={<ChartAxisTick axis="y" />} label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
            <Tooltip content={<CustomTooltip dataKey={yAxisKey} keyLabel={yAxisLabel} />}/>
            <Bar dataKey={yAxisKey} fill={colours[colourChoice]} onClick={onBarClick} />
          </BarChart>
        </ResponsiveContainer>
      </Root>
    );
  }
}

export default (TimeSeriesBarChart);
