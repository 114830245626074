export const fuelOpt = [
  {'value': 'DIESEL', 'label': 'Diesel'},
  {'value': 'ULP', 'label': 'Unleaded Petrol'},
  {'value': 'LPG', 'label': 'Liquid Petroleum Gas'},
  {'value': 'OTHER', 'label': 'Other'}
];

export const regoStateOpt = [
  {'value': '', 'label': 'Unknown'},
  {'value': 'NSW', 'label': 'New South Wales'},
  {'value': 'QLD', 'label': 'Queensland'},
  {'value': 'SA', 'label': 'South Australia'},
  {'value': 'TAS', 'label': 'Tasmania'},
  {'value': 'VIC', 'label': 'Victoria'},
  {'value': 'WA', 'label': 'Western Australia'},
  {'value': 'ACT', 'label': 'Australian Capital Territory'},
  {'value': 'NT', 'label': 'Northern Territory'}
];

export const transmissionOpt = [
  {'value': 'AUTOMATIC', 'label': 'Automatic'},
  {'value': 'MANUAL', 'label': 'Manual'},
  {'value': 'DUAL CLUTCH', 'label': 'Dual Clutch'},
  {'value': 'CVT', 'label': 'CVT'},
  {'value': 'ELECTRIC', 'label': 'Electric'}
];

export const rucVehicleType = [
  {'value': 'RIGID TRUCK', 'label': 'Rigid Truck'},
  {'value': 'ARTICULATED TRUCK', 'label': 'Articulated Truck'},
  {'value': 'TRAILER', 'label': 'Trailer'},
  {'value': 'BUS', 'label': 'Bus'},
  {'value': 'ARTICULATED BUS', 'label': 'Articulated Bus'},
  {'value': 'OTHER', 'label': 'Other'},
];

export const yesNoOpt = [
  {'value': 'true', 'label': 'Yes'},
  {'value': 'false', 'label': 'No'},
];

