import * as AnalyticsAPI from './analytics';
import * as AreasAPI from './areas';
import * as AuditLogsAPI from './audit_log';
import * as AuthAPI from './auth';
import * as ClientAPI from './clients';
import * as DBWorkersAPI from './dbworkers';
import * as FuelAPI from './fuel';
import * as JobsAPI from './jobs';
import * as RefsAPI from './refs';
import * as ReportFilesAPI from './reportFiles';
import * as ReportsAPI from './reports';
import * as RucReports from './rucReports';
import * as SourcesAPI from './sources';
import * as UserAPI from './users';
import * as VehicleAPI from './vehicles';
export {
  AuthAPI,
  ClientAPI,
  UserAPI,
  RefsAPI,
  VehicleAPI,
  AnalyticsAPI,
  ReportsAPI,
  ReportFilesAPI,
  FuelAPI,
  SourcesAPI,
  JobsAPI,
  DBWorkersAPI,
  AreasAPI,
  AuditLogsAPI,
  RucReports,
};
