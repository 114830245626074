import React ,{useEffect, useState}from 'react';
import {useSelector} from 'react-redux';
import {SelectInput,TextInput} from '../../../components/Form';
import SaveIcon from '@mui/icons-material/Save'; 
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import {VehicleAPI} from '../../../api';

const PREFIX = 'ReportAction';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display:'flex',
    alignItems:'center',
    width: '50%',
    margin: '0 auto'
  },
});


const ReportAction =({ name, refreshHealthDetails, selectedVehicleId}) =>{
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const categories = useSelector((state) => state.vehicles.categories.items);
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [vehicle,setVehicle] = useState({});


  const getElements =() =>{
    if (name ==='vehicle_category') {
      return  <SelectInput label="Vehicle Category" value={vehicle} values={formattedCategories} field="category" onChange={onChange} />;
    }

    if (name ==='make_model') {
      return(
        <>
          <TextInput label="Make" value={vehicle} field="make" required onChange={onChange}/>
          <TextInput label="Model" value={vehicle} field="model" required onChange={onChange}/>
        </>
      );
    }

    if (name ==='registration') {
      return(
        <>
          <TextInput label="Rego" value={vehicle} field="registration" onChange={onChange}/>
        </>
      );
    }
  };
  


  const onChange = (field, value) => {
    const updatedVehicle = {...vehicle, [field]: value};
    setVehicle(updatedVehicle);
  };

  const updateVehicle = () =>{
    VehicleAPI.update(clientId,selectedVehicleId, vehicle).then((res) => {
      if (res && res.status_code ===200){
        refreshHealthDetails();
      }
    })
      .catch(err => {
        console.log(err);
      });
  };


  useEffect(() => {
    const cats = categories.map(category => ({label: category, value: category}));
    setFormattedCategories(cats);
  }, [categories]); // eslint-disable-line

  return(
    <Root className={classes.root}>
      {getElements()}
      <IconButton onClick={updateVehicle}>
        <SaveIcon color={'primary'} fontSize={'medium'}/>
      </IconButton>
    </Root>
  );


};

ReportAction.propTypes = {
  name: PropTypes.string,
  refreshHealthDetails: PropTypes.func,
  selectedVehicleId: PropTypes.string
};


export default ReportAction;