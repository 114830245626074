import React from 'react';
import { Typography} from '@material-ui/core';

import {  styled } from '@material-ui/core/styles';
import { ReactComponent as TrendingUp} from '../../../assets/icons/trending-up-icon.svg';
import { ReactComponent as TrendingDown} from '../../../assets/icons/trending-down-icon.svg';
import {ReactComponent as NoChange } from '../../../assets/icons/no-change-icon.svg';
import PropTypes from 'prop-types';



const PREFIX = 'AnalyticsCard';

const classes = {
  card: `${PREFIX}-card`,
  imageText: `${PREFIX}-imageText`,
  imageTextTrendingUp: `${PREFIX}-imageTextTrendingUp`,
  imageTextTrendingDown: `${PREFIX}-imageTextTrendingDown`,
  imageContainer: `${PREFIX}-imageContainer`,
  cardImage: `${PREFIX}-cardImage`,
  cardText: `${PREFIX}-cardText`,
  subTextContainer: `${PREFIX}-subTextContainer`,
  cardSubText: `${PREFIX}-cardSubText`,
  imageAndTextContainer: `${PREFIX}-imageAndTextContainer`
};

const Root = styled('div')({
  [`&.${classes.card}`]: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '0.5rem',
    width: '11.5rem',
    marginTop: '0.5rem',
    marginRight: '1.3rem',
    marginBottom: '0.5rem',
  },
  [`& .${classes.imageText}`]: {
    display:'inline-block',
    fontSize:'0.7rem'
  },
  [`& .${classes.imageTextTrendingUp}`]: {
    color: '#059669',
  },
  [`& .${classes.imageTextTrendingDown}`]: {
    color: '#E97878',
  },
  [`& .${classes.imageContainer}`]: {
    height:'1.1rem',
    borderRadius:'8px',
    minWidth:'3rem',
    paddingLeft:'0.5rem',
    paddingRight:'0.5rem',
    backgroundColor:'rgba(115,115,115,0.1)',
    justifyContent:'center',
    marginLeft: 'auto',
    alignSelf:'end',
    display:'flex',
    marginBottom:'0.5rem'
  },
  [`& .${classes.cardImage}`]: {
    paddingTop:'0.1rem',
    height:'0.8rem',
    width:'0.8rem',
  },
  [`& .${classes.cardText}`]: {
    fontWeight:'600',
    color: '#202938'
  },
  [`& .${classes.subTextContainer}`]: {
    position: 'relative',
    left: 0,
  },
  [`& .${classes.cardSubText}`]: {
    paddingBottom:'0.5rem',
    fontSize: '0.7rem',
    justifyContent:'flex-start',
    color: '#737373',
    height:'2rem'
  },
  [`& .${classes.imageAndTextContainer}`]: {
    width:'100%',
    display:'inline-flex'
  }
});



const AnalyticsCard = ({percentage_change, percentage_change_type,title, value}) =>{


  const trendIconMap = {
    'Increase': <TrendingUp stroke={'#059669'} className={classes.cardImage}/>,
    'Decrease': <TrendingDown stroke={'#E97878'} className={classes.cardImage}/>,
    'NoChange': <NoChange stroke={'#737373'} className={classes.cardImage}/>
  };

  const trendTypographyMap = {
    'Increase': <Typography className={`${classes.imageTextTrendingUp} ${classes.imageText}`}
      variant={'body1'}>{percentage_change}</Typography>,
    'Decrease': <Typography className={`${classes.imageTextTrendingDown} ${classes.imageText}`} variant={'body1'}>{percentage_change}</Typography>,
    'NoChange': <Typography/>
  };

  const imageContainerBackgroundColor ={
    'Increase': 'rgba(5,150,105,0.1)',
    'Decrease': 'rgba(233,120,120,0.1)',
    'NoChange': 'rgba(115,115,115,0.1)'
  };

  return (
    <Root className={classes.card}>
      <div className={classes.subTextContainer}>
        <Typography className={classes.cardSubText} align={'left'}>{title}</Typography>
      </div>
      <div className={classes.imageAndTextContainer}>
        <Typography className={classes.cardText} variant={'h4'} align={'left'}>{value}</Typography>
        <div className={classes.imageContainer} style={{ backgroundColor : imageContainerBackgroundColor[percentage_change_type]}}>
          {trendTypographyMap[percentage_change_type] ||<Typography/>}
          {trendIconMap[percentage_change_type] || <NoChange stroke={'#737373'} className={classes.cardImage}/>}
        </div>
      </div>
    </Root>
  );
};

AnalyticsCard.propTypes= {
  percentage_change: PropTypes.number,
  percentage_change_type: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default AnalyticsCard;