import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import AreasTable from '../components/AreasTable';
import {LoadingStore} from '../../../ducks';
import { AreasAPI } from '../../../api';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {FabActionButton} from '../../../components/ActionButton';
import AddIcon from '@mui/icons-material/Add';
import {Link} from 'react-router-dom';
import { themeConfig } from 'ThemeProvider';

const PREFIX = 'Areas';

const classes = {
  page: `${PREFIX}-page`,
  headingBox: `${PREFIX}-headingBox`,
  pageHeading: `${PREFIX}-pageHeading`,
  subText: `${PREFIX}-subText`,
  tableContainer: `${PREFIX}-tableContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.page}`]: {
    margin: '0 auto 1rem auto',
    maxWidth: '70%',
    paddingLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginBottom: '3rem',
    },
  },

  [`& .${classes.headingBox}`]: {
    paddingTop: '1rem',
    paddingBottom: '0.4rem',
    marginBottom: '0',
    border: 'none',
  },

  [`& .${classes.pageHeading}`]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: themeConfig.palette.primary.main,
    marginBottom: '0.1rem',
  },

  [`& .${classes.subText}`]: {
    color: '#374151',
    fontSize: '0.9rem',
    marginTop: '5px',
  },

  [`& .${classes.tableContainer}`]: {
    maxWidth:'100%',
    marginBottom:'100px'
  },

  [`& .${classes.buttonContainer}`]: {
    display:'flex',
    justifyContent:'flex-end',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10,
    marginTop: 10,
  }
}));

const formatDate = d => {
  if (d)
    return moment(d).format('ll');
  else return '';
};



const Areas = () => {

  const dispatch = useDispatch();

  const clientId = useSelector((state) => state.clients.selectedItemId);
  const [customAreasList, setCustomAreasList] = useState([]);
  const getCustomAreasList = async () => {
    dispatch(LoadingStore.loading(true    ));
    try {
      const response = await AreasAPI.getCustomAreasList(clientId);
      const result = response[0].map(d=>({
        'id':d.id,
        'start_time': formatDate(d.start_time),
        'end_time': formatDate(d.end_time),
        'name': d.name,
        'source': d.source,
        'description': d.description,
        'admin_approved': d.admin_approved,
        'active': d.active,
      }));
      setCustomAreasList(result);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  const refreshCustomAreasList = () => {
    getCustomAreasList();
  };

  useEffect(() => {
    getCustomAreasList();
  }, []);// eslint-disable-line

  return (
    <Root className={classes.page}>
      <div className={classes.headingBox}>
        <h1 className={classes.pageHeading}>Custom Geofence Areas</h1>
        <p className={classes.subText}>
         Create and edit custom geofences that are applied to the activity apportionment for this account.
        </p>
        {customAreasList.length===0 && <p>Get Started by clicking the create button</p>}
        <div className={classes.buttonContainer}>
          <Link to={'/areas/new_area'}>
            <FabActionButton
              size="small"
              tooltip={'Create New Custom Area'}
              className={classes.button}
              color="primary"
              Icon={AddIcon}
            />
          </Link>
        </div>

      </div>
      <div className={classes.tableContainer}>
        <AreasTable customAreas ={customAreasList} refreshCustomAreasList={refreshCustomAreasList}/>
      </div>
    </Root>
  );
};
export default Areas;