import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const PREFIX = 'ClientAdminControl';

const classes = {
  button: `${PREFIX}-button`
};

const StyledLink = styled(Link)(() => ({
  [`& .${classes.button}`]: {
    color: '#fff',
  }
}));

class ClientAdminControl extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    profile: PropTypes.object,
    isSuperUser: PropTypes.bool,
    clientId: PropTypes.string,
  };

  isClientAdmin = () => {
    const { profile, clientId, isSuperUser } = this.props;
    if (isSuperUser) return true;
    return (
      profile &&
      clientId &&
      profile.roles[clientId] &&
      profile.roles[clientId].includes('Client Admin')
    );
  }

  render() {
    const isClientAdmin = this.isClientAdmin();

    if (!isClientAdmin) return null;

    return (
      <StyledLink to="/clientadmin">
        <IconButton className={classes.button} size="large">
          <SettingsIcon/>
        </IconButton>
      </StyledLink>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isSuperUser: state.auth.isSuperUser,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)(
  (ClientAdminControl)
);
