import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const PREFIX = 'Page';

const classes = {
  loggedIn: `${PREFIX}-loggedIn`,
  loggedOut: `${PREFIX}-loggedOut`
};

const Root = styled('div')(() => ({
  [`&.${classes.loggedIn}`]: {
    paddingTop: '52px',
    height: '100vh',
    width: '100%',
    color: '#333333',
    overflow: 'hidden',
  },

  [`&.${classes.loggedOut}`]: {
    paddingTop: '0',
    height: '100vh',
    width: '100%',
    color: '#333333',
    overflow: 'hidden',
  }
}));

const Page = ({ children }) => {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const className = isAuthenticated ? classes.loggedIn : classes.loggedOut;
  return <Root className={className}>{children}</Root>;
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
