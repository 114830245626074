import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';


const PREFIX = 'AuthFormGroup';

const classes = {
  control: `${PREFIX}-control`
};

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.control}`]: {
    marginBottom: '1rem',
  }
}));


class AuthFormGroup extends React.Component {

  static propTypes = {
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    glyph: PropTypes.string,
    autoComplete: PropTypes.string,
    label: PropTypes.string.isRequired,
    exposable: PropTypes.bool,
    helperText: PropTypes.node,
    value: PropTypes.string,
    onEnter: PropTypes.func,
    focus: PropTypes.bool,
  }

  state = {
    showPassword: false,
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if focus prop has changed to true, focus the input.
    if (this.props.focus && !prevProps.focus) {
      this.inputRef.current.focus();
    }
  }

  onTogglePassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  }

  inputProps = () => {
    const {type, glyph, exposable=false} = this.props;
    switch (type) {
    case 'email':
      return {
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      };
    case 'password':
      if (exposable) {
        return {
          type: this.state.showPassword ? 'text' : 'password',
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.onTogglePassword}
                size="large">
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        };
      } else {
        return {
          type: 'password',
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          )
        };
      }

    default:
      switch (glyph) {
      case 'account':
        return {
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        };
      default:
        return null;
      }
    }
  }

  onKeyUp = (event) => {
    if (event.keyCode === 13 && this.props.onEnter) {
      this.props.onEnter();
    }
  }

  render() {
    const {onChange, name, type, label, autoComplete, helperText, value='', focus=false, readOnly=false} = this.props;
    return (
      <StyledFormControl className={classes.control}>
        <TextField
          inputRef={this.inputRef}
          label={label}
          name={name}
          type={type}
          value={value}
          autoFocus={focus}
          InputLabelProps={{ shrink: true }}
          InputProps={this.inputProps()}
          onChange={onChange ? (event) => onChange(event.target.name, event.target.value) : undefined}
          autoComplete={autoComplete}
          helperText={helperText}
          disabled={readOnly}
          onKeyUp={this.onKeyUp}
        />
      </StyledFormControl>
    );
  }
}

export default (AuthFormGroup);
