import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ResetPasswordForm } from '../components';
import { UserAPI } from '../../../api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
const PREFIX = 'ChangePassword';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.title}`]: {

  }
}));

class ChangePassword extends React.Component {

  static propTypes = {
    email: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
  }

  state = {
    token: undefined,
    userId: undefined,
    errorText: undefined,
    username: undefined,
    redirect: false,
  }

  onCancel = () => {
    this.setState({redirect: true});
  }

  onReset = async (newPassword, oldPassword) => {
    const clientId = this.props.clientId;
    this.setState({errText: undefined});
    try {
      await UserAPI.setPassword(clientId, oldPassword, newPassword);
      this.setState({redirect: true});
    } catch (err) {
      if (err.body && err.body.message) {
        this.setState({errorText: err.body.message});
      } else {
        this.setState({errText: 'Unexpected error changing password.'});
      }
    }
  }

  render() {
    const { redirect, errorText } = this.state;
    const { email, } = this.props;
    if (redirect) return <Redirect to="/prism"/>;
    const title = <h1>Change Password</h1>;

    return (
      <Root className={classes.container}>
        <ResetPasswordForm
          title={title}
          buttonText="password_reset_button"
          username={email}
          onReset={this.onReset}
          onCancel={this.onCancel}
          homeLink={false}
          alertText={errorText}
          requireOldPassword={true}
        />
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    email: state.auth.profile.email,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)(withRouter((ChangePassword)));
