import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AreasDeleteDialog from './AreasDeleteDialog';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

const AreasAction =({id, refreshCustomAreasList})=> {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [viewArea, setViewArea] = React.useState(false  );
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const profile = useSelector((state) => state.auth.profile);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const canDelete = isSuperUser || profile.roles[clientId].includes('Areas Admin') || profile.roles[clientId].includes('Client Admin');

  const onViewButtonClicked = () =>{
    setViewArea(true    );
  };

  const onEditButtonClicked = () =>{
    console.log('Edit');
  };


  const onDeleteButtonClicked = () =>{
    setOpenDelete(true);
  };
  
  return (
    <div>
      <IconButton aria-label="view" onClick={onViewButtonClicked} size="large"><VisibilityIcon/></IconButton>
      <IconButton
        aria-label="edit"
        disabled={true}
        onClick={onEditButtonClicked}
        size="large"><EditIcon/></IconButton>
      <IconButton 
        aria-label="delete" 
        disabled={!canDelete}
        onClick={onDeleteButtonClicked} 
        size="large"><DeleteIcon/></IconButton>
      <AreasDeleteDialog open={openDelete} setOpen={setOpenDelete} id={id} refreshCustomAreasList={refreshCustomAreasList}/>
      { viewArea && <Redirect push to={`/areas/area/${id}`}/>}
    </div>
  );
};

AreasAction.propTypes = {
  id: PropTypes.string,
  refreshCustomAreasList : PropTypes.func
};

export default AreasAction;