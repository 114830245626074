import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import OkayIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

const PREFIX = 'FuelTable';

const classes = {
  okayIcon: `${PREFIX}-okayIcon`,
  errorIcon: `${PREFIX}-errorIcon`,
  tableCell: `${PREFIX}-tableCell`
};

const StyledTable = styled(Table)((
  {
    theme
  }
) => ({
  [`& .${classes.okayIcon}`]: {
    color: theme.palette.common.success,
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.common.error,
  },

  [`& .${classes.tableCell}`]: {
    paddingRight: '5px',
    paddingLeft: '5px',
  }
}));

const columns = [
  {field: 'client_vehicle_id', label: 'IVMS ID'},
  {field: 'make', label: 'Make'},
  {field: 'model', label: 'Model'},
  {field: 'registration', label: 'Registration'},
  {field: 'description', label: 'Description'},
  {field: 'activity', label: 'Activity'},
  {field: 'fuel_amount_l', label: 'Fuel (litres)'},
];

class FuelTable extends React.Component {

  static propTypes = {
    vehicles: PropTypes.array,
  };

  emptyState = () => (
    <div>No Vehicles</div>
  );

  statusIcon = (status) => {
    if (status === true) return <OkayIcon className={classes.okayIcon}/>;
    if (status === false) return <ErrorIcon className={classes.errorIcon}/>;
    return null;
  };

  tableRow = (v, i) => {
    return (
      <TableRow key={i}>
        <TableCell key="n" className={classes.tableCell}>{i + 1}</TableCell>
        {columns.map((col, j) => (
          <TableCell key={j} className={classes.tableCell}>
            {col.render ? col.render(v[col.field]) : v[col.field]}
          </TableCell>
        ))}
        <TableCell key="ok" className={classes.tableCell}>
          {this.statusIcon(v.status)}
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const {  vehicles } = this.props;
    if (vehicles === undefined || vehicles.length === 0) return this.emptyState();
    return (
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell key="n" className={classes.tableCell}></TableCell>
            {columns.map((col, i) => (<TableCell key={i} className={classes.tableCell}>{col.label}</TableCell>))}
            <TableCell key="ok" className={classes.tableCell}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((v, i) => this.tableRow(v, i))}
        </TableBody>
      </StyledTable>
    );
  }
}


export default (FuelTable);
