import { makeDateList, makeMonthFilter } from 'lib/date';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingStore } from 'ducks';
import PropTypes from 'prop-types';
import React from 'react';
import { AnalyticsAPI } from 'api';
import Tooltip from '@mui/material/Tooltip';
import Toolkit from '../../../components/Toolkit';

import moment from 'moment';
import { useState } from 'react';
import {
  Container,
  Section,
  PageHeader,
  MonthFilter,
} from '../components';


const ActivityCell = styled('div')(( ) => ({
  width: 16,
  height: 16,
  borderWidth: 1,
  borderColor: 'rgba(27, 31, 35, 0.06)',
  borderStyle: 'solid',
  backgroundColor: '#ebedf0',
  borderRadius: 2,
  margin: 2,
}));

const Active25 = styled(ActivityCell)(()=>({
  backgroundColor: '#9be9a8',
}));

const Active50 = styled(ActivityCell)(()=>({
  backgroundColor: '#40c463',
}));

const Active75 = styled(ActivityCell)(()=>({
  backgroundColor: '#30a14e',
}));

const Active100 = styled(ActivityCell)(()=>({
  backgroundColor: '#216e39',
}));

const DivActivityColumn = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const DivActivityDate = styled('div')(( ) => ({
  fontWeight:700,
  textAlign: 'center',
}));

const DivActivityLabel = styled('div')(( ) => ({
  paddingRight: 3,
  height: 20,
}));

const DivActivityTable = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '1rem',
}));


const ActivityTable = ({ activity, vehicles, dates, break_points: breakPoints }) => {

  if (activity === undefined) return null;
  const formatDayOfMonth = d => moment(d).date();
  const formatDate = d => moment(d).format('DD/MM/YY');

  const cellClass = d => {
    if (d >= breakPoints[3]) return <Active100/>;
    if (d >= breakPoints[2]) return <Active75/>;
    if (d >= breakPoints[1]) return  <Active50/>;
    return  <Active25/>;
  };

  const dayLabel = (date, distance) => formatDate(date) + ' ' + Math.round(distance) + ' km';

  const dayCell = (date, vehicle, distance) => {
    if (distance === undefined) {
      return <ActivityCell key={vehicle}></ActivityCell>;
    }
    return (
      <Tooltip key={vehicle} title={dayLabel(date, distance)} arrow classes={{ 
        fontSize: '1rem',
        backgroundColor: (theme) =>theme.palette.gray['800'],
      }}>
        {cellClass(distance)}
      </Tooltip>
    );
  };

  const dayColumn = (date, activity) => (
    <DivActivityColumn key={date}>
      <DivActivityDate>{formatDayOfMonth(date)}</DivActivityDate>
      {vehicles.map((v) => dayCell(date, v, activity[v]))}
    </DivActivityColumn>
  );

  const labelColumn = (
    <DivActivityColumn style={{whiteSpace: 'nowrap', maxWidth:'500px', overflow:'hidden'}}>
      <DivActivityLabel style={{fontWeight:700}}>Vehicle</DivActivityLabel>
      {vehicles.map((v) => (
        <DivActivityLabel key={v}>{v}</DivActivityLabel>
      ))}
    </DivActivityColumn>
  );

  return (
    <DivActivityTable>
      {labelColumn}
      {dates.map((d, i) => dayColumn(d, activity[i]))}
    </DivActivityTable>

  );
};

ActivityTable.propTypes = {
  activity: PropTypes.array,
  vehicles: PropTypes.array,
  dates: PropTypes.array,
  break_points: PropTypes.array,
};

const DailyActivityCalendar = ({ dateRange }) => {
  const { min: minDate, max: maxDate } = dateRange;
  const [activity, setActivity] = useState();
  const dateList = makeDateList(minDate, maxDate);
  const [date, setDate] = useState(dateList[0]);
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);

  React.useEffect(() => {
    if (!date) return;

    const fetchDailyActivity = async () => {
      dispatch(LoadingStore.loading(true));
      try {
        const filter = makeMonthFilter(date);
        const response = await AnalyticsAPI.getDailyActivity(clientId, filter);
        setActivity(response.payload);
      } catch (err) {
        console.error(err);
        setActivity(undefined);
      } finally {
        dispatch(LoadingStore.loading(false));
      }
    };
    fetchDailyActivity();

  }, [date, clientId, dispatch]);

  return (
    <Container>
      <PageHeader
        title="Daily vehicle activity"
        subtitle="A calendar view comparing distance travelled by each vehicle for each day across the month"
      />
      <Toolkit>
        <MonthFilter
          label="Select month:"
          filter={{ date: date }}
          onFilterChanged={setDate}
          dateList={dateList}
        />
      </Toolkit>
      <Section fullHeight>
        <ActivityTable {...activity} />
      </Section>
    </Container>
  );
};

DailyActivityCalendar.propTypes = {
  dateRange: PropTypes.object.isRequired,
};

export default DailyActivityCalendar;
