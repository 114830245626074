import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import MD5 from 'md5';

const PREFIX = 'Gravatar';

const avatarClasses = {
  avatar: `${PREFIX}-avatar`
};

const StyledAvatar
 = styled(Avatar
 )(() => ({
   [`&.${avatarClasses.avatar}`]: {
     height: 25,
     width: 25,
     marginRight: 10,
     color: '#fff',
     backgroundColor: '#ffffff',
   }
 }));

const url = (email) => email ? `https://secure.gravatar.com/avatar/${MD5(email)}?d=retro` : '';
const Gravatar = ({user}) => (
  <StyledAvatar
    alt={user.nickname}
    src={url(user.email)}
    className={avatarClasses.avatar}
  />
);

Gravatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default (Gravatar);
