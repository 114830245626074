import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';


const PREFIX = 'DataTable';

const classes = {
  paper: `${PREFIX}-paper`,
  headerCell: `${PREFIX}-headerCell`,
  row: `${PREFIX}-row`,
  cell: `${PREFIX}-cell`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.paper}`]: {
    height: '100%',
    flex: '1 0 auto',
    overflowY: 'scroll',
    borderRadius: '0',
  },

  [`& .${classes.headerCell}`]: {
    color: '#333333',
    fontWeight: '700',
    padding: '5px',
    whiteSpace: 'nowrap',
  },

  [`& .${classes.row}`]: {
    height: '1rem',
  },

  [`& .${classes.cell}`]: {
    padding: '5px',
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
  }
}));


function defaultCmp(a, b) {
  return (b < a) ? -1 : ((b > a) ? 1 : 0);
}

class DataTable extends React.Component {

  state = {
    orderBy: undefined,
    order: 'asc',
  };

  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelected: PropTypes.func,
    idField: PropTypes.string,
    onOrderChanged: PropTypes.func,
  };

  stableSort = (data, columns) => {
    const { orderBy, order } = this.state;
    if (orderBy === undefined || this.props.onOrderChanged !== undefined) return data;
    const cmp = columns[orderBy].sort || defaultCmp;
    const stabilizedData = data.map((row, index) => [this.sortValue(columns[orderBy], row), index]);
    stabilizedData.sort((a, b) => {
      const rel = order === 'asc' ? cmp(a[0], b[0]) : cmp(b[0], a[0]);
      if (rel !== 0) return rel;
      return a[1] - b[1];
    });
    return stabilizedData.map(row => data[row[1]]);
  };

  sortHandler = (orderBy) => {
    const order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';
    this.setState({order, orderBy});
    if (this.props.onOrderChanged !== undefined){
      this.props.onOrderChanged(order,this.props.columns[orderBy].sortField);
    }
  }

  cellValue = (column, row) => {
    const value = column.field instanceof Function ? column.field(row) : row[column.field];
    return (value === undefined || value === null) ? '' : value;
  };

  sortValue = (column, row) => {
    if (column.sortValue !== undefined) {
      return column.sortValue instanceof Function ? column.sortValue(row) : column.sortValue;
    } else {
      return this.cellValue(column, row);
    }
  };

  renderCell = (column, row) => {
    let value = this.cellValue(column, row);
    if (column.format) value = column.format(value, row);
    return value;
  };

  renderHeaderCell = (column, index) => {
    const {orderBy, order} = this.state;
    if (column.label instanceof Function) {
      return column.label();
    }
    if (column.sort === false) {
      return column.label;
    }
    return (
      <Tooltip
        title="Sort"
        enterDelay={300}
      >
        <TableSortLabel
          active={orderBy === index}
          direction={order}
          onClick={() => this.sortHandler(index)}
        >
          {column.label}
        </TableSortLabel>
      </Tooltip>
    );
  };

  render() {
    const {  data, columns, selected, onSelected, idField='id' } = this.props;
    const sortedData = this.stableSort(data, columns);
    return (
      <StyledPaper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.row}>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.headerCell}
                >
                  {this.renderHeaderCell(column, i)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map(row => (
              <TableRow
                key={row[idField]}
                selected={selected === row[idField]}
                onClick={onSelected ? () => onSelected(row) : undefined}
                hover={onSelected ? true : false}
                className={classes.row}
              >
                {columns.map(column => (
                  <TableCell key={column.id} align={column.align || 'left'} className={classes.cell}>
                    {this.renderCell(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPaper>
    );
  }
}

export default (DataTable);
