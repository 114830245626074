import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { formatValue } from './helpers.js';
import { Text } from 'recharts';

const PREFIX = 'ChartAxisTick';

const classes = {
  text: `${PREFIX}-text`
};

const Root = styled('g')(() => ({
  [`& .${classes.text}`]: {
    fontFamily: 'Helvetica',
    fill: '#000',
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#374151',
  }
}));

const ChartAxisTick = ({x, y, payload,  axis}) => {
  return (
    <Root transform={`translate(${x},${y})`}>
      <Text
        x={0}
        y={0}
        dy={axis === 'x' ? 16 : 4}
        width={120}
        className={classes.text}
        textAnchor={axis === 'x' ? 'middle' : 'end'}
      >
        {formatValue(payload.value)}
      </Text>
    </Root>
  );
};

ChartAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  axis: PropTypes.string,
  width: PropTypes.number,
};

export default (ChartAxisTick);
