import { request, makePath } from './http';
const API_NAME = 'analytics';

export function getDateRange(clientId) {
  return request('GET', makePath(API_NAME, 'date-range'), {clientId});
}

export function getClientStatus(clientId) {
  return request('GET', makePath(API_NAME, 'client-status'), {clientId});
}

export function getDailyMetrics(clientId) {
  return request('GET', makePath(API_NAME, 'daily-metrics'), {clientId});
}

export function getMonthlyVehicleMetrics(clientId, params) {
  return request('GET', makePath(API_NAME, 'monthly-vehicle-metrics'), {clientId, params});
}

export function getVehicleDateRange(clientId, params) {
  return request('GET', makePath(API_NAME, 'trips', 'vehicle-date-range'), {clientId, params});
}

export function getVehicleTripList(clientId, params) {
  return request('GET', makePath(API_NAME, 'trips', 'vehicle-trip-list'), {clientId, params});
}

export function getTripPoints(clientId, params) {
  return request('GET', makePath(API_NAME, 'trips', 'points'), {clientId, params});
}

export function getDailyActivity(clientId, params) {
  return request('GET', makePath(API_NAME, 'daily-activity'), {clientId, params});
}

export function getMonthlyIdling(clientId, params) {
  return request('GET', makePath(API_NAME, 'monthly-idling'), {clientId, params});
}

export function getMonthlySeries(clientId, params) {
  return request('GET', makePath(API_NAME, 'monthly-series'), {clientId, params});
}

export function getCrossSuburbStats(clientId, params) {
  return request('GET', makePath(API_NAME, 'cross-suburb-stats'), {clientId, params});
}

export function getMonthlyOverview(clientId) {
  return request('GET', makePath(API_NAME, 'overview/monthly'), {clientId});
}

export function getClientMetrics(params) {
  return request('GET', makePath(API_NAME, 'get-client-metrics'), {params});
}

export function getClientMetricsDateRange(data) {
  return request('POST', makePath(API_NAME, 'get-client-metrics-date-range'), {body: data});
}

export function updateClientMetrics(data) {
  return request('POST', makePath(API_NAME, 'update-client-metrics'), {body: data});
}
