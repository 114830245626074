import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { VehicleAPI } from '../../../api';
import FilterSelect from '../../../components/FilterSelect';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const PREFIX = 'Toolbar';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  label: `${PREFIX}-label`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 0,
  },
  [`& .${classes.item}`]: {
    marginRight: '1rem',
    zIndex:101
  },
  [`& .${classes.label}`]: {
    color: '#3c424a',
    fontSize: '0.7rem',
    fontWeight: '600',
    textAlign: 'left',
    margin: 0,
    paddingBottom: '0.3rem',
  },
});

const maxVehicles = 200;
const vehicleFilterWidth = 400;

// convert item list into an indexed list for faster access.
const indexReducer = (o, item) => {o[item.value] = item; return o;};
const indexItems = memoize(items => items.reduce(indexReducer, {}));

const Toolbar = ({ clientId, items, onChanged, selectedItem, onDateRangeChanged, selectedDateRange }) => {


  const onVehicleSearch = async (key) => {
    const indexedItems = indexItems(items);
    const response = await VehicleAPI.search(clientId, { query:key, limit:maxVehicles });
    const filteredVehicleList = response.payload.map(v => indexedItems[v.vehicle_id]);
    return filteredVehicleList.filter(i => !!i);
    // The above line filters out undefined records that appear because
    // the getVehicleList function in TripViewer.js excludes disabled vehicles
    // but the search API does not have this filter, so returns some items
    // that cannot be found in the indexedItems object. Undefined values in the
    // list populating the vehicle select component cause it to crash.
  };

  return (
    <Root className={classes.root}>
      <div className={classes.item}>
        <p className={classes.label}>Select Vehicle</p>
        <FilterSelect
          placeholder="id/rego/description"
          onChange={onChanged}
          loadOptions={onVehicleSearch}
          options={items}
          value={selectedItem}
          maxItems={maxVehicles}
          width={vehicleFilterWidth}
        />
      </div>
      <div className={classes.item}>
        <p className={classes.label}>Select Date Range</p>
        <DateRangePicker
          onChange={onDateRangeChanged}
          value={selectedDateRange}
          slotProps={{
            textField: {
              size: 'small',
              sx: { background: 'white' },
            },
          }}
        />
      </div>
    </Root>
  );
};

Toolbar.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string,
  onChanged: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  onDateRangeChanged: PropTypes.func,
  selectedDateRange: PropTypes.array,
};

export default Toolbar;
