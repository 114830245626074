import withRole from '../../hocs/withRole';
import Reports from './containers/Reports';

export default [
  {
    path: '/reports',
    exact: false,
    component: withRole(
      ['Vehicle Admin', 'Client Admin', 'Client View'],
      Reports
    ),
  },
];
