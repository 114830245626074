import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Stats from './components/Stats';
import ToolItem from './components/ToolItem';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import vehicleIcon from '../../assets/icons/vehicles-icon.svg';
import ftcReportsIcon from '../../assets/icons/reports-icon.svg';
import tripsIcon from '../../assets/icons/trips-icon.svg';
import analyticsIcon from '../../assets/icons/stats-icon.svg';
import fuelIcon from '../../assets/icons/fuel-icon.svg';
import adminIcon from '../../assets/icons/settings-icon.svg';
import areasIcon from '../../assets/icons/areas-icon.svg';
import vehicleIconBarton from '../../assets/icons/vehicles-icon-barton.svg';
import ftcReportsIconBarton from '../../assets/icons/reports-icon-barton.svg';
import tripsIconBarton from '../../assets/icons/trips-icon-barton.svg';
import analyticsIconBarton from '../../assets/icons/stats-icon-barton.svg';
import fuelIconBarton from '../../assets/icons/fuel-icon-barton.svg';
import adminIconBarton from '../../assets/icons/settings-icon-barton.svg';
import trailerIcon from '../../assets/icons/trailer-configuration-icon.svg';
import { LoadingStore } from '../../ducks';
import { ClientAPI, AnalyticsAPI, VehicleAPI } from '../../api';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import AnalyticsCard from './components/AnalyticsCard';
import config from 'config/config';
import nationalPilotBanner from '../../assets/national-pilot-banner.png';
import { themeConfig } from 'ThemeProvider';

const PREFIX = 'Menu';

const classes = {
  page: `${PREFIX}-page`,
  headingBox: `${PREFIX}-headingBox`,
  pageHeading: `${PREFIX}-pageHeading`,
  subText: `${PREFIX}-subText`,
  section: `${PREFIX}-section`,
  sectionHeading: `${PREFIX}-sectionHeading`,
  itemList: `${PREFIX}-itemList`,
  cardList: `${PREFIX}-cardList`,
  headingBoxLeft: `${PREFIX}-headingBoxLeft`,
  headingBoxRight: `${PREFIX}-headingBoxRight`,
  link: `${PREFIX}-link`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.page}`]: {
    margin: '0 auto 1rem auto',
    maxWidth: '70%',
    paddingLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginBottom: '3rem',
    },
  },

  [`& .${classes.headingBox}`]: {
    display: 'flex',
    paddingTop: '1rem',
    paddingBottom: '0.4rem',
    marginBottom: '0',
    border: 'none',
  },

  [`& .${classes.pageHeading}`]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: themeConfig.palette.primary.main,
    marginBottom: '0.1rem',
  },

  [`& .${classes.subText}`]: {
    color: '#6B7280',
    fontSize: '0.7rem',
    marginTop: '5px',
  },

  [`& .${classes.section}`]: {
    marginTop: '0.5rem',
    paddingBottom: '1rem',
    marginBottom: '0',
    borderTop: config.isBarton ? '1px dashed #A6D178' : '1px solid #D1D5DB',
  },

  [`& .${classes.sectionHeading}`]: {
    paddingTop: '0.2rem',
    fontSize: '0.9rem',
    fontWeight: 700,
    color: '#374151',
  },

  [`& .${classes.itemList}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'left',
  },

  [`& .${classes.cardList}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'left',
    paddingBottom: '1rem',
  },

  [`& .${classes.headingBoxLeft}`]: {
    width: '70%',
  },

  [`& .${classes.headingBoxRight}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 0,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const BartonImage = styled('img')(({ theme }) => ({
  width: '420px',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '50vw',
    marginBottom: '3rem',
  },
}));

const Menu = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const profile = useSelector((state) => state.auth.profile);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const client = useSelector((state) => state.clients.selectedItem);
  const [enableConfigSelector, setEnableConfigSelector] = useState(false);
  const canViewAreas =
    isSuperUser ||
    profile.roles[client['client_id']].includes('Areas Admin') ||
    profile.roles[client['client_id']].includes('Client Admin');
  const canViewVehicleConfiguration =
    enableConfigSelector && (isSuperUser ||
    profile.roles[client['client_id']].includes('Vehicle Admin') ||
    profile.roles[client['client_id']].includes('Client Admin'));
  const canViewAuditReports = isSuperUser ||
    profile.roles[clientId].includes('Client Admin') ||
    profile.roles[clientId].includes('Audit Reports');
  const userName = profile ? profile.nickname : 'stranger';
  const lastLogin = profile
    ? moment(profile.last_login).format('MMMM Do YYYY, h:mm:ss a')
    : '';
  const analyticsEnabled =
    client && client.features.includes('analytics_module');

  const [overallHealth, setOverallHealth] = useState({});
  const [monthlyOverview, setMonthlyOverview] = useState([]);
  const prvDateMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1
  );

  const getClientHealthOverview = async () => {
    dispatch(LoadingStore.loading(true));
    try {
      return await ClientAPI.health(clientId);
    } catch (error) {
      console.log(error);
    }
  };

  const getIsConfigSelectorEnabled = async () => {
    dispatch(LoadingStore.loading(true));
    try {
      return await VehicleAPI.isConfigSelectorEnabled(clientId);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientMonthlyOverview = async () => {
    dispatch(LoadingStore.loading(true));
    try {
      return await AnalyticsAPI.getMonthlyOverview(clientId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMonthlyOverview({});
    getClientHealthOverview().then((r) => setOverallHealth(r.message.data));
    getClientMonthlyOverview().then((r) => {
      setMonthlyOverview(r.payload.data);
    });
    getIsConfigSelectorEnabled().then((r) => {
      setEnableConfigSelector(r.payload.is_config_selector_enabled);
    });
    dispatch(LoadingStore.loading(false));
  }, [clientId]); // eslint-disable-line

  return (
    <Root className={classes.page}>
      <div className={classes.headingBox}>
        <div className={classes.headingBoxLeft}>
          {config.isBarton ? (
            <div>
              <BartonImage
                src={nationalPilotBanner}
                alt="National pilot banner"
              />
            </div>
          ) : (
            <>
              <h1 className={classes.pageHeading}>Welcome back {userName}</h1>
              <p className={classes.subText}>Last login: {lastLogin}</p>
            </>
          )}
        </div>
        <div>
          {!isLoading && overallHealth.action === 'success' && (
            <Alert severity={'success'}>{overallHealth.message}</Alert>
          )}
          {!isLoading && overallHealth.action === 'warning' && (
            <Link to="/account_health" className={classes.link}>
              <Alert severity={'warning'}>{overallHealth.message}</Alert>
            </Link>
          )}
        </div>
      </div>
      <div className={classes.section}>
        <h2 className={classes.sectionHeading}>Tools</h2>

        <div className={classes.itemList}>
          {config.isBarton ? (
            <>
              <ToolItem
                to="/vehicles"
                label="Vehicles"
                image={vehicleIconBarton}
              />
              {canViewVehicleConfiguration && (
                <ToolItem
                  to="/vehicleconfiguration"
                  label="Configuration Selector"
                  image={trailerIcon}
                />
              )}
              <ToolItem
                to="/invoices"
                label="RUC Documents"
                image={ftcReportsIconBarton}
              />
              <ToolItem
                to="/reports"
                label="Reports"
                image={ftcReportsIconBarton}
              />
              <ToolItem
                to="/tripviewer"
                label="Trip Viewer"
                image={tripsIconBarton}
              />
              {client && analyticsEnabled && (
                <ToolItem
                  to="/analytics"
                  label="Analytics"
                  image={analyticsIconBarton}
                />
              )}
              {client && client.use_client_fuel && (
                <ToolItem
                  to="/fuel"
                  label="Fuel Records"
                  image={fuelIconBarton}
                />
              )}
              {canViewAuditReports && (
                <ToolItem
                  to="/auditreports"
                  label="Audit Reports"
                  image={ftcReportsIconBarton}
                />
              )}
              {isSuperUser && (
                <ToolItem
                  to="/superadmin"
                  label="Admin"
                  image={adminIconBarton}
                />
              )}
            </>
          ) : (
            <>
              <ToolItem to="/vehicles" label="Vehicles" image={vehicleIcon} />
              <ToolItem
                to="/tripviewer"
                label="Trip Viewer"
                image={tripsIcon}
              />
              <ToolItem
                to="/ftcreports"
                label="FTC Reports"
                image={ftcReportsIcon}
              />
              <ToolItem
                to="/reports"
                label="Reports"
                image={ftcReportsIcon}
              />
              {canViewAuditReports && (
                <ToolItem
                  to="/auditreports"
                  label="Audit Reports"
                  image={ftcReportsIcon}
                />
              )}
              {client && analyticsEnabled && (
                <ToolItem
                  to="/analytics"
                  label="Analytics"
                  image={analyticsIcon}
                />
              )}
              {client && client.use_client_fuel && (
                <ToolItem to="/fuel" label="Fuel Records" image={fuelIcon} />
              )}
              {canViewAreas && (
                <ToolItem to="/areas" label="Areas" image={areasIcon} />
              )}
              {isSuperUser && (
                <ToolItem to="/superadmin" label="Admin" image={adminIcon} />
              )}
            </>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <h2 className={classes.sectionHeading}>Stats</h2>
        {monthlyOverview.length > 0 && (
          <p className={classes.subText}>
            Stats for {prvDateMonth.toLocaleString('en-US', { month: 'long' })},{' '}
            {prvDateMonth.toLocaleString('en-US', { year: 'numeric' })}
          </p>
        )}
        <div className={classes.cardList}>
          {monthlyOverview.length > 0 &&
            monthlyOverview.map((row) => {
              return (
                <AnalyticsCard
                  key={row.key}
                  percentage_change={row.percentage_change}
                  percentage_change_type={row.percentage_change_type}
                  title={row.title}
                  value={row.value}
                />
              );
            })}
        </div>
        <Stats />
      </div>
    </Root>
  );
};

export default Menu;
