import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';


const DrawControl = (props) => {
  let draw = useControl(({ map }) => {
    map.on('draw.create', props.onCreate);
    map.on('draw.update', props.onUpdate);
    map.on('draw.delete', props.onDelete);
    map.on('draw.combine', props.onCombine);
    map.on('draw.uncombine', props.onUnCombine);

    map.on('load', function () {
      if (props.feature) {
        props.feature.forEach((f)=>{
          draw.add(f);
        }
        );}
    });
    return new MapboxDraw(props);
  }, ({ map }) => {
    map.off('draw.create', props.onCreate);
    map.off('draw.update', props.onUpdate);
    map.off('draw.delete', props.onDelete);
    map.off('draw.combine', props.onCombine);
    map.off('draw.uncombine', props.onUnCombine);
  }, {
    position: props.position
  });
  return null;
};

DrawControl.defaultProps = {
  onCreate: () => { },
  onUpdate: () => { },
  onDelete: () => { },
  onCombine:()=> { },
  onUnCombine:()=> { }
};

export default DrawControl;