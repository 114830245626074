import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import Form, { Section, Title } from '../../../components/Form';
import FuelTable from './FuelTable';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import {FuelAPI} from '../../../api';

const PREFIX = 'UploadReviewForm';

const classes = {
  radioGroup: `${PREFIX}-radioGroup`,
  actionButton: `${PREFIX}-actionButton`,
  toolbar: `${PREFIX}-toolbar`,
  infoText: `${PREFIX}-infoText`
};

const Root = styled('div')(() => ({
  [`& .${classes.radioGroup}`]: {
    flexDirection: 'row',
    marginLeft: 40,
  },

  [`&.${classes.actionButton}`]: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontWeight: 900,
    width: '10rem',
  },

  [`& .${classes.toolbar}`]: {
    minHeight: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.infoText}`]: {
    display: 'flex',
    fontWeight: 900,
  }
}));

const READY = 'ready';
const DONE = 'done';
const UPLOAD = 'upload';
const CANCEL = 'cancel';

class UploadReviewForm extends React.Component {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    fileName: PropTypes.string,
    newObjects: PropTypes.array.isRequired,
    objects: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    clientId: PropTypes.string,
    title: PropTypes.string.isRequired,
    fuelMonthStartDate: PropTypes.string.isRequired,
  };

  state = {
    action: READY,
    method: UPLOAD,
    cancelled: false,
    status: [],
    progress: 0,
  };

  onSave = async () => {
    const { clientId, fuelMonthStartDate, newObjects } = this.props;

    const newVehicles = newObjects.filter(v => v.fuel_amount_l > 0);
    const opCount = newVehicles.length;
    this.setState({action: UPLOAD, progress: 0, progressRange: opCount});

    await FuelAPI.create(clientId, {
      month_start_date: fuelMonthStartDate,
      vehicles: newVehicles,
    }).then(() => {
      const newStatus = newObjects.map(() => { return true; });
      this.setState({progress: opCount, status: newStatus});
      this.props.refresh();
    }).then(() => {
      this.setState({action: DONE});
      this.props.refresh();
    });
  };

  onClose = () => {
    this.setState({action: CANCEL});
    this.props.onClose();
  };

  onChangeMethod = (event) => {
    this.setState({method: event.target.value});
  };

  infoText = () => {
    const { newObjects } = this.props;
    const { action } = this.state;

    if (action === DONE) return 'Done';
    if (action === CANCEL) return 'Cancelled';

    const updateCount = newObjects.filter(v => v.fuel_amount_l > 0).length;
    if (updateCount > 0) {
      return `Upload ${updateCount}`;
    } else {
      return 'No changes';
    }
  };

  actionButton = () => {
    const { action } = this.state;
    if (action === READY) return (
      <Button variant="contained" color="primary" className={classes.actionButton} onClick={() => this.onSave()}>
        Continue
        <DoneIcon/>
      </Button>
    );
    return <Root className={classes.actionButton}/>;
  };

  renderToolbar = () => {
    const { action, method } = this.state;
    const methodDisabled = action !== READY;
    return (
      <div className={classes.toolbar}>
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="method"
              name="method"
              className={classes.radioGroup}
              value={method}
              onChange={this.onChangeMethod}
            >
              <FormControlLabel
                value={UPLOAD}
                control={<Radio color="primary" disabled={methodDisabled}/>}
                label="Upload"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.infoText}>{this.infoText()}</div>
        {this.actionButton()}
      </div>
    );
  };

  render() {
    const { fileName, newObjects, title } = this.props;
    const { status, progress, progressRange } = this.state;
    const progressPercent = progressRange ? 100 * progress / progressRange : 0;
    return (
      <div>
        <Form>
          <div align="left">
            <IconButton onClick={this.onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
          <Title>{title}</Title>
          {this.renderToolbar()}
          <div style={{height: 5}}>
            <LinearProgress variant="determinate" value={progressPercent} />
          </div>
          <Section title={fileName}>
            <FuelTable vehicles={newObjects.map((v, i) => Object.assign({status: status[i]}, v))}/>
          </Section>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)((UploadReviewForm));