import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { groupData } from 'components/analytics/helpers.js';
import ChartAxisTick from 'components/analytics/ChartAxisTick.js';
import ChartTooltip from 'components/analytics/ChartTooltip.js';
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Legend } from 'recharts';
import './Scatterplot.css';

const PREFIX = 'Scatterplot';

const classes = {
  container: `${PREFIX}-container`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    flex: 1,
    height: '700px',
  }
}));

// Use more vibrant colours for this scatter plot
const bubbleColours = ['#009DDC', '#F26430', '#CC2936', '#2BA84A', '#5995ED', '#FFAD05'];

const CustomTooltip = ({ active, xAxisKey, yAxisKey, payload }) => {
  if (active) {
    const vehicle = payload[0].payload;
    const data = [
      {name: 'Prism ID', value: vehicle.vehicle_id},
      {name: 'make', value: vehicle.make},
      {name: 'model', value: vehicle.model},
      {name: 'category', value: vehicle.category},
      {name: 'fuel_type', value: vehicle.fuel_type},
      {name: xAxisKey, value: Math.round(vehicle[xAxisKey]).toLocaleString()},
      {name: yAxisKey, value: Math.round(vehicle[yAxisKey]).toLocaleString()},
    ];
    const heading = `Rego: ${vehicle.registration}`;
    return (<ChartTooltip heading={heading} data={data} />);
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  xAxisKey: PropTypes.string,
  yAxisKey: PropTypes.string,
};

const Scatterplot = ({ data, xAxisKey, yAxisKey, colorKey, showLabels }) => {

  if (data === null || data === undefined) return null;
  const groupedData = groupData(data, colorKey);
  return (
    <Root className={classes.container}>
      <ResponsiveContainer margin={{ top: 0, right: 10, bottom: 10, left: 10 }} height='80%'>
        <ScatterChart data={groupedData} margin={{top: 20, left: 10, right: 10}}>
          <XAxis dataKey={xAxisKey} type='number' tick={<ChartAxisTick axis='x' />} />
          <YAxis dataKey={yAxisKey} type='number' tick={<ChartAxisTick axis='y' />} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={<CustomTooltip xAxisKey={xAxisKey} yAxisKey={yAxisKey} /> } />
          <Legend verticalAlign='top' />
          {groupedData.map((d, i) =>
            <Scatter
              name={d.name}
              data={d.data}
              key={i}
              fill={bubbleColours[i]}
            >
              {showLabels ? <LabelList dataKey='registration' position='left' /> : null}
            </Scatter>
          )}
        </ScatterChart>
      </ResponsiveContainer>
    </Root>
  );
};

Scatterplot.propTypes = {
  data: PropTypes.array,
  xAxisKey: PropTypes.string.isRequired,
  yAxisKey: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
  showLabels: PropTypes.bool.isRequired,
};

export default Scatterplot;
