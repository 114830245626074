const FIRST_INTERVAL = 3000;
const FAST_INTERVAL = 1000;
const SLOW_INTERVAL = 30000;
const TIMEOUT = 120000;

/*
 * Operation:
 *   - pass a fetchData fn into the constructor that returns an array of objects
 *   - optionally pass a list of objects you expect to be updated
 *   - call start(o) with an object
 * The poller will call fetchData until it gets back an updated version
 * of the object passed to start.
 */

export default class Poller {
  constructor(fetchData) {
    this.fetchData = fetchData;
    this.matchObject = undefined;
    this.list = [];
    this.interval = undefined;
    this.timer = undefined;
  }

  watch = (object, matchObject) => {
    this.stop();
    this.matchObject = matchObject;
    this.list.push(object);
    this.interval = FAST_INTERVAL;
    this.startTime = Date.now();
    this.timer = setTimeout(this.poll, FIRST_INTERVAL);
  }

  start = () => {
    this.stop();
    this.interval = SLOW_INTERVAL;
    this.startTime = Date.now();
    this.timer = setTimeout(this.poll, FIRST_INTERVAL);
  }

  clearObject = (object) => {
    const index = this.list.findIndex(o => this.matchObject(object, o));
    if (index >= 0) {
      this.list.splice(index, 1);
    }
  }

  poll = async() => {
    const update = await this.fetchData();
    if (this.matchObject) {
      if (update !== undefined) {
        update.forEach(o => this.clearObject(o));
      }
      const done = this.list.length === 0 || (Date.now() - this.startTime) > TIMEOUT;
      if (done) {
        this.timer = undefined;
        return;
      }
    }
    this.timer = setTimeout(this.poll, this.interval);
    this.interval = this.interval * 1.2;
    if (this.interval > SLOW_INTERVAL) this.interval = SLOW_INTERVAL;
  }

  stop = () => {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }
}
