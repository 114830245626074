import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SettingsPanel from '../components/SettingsPanel';
import { SourcesAPI } from '../../../api';
import { SourcesStore } from '../../../ducks';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import DataTable from '../../../components/DataTable';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import SubscriptionConfig from './SubscriptionConfig';
import moment from 'moment-timezone';

const DivContent = styled('div')(() => ({
  height: '100%',
}));

const DivRight = styled('div')(() => ({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
}));

const timeZoneName = moment.tz(moment.tz.guess()).zoneAbbr();
const formatElapsed = (utc) => utc ? moment.utc(utc).fromNow() : null;
const formatLocal = (utc) => utc ? moment.utc(utc).local().format('YYYY-MM-DDTHH:mm:ss') : null;
const formatBoolean = (value) => value ? <CheckIcon/> : <CrossIcon color="error"/>;

class SourceSettingsPanel extends React.PureComponent {

  static propTypes = {
    client: PropTypes.object,
    systems: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    expanded: false,
    enabled: false,
    subscriptions: undefined,
    loading: false,
    drawerOpen: false,
    selected: undefined,
  };

  componentDidMount() {
    this.props.dispatch(SourcesStore.getSystems());
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client) {
      this.reload();
    }
  }

  systemName = (systemId) => {
    const { systems } = this.props;
    const system = systems[systemId];
    return system ? system.name : systemId;
  }

  summaryText = (subscriptions) => {
    if (subscriptions === undefined) return '...';
    if (subscriptions.length === 0) return 'No sources configured';
    const summaries = subscriptions.map(s => `${this.systemName(s.system_id)} ${s.active ? '' : '(disabled)'}`);
    return summaries.join(', ');
  }

  reload = async () => {
    const { client } = this.props;
    this.setState({loading: true});
    const result = await SourcesAPI.getSubscriptions(client.client_id);
    this.setState({loading: false, subscriptions: result.payload});
  }

  /*
  {
    "id": 95,
    "credentials": "AQICAHg+ElraCQs2sGEQnmyYTY4bAlPwSfN4Jnp7GwVchkUmNgHSkBGzq8slzlU6+01IE0A+AAAAcDBuBgkqhkiG9w0BBwagYTBfAgEAMFoGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMrT1nxpiVg3C1ErS3AgEQgC2QBPN34+gL7kFmKcgK3o2tl5orFt7oW6W4UQ7Ff/BtRY/bcg5XhTgC1GVnJDM=",
    "args": {
      "period": "1:00:00:00"
    },
    "client_id": "2e34412b",
    "start_time": "2019-04-30T14:00:00",
    "end_time": null,
    "active": false,
    "system_id": 5,
    "schedule": "1:00:00:00",
    "created_at": "2019-05-28T22:53:22.418973",
    "updated_at": null
  }
  */
  columns = [
    {
      id: 'system_id',
      label: 'System',
      field: 'system_id',
      format: this.systemName,
    },
    {
      id: 'start_time',
      label: `Start (${timeZoneName})`,
      field: 'start_time',
      format: formatLocal,
    },
    {
      id: 'end_time',
      label: `End (${timeZoneName})`,
      field: 'end_time',
      format: formatLocal,
    },
    {
      id: 'created_at',
      label: 'Created',
      field: 'created_at',
      format: formatElapsed,
    },
    {
      id: 'active',
      label: 'Enabled',
      field: 'active',
      format: formatBoolean,
    },
  ];



  onAdd = () => {
    this.setState({drawerOpen: true, selected: {}});
  }

  onSelected = (subscription) => {
    this.setState({drawerOpen: true, selected: subscription});
  }

  onCloseDrawer = () => {
    this.setState({drawerOpen: false});
  }

  renderSubscriptions = (subscriptions, selected) => {
    if (subscriptions === undefined) return '...';
    if (subscriptions.length === 0) return null;
    return <DataTable
      data = {subscriptions}
      columns={this.columns}
      selected={selected ? selected.id : undefined}
      onSelected={this.onSelected}
    />;
  }

  render() {
    const { expanded, subscriptions, drawerOpen, selected } = this.state;
    return (
      <SettingsPanel
        title="Source Settings"
        summary={this.summaryText(subscriptions)}
        expanded={expanded}
        onExpand={(expanded) => this.setState({expanded})}
      >
        <DivContent>
          {this.renderSubscriptions(subscriptions, selected)}
          <DivRight>
            <Button
              variant="contained"
              size="small"
              onClick={this.onAdd}
              color="primary"
            >
                Add Source
            </Button>
          </DivRight>
        </DivContent>
        <Drawer
          anchor='right'
          open={drawerOpen}
          onClose={this.onCloseDrawer}>
          <SubscriptionConfig
            subscription={selected}
            onClose={() => {this.reload(); this.onCloseDrawer();}}
            onCreate={subscription => {this.reload(); this.setState({selected: subscription});}}
            onDelete={() => {this.reload(); this.onCloseDrawer();}}
            onSave={this.reload}
          />
        </Drawer>
      </SettingsPanel>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.clients.selectedItem,
    systems: state.sources.systems.items,
  };
};

export default connect(mapStateToProps)(
  (SourceSettingsPanel)
);
