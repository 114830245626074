import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Input from '@mui/material/Input';

const PREFIX = 'NewFileReportDialog';

const classes = {
  button: `${PREFIX}-button`,
  leftButton: `${PREFIX}-leftButton`,
  toggleButton: `${PREFIX}-toggleButton`,
  formRow: `${PREFIX}-formRow`,
  radioLabel: `${PREFIX}-radioLabel`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.button}`]: {
    fontWeight: 900,
  },

  [`& .${classes.leftButton}`]: {
    marginRight: 'auto',
  },

  [`& .${classes.toggleButton}`]: {
    marginTop: '5rem',
  },

  [`& .${classes.formRow}`]: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.radioLabel}`]: {
    fontSize: '0.75rem',
  }
}));

class NewFileReportDialog extends React.Component {

  static propTypes = {
    show: PropTypes.bool,
    onAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    reportingMonths: PropTypes.array,
    formats: PropTypes.array.isRequired,
    hideAdvanced: PropTypes.bool,
  }

  state = {
    date: undefined,
    startDate: undefined,
    endDate: undefined,
    selectedFormats: {},
    advanced: false,
  }

  componentDidMount() {
    const { reportingMonths, formats } = this.props;
    if (reportingMonths) {
      this.setState({
        date: reportingMonths[0].value,
        endDate: reportingMonths[0].value,
        startDate: reportingMonths[this.props.reportingMonths.length-1].value,
        format: formats[0].format,
      });
    }
  }

  yearMonth = (date) => {
    return this.props.reportingMonths.find(d => d.value === date);
  }

  elapsedMonths = (date1, date2) => {
    const delta = (date1.year * 12 + date1.month) - (date2.year * 12 + date2.month);
    return delta;
  }

  onAction = () => {
    const { advanced, date, startDate, endDate, selectedFormats } = this.state;
    let year, month, months;
    if (advanced) {
      const date1 = this.yearMonth(startDate);
      const date2 = this.yearMonth(endDate);
      const delta = this.elapsedMonths(date1, date2);
      const date = delta > 0 ? date1 : date2;
      ({year, month} = date);
      months = Math.abs(delta) + 1;
    } else {
      ({ year, month } = this.yearMonth(date));
      months = 1;
    }

    // eslint-disable-next-line
    for (const format in selectedFormats) {
      if (selectedFormats[format]) {
        this.props.onAction(year, month, months, format, advanced);
      }
    }
  }

  toggleFormat = (format, selected) => {
    this.setState((state) => {
      let selectedFormats = state.selectedFormats;
      selectedFormats[format] = selected;
      return {selectedFormats};
    });
  }

  basicForm() {
    const { reportingMonths, } = this.props;
    const { date } = this.state;

    return (
      <React.Fragment>
        <FormGroup>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-date">Time Period</InputLabel>
            <Select
              value={date}
              input={<Input id="select-date" />}
              onChange={event => this.setState({date: event.target.value})}
            >
              {reportingMonths.map((date) => (
                <MenuItem key={date.value} value={date.value}>{date.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </React.Fragment>
    );
  }

  advancedForm() {
    const { reportingMonths, } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="start-date">Start Month</InputLabel>
          <Select
            value={startDate}
            input={<Input id="start-date" />}
            onChange={event => this.setState({startDate: event.target.value})}
          >
            {reportingMonths.map((date) => (
              <MenuItem key={date.value} value={date.value}>{date.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="end-date">End Month</InputLabel>
          <Select
            value={endDate}
            input={<Input id="end-date" />}
            onChange={event => this.setState({endDate: event.target.value})}
          >
            {reportingMonths.map((date) => (
              <MenuItem key={date.value} value={date.value}>{date.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }

  formatControl() {
    const { formats, } = this.props;
    const { selectedFormats } = this.state;
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.radioLabel}>Format</FormLabel>
        <FormGroup
          aria-label="format"
          name="format"
          className={classes.group}
          onChange={(event) => this.setState({format: event.target.value})}
        >
          {formats.map(format => (
            <FormControlLabel
              key={format.format}
              value={format.format}
              control={<Checkbox checked={selectedFormats[format.format] === true} color="primary" onChange={(event) => this.toggleFormat(format.format, event.target.checked)}/>}
              label={format.label}
              labelPlacement="start"
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  }

  render() {
    const { show, onCancel, } = this.props;
    const { advanced, selectedFormats } = this.state;
    const anyFormat = Object.keys(selectedFormats).some(f => selectedFormats[f]);
    if (!show) return null;
    return (
      <StyledDialog
        open={show}
        onClose={this.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select the report month and format for the generated report.  The time period list below includes all months for which data is available.
          </DialogContentText>

          <div className={classes.formRow}>
            {advanced ? this.advancedForm() : this.basicForm()}
            {this.formatControl()}
          </div>

        </DialogContent>
        <DialogActions>
          {
            !this.props.hideAdvanced && <Button variant="outlined" className={classes.leftButton} onClick={()=>this.setState({advanced: !advanced})}>
              {advanced ? 'Basic →' : 'Advanced →'}
            </Button>
          }
          <Button variant="contained" onClick={onCancel} className={classes.button} autoFocus>
            Cancel
          </Button>
          <Button variant="contained" disabled={!anyFormat} color="primary" onClick={this.onAction} className={classes.button}>
            Continue
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }

}

export default (NewFileReportDialog);
