import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Container as MuiContainer } from '@mui/material';

const PREFIX = 'Container';

const classes = {
  root: `${PREFIX}-root`
};

const StyledMuiContainer = styled(MuiContainer)(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    backgroundColor: '#F3F4F6',
  }
}));

const Container = ({ children }) => {

  return (
    <StyledMuiContainer maxWidth="xl" className={classes.root}>
      {children}
    </StyledMuiContainer>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};

export default Container;
