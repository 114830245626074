import Login from './containers/Login';
import Logout from './containers/Logout';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import TermsAndConditions from './containers/TermsAndConditions';
import ChangePassword from './containers/ChangePassword';

export default [
  {
    path: '/',
    exact: true,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
  },
  {
    path: '/activate',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/forgot',
    exact: true,
    component: ForgotPassword,
  },
  {
    path: '/reset',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/termsandconditions',
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: '/changepassword',
    exact: true,
    component: ChangePassword,
  }
];
