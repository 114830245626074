import * as React from 'react';
import { useState, useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import RegistrationComponent from './RegistrationDetail';
import AddIcon from '@mui/icons-material/Add';
import {FabActionButton} from '../../../components/ActionButton';

const PREFIX = 'Registration';

const classes = {
  accordion: `${PREFIX}-accordion`,
  button: `${PREFIX}-button`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  total: `${PREFIX}-total`,
  note: `${PREFIX}-note`,

};

const Root = styled('div')(( {theme}) => ({
  [`& .${classes.accordion}`]: {
    marginLeft: 10,
    width: '700px',
    marginTop: 10,
    marginBottom: 10,
    overflow: 'auto'
  },
  [`& .${classes.button}`]: {
    float: 'left',
    marginLeft: '26px',
    marginBottom: '10px',
    fontWeight: 900,
  },
  [`& .${classes.accordionTitle}`]: {
    width: '75%',
    flexShrink: 0
  },
  [`& .${classes.total}`]: {
    width:  '25%',
    color: 'rgba(0,0,0,0.54)' ,
  },
  [`& .${classes.note}`]: {
    color: theme.palette.common.danger ,
    marginTop: 10,
    fontWeight: 'bold'
  },

}));


const RegistrationAccordion = ({registrationDetailsField, registrationDetailsValue, onChange}) => {
  const [expanded, setExpanded] = useState(false);
  const [total, setTotal] = useState(0);

  const transformRegistrationDetailsToStore = (registrationDetails) => {
    //   The registration details need to be stored as a key, value pair with varying keys and values that could either
    //   be input from the NHVR register or edited/added by a user. This is different from their current state
    //   where they are stored as map where the key is an index number in order to preserve the position when displaying
    //   Expected output:
    //   {
    //      key : value
    //   }
    const transformedRegistration = {};
    Object.keys(registrationDetails).forEach((index) => {
      const key = registrationDetails[index].key;
      transformedRegistration[key] = registrationDetails[index].value;
    });
    return transformedRegistration;
  };

  useEffect(() => {
    const registrationTotal = Object.values(registrationDetailsValue).reduce((acc, {value}) => acc + value, 0);
    setTotal(Number(registrationTotal.toFixed(2)));
  }, [registrationDetailsValue]);

  const onRegistrationComponentChange = (count, newField, newValue) => {
    const changedRegistrationDetail = new Map(Object.entries(registrationDetailsValue));
    changedRegistrationDetail.set(count, {key: newField, value: newValue});
    onChange(registrationDetailsField, transformRegistrationDetailsToStore(Object.fromEntries(changedRegistrationDetail)));
  };

  const handleAddRegistrationDetail = () => {
    const newRegistrationDetail = new Map(Object.entries(registrationDetailsValue));
    const nextKey = newRegistrationDetail.size === 0 ? 0 : Math.max(...newRegistrationDetail.keys()) + 1;
    newRegistrationDetail.set(nextKey, { key: '', value: 0 });
    onChange(registrationDetailsField, transformRegistrationDetailsToStore(Object.fromEntries(newRegistrationDetail)));
  };

  const handleDelete = (count) => {
    const updatedRegistrationDetails = new Map(Object.entries(registrationDetailsValue));
    updatedRegistrationDetails.delete(String(count));
    onChange(registrationDetailsField, transformRegistrationDetailsToStore(Object.fromEntries(updatedRegistrationDetails)));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Root>
      <Accordion disableGutters={true} className={classes.accordion} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.accordionTitle}>
           Registration Cost Details
          </Typography>
          <Typography className={classes.total} align={'right'}> {total} $</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           The section below provides a breakdown of your vehicle registration and allows you to manually include any concession or rebate charges
          </Typography>
          <Typography className={classes.note}>
            NOTE: Rebate charges should be added in as a negative value e.g. -1000.50
          </Typography>
          {Object.entries(registrationDetailsValue).map(([index, detail]) => (
            <RegistrationComponent
              key={index}
              count={parseInt(index)}
              field={detail.key}
              value={detail.value}
              handleDelete={handleDelete}
              onChange={onRegistrationComponentChange}
            />
          ))}
        </AccordionDetails>
        <FabActionButton
          size="small"
          onClick={handleAddRegistrationDetail}
          tooltip="Add Registration Detail"
          className={classes.button}
          color="secondary"
          Icon={AddIcon}
        />
      </Accordion>
    </Root>
  );
};

RegistrationAccordion.propTypes = {
  registrationDetailsValue: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.number
  }),
  registrationDetailsField: PropTypes.string,
  onChange: PropTypes.func,
};

export default RegistrationAccordion;


