import { AuthStore, RefsStore } from '../../ducks';

import { styled } from '@mui/material/styles';

import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import ErrorDialog from '../ErrorDialog';
import Gravatar from '../Gravatar';
import GroupAddForm from './GroupAddForm';
import NoData from '../NoData';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from './Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { UserAPI } from '../../api';
import UserForm from './UserForm';
import { connect } from 'react-redux';
import moment from 'moment';
const PREFIX = 'UserTable';

const classes = {
  container: `${PREFIX}-container`,
  chip: `${PREFIX}-chip`,
  scroller: `${PREFIX}-scroller`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.chip}`]: {
    margin: theme.spacing(),
  },

  [`& .${classes.scroller}`]: {
    overflowY: 'scroll',
  }
}));

const formatLastLogin = (time) => {
  if (time === null) return null;
  const t = moment(time);
  return (
    <Tooltip interactive={true} title={t.format('ddd D MMM YYYY, h:mm:ss a')}>
      <span>{t.fromNow()}</span>
    </Tooltip>
  );
};

class UserTable extends React.Component {

  static propTypes = {
    showRoles: PropTypes.bool.isRequired,
    activeClient: PropTypes.object,
    profile: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    users: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    clientId: PropTypes.string,
    clients: PropTypes.array,
    get: PropTypes.func.isRequired,
    total: PropTypes.number,
    roles: PropTypes.array,
    adminMode: PropTypes.bool.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
  }

  state = {
    loading: false,
    drawerOpen: false,
    groupDrawerOpen: false,
    selectedUser: undefined,
    error: undefined,
  }

  loadMoreFrom = false;

  componentDidMount() {
    this.reloadUsers(false);
    this.getRoles();
  }

  onRefresh = () => {
    this.reloadUsers(true);
  }

  reloadUsers = (refresh=false) => {
    this.props.get(refresh);
  }

  onNewUser = () => {
    const clientId = this.props.clientId;
    this.setState({
      drawerOpen: true,
      selectedUser: {roles:{[clientId]:['Client View']}},
    });
  }

  reloadProfile = (user) => {
    if (user.user_id === this.props.profile.user_id) {
      this.props.dispatch(AuthStore.reloadProfile(true));
    }
  }

  getRoles = () => {
    if (this.props.showRoles) {
      this.props.dispatch(RefsStore.getRoles());
    }
  }

  onUserSelect = (user) => {
    this.setState({
      selectedUser: user,
      drawerOpen: true
    });
  }

  onDrawerClose = () => {
    this.setState({drawerOpen: false});
  }

  onSave = (user) => {
    if (user.user_id === undefined) {
      this.createUser(user);
    } else {
      this.updateUser(user);
    }
  }

  onGroupDrawerOpen = () => {
    this.setState({groupDrawerOpen: true});
  }

  onGroupDrawerClose = () => {
    this.setState({groupDrawerOpen: false});
  }

  onGroupAdd = (emails, roles) => {
    let errors = [];
    const { clientId } = this.props;
    Promise.all(emails.map(email =>
      // create all users in parallel, aggregate errors in `errors`
      UserAPI.create(clientId, {
        email: email,
        nickname: email,
        roles: {[clientId]: roles},
      }).catch(e => {
        const message = e.body.message || `${e.status}: ${e.message}`;
        errors.push(`${email} (${message})`);
      })
    )).then(() => {
      // when all complete update user list and report any errors
      if (errors.length) {
        this.setState({error: {
          title: 'Not all users were added.',
          message: errors.join(', ')
        }});
      }
      this.reloadUsers(true);
      this.setState({groupDrawerOpen: false});
    });
  }

  createUser = (user) => {
    UserAPI.create(this.props.clientId, user)
      .then(() => {
        this.setState({selectedUser: undefined, drawerOpen: false});
        this.reloadProfile(user);
        this.reloadUsers(true);
      });
  }

  updateUser = (user) => {
    const update = this.props.adminMode ? UserAPI.updateAdmin : UserAPI.update;
    update(this.props.clientId, user.user_id, user)
      .then(() => {
        // TODO - more efficient to use the returned profile instead of reloading everything.
        // probably best to move update methods into ducks and manage it there.
        this.setState({selectedUser: undefined, drawerOpen: false});
        this.reloadProfile(user);
        this.reloadUsers(true);
      });
  }

  onDelete = (user) => {
    const removeUser = this.props.adminMode ? UserAPI.removeAdmin : UserAPI.remove;
    removeUser(this.props.clientId, user.user_id)
      .then(() => {
        this.reloadUsers(true);
        this.setState({selectedUser: undefined, drawerOpen: false});
      });
  }

  clientChip = (clientId) => {
    const {  clients } = this.props;
    const client = clients && clients.find(c => c.client_id === clientId);
    return (
      <Chip
        key={clientId}
        label={client ? client.nickname : clientId}
        className={classes.chip}
      />
    );
  }

  renderUserRoles = (user, roles) => {
    const clientId = this.props.clientId;
    return roles.map(role => (
      <TableCell key={role.id}>
        {user.roles[clientId] && user.roles[clientId].includes(role.name) && <CheckIcon/>}
      </TableCell>
    ));
  }

  renderUserClients = (user) => {
    const MAX_CHIPS_SHOWN = 3;
    const getClientNickname = (clientId) => {
      const { clients } = this.props;
      const client = clients && clients.find((c) => c.client_id === clientId);
      return client ? client.nickname : clientId;
    };

    const clientIds = Object.keys(user.roles);
    let toDisplay = [];
    const numClients = clientIds.length;
    if (numClients > MAX_CHIPS_SHOWN) {
      // If over 3 clients, then show the first 3 and have a (+N) chip where N is the number of clients -3
      // and show a Tooltip with the remaining clients
      // e.g. if clients = [a,b,c,d,e,f] show chips (a) (b) (c) (+3)
      toDisplay = clientIds.slice(0, MAX_CHIPS_SHOWN).map((clientId) => this.clientChip(clientId));
      toDisplay.push(
        <Tooltip
          title={clientIds
            .slice(MAX_CHIPS_SHOWN)
            .map((clientId) => getClientNickname(clientId))
            .join(', ')}
        >
          <Chip
            label={`+${numClients - MAX_CHIPS_SHOWN}`}
            className={classes.chip}
          />
        </Tooltip>
      );
    } else {
      toDisplay = clientIds.map((clientId) => this.clientChip(clientId));
    }

    return <TableCell key="clients">{toDisplay}</TableCell>;
  }

  renderPermissionHeaders = (roles) =>
    roles.map(role => (
      <TableCell key={role.id}>{role.name}</TableCell>
    ));

  renderClientHeaders = () => (<TableCell key="clients">Clients</TableCell>)

  render() {
    const {  profile, users, isLoading, adminMode, showRoles, roles, clientId, isSuperUser } = this.props;
    const { drawerOpen, groupDrawerOpen, selectedUser, error } = this.state;
    if (!users) return (this.loading ? <NoData message="Loading"/> : <NoData/>);
    const roleList = roles || [];
    const selfSelected = selectedUser && profile && selectedUser.user_id === profile.user_id;

    return (
      <Root className={classes.container}>
        <Toolbar
          itemName="user"
          onNew={adminMode ? undefined : this.onNewUser}
          onRefresh={this.onRefresh}
          onGroupAdd={!adminMode && isSuperUser ? this.onGroupDrawerOpen : undefined}
          refreshDisabled={isLoading}
          showing={users.length}/>
        <div className={classes.scroller}>
          <Table className={classes.table} aria-labelledby="user table" padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Logins</TableCell>
                {showRoles ? this.renderPermissionHeaders(roleList) : this.renderClientHeaders()}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.user_id} onClick={() => this.onUserSelect(user)}>
                  <TableCell>
                    <Gravatar user={user} />
                  </TableCell>
                  <TableCell>
                    {user.user_id}
                  </TableCell>
                  <TableCell>
                    {user.nickname}
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    {formatLastLogin(user.last_login)}
                  </TableCell>
                  <TableCell>
                    {user.login_count ? user.login_count : '-'}
                  </TableCell>
                  {showRoles ? this.renderUserRoles(user, roleList) : this.renderUserClients(user)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={this.onDrawerClose}>
          <UserForm
            isSelf={selfSelected}
            user={selectedUser}
            onClose={this.onDrawerClose}
            onDelete={this.onDelete}
            onSave={this.onSave}
            roles={roleList}
            clientId={clientId}
            showRoles={showRoles}
          />
        </Drawer>
        <Drawer anchor="right" open={groupDrawerOpen} onClose={this.onGroupDrawerClose}>
          <GroupAddForm
            onClose={this.onGroupDrawerClose}
            onSave={this.onGroupAdd}
            roles={roles}
            clientId={clientId}
          />
        </Drawer>
        {error && <ErrorDialog
          show={true}
          title={error.title}
          text={error.message}
          onClose={() => this.setState({error: undefined})}/>
        }
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItemId,
    clients: state.clients.all.items,
    profile: state.auth.profile,
    roles: state.refs.roles.items,
    isSuperUser: state.auth.isSuperUser,
  };
};

export default connect(mapStateToProps)(
  (UserTable)
);
