import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {  styled } from '@material-ui/core/styles';
import { LoadingStore } from 'ducks';
import { AnalyticsAPI } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { makeDateList, makeMonthFilter } from 'lib/date';
import moment from 'moment';
import {
  Container,
  Section,
  PageHeader,
  ActivityBarChart,
  MonthFilter,
  Metric,
} from '../components';
import Toolkit from '../../../components/Toolkit';
import CategoryFilter from '../../../components/CategoryFilter';

const DivMetrics = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: '1rem',
}));

const attributes = [
  {
    'key': 'distance',
    'barKeys': ['Distance'],
    'yAxisUnits': 'KM',
  },
  {
    'key': 'time',
    'barKeys': ['Driving hours', 'Idling hours'],
    'yAxisUnits': 'Hours',
  },
  {
    'key': 'utilisation',
    'barKeys': ['Driving proportion', 'Idling proportion'],
    'yAxisUnits': '% of available time',
  },
  {
    'key': 'idling',
    'barKeys': ['Idling rate'],
    'yAxisUnits': '% of operating time',
  },
  {
    'key': 'vehicle_count',
    'barKeys': ['Active vehicles'],
    'yAxisUnits': 'Number of vehicles',
  },
];

const formatDate = d => moment(d).format('ll');
const fieldSum = (arr, key) => arr.reduce((total, obj) => obj[key] + total, 0);
const fieldMax = (arr, key) => Math.max.apply(Math, arr.map(function(o) { return o[key]; }));

const ActivityDashboard = ({ dateRange }) => {

  const dispatch = useDispatch();
  const { min: minDate, max: maxDate } = dateRange;
  const [data, setData] = useState(null);
  const dateList = makeDateList(minDate, maxDate);
  const [date, setDate] = useState(dateList[0]);
  const [attribute, setAttribute] = useState(attributes[0]);
  const [totalDistance, setTotalDistance] = useState(null);
  const [totalDrivingHours, setTotalDrivingHours] = useState(null);
  const [totalIdlingHours, setTotalIdlingHours] = useState(null);
  const [totalUtilisationRate, setTotalUtilisationRate] = useState(null);
  const [totalIdlingRate, setTotalIdlingRate] = useState(null);
  const [maxActiveVehicles, setMaxActiveVehicles] = useState(null);
  const clientId = useSelector((state) => state.clients.selectedItemId);

  const setFromAttributeSet = key => {
    const selectedAttribute = attributes.filter(a => a.key === key);
    setAttribute(selectedAttribute[0]);
  };

  React.useEffect(() => {
    const fetchData = async (date) => {
      dispatch(LoadingStore.loading(true));
      try {
        const queryParams = makeMonthFilter(date);
        const response = await AnalyticsAPI.getMonthlySeries(clientId, queryParams);
        const result = response.payload.map(d => ({
          'Date': formatDate(d.date),
          'Active vehicles': d.active_vehicle_count,
          'Distance': d.distance_km,
          'Driving hours': d.driving_hours,
          'Idling hours': d.idling_hours,
          'Driving proportion': d.utilisation_percent_driving * 100,
          'Idling proportion': d.utilisation_percent_idling * 100,
          'Idling rate': d.idling_rate * 100,
        }));
        setData(result);
        setTotalDistance(fieldSum(response.payload, 'distance_km'));
        setTotalDrivingHours(fieldSum(response.payload, 'driving_hours'));
        setTotalIdlingHours(fieldSum(response.payload, 'idling_hours'));
        setTotalUtilisationRate(fieldSum(response.payload, 'total_hours')/fieldSum(response.payload, 'available_hours'));
        setTotalIdlingRate(fieldSum(response.payload, 'idling_hours')/fieldSum(response.payload, 'total_hours'));
        setMaxActiveVehicles(fieldMax(response.payload, 'active_vehicle_count'));
      } catch (err) {
        console.error(err);
        setData(null);
      } finally {
        dispatch(LoadingStore.loading(false));
      }
    };
    fetchData(date);
  }, [date, clientId, dispatch]);

  return (
    <Container>
      <PageHeader
        title="Activity dashboard"
        subtitle="Key vehicle activity metrics and series for the month"
      />
      <Toolkit>
        <MonthFilter
          label="Select month:"
          filter={{ date: date }}
          onFilterChanged={setDate}
          dateList={dateList}
          rightMargin
        />
        <CategoryFilter
          label="Chart dimension:"
          value={attribute.key}
          valuesList={attributes.map((a) => a.key)}
          onFilterChanged={setFromAttributeSet}
        />
      </Toolkit>
      <Section fullHeight>
        <DivMetrics>
          <Metric
            label="Total distance"
            value={totalDistance}
            format="number"
            units="KM"
          />
          <Metric
            label="Driving Time"
            value={totalDrivingHours}
            format="number"
            units="Hours"
          />
          <Metric
            label="Idling Time"
            value={totalIdlingHours}
            format="number"
            units="Hours"
          />
          <Metric
            label="Utilisation rate"
            value={totalUtilisationRate}
            format="percentage"
          />
          <Metric label="Idling rate" value={totalIdlingRate} format="percentage" />
          <Metric
            label="Active vehicles"
            value={maxActiveVehicles}
            format="number"
          />
        </DivMetrics>
        <ActivityBarChart
          data={data}
          indexKey="Date"
          barKeys={attribute.barKeys}
          yAxisUnits={attribute.yAxisUnits}
        />
      </Section>
    </Container>
  );
};

ActivityDashboard.propTypes = {
  dateRange: PropTypes.object.isRequired,
};

export default ActivityDashboard;
