import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';

import { red, orange, green } from '@mui/material/colors';

const PREFIX = 'ProgressBar';

const classes = {
  container: `${PREFIX}-container`,
  checkContainer: `${PREFIX}-checkContainer`,
  progress: `${PREFIX}-progress`,
  progressGood: `${PREFIX}-progressGood`,
  progressFair: `${PREFIX}-progressFair`,
  progressPoor: `${PREFIX}-progressPoor`,
  checkComplete: `${PREFIX}-checkComplete`,
  checkIncomplete: `${PREFIX}-checkIncomplete`,
  helpText: `${PREFIX}-helpText`,
  barContainer: `${PREFIX}-barContainer`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.checkContainer}`]: {
    flex: 1,
  },

  [`& .${classes.progress}`]: {
  },

  [`& .${classes.progressGood}`]: {
    backgroundColor: green[400],
  },

  [`& .${classes.progressFair}`]: {
    backgroundColor: orange[400],
  },

  [`& .${classes.progressPoor}`]: {
    backgroundColor: red[400],
  },

  [`& .${classes.checkComplete}`]: {
    color: green[400],
  },

  [`& .${classes.checkIncomplete}`]: {
    color: '#dddddd',
  },

  [`& .${classes.helpText}`]: {
    fontSize: 12,
    marginTop: 4,
    color: theme.palette.common.danger,
    minHeight: '1em',
  },

  [`& .${classes.barContainer}`]: {
    flex: 8,
    display: 'flex',
    flexDirection: 'column',
  }
}));

const findClass = (score, range) => {
  const r = range.find(r => score <= r[0]);
  return r[1];
};

class ProgressBar extends React.Component
{
  static propTypes = {
    value: PropTypes.number,
    complete: PropTypes.bool,
    message: PropTypes.string,
  }

  render() {
    const { message='', value=0, complete=false, } = this.props;
    const progressClass = findClass(value, [[50, classes.progressPoor], [99, classes.progressFair], [100, classes.progressGood]]);

    return (
      <Root className={classes.container}>
        <div className={classes.checkContainer}>
          <CheckIcon className={complete ? classes.checkComplete : classes.checkIncomplete}/>
        </div>
        <div className={classes.barContainer}>
          <LinearProgress variant="determinate" value={value} classes={{root: classes.progress, barColorPrimary: progressClass}}/>
          <div className={classes.helpText}>{message}</div>
        </div>
      </Root>
    );
  }
}

export default (ProgressBar);
