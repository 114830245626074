import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const DivRoot = styled('div')(({ theme }) => ({
  padding: '1rem',
  marginBottom: '1rem',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.gray['300']}`,
  borderRadius: '3px',
  boxShadow: `2px 2px 5px 1px ${theme.palette.gray['100']}`,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

const DivFullHeight = styled('div')(( ) => ({
  minHeight: 'calc(100vh - 20rem)',
}));

const Section = ({ fullHeight=false, children }) => {


  if (fullHeight === true) {
    return (
      <DivRoot>
        <DivFullHeight>{children}</DivFullHeight>
      </DivRoot>
    );
  }

  return (
    <DivRoot>{children}</DivRoot>

  );
};

Section.propTypes = {
  fullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};

export default Section;
