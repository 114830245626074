import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ButtonBase from '@mui/material/ButtonBase';
import classNames from 'classnames';
import moment from 'moment';

import defaultVehicleImg from '../../../assets/vehicles/default-vehicle.svg';
import primeMoverImg from '../../../assets/vehicles/prime-mover.svg';
import largeUtillityImg from '../../../assets/vehicles/large-utility.svg';
import rigidTruckImg from '../../../assets/vehicles/rigid-truck.svg';
import largeBusImg from '../../../assets/vehicles/large-bus.svg';
import mediumBusImg from '../../../assets/vehicles/medium-bus.svg';
import mediumVanImg from '../../../assets/vehicles/medium-van.svg';
import craneImg from '../../../assets/vehicles/crane.svg';
import mediumCarImg from '../../../assets/vehicles/medium-car.svg';
import noFuelUseImg from '../../../assets/vehicles/no-fuel-use.svg';
import largeVanImg from '../../../assets/vehicles/large-van.svg';
import loaderImg from '../../../assets/vehicles/loader.svg';
import largeCarImg from '../../../assets/vehicles/large-car.svg';
import specialisedImg from '../../../assets/vehicles/specialised.svg';
import garbageTruckImg from '../../../assets/vehicles/garbage-truck.svg';
import smallUtilityImg from '../../../assets/vehicles/small-utility.svg';
import tractorImg from '../../../assets/vehicles/tractor.svg';
import dumpTruckImg from '../../../assets/vehicles/dump-truck.svg';
import diggerImg from '../../../assets/vehicles/digger.svg';
import bDoubleImg from '../../../assets/vehicles/b-double.svg';
import bTripleImg from '../../../assets/vehicles/b-triple.svg';

// these images are missing
import smallBusImg from '../../../assets/vehicles/medium-bus.svg';
import streetSweeperImg from '../../../assets/vehicles/specialised.svg';
import mowerImg from '../../../assets/vehicles/specialised.svg';


const PREFIX = 'VehicleCard';

const classes = {
  card: `${PREFIX}-card`,
  selectedCard: `${PREFIX}-selectedCard`,
  disabledCard: `${PREFIX}-disabledCard`,
  selectedDisabledCard: `${PREFIX}-selectedDisabledCard`,
  criticalLabel: `${PREFIX}-criticalLabel`,
  content: `${PREFIX}-content`,
  image: `${PREFIX}-image`,
  upper: `${PREFIX}-upper`,
  lower: `${PREFIX}-lower`,
  block: `${PREFIX}-block`,
  missing: `${PREFIX}-missing`,
  title: `${PREFIX}-title`,
  note: `${PREFIX}-note`
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    width: 250,
    minHeight: 150,
    margin: 10,
  },

  [`& .${classes.selectedCard}`]: {
    background: '#e6e8ff',
  },

  [`& .${classes.disabledCard}`]: {
    background: '#cccccc',
  },

  [`& .${classes.selectedDisabledCard}`]: {
    background: '#b3b9ff',
  },

  [`& .${classes.criticalLabel}`]: {
    color: 'red',
    fontWeight: 'bold',
    marginRight: '0.5rem',
  },

  [`& .${classes.content}`]: {
    display: 'block',
    padding: '0.7rem',
  },

  [`& .${classes.image}`]: {
    height: 60,
  },

  [`& .${classes.upper}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0',
    paddingBottom: '0.5rem',
  },

  [`& .${classes.lower}`]: {
    borderTop: '1px solid #cccccc',
    padding: '0',
    paddingTop: '0.5rem',
    lineHeight: '15px',
  },

  [`& .${classes.block}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '1rem',
  },

  [`& .${classes.missing}`]: {
    color: '#ff0000',
  },

  [`& .${classes.title}`]: {
    fontSize: '0.8rem',
    fontWight: 700,
    color: '#333333',
  },

  [`& .${classes.note}`]: {
    color: '#444',
    marginRight: '1rem',
    fontSize: '0.7rem',
  }
}));


const vehicleImageMap = {
  'DEFAULT VEHICLE': defaultVehicleImg,
  'PRIME MOVER TRUCK': primeMoverImg,
  'LARGE UTILITY': largeUtillityImg,
  'RIGID TRUCK': rigidTruckImg,
  'LARGE BUS': largeBusImg,
  'MEDIUM BUS': mediumBusImg,
  'MEDIUM VAN': mediumVanImg,
  'CRANE': craneImg,
  'MEDIUM CAR': mediumCarImg,
  'NO FUEL USE': noFuelUseImg,
  'MOWER': mowerImg,
  'LARGE VAN': largeVanImg,
  'LOADER': loaderImg,
  'LARGE CAR': largeCarImg,
  'SPECIALISED': specialisedImg,
  'GARBAGE TRUCK': garbageTruckImg,
  'SMALL UTILITY': smallUtilityImg,
  'TRACTOR': tractorImg,
  'SMALL BUS': smallBusImg,
  'DUMP TRUCK': dumpTruckImg,
  'DIGGER': diggerImg,
  'STREET SWEEPER': streetSweeperImg,
  'B-DOUBLE TRUCK': bDoubleImg,
  'B-TRIPLE TRUCK': bTripleImg,
};

const vehicleDisposed = (v) => {
  if (v['disposed_date'] === '' || v['disposed_date'] === null) return false;
  let disposed = moment(v['disposed_date'], moment.ISO_8601);
  return moment().diff(disposed, 'days') > 0;
};

class VehicleCard extends React.Component {

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    selected: PropTypes.bool,
  }

  textVal = (label, value, className) => {
    let val = <b>{value}</b>;
    if (value === null || value === '') {
      val = <b className={classes.missing}>MISSING</b>;
    }
    return (
      <div className={className}>{label}: {val}</div>
    );
  }

  vehicleImage = (category) => {
    let imageSrc = vehicleImageMap[category];
    if (imageSrc === undefined) {
      imageSrc = defaultVehicleImg;
    }
    return (<img src={imageSrc} alt="vehicle" className={classes.image}/>);
  }

  render = () => {
    const {onClick, data,  selected=false} = this.props;
    const vehicleImg = this.vehicleImage(data.category, classes.image);
    const disposed = vehicleDisposed(data);
    const cardClass = data.disabled ? (selected ? classes.selectedDisabledCard : classes.disabledCard) : (selected ? classes.selectedCard : null);
    return (
      <StyledCard className={classNames(classes.card, cardClass)} raised={selected}>
        <ButtonBase component={CardContent} className={classes.content} onClick={onClick}>
          <div className={classes.upper}>
            {vehicleImg}
            <div className={classes.block}>
              {this.textVal('Reg', data.registration, classes.title)}
              {this.textVal('Prism ID', data.vehicle_id, classes.note)}
              {this.textVal('IVMS ID', data.client_vehicle_id, classes.note)}
            </div>
          </div>
          <div className={classes.lower}>
            <div className={classes.block}>
              {this.textVal('Make', data.make, classes.note)}
              {this.textVal('Model', data.model, classes.note)}
              {this.textVal('Fuel', data.fuel_type, classes.note)}
              {this.textVal('FTC On-Road Claim', data.ftc_on_road ? 'Yes' : 'No', classes.note)}
              {data.disabled && <div className={classes.criticalLabel}>INACTIVE</div>}
              {disposed && <div className={classes.criticalLabel}>DISPOSED</div>}

            </div>
          </div>
        </ButtonBase>

      </StyledCard>
    );
  };
}

export default (VehicleCard);
