
import React from 'react';
import { styled } from '@mui/material/styles';
import DownArrow from '@mui/icons-material/ArrowDownward';
import './ScrollDownArrow.css';

const Root = styled('div')(() => ({}));

const ScrollDownArrow = () => (
  <Root className="bounce">
    <DownArrow/>
  </Root>
);

ScrollDownArrow.propTypes = {

};

export default (ScrollDownArrow);
