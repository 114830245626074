import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
const PREFIX = 'NoData';

const classes = {
  container: `${PREFIX}-container`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '1rem',
    fontSize: '1.4rem',
    fontWeight: '900',
    color: '#888888',
  }
}));

class NoData extends React.Component
{
  static propTypes = {
    message: PropTypes.string,
  }

  render() {
    const { message='No data available.', } = this.props;
    return (
      <Root className={classes.container}>
        {message}
      </Root>
    );
  }
}

export default (NoData);
