import React, { useEffect, useState } from 'react';
import AuditReportsPage from './AuditReportsPage';
import PropTypes from 'prop-types';
import { ReportsAPI } from '../../../api';
import { useSelector } from 'react-redux';

const AuditReports = ({ ...props }) => {
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const [dateRange, setDateRange] = useState({ min: null, max: null });

  const getDateRange = async () => {
    const response = await ReportsAPI.getFTCReports(clientId);
    const activityReport = response.payload.find(r => r.name === 'vehicle_activity');
    setDateRange(activityReport.date_range);
  };

  useEffect(() => {
    getDateRange();
  }, []); // eslint-disable-line

  return (
    <div style={{ height: '100%' }}>
      <AuditReportsPage dateRange={dateRange} {...props}/>
    </div>
  );

};

AuditReports.propTypes = {
  clientId: PropTypes.string,
  profile: PropTypes.object.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
};

export default AuditReports;
