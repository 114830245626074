import {request, makePath} from './http';
const API_NAME = 'areas';

export function getCustomAreasList(clientId) {
  return request('GET',makePath(API_NAME), {clientId});
}

export function getCustomArea(clientId, id) {
  return request('GET',makePath(API_NAME, id), {clientId},{'Accept': 'application/geo+json'});
}

export function createCustomArea(clientId, data) {
  return request('POST',makePath(API_NAME), {clientId, body:data});
}

export function deleteCustomArea(clientId, id) {
  return request('DELETE',makePath(API_NAME,id), {clientId});
}