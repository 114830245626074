import { UserAPI } from '../api';

const LOADING       = 'users/LOADING';
const UPDATED       = 'users/UPDATE';
const ERROR         = 'users/ERROR';
const CACHED        = 'users/CACHED';
const CLIENT_CHANGED= 'users/CLIENTCHANGED';
const ALL_LOADING   = 'users/ALL_LOADING';
const ALL_UPDATED   = 'users/ALL_UPDATED';
const ALL_CACHED    = 'users/ALL_CACHED';

const CACHE_TTL = 60 * 1000;  // in milliseconds
const ORDER_BY = 'email,id';
const PAGE_SIZE = 50;

const initialState = {
  clientId: undefined,
  isLoading: false,
  items: [],
  loadTime: undefined,
  total: undefined,
  all: { // sysadmin user list
    items: [],
    total: undefined,
    loadTime: undefined,
    isLoading: false,
  },
};


const removeById = (list, idList) => {
  return list.filter(u => !idList.includes(u.user_id) );
};

/*
 * To manage chunked loading in a robust way, we merge the new chunk into the
 * existing list, removing any matching ids from the succeeding chunks.
 *  This ensures that the list is smoothly edited without duplicates as
 * the updates arrive.
 */
const mergeUsers = (current, update, offset, total) => {
  const ids = update.map(c => c.user_id);
  const before = current.slice(0, offset);
  const after = offset + update.length < total ? removeById(current.slice(offset), ids) : [];
  return [
    ...before,
    ...update,
    ...after,
  ];
};

// Action Creators

export function loading() {
  return {
    type: LOADING,
  };
}

export function updated(response, offset) {
  const items = response.payload;
  const total = response.total;
  return {
    type: UPDATED,
    items,
    offset,
    total,
    complete: offset + items.length >= total,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

export function cached() {
  return {
    type: CACHED,
  };
}

export function clientChanged(clientId) {
  return {
    type: CLIENT_CHANGED,
    clientId,
  };
}

export function allLoading() {
  return {
    type: ALL_LOADING,
  };
}

export function allUpdated(response, offset) {
  const items = response.payload;
  const total = response.total;
  return {
    type: ALL_UPDATED,
    items,
    total,
    offset,
    complete: offset + items.length >= total,
  };
}

export function allCached() {
  return {
    type: ALL_CACHED,
  };
}

// Async Action Creators

async function chunkedFetch(dispatch, getMethod, updateMethod) {
  let offset = 0;
  let done = false;
  while (!done) {
    const params = {offset, pagesize:PAGE_SIZE, order_by:ORDER_BY};
    const response = await getMethod(params);
    dispatch(updateMethod(response, offset));
    offset += PAGE_SIZE;
    done = offset >= response.total;
  }
}

export function list(refresh=false) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedClientId = state.clients.selectedItemId;
    const { users } = getState();
    const age = Date.now() - users.loadTime;
    const sameClient = selectedClientId === state.users.clientId;
    if (!refresh && sameClient && age < CACHE_TTL) { // age might be NaN, which compares false
      dispatch(cached());
    } else {
      if (!sameClient) dispatch(clientChanged(selectedClientId));
      dispatch(loading());
      chunkedFetch(
        dispatch,
        (params) => UserAPI.list(selectedClientId, params),
        updated,
      );
    }
  };
}

export function listAll(refresh=false) {
  return (dispatch, getState) => {
    const { all } = getState().users;
    const age = Date.now() - all.loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      dispatch(allCached());
    } else {
      dispatch(allLoading());
      chunkedFetch(
        dispatch,
        UserAPI.listAll,
        allUpdated,
      );
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case LOADING:
    return {
      ...state,
      isLoading: true,
    };

  case UPDATED:
    return {
      ...state,
      items: mergeUsers(state.items, action.items, action.offset, action.total),
      total: action.total,
      loadTime: Date.now(),
      isLoading: !action.complete,
    };

  case ERROR:
    return {
      ...state,
      error: action.error,
    };

  case CLIENT_CHANGED:
    return {
      ...state,
      clientId: action.clientId,
      items: [],
      loadTime: undefined,
      total: undefined,
    };

  case ALL_LOADING:
    return {
      ...state,
      all: {
        ...state.all,
        isLoading: true,
      },
    };

  case ALL_UPDATED:
    return {
      ...state,
      all: {
        ...state.all,
        items: mergeUsers(state.all.items, action.items, action.offset, action.total),
        total: action.total,
        isLoading: false,
        loadTime: Date.now(),
      },
    };

  default:
    return state;
  }
}
