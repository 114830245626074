import { ClientAPI } from '../api';

const LOADING     = 'clients/LOADING';
const UPDATED     = 'clients/UPDATE';
const UPDATED_ONE = 'clients/UPDATE_ONE';
const ERROR       = 'clients/ERROR';
const CACHED      = 'clients/CACHED';
const SELECT      = 'clients/SELECT';
const ALL_LOADING = 'clients/ALL_LOADING';
const ALL_UPDATED = 'clients/ALL_UPDATED';
const ALL_CACHED  = 'clients/ALL_CACHED';
const LOAD_ADDITIONAL_CLIENT = 'clients/LOAD_ADDITIONAL_CLIENT';

const CACHE_TTL = 60 * 1000;
const PAGE_SIZE = 100;
const ORDER_BY = 'nickname,id';

const findClient = (clients, clientId) => {
  if (clients === undefined || clientId === undefined) return undefined;
  return clients.find(c => c.client_id === clientId);
};

const removeById = (list, idList) => {
  return list.filter(c => !idList.includes(c.client_id) );
};

/*
 * To manage chunked loading in a robust way, we merge the new chunk into the
 * existing list, removing any matching ids from the succeeding chunks.
 *  This ensures that the list is smoothly edited without duplicates as
 * the updates arrive.
 */
const mergeClients = (current, update, offset, total) => {
  const ids = update.map(c => c.client_id);
  const before = current.slice(0, offset);
  const after = offset + update.length < total ? removeById(current.slice(offset), ids) : [];
  return [
    ...before,
    ...update,
    ...after,
  ];
};

const initialState = {
  isLoading: false,
  items: [],
  total: undefined,
  selectedItemId: undefined,
  selectedItem: undefined,
  loadTime: undefined,
  all: { // sysadmin client list
    items: [],
    total: undefined,
    loadTime: undefined,
    isLoading: false,
  },
  schemas: {
    items: ClientAPI.schemas,
    isLoading: false,
  },
};

// Action Creators

export function loading() {
  return {
    type: LOADING,
  };
}

export function updated(response, offset) {
  const items = response.payload;
  const total = response.total;
  return {
    type: UPDATED,
    items,
    total,
    offset,
    complete: offset + items.length >= total,
  };
}

export function updatedOne(client) {
  return {
    type: UPDATED_ONE,
    client,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

export function cached() {
  return {
    type: CACHED,
  };
}

export function select(itemId) {
  return {
    type: SELECT,
    itemId,
  };
}

export function allLoading() {
  return {
    type: ALL_LOADING,
  };
}

export function allUpdated(response, offset) {
  const items = response.payload;
  const total = response.total;
  return {
    type: ALL_UPDATED,
    items,
    total,
    offset,
    complete: offset + items.length >= total,
  };
}

export function allCached() {
  return {
    type: ALL_CACHED,
  };
}

export function loadAdditionalClient(clientId) {
  return {
    type: LOAD_ADDITIONAL_CLIENT,
    clientId: clientId,
  };
}

// Async Action Creators

export function updateOne(clientId, update) {
  return (dispatch) => {
    dispatch(loading());
    ClientAPI.update(clientId, update).then(response => {
      dispatch(updatedOne(response.payload));
    });
  };
}

export function updateConops(clientId, update) {
  return (dispatch) => {
    dispatch(loading());
    ClientAPI.updateConops(clientId, update).then(response => {
      dispatch(updatedOne(response.payload));
    });
  };
}

async function chunkedFetch(dispatch, getMethod, updateMethod) {
  let offset = 0;
  let done = false;
  while (!done) {
    const params = {offset, pagesize:PAGE_SIZE, order_by:ORDER_BY};
    const response = await getMethod(params);
    dispatch(updateMethod(response, offset));
    offset += PAGE_SIZE;
    done = offset >= response.total;
  }
}

export function list(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().clients;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      dispatch(cached());
    } else {
      dispatch(loading());
      chunkedFetch(
        dispatch,
        ClientAPI.list,
        updated,
      );
    }
  };
}

export function listAll(refresh=false) {
  return (dispatch, getState) => {
    const { all } = getState().clients;
    const age = Date.now() - all.loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      dispatch(allCached());
    } else {
      dispatch(allLoading());
      chunkedFetch(
        dispatch,
        ClientAPI.listAll,
        allUpdated
      );
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case LOADING:
    return {
      ...state,
      isLoading: true,
    };

  case UPDATED:
  {
    const items = mergeClients(state.items, action.items, action.offset, action.total);
    return {
      ...state,
      items,
      total: action.total,
      selectedItem: findClient(items, state.selectedItemId),
      loadTime: Date.now(),
      isLoading: !action.complete,
    };
  }

  case UPDATED_ONE:
  {
    /* if this item exists in state.items, replace it there */
    let items = state.items;
    const index = items.findIndex(item => item.client_id === action.client.client_id);
    if (index !== -1) {
      items = [
        ...items.slice(0, index),
        action.client,
        ...items.slice(index+1),
      ];
    }

    /* if this item exists in state.all.items, replace it there */
    let allItems = state.all.items;
    const allIndex = allItems.findIndex(item => item.client_id === action.client.client_id);
    if (allIndex !== -1) {
      allItems = [
        ...allItems.slice(0, allIndex),
        action.client,
        ...allItems.slice(allIndex+1),
      ];
    }

    return {
      ...state,
      items,
      selectedItem: state.selectedItemId === action.client.client_id ? action.client : state.selectedItem,
      isLoading: false,
      all: {
        ...state.all,
        items: allItems,
      }
    };
  }

  case SELECT:
    return {
      ...state,
      selectedItemId: action.itemId,
      selectedItem: findClient(state.items, action.itemId),
    };

  case ALL_LOADING:
    return {
      ...state,
      all: {
        ...state.all,
        isLoading: true,
      },
    };

  case ALL_UPDATED:
    return {
      ...state,
      all: {
        ...state.all,
        items: mergeClients(state.all.items, action.items, action.offset, action.total),
        total: action.total,
        isLoading: !action.complete,
        loadTime: Date.now(),
      },
    };

  case LOAD_ADDITIONAL_CLIENT: {
    let client = findClient(state.items, action.clientId);

    if (!client) {
      const allItems = state.all.items;
      const allIndex = allItems.findIndex(item => item.client_id === action.clientId);
      if (allIndex !== -1) {
        client = allItems[allIndex];
      }
      return {
        ...state,
        items: [...state.items, client],
        selectedItemId: action.clientId,
        selectedItem: client,
      };
    }

    return {
      ...state,
      selectedItemId: action.clientId,
      selectedItem: client,
    };

  }

  default:
    return state;
  }
}
