import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import UserTable from '../../../components/admin/UserTable';
import { connect } from 'react-redux';
import { UserStore, ClientStore } from '../../../ducks';


const Root = styled(
  UserTable
)(() => ({}));

class AllUserManagement extends React.Component {

  static propTypes = {
    users: PropTypes.array,
    total: PropTypes.number,
    isLoading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
  }

  render() {
    const { dispatch } = this.props;
    return (
      <Root
        showRoles={true}
        users={this.props.users}
        total={this.props.total}
        get={(refresh) => {dispatch(UserStore.list(refresh)); dispatch(ClientStore.list(refresh));}}
        adminMode={false}
        isLoading={this.props.isLoading}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.items,
    total: state.users.total,
    isLoading: state.users.isLoading,
    isSuperUser: state.auth.isSuperUser,
  };
};

export default connect(mapStateToProps)(
  (AllUserManagement)
);
