import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStore } from '../../../ducks';
import PropTypes from 'prop-types';
import { ClientAPI } from '../../../api';


const headerHeight = 52;
const footerHeight = 40;
const netReduction = headerHeight + footerHeight;
const PREFIX = 'Report';

const classes = {
  page: `${PREFIX}-page`
};

const Root = styled('div')({
  [`&.${classes.page}`]: {
    height: `calc(100vh - ${netReduction}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
});

const Report = ({ report,  ViewComponent }) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const [reportData, setReportData] = useState();

  const getHealthDetails = async () => {
    dispatch(LoadingStore.loading(true));
    try {
      const response = await ClientAPI.healthDetails(clientId, report.key);
      const result = response.message.data;
      setReportData(result);
    } catch (err) {
      setReportData([]); // HACK around lack of payload.
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  useEffect(() => {
    getHealthDetails();
  },[clientId]);// eslint-disable-line

  const refreshHealthDetails = () =>{
    getHealthDetails();
  };


  if (report === undefined || isLoading === undefined) return null;

  return (
    <Root className={classes.page}>
      {!isLoading && reportData && (

        <ViewComponent
          reportName= {reportData.key}
          title={reportData.title}
          description={reportData.description}
          helpfulText={reportData.helpful_text}
          refreshHealthDetails={refreshHealthDetails}
          helpfulLink={reportData.helpful_link}
          numberOfIssues={reportData.number_of_issues}
          issuesColor={reportData.issues_color}
          vehicleInformation={reportData.vehicle_information}
        />
      )}
    </Root>
  );
};

Report.propTypes = {
  report: PropTypes.object,
  isLoading: PropTypes.bool,
  ViewComponent: PropTypes.func.isRequired,
  clientId: PropTypes.string,
};

export default Report;
