import { RefsAPI } from '../api';

const ROLES_LOADING                 = 'refs/roles/LOADING';
const ROLES_UPDATED                 = 'refs/roles/UPDATE';
const AUX_FUEL_USES_LOADING         = 'refs/auxFuelUses/LOADING';
const AUX_FUEL_USES_UPDATED         = 'refs/auxFuelUses/UPDATED';
const AUX_FUEL_METHODS_LOADING      = 'refs/auxFuelMethods/LOADING';
const AUX_FUEL_METHODS_UPDATED      = 'refs/auxFuelMethods/UPDATED';
const SCHEMAS_LOADING               = 'refs/schemas/LOADING';
const SCHEMAS_UPDATED               = 'refs/schemas/UPDATED';
const REFERRERS_LOADING             = 'refs/referrers/LOADING';
const REFERRERS_UPDATED             = 'refs/referrers/UPDATED';
const SERVICE_TYPES_LOADING         = 'refs/serviceTypes/LOADING';
const SERVICE_TYPES_UPDATED         = 'refs/serviceTypes/UPDATED';
const BILLING_SERVICE_PLANS_LOADING = 'refs/billingServicePlans/LOADING';
const BILLING_SERVICE_PLANS_UPDATED = 'refs/billingServicePlans/UPDATED';
const BILLING_DATA_PLANS_LOADING    = 'refs/billingDataPlans/LOADING';
const BILLING_DATA_PLANS_UPDATED    = 'refs/billingDataPlans/UPDATED';
const ERROR                         = 'refs/ERROR';

const CACHE_TTL = 3600000;

const initialState = {
  roles: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  auxFuelUses: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  auxFuelMethods: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  schemas: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  referrers: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  serviceTypes: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  billingServicePlans: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  billingDataPlans: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
};

// Action Creators

export function rolesLoading() {
  return {
    type: ROLES_LOADING,
  };
}

export function rolesUpdated(response) {
  const items = response.payload;
  return {
    type: ROLES_UPDATED,
    items,
  };
}

export function auxFuelUsesLoading() {
  return {
    type: AUX_FUEL_USES_LOADING,
  };
}

export function auxFuelUsesUpdated(response) {
  const items = response.payload;
  return {
    type: AUX_FUEL_USES_UPDATED,
    items,
  };
}

export function auxFuelMethodsLoading() {
  return {
    type: AUX_FUEL_METHODS_LOADING,
  };
}

export function auxFuelMethodsUpdated(response) {
  const items = response.payload;
  return {
    type: AUX_FUEL_METHODS_UPDATED,
    items,
  };
}

export function schemasLoading() {
  return {
    type: SCHEMAS_LOADING,
  };
}

export function schemasUpdated(response) {
  const items = response.payload;
  return {
    type: SCHEMAS_UPDATED,
    items,
  };
}

export function referrersLoading() {
  return {
    type: REFERRERS_LOADING,
  };
}

export function referrersUpdated(response) {
  const items = response.payload;
  return {
    type: REFERRERS_UPDATED,
    items,
  };
}

export function serviceTypesLoading() {
  return {
    type: SERVICE_TYPES_LOADING,
  };
}

export function serviceTypesUpdated(response) {
  const items = response.payload;
  return {
    type: SERVICE_TYPES_UPDATED,
    items,
  };
}

export function billingServicePlansLoading() {
  return {
    type: BILLING_SERVICE_PLANS_LOADING,
  };
}

export function billingServicePlansUpdated(response) {
  const items = response.payload;
  return {
    type: BILLING_SERVICE_PLANS_UPDATED,
    items,
  };
}

export function billingDataPlansLoading() {
  return {
    type: BILLING_DATA_PLANS_LOADING,
  };
}

export function billingDataPlansUpdated(response) {
  const items = response.payload;
  return {
    type: BILLING_DATA_PLANS_UPDATED,
    items,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

// Async Action Creators

export function getRoles(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.roles;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(rolesLoading());
      return RefsAPI.roles()
        .then(response => dispatch(rolesUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getAuxFuelUses(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.auxFuelUses;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(auxFuelUsesLoading());
      return RefsAPI.auxiliaryFuelUses()
        .then(response => dispatch(auxFuelUsesUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getAuxFuelMethods(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.auxFuelMethods;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(auxFuelMethodsLoading());
      return RefsAPI.auxiliaryFuelMethods()
        .then(response => dispatch(auxFuelMethodsUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getSchemas(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.schemas;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(schemasLoading());
      return RefsAPI.schemas()
        .then(response => dispatch(schemasUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getReferrers(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.referrers;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(referrersLoading());
      return RefsAPI.referrers()
        .then(response => dispatch(referrersUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getServiceTypes(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.serviceTypes;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(serviceTypesLoading());
      return RefsAPI.serviceTypes()
        .then(response => dispatch(serviceTypesUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getBillingServicePlans(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.billingServicePlans;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(billingServicePlansLoading());
      return RefsAPI.billingServicePlans()
        .then(response => dispatch(billingServicePlansUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getBillingDataPlans(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().refs.billingDataPlans;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(billingDataPlansLoading());
      return RefsAPI.billingDataPlans()
        .then(response => dispatch(billingDataPlansUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}


// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case ROLES_LOADING:
    return {
      ...state,
      roles: {
        ...state.roles,
        isLoading: true,
      }
    };

  case ROLES_UPDATED:
    return {
      ...state,
      roles: {
        ...state.roles,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  case AUX_FUEL_USES_LOADING:
    return {
      ...state,
      auxFuelUses: {
        ...state.auxFuelUses,
        isLoading: true,
      }
    };

  case AUX_FUEL_USES_UPDATED:
    return {
      ...state,
      auxFuelUses: {
        ...state.auxFuelUses,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  case AUX_FUEL_METHODS_LOADING:
    return {
      ...state,
      auxFuelMethods: {
        ...state.auxFuelMethods,
        isLoading: true,
      }
    };

  case AUX_FUEL_METHODS_UPDATED:
    return {
      ...state,
      auxFuelMethods: {
        ...state.auxFuelMethods,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  case SCHEMAS_LOADING:
    return {
      ...state,
      schemas: {
        ...state.schemas,
        isLoading: true,
      }
    };

  case SCHEMAS_UPDATED:
    return {
      ...state,
      schemas: {
        ...state.schemas,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  case REFERRERS_LOADING:
    return {
      ...state,
      referrers: {
        ...state.referrers,
        isLoading: true,
      }
    };

  case REFERRERS_UPDATED:
    return {
      ...state,
      referrers: {
        ...state.referrers,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items.sort(),
      }
    };

  case SERVICE_TYPES_LOADING:
    return {
      ...state,
      serviceTypes: {
        ...state.serviceTypes,
        isLoading: true,
      }
    };

  case SERVICE_TYPES_UPDATED:
    return {
      ...state,
      serviceTypes: {
        ...state.serviceTypes,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items.sort(),
      }
    };

  case BILLING_SERVICE_PLANS_LOADING:
    return {
      ...state,
      billingServicePlans: {
        ...state.billingServicePlans,
        isLoading: true,
      }
    };

  case BILLING_SERVICE_PLANS_UPDATED:
    return {
      ...state,
      billingServicePlans: {
        ...state.billingServicePlans,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items.sort(),
      }
    };

  case BILLING_DATA_PLANS_LOADING:
    return {
      ...state,
      billingDataPlans: {
        ...state.billingDataPlans,
        isLoading: true,
      }
    };

  case BILLING_DATA_PLANS_UPDATED:
    return {
      ...state,
      billingDataPlans: {
        ...state.billingDataPlans,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items.sort(),
      }
    };

  default:
    return state;
  }
}
