import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Confirmation from '../../../components/Confirmation';
import CreateToolbar from '../components/CreateToolbar';
import ErrorDialog from '../../../components/ErrorDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownload from '../components/FileDownload';
import { LoadingStore } from '../../../ducks';
import NewFileReportDialog from '../components/NewFileReportDialog';
import NoData from '../../../components/NoData';
import Poller from '../components/Poller';
import PropTypes from 'prop-types';
import ReportFileCard from '../components/ReportFileCard';
import { ReportFilesAPI } from '../../../api';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { months } from '../../../helpers/date';
import { styled } from '@mui/material/styles';

const PREFIX = 'DownloadReports';

const classes = {
  container: `${PREFIX}-container`,
  panelRoot: `${PREFIX}-panelRoot`,
  panelHeading: `${PREFIX}-panelHeading`,
  expansionPanel: `${PREFIX}-expansionPanel`,
  panelSummary: `${PREFIX}-panelSummary`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    overflowY: 'scroll',
    height: '100%',
    backgroundColor: '#ffffff',
  },

  [`&.${classes.panelRoot}`]: {
    width: '100%',
    border: `1px solid ${theme.palette.gray['100']}`,
  },

  [`& .${classes.panelHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  [`& .${classes.expansionPanel}`]: {
    display:'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.panelSummary}`]: {
    backgroundColor: '#eeeeee',
  }
}));

const REPORTING_DATE = 5; // reports are available on the 5th of each month.

const fileFormats = [
  { format: 'pdf', label: 'PDF' },
  { format: 'excel', label: 'Excel' },
];

const previousMonth = (year, month) => {
  month -= 1;
  if (!month) {
    year -= 1;
    month = 12;
  }
  return [year, month];
};

const matchReport = (r1, r2) => (
  r1.month === r2.month &&
  r1.year === r2.year &&
  r1.format === r2.format &&
  r1.created !== r2.created
);

const DownloadReports = ({ dateRange }) => {

  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const [reports, setReports] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState();
  const [showNewFileReportDialog, setShowNewFileReportDialog] = useState(false);
  const [error, setError] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [reportToSend, setReportToSend] = useState();

  const getAllReportingMonths = (dateRange) => {
    if (dateRange.min === null || dateRange.max === null) return [];
    const lastAvailableReportDate = nextReportingDate().subtract({months: 1});
    const lastYear = lastAvailableReportDate.year();
    const lastMonth = lastAvailableReportDate.month() + 1;
    const [ year0, month0 ] = dateRange.min.split('-').map(v => Number(v));
    const [ year1, month1 ] = dateRange.max.split('-').map(v => Number(v));
    let year = year1;
    let month = month1;
    // don't offer to create reports for the incomplete reporting period.
    if (year > lastYear || (year === lastYear && month > lastMonth)) {
      year = lastYear;
      month = lastMonth;
    }
    let dates = [];

    while (year > year0 || (year === year0 && month >= month0)) {
      dates.push({
        month: month,
        year: year,
        value: `${year}-${month}`,
        label: `${months[month-1]} ${year}`
      });
      [ year, month ] = previousMonth(year, month);
    }
    return dates;
  };

  // return the date of the last day of the next month not yet processed.
  const nextReportingDate = () => {
    let reportDate = moment();
    if (!isSuperUser && reportDate.date() < REPORTING_DATE) {
      reportDate = reportDate.subtract({ months: 1 });
    }
    return reportDate.endOf('month');
  };

  const reportPendingMessage = () => {
    let reportDate = nextReportingDate();
    const availableDate = reportDate.clone().add({months: 1}).date(REPORTING_DATE);
    return `Documents for ${reportDate.format('MMMM YYYY')} will be available from ${availableDate.format('D MMM YYYY')}.`;
  };

  const loadReports = async (silent = false) => {
    if (!silent) dispatch(LoadingStore.loading(true));
    try {
      const response = await ReportFilesAPI.getReportFiles(clientId);
      setReports(response.payload);
      return response.payload;
    } catch (err) {
      setReports([]);
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  const openReport = async (report) => {
    const params = {
      'content_disposition': `attachment; filename="${report.name}"`,
      'content_type': report.content_type,
    };
    const response = await ReportFilesAPI.getReportFile(clientId, report.name, params);
    setDownloadUrl(response.payload.location);
  };

  const confirmSendReport = (report) => {
    setReportToSend(report);
    setShowConfirmationDialog(true);
  };

  const sendReport = async () => {
    setReportToSend();
    setShowConfirmationDialog(false);
    ReportFilesAPI.sendReport(clientId, {
      clientId: clientId,
      month: reportToSend.month,
      year: reportToSend.year,
      name: reportToSend.name,
      doc_format: reportToSend.format
    });
  };

  const createNewReport = async (year, month, months, format='pdf') => {
    setShowNewFileReportDialog(false);
    const report = { year, month, months, format };
    try {
      await ReportFilesAPI.generateReportFile(clientId, report);
    } catch (err) {
      setError({ title: 'Report Failed', text: 'Report generation failed.  The most common reason for failure is incomplete data.' });
    }
    poller.watch(report, matchReport);
  };

  const regenerateReport = async (report) => {
    await ReportFilesAPI.generateReportFile(clientId, report);
    poller.watch(report, matchReport);
  };

  const createAPanel = (list, reports, year, month, expanded) => {
    const reportsInMonth = list.map(i => reports[i]);
    return (
      <Root className={classes.panelRoot} key={year + ' ' + month}>
        <Accordion defaultExpanded={expanded}>
          <AccordionSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.panelHeading}><b>{months[month-1]} {year}</b></Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanel}>
            {reportsInMonth.map((report, i) => (
              <ReportFileCard
                key={i}
                report={report}
                regenerate={regenerateReport}
                openReport={openReport}
                sendReport={confirmSendReport}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Root>
    );
  };

  const createReportPanels = (reports) => {
    if (!reports) return null;
    // objs structure: {year: {month: [index1, index2, ...], ... }, ...}
    const objs = reports.reduce((o, r, i) => {
      const yearValue = o[r.year] || {};
      const monthValue = yearValue[r.month] || [];
      yearValue[r.month] = monthValue.concat(i);
      o[r.year] = yearValue;
      return o;}, {});

    let ret = [];
    let expandFirst = true;

    // eslint-disable-next-line
    for (let year of Object.keys(objs).sort((a, b) => b - a)) {
      // eslint-disable-next-line
      for (let month of Object.keys(objs[year]).sort((a, b) => b - a)) {
        ret.push(createAPanel(objs[year][month], reports, year, month, expandFirst));
        expandFirst = false;
      }
    }
    return ret;
  };

  const poller = new Poller(() => loadReports(true));
  useEffect(() => {
    loadReports();
  }, []); // eslint-disable-line

  const reportingMonths = getAllReportingMonths(dateRange);
  const dataAvailable = reportingMonths !== undefined && reportingMonths.length > 0;
  if (isLoading) return null;

  return (
    <div className={classes.container}>
      {dataAvailable &&
        <CreateToolbar
          message={reportPendingMessage()}
          toolTip="Create new report"
          onNew={() => setShowNewFileReportDialog(true)}
          onRefresh={loadReports}
        />
      }
      {reports.length ? createReportPanels(reports) : <NoData/>}
      {dataAvailable &&
        <NewFileReportDialog
          show={showNewFileReportDialog}
          onAction={createNewReport}
          onCancel={() => setShowNewFileReportDialog(false)}
          reportingMonths={reportingMonths}
          formats={fileFormats}
        />
      }
      {downloadUrl &&
        <FileDownload
          url={downloadUrl}
          onDownloadComplete={() => setDownloadUrl()}
        />
      }
      {error &&
        <ErrorDialog
          show={true}
          title={error.title}
          text={error.text}
          onClose={() => setError()}
        />
      }
      <Confirmation
        show={showConfirmationDialog}
        color="primary"
        title="Send report by email?"
        text="This report will be sent to any recipients registered for FTC reports."
        onCancel={() => setShowConfirmationDialog(false)}
        onAction={() => sendReport()}
      />
    </div>
  );
};

DownloadReports.propTypes = {
  dateRange: PropTypes.object,
};

export default DownloadReports;
