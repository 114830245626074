import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator} from '@mui/lab';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import MapIcon from '@mui/icons-material/Map';
import LoginIcon from '@mui/icons-material/Login';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {styled} from '@mui/material/styles';
import MoreIcon from '@mui/icons-material/ArrowDropDownCircle';
import IconButton from '@mui/material/IconButton';

const DivMoreBar = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));


const StyledDot = styled(TimelineDot)(( ) => ({
  margin:'5.5px 0',
  backgroundColor:'white',
  borderColor:'#bdbdbd',
  boxShadow:'unset'
}));

const StyledTimeline = styled(Timeline)(({theme})=>({
  backgroundColor: theme.palette.background.default,
  paddingBottom: '2rem',
  paddingLeft: '0px'
}));

const TextContainer = styled('div')(( ) => ({
  width:'50%',
  backgroundColor:'#F3F4F6',
  borderRadius:'10px',
  paddingLeft:'1rem',
  paddingTop:'0.5em',
  paddingBottom:'0.5em'
}));

const StyledDate = styled('p')(( ) => ({
  fontSize: '0.7rem',
  fontWeight: 500,
  marginBlock:'0.1em'
}));

const StyledDescription = styled('p')(( ) => ({
  fontSize: '0.7rem',
  fontWeight: 500,
  marginBlockEnd: '0.1em',
  marginBlockStart:'0.2em'
}));
const StyledLog = styled('p')(( ) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  marginBlockStart: '0.1em',
  marginBlockEnd:'0.2em'
}));


const AuditTimeline =({auditLogs, totalSize,showMore}) =>{
  const icons = {
    'Areas': <MapIcon sx={{color:'#22c55e'}}/>,
    'Auth': <LoginIcon sx={{color:'#f472b6'}}/>,
    'Clients': <BusinessCenterIcon sx={{color:'#fbbf24'}}/>,
    'Fuel': <LocalGasStationIcon sx={{color:'#a3e635'}}/>,
    'Report Files': <InsertDriveFileIcon sx={{color:'#c084fc'}}/>,
    'Users': <PersonIcon sx={{color:'#60a5fa'}}/>,
    'Vehicles': <DirectionsCarIcon sx={{color:'#9ca3af'}}/>
  };

  return  (
    <StyledTimeline position ='right'
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 0,
        },
      }}
    >
      { auditLogs.map((auditLog, index) => {
        return (
          <TimelineItem key={auditLog.id} >
            <TimelineSeparator >
              <StyledDot >
                {icons[auditLog.stack.trim(0)]}
              </StyledDot>
              {index < totalSize -1 && <TimelineConnector sx={{height:'4rem'}}/>}
            </TimelineSeparator>
            <TimelineContent >
              <TextContainer>
                <StyledDate>
                  {moment(auditLog.created_at).format('D MMM YYYY hh:mm:ss')}
                </StyledDate>
                <StyledLog> {`${auditLog.stack} - ${auditLog.action}`} </StyledLog>
                <StyledDescription> {auditLog.description} </StyledDescription>
              </TextContainer>
            </TimelineContent>
          </TimelineItem>
        );})}
      { auditLogs.length < totalSize && <DivMoreBar>
        <IconButton onClick={showMore} >
          <MoreIcon/>
        </IconButton>
      </DivMoreBar> }
    </StyledTimeline>);
};

AuditTimeline.propTypes = {
  auditLogs : PropTypes.array.isRequired,
  totalSize: PropTypes.number.isRequired,
  showMore: PropTypes.func.isRequired,
};

export default AuditTimeline;