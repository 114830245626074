import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStore } from '../../../ducks';
import PropTypes from 'prop-types';
import { ReportsAPI } from '../../../api';
import { makeDateList } from 'lib/date';
import moment from 'moment';

const headerHeight = 52;
const footerHeight = 40;
const reportHeaderHeight = 52;
const netReduction = headerHeight + footerHeight + reportHeaderHeight;
const PREFIX = 'Report';

const classes = {
  page: `${PREFIX}-page`
};

const Root = styled('div')({
  [`&.${classes.page}`]: {
    height: `calc(100vh - ${netReduction}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
});

const Report = ({ report, filter, onFilterChanged, ViewComponent, version }) => {

  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const [reportData, setReportData] = useState();
  const [dateList, setDateList] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(filter);
  const endpointFunction = version === 2 ? ReportsAPI.getFTCReport : ReportsAPI.getReport;

  const fetchData = async (filter) => {
    dispatch(LoadingStore.loading(true));
    const reportName = report.name === 'ftc_summary' ? 'ftc_fleet' : report.name;  // hack around api bug for now
    try {
      const response = await endpointFunction(clientId, reportName, filter);
      const result = response.payload;
      setReportData(result);
    } catch (err) {
      setReportData([]); // HACK around lack of payload.
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  const monthFilter = (date) => {
    const d = moment(date);
    return {
      start_date: d.startOf('month').format('YYYY-MM-DD'),
      end_date: d.endOf('month').format('YYYY-MM-DD')
    };
  };

  const onFilterUpdate = (filter) => {
    const reportDateList = makeDateList(report.date_range.min, report.date_range.max);
    let currentFilter = filter;
    if (!(reportDateList.includes(currentFilter.date))) {
      currentFilter = { date: reportDateList[0] };
    }
    setDateList(makeDateList(report.date_range.min, report.date_range.max));
    fetchData(monthFilter(currentFilter.date));
    setAppliedFilter(currentFilter);
  };

  useEffect(() => {
    onFilterUpdate(filter);
  }, [filter]); // eslint-disable-line

  if (report === undefined || isLoading === undefined) return null;

  return (
    <Root className={classes.page}>
      {!isLoading && (
        <ViewComponent
          name={report.name}
          title={report.description}
          reportData={reportData}
          filter={appliedFilter}
          onFilterChanged={onFilterChanged}
          dateList={dateList}
          exportFileName={`${report.name}_${filter.date}.csv`}
        />
      )}
    </Root>
  );
};

Report.propTypes = {
  report: PropTypes.object,
  onFilterChanged: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  ViewComponent: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  version: PropTypes.number.isRequired,
};

export default Report;
