import AccountHealth from './containers/AccountHealth';

export default [
  {
    key: 1,
    path: '/account_health',
    exact: false,
    component:  AccountHealth
  }
];
