import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import AreasAction from './AreasAction';

const PREFIX = 'AreasTable';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPaper = styled(Paper)({
  [`&.${classes.root}`]: {
    width: '100%',
  },
});

const activeColumn = (row) => {
  if (row.admin_approved)
  {
    return <Checkbox checked={row.active} disabled={true} color="primary" />;
  }
  return <Alert severity="warning">Awaiting admin approval</Alert>;

};

const actionColumn =(row,refreshCustomAreasList) => {
  return <AreasAction id={row.id} refreshCustomAreasList={refreshCustomAreasList}/>;
};


const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'start_time', label: 'Start Date',type:'date',dateSetting: { locale: 'en-GB' }, minWidth: 100 },
  { id: 'end_time', label: 'End Date', minWidth: 100 },
  { id: 'source', label: 'Source', minWidth: 100 },
  {
    id: 'description',
    label: 'Description',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'active',
    label: 'Active',
    minWidth: 100,
    align: 'left',
    component: activeColumn
  },
  {
    label: 'Action',
    minWidth: 180,
    align: 'left',
    component: actionColumn
  },
];



const AreasTable = ({customAreas, refreshCustomAreasList}) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledPaper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customAreas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.component ? column.component(row,refreshCustomAreasList) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={customAreas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
};

AreasTable.propTypes = {
  customAreas :  PropTypes.array,
  refreshCustomAreasList: PropTypes.func
};

export default AreasTable;