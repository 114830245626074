export const ARTICULATED_TRUCK = 'ARTICULATED TRUCK';
export const RIGID_TRUCK = 'RIGID TRUCK';

export const rigidTypes = [
  {
    name: 'Rigid Truck no trailer',
    value: [],
  },
  {
    name: 'Rigid Truck with 2 Axle Dog Trailer',
    value: ['TD2'],
  },
  {
    name: 'Rigid Truck with 2 Axle Pig Trailer',
    value: ['TP2'],
  },
  {
    name: 'Rigid Truck with 3 Axle Dog Trailer',
    value: ['TD3'],
  },
  {
    name: 'Rigid Truck with 3 Axle Pig Trailer',
    value: ['TP3'],
  },
  {
    name: 'Rigid Truck with 4 Axle Dog Trailer',
    value: ['TD4'],
  },
];

export const articulatedTypes = [
  {
    name: '1 Axle Semitrailer',
    value: ['TS1'],
  },
  {
    name: '2 Axle Semitrailer',
    value: ['TS2'],
  },
  {
    name: '3 Axle Semitrailer',
    value: ['TS3'],
  },
  {
    name: '4 Axle B-double',
    value: ['TL2', 'TS2'],
  },
  {
    name: '5 Axle B-double',
    value: ['TL3', 'TS2'],
  },
  {
    name: '6 Axle B-double',
    value: ['TL3', 'TS3'],
  },
  {
    name: '6 Axle A-double',
    value: ['TL2', 'TY2', 'TS2'],
  },
  {
    name: '8 Axle A-double',
    value: ['TL3', 'TY2', 'TS3'],
  },
  {
    name: '9 Axle A-double',
    value: ['TL3', 'TY3', 'TS3'],
  },
  {
    name: '9 Axle B-triple',
    value: ['TL3', 'TS3', 'TS3'],
  },
  {
    name: '11 Axle AB-triple',
    value: ['TL3', 'TY2', 'TS3', 'TS3'],
  },
  {
    name: '12 Axle AB-triple',
    value: ['TL3', 'TY3', 'TS3', 'TS3'],
  },
  {
    name: '13 Axle A-triple',
    value: ['TL3', 'TY2', 'TS3', 'TY2', 'TS3'],
  },
  {
    name: '15 Axle A-triple',
    value: ['TL3', 'TY3', 'TS3', 'TY3', 'TS3'],
  },
];
