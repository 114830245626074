import { REHYDRATE } from 'redux-persist';

const LOADING     = 'loading/LOADING';

const initialState = {
  isLoading: false,
  isRehydrated: false,
};

// Action Creators

export function loading(isLoading=true) {
  return {
    type: LOADING,
    isLoading
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case LOADING:
    return {
      ...state,
      isLoading: action.isLoading,
    };

  case REHYDRATE:
    return {
      ...state,
      isRehydrated: true
    };

  default:
    return state;
  }
}
