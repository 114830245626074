import { makePath, request } from './http';
import config from 'config/config';

const API_NAME = 'report-files';

export function getReportFiles(clientId) {
  return request('GET', makePath(API_NAME), {clientId});
}

export function generateReportFile(clientId, {year, month, months, format}) {
  const params = {year, month, months, format};
  return request('POST', makePath(API_NAME, 'generate'), {clientId, params});
}

export function generateAllReportFiles() {
  return request('POST', makePath(API_NAME, config.isBarton?'generate_all_ruc_reports':'generate_all'));
}

/*
 * supported optional params:
 * 'content_disposition',
 * 'cache_control',
 * 'content_encoding',
 * 'content_language',
 * 'content_type'
 */
export function getReportFile(clientId, fileName, params) {
  return request('GET', makePath(API_NAME, fileName), {clientId, params});
}

export function sendReport(clientId, {year, month, name, doc_format}) {
  const params = {year, month, doc_format, name};
  return request('POST', makePath(API_NAME, 'send'), {clientId, params});
}

export function sendReportsAuto() {
  return request('POST', makePath(API_NAME, config.isBarton?'send_ruc_reports_auto':'send_reports_auto'));
}

export function getQueryReports() {
  return request('GET', makePath(API_NAME, 'query'));
}

export function getQueryReportFile(query, params) {
  return request('GET', makePath(API_NAME, 'query', query), {params});
}

export function generateQueryReportFile(query) {
  return request('POST', makePath(API_NAME, 'query', query));
}

export function getAuditFiles(clientId) {
  return request('GET', makePath(API_NAME, 'audit'), {clientId});
}

export function getAuditFile(clientId, fileName, params) {
  return request('GET', makePath(API_NAME, 'audit', fileName), {clientId, params});
}

export function generateAuditFiles(clientId, {year, month}) {
  const params = {year, month};
  return request('POST', makePath(API_NAME, 'audit'), {clientId, params});
}
