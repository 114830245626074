import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Roles from './Roles';
import Form, {
  Section,
  TextInput,
} from '../Form';
import IndustrySelector from 'components/IndustrySelector';

const PREFIX = 'ClientForm';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  closeButton: `${PREFIX}-closeButton`,
  saveButton: `${PREFIX}-saveButton`
};

const Root = styled('div')(() => ({
  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  [`& .${classes.closeButton}`]: {
  },

  [`& .${classes.saveButton}`]: {
    marginTop: '1rem',
    fontWeight: 900,
    alignSelf: 'center',
    width: '10rem',
  }
}));

class ClientForm extends React.Component
{
  static propTypes = {
    client: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    userRoles: PropTypes.array.isRequired,
    readOnlyRoles: PropTypes.array.isRequired,
  }

  state = {
    client: undefined, // as passed from props
    newClient: undefined, // as modified locally
    showConfirmation: false,
    newRoles: undefined,
  }

  onChange = (field, value) => {
    this.setState((state) => {
      state.newClient[field] = value;
      return state;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (nextProps.client !== prevState.client) {
      state.client = nextProps.client;
      state.newClient = Object.assign({}, nextProps.client);
      state.newRoles = nextProps.userRoles.slice();
      return state;
    }
    return null;
  }

  onSave = () => {
    this.props.onSave(this.state.newClient, this.state.newRoles);
  }

  render() {
    const {  onClose, roles, readOnlyRoles } = this.props;
    const { newClient, newRoles } = this.state;
    if (!newClient) return null;
    const createClient = newClient.client_id === undefined;
    const title = createClient ? 'Create a new client' : 'Edit this client';
    const valid =
      newClient.nickname && newClient.nickname.length > 0 &&
      newClient.full_name && newClient.full_name.length > 0;
    /*
     * Note: outer div is required for vertical scrolling to work correctly
     */
    return (
      <Root>
        <Form>
          <div className={classes.toolbar}>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>

          </div>
          <h1>{title}</h1>

          <Section title="Identification">
            <FormGroup row>
              <TextInput label="Client ID" value={newClient} field="client_id" readOnly/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="Full Name" value={newClient} field="full_name" onChange={this.onChange}/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="Nickname" value={newClient} field="nickname" onChange={this.onChange}/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="ABN" value={newClient} field="abn" onChange={this.onChange}/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="State" value={newClient} field="state" onChange={this.onChange}/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="Postal Address" value={newClient} field="postal_address" onChange={this.onChange}/>
            </FormGroup>
          </Section>

          <Section title="Market Segment">
            <IndustrySelector
              industry={newClient.industry}
              onChange={(value) =>
                this.onChange('industry', {
                  ...(newClient.industry ?? {}),
                  ...value,
                })
              }
            />
          </Section>

          <Section title="Your Permissions">
            <Roles
              roles={roles}
              userRoles={newRoles}
              onChange={(roles) => this.setState({newRoles: roles})}
              readOnlyRoles={readOnlyRoles}
            />
          </Section>

          <Button variant="contained" color="primary" className={classes.saveButton} onClick={this.onSave} disabled={!valid}>
            Save
            <DoneIcon/>
          </Button>
        </Form>
      </Root>
    );
  }
}

export default (ClientForm);
