import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FatalError from '../components/FatalError';
import ErrorBoundary from '../components/ErrorBoundary';
import { connect } from 'react-redux';
import { LoadingStore } from '../ducks';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { currentTsAndCs } from '../components/TsAndCs';

function withRole(role, WrappedComponent) {
  class WithRole extends Component {
    static propTypes = {
      history: PropTypes.object,
      isAuthenticated: PropTypes.bool,
      acceptedTsAndCs: PropTypes.string,
      tokenExpiresAt: PropTypes.number,
      clientId: PropTypes.string,
      profile: PropTypes.object,
      isSuperUser: PropTypes.bool,
      dispatch: PropTypes.func.isRequired,
      isRehydrated: PropTypes.bool.isRequired,
    };

    // we use this to force special behaviour on first load (or on reload)
    // when the token is expired.  If loaded is false, we redirect to the
    // login screen.  Otherwise we show the error page with a prompt to login.
    loaded = false;

    renderContent() {
      return (
        <div className="App DefaultTheme">
          <WrappedComponent
            {...this.props}
          />
        </div>
      );
    }

    renderError() {
      // REVISIT: in this state we seem to continually re-render.  Find out why.
      let nextPath, title, message;
      const isAuth = this.props.isAuthenticated && this.props.tokenExpiresAt > Date.now();
      if (!isAuth) {
        nextPath = '/login';
        title = 'Session expired';
        message = 'Please login again.';
      } else {
        nextPath = '/prism';
        title = 'Permission denied';
        message = 'This operation is not allowed.';
      }

      this.props.dispatch(LoadingStore.loading(false));
      return (
        <FatalError
          history={this.props.history}
          title={title}
          message={message}
          nextPath={nextPath}
        />
      );
    }

    hasRole = (roles) => {
      const { profile, clientId, isSuperUser } = this.props;
      if (isSuperUser) { return true; }
      if (profile && clientId && profile.roles[clientId]) {
        return roles === '*' ||
          roles.find(role => profile.roles[clientId].includes(role));
      }
      return false;
    };

    render() {
      const { isRehydrated, isAuthenticated, acceptedTsAndCs, profile, clientId, tokenExpiresAt } = this.props;
      const sessionValid = isAuthenticated && tokenExpiresAt > Date.now();
      if (!isRehydrated) return null; // waiting to rehydrate
      // we redirect to login in two cases:
      // a) if we are not logged in
      // b) if the token has expired and this is a fresh page load
      if (!isAuthenticated || (!this.loaded && !sessionValid)) return <Redirect to="/login"/>;
      if (currentTsAndCs !== acceptedTsAndCs) return <Redirect to="/termsandconditions"/>;
      if (!profile || !clientId) return null; // waiting for profile
      this.loaded = true;
      return (
        <ErrorBoundary history={this.props.history}>
          {
            sessionValid && this.hasRole(role) ? this.renderContent() : this.renderError()
          }
        </ErrorBoundary>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      isRehydrated: state.loading.isRehydrated,
      isAuthenticated: state.auth.isAuthenticated,
      acceptedTsAndCs: state.auth.acceptedTsAndCs,
      tokenExpiresAt: state.auth.tokenExpiresAt,
      profile: state.auth.profile,
      clientId: state.clients.selectedItemId,
      isSuperUser: state.auth.isSuperUser,
    };
  };

  return withRouter(connect(mapStateToProps)(WithRole));

}

export default (role, Component) => withRole(role, Component);
