import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Confirmation from '../Confirmation';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Form, {
  Section,
  TextInput,
  CheckboxInput,
} from '../Form';

const PREFIX = 'UserForm';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  closeButton: `${PREFIX}-closeButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  saveButton: `${PREFIX}-saveButton`
};

const Root = styled('div')(() => ({
  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  [`& .${classes.closeButton}`]: {
  },

  [`& .${classes.deleteButton}`]: {
    float: 'right',
  },

  [`& .${classes.saveButton}`]: {
    marginTop: '1rem',
    fontWeight: 900,
    alignSelf: 'center',
    width: '10rem',
  }
}));

class UserForm extends React.Component
{
  static propTypes = {
    user: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.array,
    isSelf: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    showRoles: PropTypes.bool.isRequired,
  }

  state = {
    user: undefined, // as passed from props
    newRoles: undefined,
    newUser: undefined, // as modified locally
    showConfirmation: false,
  }

  onChange = (field, value) => {
    this.setState((state) => {
      state.newUser[field] = value;
      return state;
    });
  }

  onChangeRole = (value, checked) => {
    this.setState((state) => {
      if (checked) {
        state.newRoles.push(value);
      } else {
        state.newRoles = state.newRoles.filter(role => role !== value);
      }
      return state;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (nextProps.user !== prevState.user) {
      if (nextProps.user !== undefined) {
        state.user = nextProps.user;
        state.newUser = Object.assign({}, nextProps.user);
        if (nextProps.showRoles) {
          state.newRoles = nextProps.user.roles[nextProps.clientId].slice();
        } else {
          state.newRoles = undefined;
          delete state.newUser.roles;
        }
        return state;
      }
    }
    return null;
  }

  onSave = () => {
    const { newUser, newRoles } = this.state;
    const { clientId, showRoles } = this.props;
    if (showRoles) {
      newUser.roles[clientId] = newRoles;
    }
    this.props.onSave(newUser);
  }

  render() {
    const {  onDelete, onClose, roles, isSelf, showRoles } = this.props;
    const { newUser, newRoles } = this.state;
    if (!newUser) return null;

    const createUser = newUser.user_id === undefined;
    const title = createUser ? 'Create a new user' : 'Edit this user';
    // cannot modify ClientAdmin on self unless we are in superUser mode.
    const readOnlyRoles = isSelf ? ['Client Admin'] : [];

    const valid =
      (!showRoles || (newRoles && newRoles.length > 0)) &&
      newUser.email && newUser.email.length > 0 &&
      newUser.nickname && newUser.nickname.length > 0;
    /*
     * Note: outer div is required for vertical scrolling to work correctly
     */
    return (
      <Root>
        <Form>
          <div className={classes.toolbar}>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
            {!isSelf && !createUser && (
              <Fab size="small" className={classes.deleteButton} onClick={() => this.setState({showConfirmation: true})}>
                <DeleteIcon/>
              </Fab>
            )}
          </div>
          <h1>{title}</h1>

          <Section title="Identification">
            <FormGroup row>
              <TextInput label="Prism ID" value={newUser} field="user_id" readOnly/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="Email" value={newUser} field="email" onChange={this.onChange} readOnly={!createUser}/>
            </FormGroup>
            <FormGroup row>
              <TextInput label="nickname" value={newUser} field="nickname" onChange={this.onChange}/>
            </FormGroup>
          </Section>

          {showRoles && newRoles && roles && (
            <Section title="Permissions">
              {roles.map(role => (
                <CheckboxInput
                  key={role.id}
                  label={role.description}
                  value={role.name}
                  checked={newRoles.includes(role.name)}
                  onChange={this.onChangeRole}
                  readOnly={readOnlyRoles.includes(role.name)}
                />
              ))}
            </Section>
          )}

          <Button variant="contained" color="primary" className={classes.saveButton} onClick={this.onSave} disabled={!valid}>
            Save
            <DoneIcon/>
          </Button>
          <Confirmation
            show={this.state.showConfirmation}
            color="secondary"
            title="Delete this user?"
            text="Deleting a user is a permanent operation and it cannot be undone!"
            onCancel={() => this.setState({showConfirmation: false})}
            onAction={() => onDelete(this.state.user)}
          />
        </Form>
      </Root>
    );
  }
}

export default (UserForm);
