import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
const PREFIX = 'PageHeader';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    width: '100%',
    marginBottom: '1rem',
  },
  [`& .${classes.title}`]: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  [`& .${classes.subtitle}`]: {
    fontWeight: 500,
    fontSize: '0.8rem',
  },
});

const PageHeader = ({ title, subtitle=null, helperText=null}) => {

  return (
    <Root className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      {helperText && <Typography className={classes.helperText}>{helperText}</Typography>}
    </Root>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  helperText: PropTypes.string,
};

export default PageHeader;
