import React, {useCallback, useEffect,useState}from 'react';
import { styled } from '@mui/material/styles';
import {useRef} from 'react';
import Map,{ NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapViewToggle from '../../../components/MapViewToggle';
import DrawControl from '../components/DrawControl';
import GeocoderControl from '../components/GeocoderControl';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import config from '../../../config/config';
import PropTypes from 'prop-types';


const DivButtonContainer = styled('div')(() => ({
  width: '100%',
  textAlign: 'center',
  position: 'fixed',
  left: '50%',
  bottom: '20px',
  transform: 'translate(-50%, -50%)',
  margin: '0 auto',
}));

const StyledLinkContainer = styled(Link)(() => ({
  textDecoration: 'none',
}));

const StyledButton = styled(Button)(() => ({
  float: 'center',
  fontSize: '14px',
  lineHeight: '18px',
  width: '284px',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.15)',
  margin: '24px',
  padding: '12px 24px',
  outline: 'none',
  cursor: 'auto',
}));


//Initial values are set to zoom into Brisbane City
const initialMapLatitude = -27.4705;
const initialMapLongitude = 153.0260;
const initialZoom = 13;
const mapMaxBounds=[[72.2460938,-55.3228175 ],[168.2249543,-9.0882278]];


const availableMapStyles = {
  'street': 'mapbox://styles/mapbox/streets-v11',
  'satellite': 'mapbox://styles/mapbox/satellite-streets-v11'
};

const NewAreaMap =({location}) =>{
  const mapRef = useRef();

  const [mapView, setMapView] = useState('street');
  const [mapStyle, setMapStyle] = useState(availableMapStyles['street']);

  const [features, setFeatures] = useState((location.state.geojson!== undefined) ? location.state.geojson.features: []);
  const toggleMapStyle = () => {
    if (mapView==='street'){
      setMapStyle(availableMapStyles['street']);
    }
    else{setMapStyle(availableMapStyles['satellite']);}
  };

  const onUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = [...currFeatures];
      // eslint-disable-next-line no-unused-vars
      for (const f of e.features) {
        let foundFeature = newFeatures.find(feature=> feature.id === f.id);
        if (foundFeature){
          foundFeature = f;
        } else {
          newFeatures.push(f);
        }
      }
      return newFeatures;
    });

  }, []);


  const onCombineUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = [...currFeatures];
      // eslint-disable-next-line no-unused-vars
      for (const f of e.deletedFeatures) {
        newFeatures.splice(newFeatures.findIndex(feature=> feature.id === f.id),1);
      }
      // eslint-disable-next-line no-unused-vars
      for (const f of e.createdFeatures) {
        let foundFeature = newFeatures.find(feature=> feature.id === f.id);
        if (foundFeature){
          foundFeature = f;
        } else {
          newFeatures.push(f);
        }
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = [...currFeatures];
      // eslint-disable-next-line no-unused-vars
      for (const f of e.features) {
        newFeatures.splice(newFeatures.findIndex(feature=> feature.id === f.id),1);
      }
      return newFeatures;
    });
  }, []);


  useEffect(() => {
    toggleMapStyle();
  }, [mapView]);// eslint-disable-line


  const getGeoJSON =() =>{

    const geojson = {
      type: 'FeatureCollection',
      features: features
    };
    return geojson;
  };
  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={config.settings.MAPBOX_KEY}
      boxZoom={true}
      initialViewState={{
        latitude: initialMapLatitude,
        longitude: initialMapLongitude,
        zoom: initialZoom,
      }}
      mapStyle={mapStyle}
      maxBounds={mapMaxBounds}
      interactiveLayerIds={['data']}
    >
      <MapViewToggle mapView={mapView} setMapView={setMapView} />
      <GeocoderControl
        mapboxAccessToken={config.settings.MAPBOX_KEY}
        position="top-left"
      />
      <GeolocateControl position="top-left" />
      <FullscreenControl position="top-left" />
      <NavigationControl position="top-left" />
      <ScaleControl />
      <DrawControl
        position="top-left"
        displayControlsDefault={false}
        style={{
          id: 'highlight-active-points',
          type: 'fill',
          filter: [
            'all',
            ['==', '$type', 'MultiPolygon'],
            ['==', 'meta', 'feature'],
            ['==', 'active', 'true'],
          ],
          paint: {
            'fill-color': '#4E3FC8',
            'fill-opacity': 0.6,
          },
        }}
        controls={{
          polygon: true,
          trash: true,
          combine_features: true,
          uncombine_features: true,
        }}
        defaultMode="draw_polygon"
        feature={features}
        onCreate={onUpdate}
        onCombine={onCombineUpdate}
        onUnCombine={onCombineUpdate}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />

      <DivButtonContainer>
        <StyledLinkContainer to={{pathname:'/areas/new_area', state: {setArea : location.state.newArea} }}>
          <StyledButton variant="contained" color="secondary">Back</StyledButton>
        </StyledLinkContainer>
        {features.length !== 0 && (
          <StyledLinkContainer
            to={{
              pathname: '/areas/new_area/review',
              state: { newArea: location.state.newArea, geojson: getGeoJSON() },
            }}
          >
            <StyledButton variant="contained" color="primary">
          Next
            </StyledButton>
          </StyledLinkContainer>
        )}
      </DivButtonContainer>
    </Map>
  );
};

NewAreaMap.propTypes = {
  location: PropTypes.object,
};

export default NewAreaMap;