import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing.unit,
  minWidth: 300,
}));

const StyledTextField = styled(TextField)(( ) => ({}));


/* format is:
 * \d+:\d{2}:\d{2}:\d{2}
 * logic is:
 * split by :
 * remove non-digits
 * reformat
 */
function formatDuration(value) {
  return value.split(':', 4).map((v, i) => {
    const n = parseInt(v.replace(/[^\d]/g, ''), 10) || 0;
    const str = '' + n;
    return i === 0 ? str : '00'.substr(0, 2 - str.length) + str;
  }).join(':');
}

const CustomField = (props) => {
  const {  label, onChange, value='',values,default_value='', read_only=false, required=true } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const renderStringField = () => {

    return (
      <StyledFormControl>
        <StyledTextField
          label={label}
          type="text"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          InputProps={{ readOnly: read_only }}
          InputLabelProps={{ shrink: true }}
          required={required}
        />
      </StyledFormControl>
    );
  };

  const renderPasswordField = () => {

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
      <StyledFormControl>
        <StyledTextField
          label={label}
          value={value}
          type={showPassword ? 'text' : 'password'}
          onChange={(event) => onChange(event.target.value)}
          InputProps = {{ endAdornment:(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}}
          InputLabelProps={{ shrink: true }}
          required={required}
        />
      </StyledFormControl>
    );
  };

  const renderDurationField = () => {

    return (
      <StyledFormControl>
        <StyledTextField
          label={label + ' d:hh:mm:ss'}
          type="text"
          value={value}
          onChange={(event) => onChange(formatDuration(event.target.value))}
          InputProps={{ readOnly: read_only }}
          InputLabelProps={{ shrink: true }}
          required={required}
        />
      </StyledFormControl>
    );
  };

  const renderSelectField = () => {
    return (
      <StyledFormControl>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value !== undefined ? value : default_value}
          readOnly={read_only}
          onChange={(event) => onChange(event.target.value)}
          label={label}
        >
          {values.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    );
  };

  switch (props.type) {
  case 'string':
    return renderStringField();
  case 'password':
    return renderPasswordField();
  case 'duration':
    return renderDurationField();
  case 'select':
    return renderSelectField();
  default:
    return <div>Unsupported field type {props.type}</div>;
  }
};

CustomField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  read_only: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.array,
  default_value: PropTypes.any,
};

export default (CustomField);
