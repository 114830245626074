import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import ButtonIcon from '@mui/icons-material/ChevronRight';

const PREFIX = 'FatalError';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  message: `${PREFIX}-message`,
  button: `${PREFIX}-button`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.title}`]: {
    fontSize: '1.2rem',
    margin: 20,
  },

  [`& .${classes.message}`]: {
    fontSize: '1rem',
    margin: 20,
  },

  [`& .${classes.button}`]: {
    margin: 20,
  }
}));

class FatalError extends React.Component
{
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    history: PropTypes.object.isRequired,
    nextPath: PropTypes.string,
  }

  render() {
    const { title, message, history, nextPath='/prism', } = this.props;
    return (
      <Root className={classes.container}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.message}>
          {message}
        </div>
        <ActionButton
          className={classes.button}
          variant="contained"
          onClick={() => history.push(nextPath)}
          tooltip="Return to main menu"
          color="primary"
          label="Continue"
          Icon={ButtonIcon}
        />

      </Root>
    );
  }
}

export default (FatalError);
