import React from 'react';
import Section from './NumberedSection';
const currentTsAndCs = '2018-11-13/c';

const TsAndCs = () => {
  const counter = {major: 0, minor: 0, para: 0};
  return (
    <div>
      <h1>Terms and Conditions</h1>

      <p>
        <span>The following terms and conditions (Terms and Conditions) govern your access to and use of the Site.</span>
      </p>

      <p>
        <span>
          Please scroll down and read all of the following Terms
          and Conditions carefully before continuing to use the Site.  
        </span>
      </p>

      <p>
        <span>
          These terms and conditions are a legally binding
          contract between you and Nuonic.  By using the Site, you agree to abide by all
          of the Terms and Conditions stated or referenced in this Site.   If you do not
          agree to abide by these Terms and Conditions, do not use, or continue to use
          the Site or any services referenced herein.
        </span>
      </p>

      <Section counter={counter} major>
        <b>Definitions</b>
        <p>In these Terms and Conditions:</p>


        <p>
          <b><span>Confidential Information</span></b>
          <span> means all information, regardless of its form,
            provided by or on behalf of a party to the other party that is by its nature
            confidential or is designated by the Disclosing Party as confidential
          (including the Vehicle Data);</span>
        </p>

        <p>
          <b><span>Data Analysis Service</span></b>
          <span> means the analysis of the Vehicle Data for the purposes of
          providing an estimate of the use of the Vehicle on non-public roads;</span>
        </p>

        <p>
          <b>
            <span>Disclosing Party</span>
          </b>
          <span> means a party whose information is disclosed, or made
          available, to the other party;</span>
        </p>

        <p>
          <b>
            <span>Fuel Tax Credits</span>
          </b>
          <span> means an entitlement arising under section 41-5 of the Australian
          Fuel Tax Act 2006 (Cth);</span>
        </p>

        <p>
          <b>
            <span>Intellectual Property Rights</span>
          </b>
          <span> means all industrial and intellectual
            property rights of any kind including but not limited to copyright (including
            rights in computer software), trade mark, service mark, design, patent, trade
            secrets, semiconductor or circuit lawyer rights, trade, business, domain or
            company names, rights in Confidential Information, know how or other
            proprietary rights (whether or not any of these are registered and including
            any application for registration) and all rights or forms of protection of a
            similar nature or having equivalent or similar effect to any of these which may
          subsist anywhere in the world;</span></p>

        <p>
          <b>
            <span>IVMS Provider</span>
          </b>
          <span> means your provider of vehicle tracking data arising from an
          in-vehicle monitoring system (IVMS);</span></p>

        <p>
          <b>
            <span>Nuonic</span>
          </b>
          <span> means Nuonic Pty Ltd (ABN 76 613 269 364);</span></p>

        <p>
          <b>
            <span>Site</span>
          </b>
          <span> means https://web.prismapp.com.au (and any successor or related site designated by us);</span></p>
        <p>
          <b>
            <span>Vehicle </span>
          </b>

          <span>means a particular vehicle identified by you or the IVMS
          Provider in respect of which Nuonic agrees to analyse Vehicle Data;</span></p>

        <p>
          <b>
            <span>Vehicle Data</span>
          </b>
          <span> means in respect of a Vehicle, telematic data which has been
          provided to Nuonic for the purpose of calculating the Fuel Tax Credits;</span>
        </p>

        <p>
          <b>
            <span>You</span>
          </b>
          <span> means the person accessing the Site and <b>your</b> is interpreted accordingly.</span>
        </p>
      </Section>

      <Section counter={counter} major>
        <b>Acceptance of Terms and Conditions</b>

        <p>
          <span>By accessing the Site, you agree to be bound by these Terms
          and Conditions.</span></p>
      </Section>

      <Section counter={counter} major>
        <b>Grant of Access</b>

        <p>
          <span>Nuonic grants you revocable, non-exclusive and
            non-transferable access to the Site and any related services in accordance with
          these Terms and Conditions.</span>
        </p>
      </Section>

      <Section counter={counter} major>
        <b>Site</b>
      </Section>

      <Section counter={counter} minor>
        <span>The Site has been
          created as a free of charge method of delivery of the Data Analysis Service.
          You acknowledge that this method of delivery is provided only for your benefit
          as an alternative to the report that has also been sent to you.
        </span>
      </Section>

      <Section counter={counter} minor>
        <span>
          Nuonic may add, remove
          or adjust functionality or elements of functionality
          to and from the Site at its absolute discretion and without notice to you.   
          You should check the Site periodically to determine if any such changes have
          been made.
        </span>
      </Section>

      <Section counter={counter} major>
        <b>Status of Parties</b>
      </Section>

      <Section counter={counter} minor>
        <span>The relationship
          between you and Nuonic is that of principal and subcontractor to the IVMS
          Provider. Nothing in these Terms and Conditions will be taken as to constitute
        any direct relationship between the parties other than as set out herein.</span>
      </Section>

      <Section counter={counter} minor>
        <span>Nuonic’s provision of
          this Site and any services or information arising from this Site or the Data
          Analysis Services is subject to your agreement with the applicable IVMS
          Provider, and Nuonic’s separate agreement with the applicable IVMS Provider (together
          the <b>Primary Agreements</b>).  If there is any inconsistency between these
          Terms and Conditions and the Primary Agreements, the Primary Agreements will prevail.
        </span>
      </Section>

      <Section counter={counter} major>
        <b><span>Confidentiality and Data Use</span></b>
      </Section>

      <Section counter={counter} minor>
        <span>You and Nuonic each must keep confidential all of the other party’s Confidential Information except
        where such Confidential Information:</span>
        <Section counter={counter} para>
          <span>is in or becomes part of the public domain (other than as a result of a breach of these Terms and Conditions);</span>
        </Section>
        <Section counter={counter} para>
          <span>was known to the
            other party at the time of disclosure of the Confidential Information except as
            a result of a prior confidential disclosure to the other party by the
            Disclosing Party;
          </span>
        </Section>
        <Section counter={counter} para>
          <span>is required to be
            disclosed to your or Nuonic’s personnel, contractors, third party service
            providers or advisers to the extent necessary for them to know the information
          for purposes related to this Site or these Terms and Conditions;</span>
        </Section>

        <Section counter={counter} para>
          <span>is disclosed to the
            other party by any third party who is not known by the other party to be acting
          in breach of a confidentiality obligation owed to the Disclosing Party;</span>
        </Section>

        <Section counter={counter} para>
          <span>is required by any
            law, or any requirement of any court, tribunal, authority, regulatory body or
          stock exchange, to be disclosed; or</span>
        </Section>

        <Section counter={counter} para>
          <span>is to be disclosed
            in connection with any legal proceedings or disputes relating to these Terms
          and Conditions, or any agreement made in connection with it.</span>
        </Section>
      </Section>

      <Section counter={counter} minor>
        <span>Through the Site,
          Nuonic may use cookies to monitor browsing preferences and use. If you allow
          cookies to be used, your personal information may be stored and used by us (or
          our third party service providers acting on our behalf), including: your name,
          your address and your email address. You have the ability to accept or decline
          cookies on our Site by emailing us through the notice details below.  If you
          choose to decline use of cookies, you may not be able use the Services.
        </span>
      </Section>

      <Section counter={counter} minor>
        <span>There is also
          information about your use of computer hardware (including hand-held devices)
          and software that is collected by or on behalf of Nuonic through the Site. This
          information can include your IP address, browser type, device type and
          location, domain names, access times, which pages you viewed, and how you
          navigated through our Sites (including fields you access or complete in forms
          and applications).  This information is used by us for the operation of the
          service, to maintain quality of the service, and/or to understand general
        statistics regarding use of the Site and related Nuonic services.</span>
      </Section>

      <Section counter={counter} major>
        <b>Warranties and Disclaimer</b>
      </Section>

      <Section counter={counter} minor>
        <span>You represent and warrant to Nuonic that:</span>
        <Section counter={counter} para>
          you have the authority to access the Site and the information that is contained within; and
        </Section>

        <Section counter={counter} para>
          the Vehicle Data that you have provided is true and accurate.
        </Section>
      </Section>

      <Section counter={counter} minor>
        <span>You acknowledge that the Site is provided “as is”. Nuonic does not represent or warrant that:</span>



        <Section counter={counter} para>
          the Site or any
          services or information derived from the Site is error-free or uninterrupted
          (as relevant), or that Nuonic will correct any identified errors; and
        </Section>

        <Section counter={counter} para>
          that the Site or any
          services or information derived from the Site is fit for any particular
          purpose.
        </Section>
      </Section>

      <Section counter={counter} major>
        <b>Intellectual Property</b>

        <p><span>Each party maintains its Intellectual Property Rights that
          existed prior to Nuonic’s provision of the Data Analysis Service. Your access
          to the Site does not give you any Intellectual Property Rights or any other
          rights in the techniques and/or methodology used by Nuonic in providing the
          Data Analysis Services or the Site, including to analyse the Vehicle Data for
          the purposes of providing an estimate of the use of the Vehicle on non-public
          roads, which remain the sole and exclusive property of Nuonic. Subject to the
          confidentiality requirements in these Terms and Conditions, any newly developed
          intellectual property resulting from the Data Analysis Services will be owned
          by Nuonic, including any modifications or updates to the Site, or any Nuonic
        systems, methods, processes or know-how used to analyse the Vehicle Data.</span></p>
      </Section>

      <Section counter={counter} major>
        <b>Limitation of Liability</b>
      </Section>

      <Section counter={counter} minor>
        <span>Services and
          information provided from the Site may be based on information provided by you
          or an IVMS Provider (as appropriate).  Accordingly, Nuonic is not liable for
          any loss or damage arising as a consequence of the content of the Vehicle Data
          or any other data or information provided by you to Nuonic and/or the IVMS
        Provider.</span>
      </Section>

      <Section counter={counter} minor>
        <span>To the
          fullest extent permitted by
          law, Nuonic excludes all liability in respect of Site outages or defects, loss
          of data, interruption of business or any consequential or incidental damages
          suffered by you.
        </span>
      </Section>

      <Section counter={counter} minor>
        <span>To
          the full extent permitted by law, Nuonic excludes all representations,
          warranties or terms (whether express or implied) other than those expressly set
          out in these Terms and Conditions.
        </span>
      </Section>

      <Section counter={counter} minor>
        <span>This
          Agreement is to be read subject to any legislation which prohibits or restricts
          the exclusion, restriction or modification of any implied warranties,
          conditions, guarantees or obligations. If such legislation applies, to the
          extent possible, Nuonic limits its liability in respect of any claim to the
          supply of the services again.
        </span>
      </Section>

      <Section counter={counter} major>
        <b>No Tax Advice</b>

        <p><span>You agree and acknowledge that:</span></p>

        <Section counter={counter} para>
          <span>the Data Analysis
            Services do not include the provision of tax advice and that any information
          provided by Nuonic should not be relied upon as such; and </span>
        </Section>

        <Section counter={counter} para>
          <span>there are many
            factors and considerations which influence entitlement to Fuel Tax Credits
            beyond non-public road use and that the provision of the Data Analysis Services
          does not guarantee an entitlement to Fuel Tax Credits.</span>
        </Section>
      </Section>

      <Section counter={counter} major>
        <b>Termination</b>
        <p>
          <span>Nuonic reserves the right to terminate these Terms and
            Conditions or your access to the Site at any time and for any reason.
            If Nuonic terminates these Terms and Conditions or your access to the Site for
            any reason, then, in addition and without prejudice to any other rights or
            remedies available, your right to use the Site immediately ceases and the
          rights and license granted under these Terms and Conditions terminate. </span></p>
      </Section>

      <Section counter={counter} major>
        <b>Amendments</b>

        <p><span>Nuonic may amend these Terms and Conditions from time to time
          by posting a revised version of such Terms and Conditions on the Site. Please
        periodically check the Site to determine if any amendments have been made. </span></p>
      </Section>

      <Section counter={counter} major>
        <b>Governing Law</b>

        <p><span>Your use of the Site and any dispute arising out of such use
        of the website is subject to the laws of New South Wales, Australia.</span></p>
      </Section>

      <Section counter={counter} major>
        <b>Notices</b>
        <p>
          <span>If you have any questions or issues, you may contact Nuonic
          about the Site through the following email address: info@nuonic.com.au</span>
        </p>
      </Section>
    </div>
  );
};

export { currentTsAndCs };
export default TsAndCs;
