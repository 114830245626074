import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

const PREFIX = 'CheckboxFilter';

const classes = {
  root: `${PREFIX}-root`,
  checkboxLabel: `${PREFIX}-checkboxLabel`,
  rightMargin: `${PREFIX}-rightMargin`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    padding: 0,
  },
  [`& .${classes.checkboxLabel}`]: {
    fontSize: '0.7rem',
  },
  [`& .${classes.rightMargin}`]: {
    marginRight: '1rem',
  },
});

const CheckboxFilter = ({ checked=false, onFilterChanged, label=null, rightMargin=false }) => {

  return (
    <Root className={classes.root}>
      <FormControlLabel
        label={<Typography className={classes.checkboxLabel}>{label}</Typography>}
        control={
          <Checkbox
            checked={checked}
            onChange={onFilterChanged}
            color="primary"
          />
        }
      />
      {rightMargin && <div className={classes.rightMargin} />}
    </Root>
  );
};

CheckboxFilter.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  rightMargin: PropTypes.bool,
};

CheckboxFilter.displayName = 'CheckboxFilter';

export default CheckboxFilter;
