import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import AccordionActions from '@mui/material/AccordionActions';

const PREFIX = 'SettingsPanel';

const classes = {
  heading: `${PREFIX}-heading`,
  title: `${PREFIX}-title`,
  summary: `${PREFIX}-summary`,
  titleColumn: `${PREFIX}-titleColumn`,
  summaryColumn: `${PREFIX}-summaryColumn`,
  footer: `${PREFIX}-footer`
};

const StyledAccordion = styled(Accordion)((
  {
    theme
  }
) => ({
  [`& .${classes.heading}`]: {
    alignItems: 'center',
  },

  [`& .${classes.title}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.summary}`]: {
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.titleColumn}`]: {
    flexBasis: 280,
    flexGrow: 0,
    flexShrink: 0,
  },

  [`& .${classes.summaryColumn}`]: {
    flexGrow: 20,
    flexShrink: 1,
  },

  [`& .${classes.footer}`]: {
    justifyContent: 'flex-start',
  }
}));

class SettingsPanel extends React.Component {

  static propTypes = {
    expanded: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onExpand: PropTypes.func.isRequired,
  }

  render() {
    const {  title, summary, children, onSave, onCancel, onExpand, expanded } = this.props;
    return (
      <StyledAccordion expanded={expanded} onChange={(obj, expanded) => onExpand(expanded)}>
        <AccordionSummary classes={{content:classes.heading}} expandIcon={<ExpandMoreIcon />}>
          <div className={classes.titleColumn}>
            <Typography className={classes.title}>{title}</Typography>
          </div>
          <div className={classes.summaryColumn}>
            <Typography className={classes.summary}>{summary}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
        {onSave || onCancel ? <Divider /> : <div/>}
        <AccordionActions classes={{root:classes.footer}}>
          {onSave &&
            <Button variant="contained" size="small" onClick={onSave} color="primary">
              Save
            </Button>
          }
          {onCancel &&
            <Button size="small" onClick={onCancel}>
              Cancel
            </Button>
          }
        </AccordionActions>
      </StyledAccordion>
    );
  }
}

export default (SettingsPanel);
