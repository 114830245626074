import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import {
  injectIntl,
  FormattedMessage as Intl
} from 'react-intl';

const PREFIX = 'AuthForm';

const classes = {
  group: `${PREFIX}-group`,
  form: `${PREFIX}-form`,
  alertText: `${PREFIX}-alertText`,
  button: `${PREFIX}-button`,
  instructions: `${PREFIX}-instructions`
};

const Root = styled('form')(() => ({
  [`&.${classes.group}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  [`& .${classes.form}`]: {
    background: 'transparent',
    borderRadius: '1rem',
    padding: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minWidth: '30rem',
  },

  [`& .${classes.alertText}`]: {
    marginTop: '0.5rem',
    color: 'red',
    textAlign: 'center',
  },

  [`& .${classes.button}`]: {
    marginTop: '1rem',
    fontWeight: 900,
  },

  [`& .${classes.instructions}`]: {
    margin: '1rem',
    maxWidth: '30rem',
    padding: '1rem',
    fontWeight: 900,
  }
}));

const AuthForm = ({children, title, buttonText, buttonAction, alertText, loading, homeLink=true, buttonDisabled=false}) => {
  return (
    <Root className={classes.group} onSubmit={e => { e.preventDefault(); }}>
      {title}
      <div className={classes.form}>
        {children}
        <Button className={classes.button} variant="contained" disabled={buttonDisabled || loading} color="primary" size="large" onClick={buttonAction}>
          <Intl id={buttonText} />
        </Button>
        {alertText && <div className={classes.alertText}>{alertText}</div>}
      </div>
      {homeLink && (
        <div className={classes.instructions}>
          <Link
            style={{ color: '#58595b', textDecoration: 'none' }}
            to="/login"
          >
            <IconButton color="inherit" size="large">
              <BackIcon />
            </IconButton>
            Back to login
          </Link>
        </div>
      )}
    </Root>
  );
};

AuthForm.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  alertText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  homeLink: PropTypes.bool,
  title: PropTypes.node.isRequired,
};

export default (injectIntl(AuthForm));
