import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ReportTable from '../../../components/ReportTable';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';

const PREFIX = 'TripTable';

const classes = {
  root: `${PREFIX}-root`,
  progress: `${PREFIX}-progress`,
  content: `${PREFIX}-content`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.progress}`]: {
    height: '0.2rem',
  },
  [`& .${classes.content}`]: {
    border: '1px solid #ccc',
    minHeight: '50vh',
  },
});

const TripTable = ({ data, onRowClick, rowsSelected, noDataText='No trips could be found.' }) => {

  const isLoading = useSelector((state) => state.loading.isLoading);

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: noDataText,
      },
    },
    downloadOptions: { filename: 'trips.csv', separator: ',' },
    selectableRows: 'single',
    selectToolbarPlacement: 'none',
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 15,
    responsive: 'standard',
    fixedHeader: true,
    tableBodyHeight: '70vh',
    onRowClick: (row, idx) => onRowClick(row, idx),
    rowsSelected: rowsSelected,
  };

  const displayData = data === null ? [] : data;

  return (
    <Root className={classes.root}>
      {isLoading && <div className={classes.progress}>
        <LinearProgress />
      </div>
      }
      <div className={classes.content}>
        <ReportTable
          name="Trip List"
          title="Trip List"
          data={displayData}
          options={tableOptions}
          onRowClick={onRowClick}
        />
      </div>
    </Root>
  );
};

TripTable.propTypes = {
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  rowsSelected: PropTypes.array,
  noDataText: PropTypes.string,
};

export default TripTable;
