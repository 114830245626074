// persistReducer.js
import { persistReducer as basePersistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default function persistReducer(key, reducer, options = {}) {
  return basePersistReducer(
    {
      key,
      storage,
      ...options,
    },
    reducer,
  );
}
