import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingStore } from 'ducks';
import { AnalyticsAPI } from 'api';
import { makeDateList, makeMonthFilter } from 'components/analytics/helpers.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Section,
  PageHeader,
  Scatterplot,
  MonthFilter,
  CheckboxFilter,
} from '../components';
import Toolkit from '../../../components/Toolkit';
import CategoryFilter from '../../../components/CategoryFilter';

// fields to filter data from selectors on
const numericFilters = ['average_speed_kmh', 'average_trip_distance_km', 'average_trip_duration_mins', 'idling_time_percentage', 'total_distance_km', 'total_time_hours', 'trip_count'];
const colorFilters = ['category', 'fuel_type', 'make'];

const ActivityScatterplot = ({ dateRange }) => {
  const { min: minDate, max: maxDate } = dateRange;
  const dateList = makeDateList(minDate, maxDate);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [xAxisKey, setXAxisKey] = useState('total_time_hours');
  const [yAxisKey, setYAxisKey] = useState('total_distance_km');
  const [colorKey, setColorKey] = useState('category');
  const [date, setDate] = useState(dateList[0]);
  const [showLabels, setShowLabels] = useState(false);
  const clientId = useSelector((state) => state.clients.selectedItemId);

  const onShowLabelsChange = () => {
    setShowLabels(!showLabels);
  };

  const onDateChanged = (value) => {
    setDate(value);
  };

  const onXAxisChange = (value) => {
    setXAxisKey(value);
  };

  const onYAxisChange = (value) => {
    setYAxisKey(value);
  };

  const onColorFilterChange = (value) => {
    setColorKey(value);
  };

  React.useEffect(() => {
    const fetchData = async (date) => {
      dispatch(LoadingStore.loading(true));
      try {
        const queryParams = makeMonthFilter(date);
        const response = await AnalyticsAPI.getMonthlyVehicleMetrics(clientId, queryParams);
        setData(response.payload);
      } catch (err) {
        console.error(err);
        setData(null);
      } finally {
        dispatch(LoadingStore.loading(false));
      }
    };
    fetchData(date);
  }, [date, clientId, dispatch]);

  return (
    <Container>
      <PageHeader
        title="Activity Scatterplot"
        subtitle="Plot two attributes from a range of options to visualise relationships"
      />
      <Toolkit>
        <MonthFilter
          filter={{ date: date }}
          onFilterChanged={onDateChanged}
          dateList={dateList}
          rightMargin
        />
        <CategoryFilter
          label="X axis:"
          value={xAxisKey}
          valuesList={numericFilters}
          onFilterChanged={onXAxisChange}
          rightMargin
        />
        <CategoryFilter
          label="Y axis:"
          value={yAxisKey}
          valuesList={numericFilters}
          onFilterChanged={onYAxisChange}
          rightMargin
        />
        <CategoryFilter
          label="Color:"
          value={colorKey}
          valuesList={colorFilters}
          onFilterChanged={onColorFilterChange}
          rightMargin
        />
        <CheckboxFilter
          label="Show labels (Rego):"
          checked={showLabels}
          onFilterChanged={onShowLabelsChange}
        />
      </Toolkit>
      <Section fullHeight>
        <Scatterplot
          data={data}
          xAxisKey={xAxisKey}
          yAxisKey={yAxisKey}
          colorKey={colorKey}
          showLabels={showLabels}
        />
      </Section>
    </Container>
  );
};

ActivityScatterplot.propTypes = {
  dateRange: PropTypes.object.isRequired,
};

export default ActivityScatterplot;
