import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import language from '../../../config/language';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import DateFilter from 'components/DateFilter';
import { transformFleetSummary, transformRatePeriodSummary, transformNumberValues } from './TransformData';
import { themeConfig } from 'ThemeProvider';

const PREFIX = 'ReportTableView';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    paddingBottom: '2rem',
  },
});

const getMuiTheme = () => createTheme({
  components: {
    MUIDataTableToolbar: {
      styleOverrides:{
        root: {
          minHeight: '20px',
          padding: '0.5rem 1rem',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        },
        titleText: {
          fontSize: '1rem',
          fontWeight: 600,
        },
        actions: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
        icon: {
          '&:hover': {
            color: themeConfig.palette.primary.main,
          },
        },
      }
    },
    MUIDataTable: {
      styleOverrides:{
        tableRoot: {
          borderBottom: '1px solid #ccc',
        },
        paper: {
          borderRadius: 0,
          boxShadow: 'none',
          backgroundColor: '#fff',
        },
        responsiveBase: {
          padding: '0 1rem',
        },
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides:{
        root: {
          padding: 0,
          verticalAlign: 'bottom',
        },
        toolButton: {
          width: '100%',
          justifyContent: 'flex-end',
          borderBottom: '3px solid #ccc',
          borderRadius: 0,
          padding: '5px 1rem',
          '&:hover': {
            backgroundColor: '#fff',
            borderBottom: `3px solid ${themeConfig.palette.primary.main}`,
          },
        },
        data: {
          width: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          fontSize: '0.8rem',
          fontWeight: 600,
          padding: '0 5px',
        },
      }
    },
    MuiTableRow: {
      styleOverrides:{
        root: {
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#E5E7EB',
          },
        },
        footer: {
          backgroundColor: '#fff',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides:{
        root: {
          fontSize: '0.7rem',
          padding: '3px 1rem',
          textAlign: 'right',
        }
      }
    },
  },
});

// list specific report data transform functions here
const reportTransforms = {
  'ftc_fleet': transformFleetSummary,
  'ftc_rate_period': transformRatePeriodSummary,
};

const ReportTableView = ({ name, title, reportData, filter, onFilterChanged, dateList, exportFileName }) => {

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  // apply any transformations required for specific reports
  const transformData = () => {
    if (reportData && reportData.length > 0) {
      if (Object.keys(reportTransforms).includes(name)) {
        setData(reportTransforms[name](reportData));
      } else {
        setData(transformNumberValues(reportData));
      }
    }
  };

  const transformColumns = () => {
    if (data.length > 0) {
      setColumns(Object.keys(data[0]).map((k) => ({
        name: k,
        label: language['en'][`report_${k}`],
        options: {
          filter: true,
          setCellProps: () => ({ style: { whiteSpace: 'nowrap' }}),
          setCellHeaderProps: () => ({ style: { whiteSpace: 'nowrap' } }),
        },
      })));
    }
  };

  // NOTE: customToolbar uses a named function to avoid Component missing display name lint error
  const options = {
    downloadOptions: {filename: exportFileName, separator: ','},
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 100,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelect: true,
    tableBodyHeight: '75vh',
    customToolbar: function renderToolbar() {
      return <DateFilter filter={filter} onFilterChanged={onFilterChanged} dateList={dateList} />;
    },
  };

  useEffect(() => {
    transformData();
  }, [reportData]); // eslint-disable-line

  useEffect(() => {
    transformColumns();
  }, [data]); // eslint-disable-line

  return (
    <Root className={classes.root}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Root>
  );
};

ReportTableView.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reportData: PropTypes.array,
  filter: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  dateList: PropTypes.array,
  exportFileName: PropTypes.string.isRequired,
};

export default ReportTableView;
