import Areas from './containers/Areas';
import ViewArea from './containers/ViewArea';
import withRole from '../../hocs/withRole';
import AreasNew from './containers/AreasNew';
import NewAreaReview from './containers/NewAreaReview';
import NewAreaMap from './containers/NewAreaMap';

export default [{
  path: '/areas',
  exact: true,
  component: withRole(['Super Admin','Areas Admin', 'Client Admin', 'Client View'], Areas),

},
{
  path: '/areas/area/:id',
  exact: true,
  component: withRole(['Super Admin','Areas Admin', 'Client Admin', 'Client View'], ViewArea),
},
{
  path: '/areas/new_area',
  exact: true,
  component: withRole(['Super Admin','Areas Admin', 'Client Admin'], AreasNew),

},
{
  path: '/areas/new_area/map',
  exact: true,
  component: withRole(['Super Admin','Areas Admin', 'Client Admin'], NewAreaMap),
},
{
  path: '/areas/new_area/review',
  exact: true,
  component: withRole(['Areas Admin', 'Client Admin'], NewAreaReview),
}];
