import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import config from 'config/config';

export const themeConfig = {
  palette: {
    primary: {
      light: '#60A5FA',
      main: config.isBarton ? '#053573' : '#4F46E5',
      dark: '#1E40AF',
    },
    secondary: {
      light: '#34D399',
      main: '#059669',
      dark: '#065F46',
    },
    common: {
      black: '#000',
      white: '#fff',
      success: '#059669',
      error: '#DC2626', // something has gone wrong
      danger: '#F59E0B', // be careful
    },
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
    },
    indigo: {
      50: '#EEF2FF',
      100: '#E0E7FF',
      200: '#C7D2FE',
      300: '#A5B4FC',
      400: '#818CF8',
      500: '#6366F1',
      600: '#4F46E5',
      700: '#4338CA',
      800: '#3730A3',
      900: '#312E81',
    },
  },
  typography: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: 14,
    useNextVariants: true,
  },
};

const Theme = createTheme(themeConfig);

export default class AppThemeProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Theme}>{this.props.children}</ThemeProvider>
      </StyledEngineProvider>
    );
  }
}
