import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const PREFIX = 'LeftNav';

const classes = {
  menu: `${PREFIX}-menu`,
  menuClosed: `${PREFIX}-menuClosed`,
  menuItem: `${PREFIX}-menuItem`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
};

const drawerOpenWidth = 250;
const drawerClosedWidth = 50;
const headerHeight = 52;
const footerHeight = 40;
const netReduction = headerHeight + footerHeight;

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  paddingTop: '1px',

  [`& .${classes.menu}`]: {
    padding: 0,
    marginLeft: 0,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuClosed}`]: {
    marginLeft: drawerClosedWidth - drawerOpenWidth,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.menuItem}`]: {
    // because we have multiline entries we need some help wrapping and spacing.
    whiteSpace: 'normal', // allow multiline wrapping
    borderBottom: 'solid 1px #cccccc',
    paddingTop: '12px', // default is 12px
    paddingBottom: '12px',
    fontSize: '0.75rem',
  },

  [`& .${classes.drawerPaper}`]: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 'auto',
  },

  [`& .${classes.drawerPaperClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerClosedWidth,
    paddingRight: '50px',
  },
}));

const Toolbar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#333333',
});

const Content = styled('div')({
  width: '100px', // some width is required to flex-grow
  flex: '1 0 auto',
  position: 'relative',
  height: `calc(100vh - ${netReduction}px)`, // could be done better
  borderRadius: 0,
  backgroundColor: '#F3F4F6',
});

const Title = styled('span')({
  marginLeft: '1rem',
  fontSize: '1rem',
});

class LeftNav extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
    };
  }

  openDrawer = () => {
    this.setState({ drawerOpen: true });
  };

  closeDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  selectItem = (selectedItem) => {
    this.setState({ selectedItem });
    this.props.history.push(selectedItem.path);
  };

  renderDrawer = (selectedItem) => {
    const { pages, title } = this.props;
    const { drawerOpen } = this.state;
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        {drawerOpen ? (
          <Toolbar>
            <Title>{title}</Title>
            <IconButton onClick={this.closeDrawer} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
        ) : (
          <Toolbar>
            <IconButton onClick={this.openDrawer} size="large">
              <ChevronRightIcon />
            </IconButton>
          </Toolbar>
        )}
        <Divider />
        <MenuList
          className={classNames(
            classes.menu,
            !drawerOpen && classes.menuClosed
          )}
        >
          {pages.map((i, j) =>
            i === '-' ? (
              <Divider key={j} />
            ) : (
              <MenuItem
                className={classes.menuItem}
                key={j}
                selected={i === selectedItem}
                onClick={() => this.selectItem(i)}
              >
                {i.label}
              </MenuItem>
            )
          )}
        </MenuList>
      </Drawer>
    );
  };

  renderContent = (selectedItem) => {
    const { pages, title, ...childProps } = this.props; // eslint-disable-line no-unused-vars
    if (!selectedItem) return null;
    const ItemContent = selectedItem.content;
    return (
      <Content className={classes.content}>
        <ItemContent {...childProps} />
      </Content>
    );
  };

  render() {
    const { pages, location } = this.props;
    const selectedItem =
      pages.find((i) => i.path === location.pathname) || pages[0];

    return (
      <StyledContainer>
        {this.renderDrawer(selectedItem)}
        {this.renderContent(selectedItem)}
      </StyledContainer>
    );
  }
}

export default LeftNav;
