import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Root = styled('div')({
  backgroundImage: 'url("/img/login-background.png")',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  color: '#000',
  paddingTop: '1rem',
});

class PublicPage extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return <Root>{this.props.children}</Root>;
  }
}

export default PublicPage;
