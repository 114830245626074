import { makePath, request } from './http';
const API_NAME = 'dbworkers';

export function startGISBuilder() {
  return request('POST', makePath(API_NAME, 'gisbuilder'));
}

export function getGISBuilderHistory() {
  return request('GET', makePath(API_NAME, 'gisbuilder/history'));
}

export function updateGISFiles() {
  return request('POST', makePath(API_NAME, 'gisbuilder/update'));
}
