import React from 'react';
import { PublicPage, AuthFormGroup, AuthForm, AuthHeader } from '../components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EmailValidator from 'email-validator';
import { UserAPI } from '../../../api';

class ForgotPassword extends React.Component {
  state = {
    loading: false,
    valid: false,
    alertText: '',
    email: '',
    showConfirmation: false,
  }
  static propTypes = {
  };

  onFormFieldChange = (field, value) => {
    if (field === 'username') {
      this.setState({email: value, valid: EmailValidator.validate(value)});
    }
  }

  onTriggerReset = () => {
    //const email = this.state.email;
    this.setState({
      loading: true,
      showConfirmation: true,
    });
    UserAPI.triggerPasswordReset(this.state.email);
  }

  onCloseConfirmation = () => {
    this.setState({loading: false, showConfirmation: false});
  }

  renderConfirmation = () => (
    <Dialog
      open={this.state.showConfirmation}
      onClose={this.onCloseConfirmation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Password Reset Sent'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          An email with password reset instructions is being delivered to <b>{this.state.email}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCloseConfirmation} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  render() {
    const { alertText, valid, email } = this.state;
    const title = <AuthHeader title="password_reset_title"/>;
    return (
      <PublicPage>
        <AuthForm
          loading={this.state.loading}
          title={title}
          buttonText="password_reset_button"
          buttonAction={this.onTriggerReset}
          buttonDisabled={!valid}
          alertText={alertText}
          instructions={this.instructions}
        >

          <AuthFormGroup
            label="Email"
            autoComplete="username"
            type="email"
            name="username"
            value={email}
            onChange={this.onFormFieldChange}
            helperText="Enter your email address to receive a password reset link by email."
            onEnter={valid ? this.onTriggerReset : undefined}
          />
        </AuthForm>
        {this.renderConfirmation()}
      </PublicPage>
    );
  }
}


export default ForgotPassword;
