import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';

const PREFIX = 'ContentItem';

const classes = {
  root: `${PREFIX}-root`,
  progress: `${PREFIX}-progress`,
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    width: '100%',
    marginBottom: '0.5rem',
    position:'relative'
  },
  [`& .${classes.progress}`]: {
    height: '0.2rem',
  },
  [`& .${classes.content}`]: {
    border: '1px solid #ccc',
    backgroundColor: '#fff',
  },
  [`& .${classes.title}`]: {
    backgroundColor: '#fff',
    width: '100%',
    height: '41px',
    margin: 0,
    padding: '0.5rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    borderBottom: '1px solid #ccc',
  },
});

const ContentItem = ({ title, children }) => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  return (
    <Root className={classes.root}>
      {
        isLoading &&
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      }
      <div className={classes.content}>
        <h1 className={classes.title}>{title}</h1>
        {children}
      </div>
    </Root>
  );
};

ContentItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
};

export default ContentItem;
