import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


const Confirmation = ({show, title, text, onAction, onCancel}) => {
  if (!show) return null;
  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel} sx={{fontWeight: 900}} autoFocus>
          Cancel
        </Button>
        <Button variant="contained" onClick={onAction} sx={{fontWeight: 900}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirmation.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default (Confirmation);
