import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import UploadForm from '../../../components/UploadForm';
import UploadReviewForm from './UploadReviewForm';
import moment from 'moment';

const PREFIX = 'UploadDrawer';

const classes = {
  longText: `${PREFIX}-longText`
};

const StyledDrawer = styled(Drawer)((
  {
    theme
  }
) => ({
  [`& .${classes.longText}`]: {
    color: theme.palette.common.black,
    width: 600,
  }
}));

class UploadDrawer extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    objects: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    fuelMonthStartDate: PropTypes.string.isRequired,
  };

  state = {
    newObjects: undefined,
    fileName: undefined,
  };

  onUpload = (fileName, newObjects) => {
    this.setState({fileName, newObjects});
  };

  onCancel = () => {
    this.setState({newObjects: undefined});
    this.props.onClose();
  };

  render() {
    const {  isOpen, refresh, objects, title, fuelMonthStartDate } = this.props;
    const { fileName, newObjects } = this.state;

    const formTitle = title + ': ' + moment(fuelMonthStartDate).format('MMM YYYY');
    return (
      <StyledDrawer anchor="right" open={isOpen} onClose={this.onCancel}>
        {this.state.newObjects
          ? <UploadReviewForm
            onClose={this.onCancel}
            onBack={() => this.setState({objects: undefined})}
            refresh={refresh}
            fileName={fileName}
            newObjects={newObjects}
            objects={objects}
            title={formTitle}
            fuelMonthStartDate={fuelMonthStartDate}
          />
          : <UploadForm
            onClose={this.onCancel}
            onUpload={this.onUpload}
            title={formTitle}
            warning="WARNING: Uploading Fuel data will delete and replace all current fuel data!">
            <p className={classes.longText}>Your file <b>must</b> include both fields called <b><code>fuel_amount_l</code></b> (Fuel in litres) and <b><code>client_vehicle_id</code></b> (the ID of the vehicle in your IVMS system). All other fields are optional and may be blank or excluded.  Any unrecognised columns will be ignored.</p>
            <p className={classes.longText}>We recommend downloading the spreadsheet for each month, editing it to add fuel details, and uploading it again.</p>
          </UploadForm>
        }
      </StyledDrawer>
    );
  }
}

export default (UploadDrawer);