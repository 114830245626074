import React from 'react';
const Signup = React.lazy(() => import('./containers/Signup'));
/*
 * We use code splitting here mostly to test the pattern - it isn't
 * hugely valuable because the depdendent code for this module is still small.
 */

export default [
  {
    path: '/signup',
    exact: false,
    component: Signup,
    module: 'signup'
  },
];
