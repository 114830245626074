import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const PREFIX = 'NewAuditReportDialog';

const classes = {
  button: `${PREFIX}-button`,
  formRow: `${PREFIX}-formRow`,
  radioLabel: `${PREFIX}-radioLabel`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.button}`]: {
    fontWeight: 900,
  },

  [`& .${classes.formRow}`]: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  [`& .${classes.radioLabel}`]: {
    fontSize: '0.75rem',
  }
}));

class NewAuditReportDialog extends React.Component {

  static propTypes = {
    show: PropTypes.bool,
    onAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    reportingMonths: PropTypes.array,
  }

  state = {
    dates: [],
  }

  componentDidMount() {
    if (this.props.reportingMonths) {
      this.setState({dates: [this.props.reportingMonths[0].value]});
    }
  }

  onAction = () => {
    const { dates } = this.state;
    for (let i=0; i<dates.length; i++) {
      const selected = this.props.reportingMonths.find(d => d.value === dates[i]);
      if (selected) {
        const { month, year } = selected;
        this.props.onAction(year, month);
      }
    }
  }

  onChange = (date) => {
    const { dates } = this.state;
    const selected = dates.find(d => d === date);
    if (selected) {
      this.setState({ dates: dates.filter(d => d !== date) });
    } else {
      this.setState({ dates: dates.concat([date])});
    }
  }

  render() {
    const { show, onCancel, reportingMonths, } = this.props;
    const { dates } = this.state;
    if (!show) return null;
    return (
      <StyledDialog
        open={show}
        onClose={this.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Audit Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select the report months for the audit report.  The time period list below includes all months for which data is available.
          </DialogContentText>

          <div className={classes.formRow}>
            <FormControl className={classes.formControl}>
              <FormLabel>Time Period</FormLabel>
              <FormGroup>
                {reportingMonths.map(date => (
                  <FormControlLabel
                    key={date.value}
                    control={<Checkbox checked={dates.includes(date.value)} onChange={() => this.onChange(date.value)} value={date.value} />}
                    label={date.label}
                  />
                ))}
              </FormGroup>
            </FormControl>

          </div>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onCancel} className={classes.button} autoFocus>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.onAction} className={classes.button}>
            Continue
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }

}

export default (NewAuditReportDialog);
