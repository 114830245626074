import { makePath, request } from './http';
const API_NAME = 'reports';

export function getReports(clientId) {
  return request('GET', makePath(API_NAME), {clientId});
}

export function getReport(clientId, reportName, params) {
  return request('GET', makePath(API_NAME, reportName), {clientId, params});
}

export function getFTCReports(clientId) {
  return request('GET', makePath(API_NAME, 'ftc'), {clientId});
}

export function getFTCReport(clientId, reportName, params) {
  return request('GET', makePath(API_NAME, 'ftc', reportName), {clientId, params});
}

export function getNewReports(clientId) {
  return request('GET', makePath(API_NAME, 'reports'), {clientId});
}

export function getReportFilters(clientId, reportId) {
  return request('GET', makePath(API_NAME, 'reports', reportId), {clientId});
}

export function getReportResults(clientId, reportId, params) {
  return request('POST', makePath(API_NAME, 'reports', reportId, 'results'), {clientId, body: params});
}
