import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProvider from './ThemeProvider';
import App from './App';
import language from './config/language';
import localeHelper from './helpers/locale';
import './config/locale';
import './index.css';
import './modules';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';
import {StyledEngineProvider} from '@mui/material/styles';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import config from './config/config';

moment.locale('en-AU');

Sentry.init({
  dsn: config.settings.SENTRY_DNS_KEY,
  integrations: [new BrowserTracing({
    tracingOrigins: [config.services.HOST],
  }),new Sentry.Replay()],
  environment:config.settings.STAGE,
  // This sets the sample rate to be 5%.
  // Below sample rate for replays that begin recording immediately and last the entirety of the user's session.
  // Commenting it out for now but can be uncommented if required in the future.
  // replaysSessionSampleRate: 0.05,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: config.settings.SENTRY_TRACES_SAMPLE_RATE,
  sampleRate: config.settings.SENTRY_TRACES_SAMPLE_RATE,
});
const locale = localeHelper();

window.show_http_responses = false;
window.show_api_hits = false;
window.show_cached_hits = false;
window.show_store_updates = false;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <IntlProvider locale={locale} messages={language[locale]}>
    <Router basename="/">
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <CssBaseline/>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  </IntlProvider>
);
