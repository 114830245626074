import FTCReports from './containers/FTCReports';
import withRole from '../../hocs/withRole';

export default [
  {
    key: 1,
    path: '/ftcreports',
    exact: false,
    component: withRole(['Vehicle Admin', 'Client Admin', 'Client View'], FTCReports),
    module: 'reports'
  }
];
