import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import UploadForm from '../../../components/UploadForm';
import UploadReviewForm from './UploadReviewForm';

const PREFIX = 'UploadDrawer';

const classes = {
  longText: `${PREFIX}-longText`
};

const StyledDrawer = styled(Drawer)((
  {
    theme
  }
) => ({
  [`& .${classes.longText}`]: {
    color: theme.palette.common.black,
    width: 600,
  }
}));

class UploadDrawer extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    vehicles: PropTypes.array.isRequired,
  };

  state = {
    newVehicles: undefined,
    fileName: undefined,
  };

  toggleDrawer = (open) => () => {
    this.setState({
      isOpen: open,
    });
  };

  onUpload = (fileName, newVehicles) => {
    this.setState({fileName, newVehicles});
  };

  onCancel = () => {
    this.setState({newVehicles: undefined});
    this.props.onClose();
  };

  render() {
    const {  isOpen, refresh, vehicles } = this.props;
    const { fileName, newVehicles } = this.state;
    return (
      <StyledDrawer anchor="right" open={isOpen} onClose={this.onCancel}>
        {this.state.newVehicles
          ? <UploadReviewForm
            onClose={this.onCancel}
            onBack={() => this.setState({vehicles: undefined})}
            refresh={refresh}
            fileName={fileName}
            newVehicles={newVehicles}
            vehicles={vehicles}/>
          : <UploadForm
            onClose={this.onCancel}
            onUpload={this.onUpload}
            title="Bulk Vehicle Upload">
            <p className={classes.longText}>Your file <b>must</b> include a field called <b><code>client_vehicle_id</code></b> which contains the ID of the vehicle in your IVMS system.  All other fields are optional and may be blank or excluded.  Any unrecognised columns will be ignored.</p>
            <p className={classes.longText}>It may be useful to download the current vehicle register CSV to get an example of the correct file format.</p>
          </UploadForm>
        }
      </StyledDrawer>
    );
  }
}

export default (UploadDrawer);