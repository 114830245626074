import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FuelByVehicleForm from './FuelByVehicleForm';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/CloudUpload';
import UploadDrawer from '../components/UploadDrawer';
import Confirmation from '../../../components/Confirmation';
import Form from '../../../components/Form';
import moment from 'moment';
import {FuelAPI} from '../../../api';
import { CSVLink } from 'react-csv';
import ActionButton from '../../../components/ActionButton';

const StyledFormContainer = styled(Form)(( ) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const DivToolbar = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: '0 0 48px',
  alignItems: 'center',
  marginBottom: 10,
}));

const DivFuelTotal = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
}));

const DivButtonGroup = styled('div')(( ) => ({
  width: 550,
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

const ActionButtonButton = styled(ActionButton)(( ) => ({
  alignSelf: 'center',
  marginTop: 5,
  marginBottom: 5,
}));

class FuelInput extends React.PureComponent {

  static propTypes = {
    clientId: PropTypes.string.isRequired,
    fuelMonthStartDate: PropTypes.string.isRequired,
    fuelSummary: PropTypes.object.isRequired,
    fuelData: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
  };

  state = {
    showConfirmation: false,
    totalFuel: null,
    vehicles: [], // initialized from fuelData.vehicles
    showUpload: false,
  };

  componentDidMount() {
    this.updateVehicles();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateVehicles();
    }
  }

  render() {
    const {  clientId, fuelMonthStartDate } = this.props;
    const { totalFuel, vehicles, showConfirmation, showUpload } = this.state;

    if (totalFuel === undefined || vehicles === undefined) {
      // TODO: show a Loading animation rather than <div>&nbsp;</div>
      return (
        <div style={{height: '100%'}}>&nbsp;</div>
      );
    }

    const date = new Date();
    const timestamp = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0') +
      date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0') + date.getSeconds().toString().padStart(2, '0');
    const csvFilename = clientId + '_' + moment(fuelMonthStartDate).format('YYYY-MM') + '_v' + timestamp + '.csv';

    console.log(this.state);

    return (
      <StyledFormContainer>
        <DivToolbar>
          <DivFuelTotal>
            {moment(fuelMonthStartDate).format('MMM YYYY')}
      :&nbsp;
            {totalFuel}
          </DivFuelTotal>

          <DivButtonGroup>
            <ActionButtonButton
              variant="contained"
              color="primary"
              tooltip="Save Fuel changes"
              onClick={this.onSave}
              label="Save"
              Icon={SaveIcon}
            />

            <ActionButtonButton
              variant="contained"
              color="primary"
              tooltip="Delete all Fuel"
              onClick={() => this.setState({ showConfirmation: true })}
              label="Delete All"
              Icon={DeleteIcon}
            />

            <CSVLink data={this.csvFormat(vehicles)} filename={csvFilename}>
              <ActionButtonButton
                variant="contained"
                tooltip="Download Fuel"
                color="primary"
                Icon={DownloadIcon}
                label="Download"
              />
            </CSVLink>

            <ActionButtonButton
              variant="contained"
              onClick={this.openUploadDrawer}
              tooltip="Upload Fuel"
              color="primary"
              Icon={UploadIcon}
              label="Upload"
            />
          </DivButtonGroup>
        </DivToolbar>

        <FuelByVehicleForm
          ref={this.formRef}
          show={true}
          onFuelChanged={this.onFuelChanged}
          vehicles={vehicles}
        />

        <UploadDrawer
          isOpen={showUpload}
          objects={vehicles}
          title="Bulk Fuel Upload"
          fuelMonthStartDate={fuelMonthStartDate}
          onClose={() => this.setState({ showUpload: false })}
          refresh={() => this.props.reload()}
        />

        <Confirmation
          show={showConfirmation}
          color="secondary"
          title="Delete this fuel data?"
          text="Are you sure you want to delete all fuel input for this month?"
          onCancel={() => this.setState({ showConfirmation: false })}
          onAction={() => this.onDelete()}
        />
      </StyledFormContainer>
    );
  }

  onFuelChanged = (vehicles) => {
    const total = totalFuel(vehicles);
    this.setState({vehicles, totalFuel: `Total ${total.toLocaleString()} litres`});
  };

  updateVehicles = async () => {
    const { fuelData } = this.props;
    await this.onFuelChanged(fuelData.vehicles.map(v => ({...v})));
  };

  onDelete = async () => {
    const { clientId, fuelMonthStartDate } = this.props;
    await FuelAPI.remove(clientId, fuelMonthStartDate).then(() => {
      this.setState({showConfirmation: false});
      this.props.reload();
    });
  };

  onSave = async () => {
    const { clientId, fuelMonthStartDate } = this.props;
    const { vehicles } = this.state;
    await FuelAPI.create(clientId, {
      month_start_date: fuelMonthStartDate,
      vehicles: vehicles.filter(v => v.fuel_amount_l > 0),
    }).then(() => {
      this.props.reload();
    });
  };

  openUploadDrawer = () => {
    this.setState({ showUpload: true});
  };

  closeUploadDrawer = () => {
    this.setState({ showUpload: false});
  };

  csvFormat = (vehicles) => {
    return vehicles.map(v => ({
      'client_vehicle_id': 'id::' + v.client_vehicle_id,
      'make': v.make,
      'model': v.model,
      'registration': v.registration,
      'description': v.description,
      'activity': (v.duration_mins === null && v.distance_km === null) ? 'No activity recorded' : `${Math.round(v.distance_km)} km / ${Math.round(v.duration_mins)} mins`,
      'fuel_amount_l': v.fuel_amount_l
    }));
  }
}

const parseFuel = (str) => {
  const v = parseFloat(str);
  return isNaN(v) ? 0 : v;
};

const totalFuel = (vehicles) => {
  const total = vehicles.reduce((sum, v) => sum + parseFuel(v.fuel_amount_l), 0);
  return Math.round(total * 100) / 100;
};

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isSuperUser: state.auth.isSuperUser,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)((FuelInput));
