import Confirmation from 'components/Confirmation';
import { styled } from '@mui/material/styles';
import ErrorDialog from 'components/ErrorDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { LoadingStore } from 'ducks';
import FileDownload from '../../ftcreports/components/FileDownload';
import CreateToolbar from '../../ftcreports/components/CreateToolbar';
import NewAuditReportDialog from '../../ftcreports/components/NewAuditReportDialog';
import Poller from '../../ftcreports/components/Poller';
import ReportFileCard from '../../ftcreports/components/ReportFileCard';
import NoData from 'components/NoData';
import PropTypes from 'prop-types';
import React from 'react';
import { ReportFilesAPI } from 'api';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import moment from 'moment';
import { months } from 'helpers/date';
const PREFIX = 'AuditReports';

const classes = {
  container: `${PREFIX}-container`,
  panelRoot: `${PREFIX}-panelRoot`,
  panelHeading: `${PREFIX}-panelHeading`,
  expansionPanel: `${PREFIX}-expansionPanel`,
  panelSummary: `${PREFIX}-panelSummary`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    overflowY: 'scroll',
    height: '100%',
    backgroundColor: '#ffffff',
  },

  [`&.${classes.panelRoot}`]: {
    width: '100%',
  },

  [`& .${classes.panelHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  [`& .${classes.expansionPanel}`]: {
    flexWrap: 'wrap',
    display:'flex'
  },

  [`& .${classes.panelSummary}`]: {
    backgroundColor: '#eeeeee',
  }
}));

// These are the report descriptions used to filter by feature flag
// Cluster and Trip Reports are comprised of multiple documents,
// Points File and Anlaysis tabels are one file each but we use the same pattern
// for simplicity
const auditClusterDocDescriptions = [
  'Audit Cluster Maps',
  'Top Non-Public Road Idling Clusters',
  'Top Non-Public Road Driving Clusters'
];
const auditTripDocDescriptions = [
  'Audit Trip Maps',
  'Top Public Road Idling Trips',
  'Top Public Road Driving Trips',
  'Top Non-Public Road Idling Trips',
  'Top Non-Public Road Driving Trips'
];
const auditPointsFileDescription = [
  'Audit Trip File'
];
const auditTablesFileDescription = [
  'Audit Analysis Tables'
];

const fileFormats = [
  { format: 'pdf', label: 'PDF' },
  { format: 'excel', label: 'Excel' },
];

const previousMonth = (year, month) => {
  month -= 1;
  if (!month) {
    year -= 1;
    month = 12;
  }
  return [year, month];
};

class DownloadReport extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    clientId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dateRange: PropTypes.object,
    profile: PropTypes.object.isRequired,
  }

  state = {
    reports: undefined,
    downloadUrl: undefined,
    showNewAuditReportDialog: false,
    showAuditReportConfirmationDialog: false,
    error: undefined,
  }

  componentDidMount() {
    this.poller = new Poller(this.loadReports);
    const { dispatch } = this.props;
    dispatch(LoadingStore.loading(true));
    this.loadReports();
    this.poller.start();
  }

  componentWillUnmount() {
    this.poller.stop();
  }

  getAllReportingMonths = (dateRange) => {
    let reportDate = moment().endOf('month');
    if (dateRange.min === null || dateRange.max === null) return [];
    const lastAvailableReportDate = reportDate.subtract({months: 1});
    const lastYear = lastAvailableReportDate.year();
    const lastMonth = lastAvailableReportDate.month() + 1;
    const [ year0, month0 ] = dateRange.min.split('-').map(v => Number(v));
    const [ year1, month1 ] = dateRange.max.split('-').map(v => Number(v));
    let year = year1;
    let month = month1;
    // don't offer to create reports for the incomplete reporting period.
    if (year > lastYear || (year === lastYear && month > lastMonth)) {
      year = lastYear;
      month = lastMonth;
    }
    let dates = [];

    while (year > year0 || (year === year0 && month >= month0)) {
      dates.push({
        month: month,
        year: year,
        value: `${year}-${month}`,
        label: `${months[month-1]} ${year}`
      });
      [ year, month ] = previousMonth(year, month);
    }
    return dates;
  }

  loadReports = async () => {
    const { dispatch, clientId } = this.props;
    try {
      const response = await ReportFilesAPI.getAuditFiles(clientId);
      dispatch(LoadingStore.loading(false));
      this.setState({reports: response.payload});
      return response.payload;
    } catch (err) {
      dispatch(LoadingStore.loading(false));
      this.setState({reports: []});
    }
  }

  openReport = async (report) => {
    const { clientId } = this.props;
    const params = {
      'content_disposition': `attachment; filename="${report.name}"`,
      'content_type': report.content_type,
    };
    const response = await ReportFilesAPI.getAuditFile(clientId, report.name, params);
    this.setState({downloadUrl: response.payload.location});
  }

  clearDownload = () => {
    this.setState({downloadUrl: undefined});
  }

  openDialog = () => {
    this.setState({showNewAuditReportDialog: true});
  }

  closeDialog = () => {
    this.setState({showNewAuditReportDialog: false});
  }

  openConfirmDialog = () => {
    this.setState({showAuditReportConfirmationDialog: true});
  }

  closeConfirmDialog = () => {
    this.setState({showAuditReportConfirmationDialog: false});
  }

  createNewReport = async (year, month) => {
    const { clientId } = this.props;
    this.closeDialog();
    this.openConfirmDialog();
    const report = { year, month };
    try {
      await ReportFilesAPI.generateAuditFiles(clientId, report);
    } catch (err) {
      this.setState({error: {title: 'Report Failed', text: 'Report generation failed.  The most common reason for failure is incomplete data.'}});
    }
  }

  createAPanel = (list, reports, year, month, expanded) => {
    const reportsInMonth = list.map(i => reports[i]);

    return (
      <Root className={classes.panelRoot} key={year + ' ' + month}>
        <Accordion defaultExpanded={expanded}>
          <AccordionSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.panelHeading}><b>{months[month-1]} {year}</b></Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanel}>
            {reportsInMonth.map((report, i) => (
              <ReportFileCard
                key={i}
                report={report}
                openReport={this.openReport}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Root>
    );
  }

  createReportPanels = (reports) => {
    if (!reports) return null;
    // objs structure: {year: {month: [index1, index2, ...], ... }, ...}
    const objs = reports.reduce((o, r, i) => {
      const yearValue = o[r.year] || {};
      const monthValue = yearValue[r.month] || [];
      yearValue[r.month] = monthValue.concat(i);
      o[r.year] = yearValue;
      return o;}, {});

    let ret = [];
    let expandFirst = true;

    // eslint-disable-next-line
    for (let year of Object.keys(objs).sort((a, b) => b - a)) {
      // eslint-disable-next-line
      for (let month of Object.keys(objs[year]).sort((a, b) => b - a)) {
        ret.push(this.createAPanel(objs[year][month], reports, year, month, expandFirst));
        expandFirst = false;
      }
    }
    return ret;
  }

  render() {
    const { isLoading, dateRange, client, } = this.props;
    const { reports=[], downloadUrl, showNewAuditReportDialog, showAuditReportConfirmationDialog, error } = this.state;

    // Filter out audit trip maps from reports list if not enabled in admin
    let filteredReports = reports;

    filteredReports = reports.filter((r) => {
      if (!client) return true; // If client is not available, keep all reports
      const { features } = client;
      const { description } = r;
      return (
        (features.includes('audit_cluster_reports') || !auditClusterDocDescriptions.includes(description)) &&
        (features.includes('audit_trip_reports') || !auditTripDocDescriptions.includes(description)) &&
        (features.includes('audit_points_files') || !auditPointsFileDescription.includes(description)) &&
        (features.includes('audit_tables_files') || !auditTablesFileDescription.includes(description))
      );
    });

    const reportingMonths = this.getAllReportingMonths(dateRange);
    const dataAvailable = reportingMonths.length > 0;
    if (isLoading) return null;

    return (
      <div className={classes.container}>
        {dataAvailable &&
          <CreateToolbar
            title="Audit Reports"
            message="Reports to aid in validating processed GPS data"
            toolTip="Create new report"
            onNew={this.openDialog}
            onRefresh={this.loadReports}
          />
        }
        {filteredReports.length ? this.createReportPanels(filteredReports) : <NoData/>}
        {dataAvailable &&
          <NewAuditReportDialog
            show={showNewAuditReportDialog}
            onAction={this.createNewReport}
            onCancel={this.closeDialog}
            reportingMonths={reportingMonths}
            formats={fileFormats}
          />
        }
        <Confirmation
          show={showAuditReportConfirmationDialog}
          onCancel={this.closeConfirmDialog}
          cancelLabel="OK"
          title="Audit Reports Queued"
          text="Audit reports have been queued for generation.  They will be available for download here when they are ready."
        />
        {downloadUrl &&
          <FileDownload
            url={downloadUrl}
            onDownloadComplete={this.clearDownload}
          />
        }
        {error &&
          <ErrorDialog
            show={true}
            title={error.title}
            text={error.text}
            onClose={() => this.setState({error: undefined})}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.clients.selectedItem,
    clientId: state.clients.selectedItemId,
    isLoading: state.loading.isLoading,
    isSuperUser: state.auth.isSuperUser,
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)((DownloadReport));
