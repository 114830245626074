import { request, makePath } from './http';
const API_NAME = 'jobs';

export function getJobs(params) {
  return request('GET', makePath(API_NAME, 'jobs'), {params});
}

export function getJobPhase(clientId, phaseId) {
  return request('GET', makePath(API_NAME, 'client', clientId, 'phase', phaseId));
}

export function putPhaseAction(clientId, phaseId, action) {
  return request('PUT', makePath(API_NAME, 'client', clientId, 'phase', phaseId, 'action', action));
}

export function deleteJob(clientId, jobId) {
  return request('DELETE', makePath(API_NAME, 'client', clientId, 'job', jobId));
}

export function deleteReQueueJob(clientId, jobId) {
  return request('DELETE', makePath(API_NAME, 'client', clientId, 'job', jobId, 'requeue'));
}

export function rerunClientJobs(clientId) {
  return request('POST', makePath(API_NAME, 'client', clientId, 'rerun'));
}
