import React ,{useEffect,useState}from 'react';
import { styled } from '@mui/material/styles';
import Toolbar from '../../../components/admin/Toolbar';
import {useDispatch,useSelector} from 'react-redux';
import {LoadingStore} from '../../../ducks';
import {AuditLogsAPI} from '../../../api';
import Toolkit from '../../../components/Toolkit';
import CategoryFilter from '../../../components/CategoryFilter';
import Button from '@mui/material/Button';
import {Typography} from '@mui/material';
import AuditTimeline from '../components/AuditTimeline';
import { CSVLink } from 'react-csv';
import moment from 'moment/moment';
import DateRangeControl from '../../../components/DateRangeControl';
import {dateToDateString} from '../../../helpers/date';

const PAGESIZE = 50;
const actionList = {'All Actions':'', 'Update':'Update', 'Create':'Create', 'Delete':'Delete', 'Send':'Send'};
const sectionList = {'All Sections':'','Areas':'Areas', 'Auth':'Auth', 'Clients':'Clients',
  'Fuel':'Fuel', 'Report Files':'Report Files', 'User':'User', 'Vehicles':'Vehicles'};

const date = new Date();
const timestamp = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, '0') +
 date.getDate().toString().padStart(2, '0') + date.getHours().toString().padStart(2, '0') +
 date.getMinutes().toString().padStart(2, '0') + date.getSeconds().toString().padStart(2, '0');


const PREFIX = 'ClientAdminManagement';


const classes = {
  container: `${PREFIX}-container`,
  toolkit: `${PREFIX}-toolkit`,
  csvButton : `${PREFIX}-csvButton`,
  csvLink: `${PREFIX}-link`,
  dateRangeContainer: `${PREFIX}-dateRangeContainer`,
  label: `${PREFIX}-label`
};

const csvFormat = auditLogs =>{
  return auditLogs.map(a => ({
    'id': 'id::' + a.id,
    'action': a.action,
    'section': a.stack,
    'client id': a.client_id,
    'created at': moment(a.created_at).format('DD-MM-YYYY HH:mm:ss'),
    'parameters': (a.parameters?.valueOf()!==undefined) ? JSON.stringify(a.parameters).replaceAll('"','""') : '',
    'description': a.description
  }));
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.toolkit}`]: {
    marginLeft: '1rem',
    marginRight: '1rem'
  },
  [`& .${classes.csvButton}`]: {
    marginLeft: '1rem',
  },
  [`& .${classes.csvLink}`]: {
    textDecoration: 'none',
  },
  [`& .${classes.dateRangeContainer}`]: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    padding: 0,
    marginRight:'1rem'
  },
  [`& .${classes.label}`]: {
    marginRight: '0.5rem',
    fontSize: '0.7rem',
    fontWeight: 500,
  },
}));

const ClientAuditManagement = () => {
  const dispatch = useDispatch();
  const [action,setAction] = useState('All Actions');
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [section,setSection] = useState('All Sections');
  const [offset, setOffset] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [auditLogs, setAuditLogs] = useState([]);

  const clientId = useSelector((state) => state.clients.selectedItemId);
  const csvFilename = clientId + '_' + + timestamp + '.csv';

  const getAuditLogs = async (params={}, showMore=false) => {
    if (selectedDateRange) {
      params = {...params, 'start_time':dateToDateString(selectedDateRange.startDate),
        'end_time':dateToDateString(selectedDateRange.endDate)};
    }
    dispatch(LoadingStore.loading(true    ));
    try {
      const response = await AuditLogsAPI.getAuditLogs(clientId,
        {...params, 'pagesize':PAGESIZE, 'offset':offset});
      if (showMore){
        setAuditLogs([...auditLogs,...response.payload[0]]);
      }
      else{
        setAuditLogs(response.payload[0]);
      }

      setTotalLogs(response.payload[2]);
    }
    catch (error) {
      console.log(error);
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  const onShowMoreClick = () =>{
    setOffset(offset+PAGESIZE);
  };

  const onActionChange = (actionChange) => {
    setAction(actionChange);
  };

  const onSectionChange = sectionChange => {
    setSection(sectionChange);
  };

  const onDateRangeChanged = (range) => {
    setSelectedDateRange(range);
  };

  useEffect(()=>{
    getAuditLogs({'action':actionList[action],'stack':sectionList[section]}, true);
  },[offset]);// eslint-disable-line

  useEffect(()=>{
    getAuditLogs({'action':actionList[action],'stack':sectionList[section]});
  },[action,section, selectedDateRange]);// eslint-disable-line

  return <Root className={classes.container}>
    <Toolbar
      itemName="Audit Log"
    />
    <div className={classes.toolkit}>
      <Toolkit>
        <div className={classes.dateRangeContainer}>
          <Typography className={classes.label}>Date Range</Typography>
          <DateRangeControl
            onDateRangeChanged={onDateRangeChanged}
            selectedDateRange={selectedDateRange}
          />
        </div>
        <CategoryFilter
          label="Action"
          value={action}
          valuesList={Object.keys(actionList)}
          onFilterChanged={onActionChange}
          rightMargin
        />
        <CategoryFilter
          label="Section"
          value={section}
          valuesList={Object.keys(sectionList)}
          onFilterChanged={onSectionChange}
          rightMargin
        />
        <CSVLink className={classes.csvLink} data={csvFormat(auditLogs)} filename={csvFilename}>
          <Button className={classes.csvButton} variant='contained' color='secondary'>
            Download as CSV
          </Button>
        </CSVLink>
      </Toolkit>
      {auditLogs.length ===0 && <Typography>No audit logs are available to display at the moment.
      If you believe this is an error please contact Customer Service</Typography>}
      {auditLogs.length >0 && <AuditTimeline auditLogs={auditLogs} showMore={onShowMoreClick} totalSize={totalLogs} />}
    </div>
  </Root>;
};

export default ClientAuditManagement;