import { makePath, request } from './http';
const API_NAME = 'report-files';

export function getReports(clientId) {
  return request('GET', makePath(API_NAME, 'ruc'), { clientId });
}

export function downloadReport(clientId, fileName, params) {
  return request('GET', makePath(API_NAME, 'ruc', fileName), {
    clientId,
    params,
  });
}

export function generateReport(clientId, { month, year, doc_format }) {
  const params = { month, year, doc_format };
  return request('POST', makePath(API_NAME, 'ruc', 'generate'), {
    clientId,
    params,
  });
}

export function sendReport(clientId, { year, month, name, doc_format }) {
  const params = { year, month, doc_format, name };
  return request('POST', makePath(API_NAME, 'ruc', 'send'), {
    clientId,
    params,
  });
}
