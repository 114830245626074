import React from 'react';
import PropTypes from 'prop-types';
import LeftNav from '../../../components/LeftNav';
import { connect } from 'react-redux';
import ClientSettings from '../content/ClientSettings';
import UserManagement from '../content/UserManagement';
import ClientManagement from '../content/ClientManagement';
import ClientAuditManagement from '../content/ClientAuditManagement';

const clientAdminMenuItems = [
  {
    label: 'Client Settings',
    path: '/clientadmin/settings',
    content: ClientSettings,
  },
  {
    label: 'User Management',
    path: '/clientadmin/users',
    content: UserManagement,
  },
  {
    label: 'Client Management',
    path: '/clientadmin/clients',
    content: ClientManagement,
  },
  {
    label: 'Audit Log',
    path:'/clientadmin/audit_log',
    content: ClientAuditManagement,
  }
];

const ClientAdmin =  (props)=>{
  const pages = clientAdminMenuItems;
  return (
    <div style={{backgroundColor:'white', overflow:'auto'}}>
      <LeftNav title='Client Settings' pages={pages} {...props}/>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isSuperUser: state.auth.isSuperUser, // sysadmin mode on
  };
};

ClientAdmin.propTypes ={
  props:PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSuperUser: PropTypes.bool,
};

export default connect(mapStateToProps)(ClientAdmin);
