import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import SettingsPanel from '../components/SettingsPanel';
const PREFIX = 'FeaturesSettingsPanel';

const classes = {
  container: `${PREFIX}-container`,
  switchRow: `${PREFIX}-switchRow`,
};

const StyledSettingsPanel = styled(SettingsPanel)({
  [`& .${classes.container}`]: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  [`& .${classes.switchRow}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
  },
});

const FeaturesSettingsPanel = ({ client, onSave }) => {
  const [expanded, setExpanded] = useState(false);
  const [useClientFuel, setUseClientFuel] = useState(client.use_client_fuel);
  const [features, setFeatures] = useState(client.features);
  const v1ReportsEnabled = features.includes('ftc_reports_v1');
  const v2ReportsEnabled = features.includes('ftc_reports_v2');
  const analyticsEnabled = features.includes('analytics_module');
  const auditClusterReportsEnabled = features.includes('audit_cluster_reports');
  const auditTripReportsEnabled = features.includes('audit_trip_reports');
  const auditPointsFilesEnabled = features.includes('audit_points_files');
  const auditTablesEnabled = features.includes('audit_tables_files');
  const classRulingReportsEnabled = features.includes('class_ruling');

  const toggleBooleanFeature = (checked, featureKey) => {
    if (checked === true) {
      setFeatures([...features, featureKey]);
    } else {
      const filteredFeatures = features.filter((f) => f !== featureKey);
      setFeatures(filteredFeatures);
    }
  };

  const add_enabled_disabled = (str, enabled) => (enabled ? `${str} enabled` : `${str} disabled`);

  const summary = () => {
    const reportV1Summary = add_enabled_disabled('V1 FTC reports', v1ReportsEnabled);
    const reportV2Summary = add_enabled_disabled('V2 FTC reports', v2ReportsEnabled);
    const fuelSummary = useClientFuel ? 'Actual fuel' : 'Estimated fuel';
    const analyticsSummary = add_enabled_disabled('Analytics', analyticsEnabled);
    const auditClusterReportSummary = add_enabled_disabled('Audit Cluster Reports', auditClusterReportsEnabled);
    const auditTripReportSummary = add_enabled_disabled('Audit Trip Reports', auditTripReportsEnabled);
    const auditPointsFilesSummary = add_enabled_disabled('Audit Points Files', auditPointsFilesEnabled);
    const auditTablesSummary = add_enabled_disabled('Audit Tables', auditTablesEnabled);
    const classRulingSummary = add_enabled_disabled('Class Ruling Reports', classRulingReportsEnabled);
    return [
      reportV1Summary,
      reportV2Summary,
      classRulingSummary,
      fuelSummary,
      analyticsSummary,
      auditClusterReportSummary,
      auditTripReportSummary,
      auditPointsFilesSummary,
      auditTablesSummary,
    ].join(', ');
  };

  const FeatureFlag = ({ checked, onChange, label }) => (
    <div className={classes.switchRow}>
      <Switch checked={checked} color="primary" onChange={onChange} />
      {label}
    </div>
  );
  FeatureFlag.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
  };

  return (
    <StyledSettingsPanel
      title="Client Features"
      summary={summary()}
      onSave={() => {
        onSave({
          use_client_fuel: useClientFuel,
          features: features,
        });
        setExpanded(false);
      }}
      onCancel={() => {
        setUseClientFuel(client.use_client_fuel);
        setFeatures(client.features);
        setExpanded(false);
      }}
      expanded={expanded}
      onExpand={() => setExpanded(!expanded)}
    >
      <div className={classes.container}>
        <FeatureFlag
          checked={v1ReportsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'ftc_reports_v1')}
          label="Version 1 FTC reports?"
        />
        <FeatureFlag
          checked={v2ReportsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'ftc_reports_v2')}
          label="Version 2 FTC reports?"
        />
        <FeatureFlag
          checked={classRulingReportsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'class_ruling')}
          label="Enable Class Ruling Reports?"
        />
        <FeatureFlag
          checked={useClientFuel}
          onChange={(e) => setUseClientFuel(e.target.checked)}
          label="Use Client-Entered Fuel in FTC Report?"
        />
        <FeatureFlag
          checked={analyticsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'analytics_module')}
          label="Enable Analytics module?"
        />
        <FeatureFlag
          checked={auditClusterReportsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'audit_cluster_reports')}
          label="Enable Audit Cluster Maps?"
        />
        <FeatureFlag
          checked={auditTripReportsEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'audit_trip_reports')}
          label="Enable Audit Trip Maps?"
        />
        <FeatureFlag
          checked={auditPointsFilesEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'audit_points_files')}
          label="Enable Audit Points Files?"
        />
        <FeatureFlag
          checked={auditTablesEnabled}
          onChange={(e) => toggleBooleanFeature(e.target.checked, 'audit_tables_files')}
          label="Enable Audit Tables Files?"
        />
      </div>
    </StyledSettingsPanel>
  );
};

FeaturesSettingsPanel.propTypes = {
  client: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default FeaturesSettingsPanel;
