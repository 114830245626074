import Map, { Layer, Popup, Source } from 'react-map-gl';
import React ,{useCallback, useEffect, useState}from 'react';
import {formatNumber, formatValue} from '../../../components/analytics/helpers';

import ContentItem from './ContentItem';
import MapViewToggle from '../../../components/MapViewToggle';
import PropTypes from 'prop-types';
import bbox from '@turf/bbox';
import config from '../../../config/config';
import {prettyLocalDatetime} from '../../../helpers/date';
import { styled } from '@mui/material/styles';
import {useRef} from 'react';

const PREFIX = 'MapTooltip';

const classes = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  text: `${PREFIX}-text`,
  overlay: `${PREFIX}-overlay`
};

const StyledContentItem = styled(ContentItem)({
  [`& .${classes.overlay}`]: {
    position: 'relative',
  },
});

const PopUpRoot = styled('div')({
  [`& .${classes.container}`]: {
    margin: '0',
    padding: '8px',
    backgroundColor: '#fff',
    border: '1px solid #e2e2e2',
  },
  [`& .${classes.header}`]: {
    width: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: '4px',
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: '700',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    color: '#3c424a',
  },
  [`& .${classes.text}`]: {
    width: '100%',
    fontSize: '12px',
    padding: 0,
    margin: 0,
    color: '#3c424a',
    textAlign: 'left',
  },
});


const dataLayer = {
  id: 'data',
  type: 'circle',
  source : 'ftc_classification',
  paint: {
    // Make circles larger as the user zooms from z12 to z22.
    'circle-radius': {
      'base': 1.75,
      'stops': [
        [1, 2],
        [10, 5],
        [22, 50]
      ]
    },
    'circle-stroke-color': [
      'match',
      ['get', 'ftc_classification'],
      'Non-Public Road',
      'rgb(174, 91, 98)',
      'Public Road',
      'rgb(91, 117, 174)',
      /* other */ '#ccc'
    ],
    'circle-stroke-opacity': 1,
    'circle-stroke-width': 1,
    // Color circles by ftc_classification, using a `match` expression.
    'circle-color': [
      'match',
      ['get', 'ftc_classification'],
      'Non-Public Road',
      'rgba(249, 130, 140, 0.5)',
      'Public Road',
      'rgba(128, 136, 249, 0.5)',
      /* other */ '#ccc'
    ]
  },
};

//Initial values are set to view Australia
const initialMapLatitude = -23.6980;
const initialMapLongitude = 133.8807;
const initialZoom = 4;
const mapMaxBounds=[[72.2460938,-55.3228175 ],[168.2249543,-9.0882278]];

const availableMapStyles = {
  'street': 'mapbox://styles/mapbox/streets-v11',
  'satellite': 'mapbox://styles/mapbox/satellite-streets-v11'
};

const TripViewerMap =({geojson, style ={}}) =>{
  const mapRef = useRef();
  const [mapView, setMapView] = useState('street');
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [mapLoaded, setMapLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);

  const onHover = useCallback(event =>{
    const {
      features,
    } = event;
    const hoveredFeature = features && features[0];
    // prettier-ignore
    setPopupInfo(hoveredFeature && {feature: hoveredFeature});
  }, []);


  const toggleMapStyle = () => {
    if (mapView==='street'){
      setMapStyle(availableMapStyles['street']);
    }
    else{setMapStyle(availableMapStyles['satellite']);}
  };


  useEffect(() => {
    toggleMapStyle();
  }, [mapView]);// eslint-disable-line


  useEffect(() => {
    if(geojson && mapRef.current){
      const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {padding: 100, duration: 1000}
      );
  }}, [geojson, mapLoaded]);// eslint-disable-line

  const onMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <StyledContentItem title="Trip map" className={classes.overlay}>
      <Map
        ref={mapRef}
        mapboxAccessToken={config.settings.MAPBOX_KEY}
        onLoad={onMapLoad}
        initialViewState={{
          latitude: initialMapLatitude,
          longitude: initialMapLongitude,
          zoom: initialZoom
        }}
        mapStyle={mapStyle}
        maxBounds={mapMaxBounds}
        style={style}
        onMouseMove={onHover}
        interactiveLayerIds={['data']}
      >
        <MapViewToggle mapView={mapView} setMapView={setMapView}/>
        {geojson && <Source type="geojson" data={geojson}>
          <Layer {...dataLayer} />
        </Source>}
        {popupInfo && (
          <Popup
            longitude={Number(popupInfo['feature']['geometry']['coordinates'][0])}
            latitude={Number(popupInfo['feature']['geometry']['coordinates'][1])}
            onClose={() => setPopupInfo(null)}
          >
            <PopUpRoot className={classes.container}>
              <h2 className={classes.header}> Point Number {formatValue(popupInfo['feature']['properties']['point_number'])}</h2>
              <p className={classes.text}>Timestamp: {prettyLocalDatetime(popupInfo['feature']['properties']['timestamp'])}</p>
              {popupInfo['feature']['properties'].activity !== null && <p className={classes.text} >Activity: {popupInfo['feature']['properties'].activity}</p>}
              {popupInfo['feature']['properties'].auxiliary_on !== null && <p className={classes.text} >PTO state: {popupInfo['feature']['properties'].auxiliary_on === true ? 'On' : 'Off'}</p>}
              <p className={classes.text}>Interval distance: {formatNumber(parseFloat(popupInfo['feature']['properties'].distance_m).toFixed(1))} meters</p>
              <p className={classes.text}>Travel time: {formatNumber(parseFloat(popupInfo['feature']['properties'].duration_s).toFixed(0))} seconds</p>
              <p className={classes.text}>Speed over interval: {formatNumber(parseFloat(popupInfo['feature']['properties'].speed_section_kmh).toFixed(0))} km/h</p>
              <p className={classes.text}>FTC classification: {popupInfo['feature']['properties'].ftc_classification}</p>
            </PopUpRoot>
          </Popup>
        )}
      </Map>
    </StyledContentItem>
  );
};

TripViewerMap.propTypes = {
  geojson :  PropTypes.object,
  style: PropTypes.object
};

export default TripViewerMap;