import { VehicleAPI } from '../api';

const LOADING       = 'vehicles/LOADING';
const UPDATED       = 'vehicles/UPDATE';
const ERROR         = 'vehicles/ERROR';
const CACHED        = 'vehicles/CACHED';
const CLIENT_CHANGED= 'vehicles/CLIENTCHANGED';
const CATEGORIES_LOADING = 'vehicles/categories/LOADING';
const CATEGORIES_UPDATED = 'vehicles/categories/UPDATED';

const CACHE_TTL = 5000;

const initialState = {
  clientId: undefined,
  isLoading: false,
  items: [],
  loadTime: undefined,
  total: undefined,
  categories: {
    items: [],
    loadTime: undefined,
    isLoading: false,
  }
};

let lastRequestId = undefined;

// Action Creators

export function loading() {
  return {
    type: LOADING,
  };
}

export function updated(vehicles, offset, complete, total) {
  return {
    type: UPDATED,
    items: vehicles,
    offset,
    complete,
    total,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

export function cached() {
  return {
    type: CACHED,
  };
}

export function clientChanged(clientId) {
  return {
    type: CLIENT_CHANGED,
    clientId,
  };
}

export function categoriesLoading() {
  return {
    type: CATEGORIES_LOADING
  };
}

export function categoriesUpdated(response) {
  return {
    type: CATEGORIES_UPDATED,
    items: response.payload,
  };
}

// Async Action Creators

async function chunkedFetch(clientId, params, dispatch, requestId, pageSize, offset, limit) {
  let done = false;
  while (!done) {
    const nextChunkSize = Math.min(pageSize, limit-offset);
    const response = await VehicleAPI.list(clientId, {...params, pagesize: nextChunkSize, offset});
    done = response.payload.length < nextChunkSize || response.payload.length === limit-offset;
    const action = updated(response.payload, offset, done, response.total);
    if (requestId !== lastRequestId) break;
    dispatch(action);
    offset += response.payload.length;
  }
}

export function list(refresh=false, params={}, offset=0, limit=10000) {
  return (dispatch, getState) => {
    refresh=true;
    const pageSize = 500;
    const state = getState();
    const selectedClientId = state.clients.selectedItemId;
    const age = Date.now() - state.loadTime;
    const sameClient = selectedClientId === state.vehicles.clientId;
    if (!refresh && sameClient && age < CACHE_TTL && state.items.length > offset) { // age might be NaN, which compares false
      // nothing to do, already cached.
      dispatch(cached());
    } else {
      if (!sameClient) dispatch(clientChanged(selectedClientId));
      const requestId = Math.random();
      lastRequestId = requestId;
      dispatch(loading());
      chunkedFetch(
        selectedClientId,
        params,
        dispatch,
        requestId,
        pageSize,
        offset,
        limit,
      );
    }
  };
}

export function getCategories(refresh=false) {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = state.clients.selectedItemId;
    const { loadTime } = state.vehicles.categories || initialState.categories;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached
    } else {
      dispatch(categoriesLoading());
      return VehicleAPI.categories(clientId)
        .then(response => dispatch(categoriesUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case LOADING:
    return {
      ...state,
      isLoading: true,
    };

  case UPDATED:
  {
    const tailOffset = action.complete ? 0 : undefined;
    const items = [
      ...state.items.slice(0, action.offset),
      ...action.items,
      ...state.items.slice(action.offset + action.items.length, tailOffset)];
    return {
      ...state,
      items: items,
      enabled: items.filter(i => !i.disabled),
      loadTime: Date.now(),
      isLoading: false
    };
  }

  case ERROR:
    return {
      ...state,
      error: action.error,
    };

  case CLIENT_CHANGED:
    return {
      ...state,
      clientId: action.clientId,
      items: [],
      loadTime: undefined,
      total: undefined,
    };

  case CATEGORIES_LOADING:
    return {
      ...state,
      categories: {
        ...state.categories,
        loading: true,
      }
    };

  case CATEGORIES_UPDATED:
    return {
      ...state,
      categories: {
        ...state.categories,
        items: action.items,
        loadTime: Date.now(),
        isLoading: false
      }
    };

  default:
    return state;
  }
}
