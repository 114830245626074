import { safePercent, safeFixed } from '../../../lib/format';
/*
 * for consistency with the PDF reports, we return 0.00 if the
 * results are infinite or NaN due a zero divisor.
 */

const isString = v => typeof(v) === 'string';
const parsesToFloat = v => typeof(parseFloat(v)) === 'number';
const includesDp = v => v.includes('.');

// Add thousand separators to values that are float and has decimal places
export const transformNumberValues = (data) => {
  const transformedData = data.map((d) => {
    const newObj = {};
    Object.entries(d).forEach(([key, value]) => {
      let v = value;
      if (isString(value) && parsesToFloat(value) && includesDp(value)) {
        v = safeFixed(parseFloat(value));
      }
      newObj[key] = v;
    });
    return newObj;
  });
  return transformedData;
};

// V1 Report transform functions

export const transformFleetSummary = (data) => {
  const d = data[0];
  return [
    {
      'Activity': 'Public Road Travel',
      'Distance (KM)': safeFixed(d.public_road_distance_km),
      'Time (Mins)': safeFixed(d.public_road_duration_mins),
      'Fuel Amount (L)': safeFixed(d.public_road_fuel_l),
      'Eligible Fuel Amount (L)': safeFixed(d.public_road_eligible_fuel_l),
      'Fuel Apportioned (%)': safePercent(d.public_road_fuel_l, d.total_fuel_l),
      'FTC Amount ($)': safeFixed(d.public_road_ftc),
    },
    {
      'Activity': 'Non-Public Road Travel',
      'Distance (KM)': safeFixed(d.non_public_road_distance_km),
      'Time (Mins)': safeFixed(d.non_public_road_duration_mins),
      'Fuel Amount (L)': safeFixed(d.non_public_road_fuel_l),
      'Eligible Fuel Amount (L)': safeFixed(d.non_public_road_eligible_fuel_l),
      'Fuel Apportioned (%)': safePercent(d.non_public_road_fuel_l, d.total_fuel_l),
      'FTC Amount ($)': safeFixed(d.non_public_road_ftc),
    },
    {
      'Activity': 'Auxiliary Operation',
      'Distance (KM)': '',
      'Time (Mins)': '',
      'Fuel Amount (L)': safeFixed(d.auxiliary_fuel_l),
      'Eligible Fuel Amount (L)': safeFixed(d.auxiliary_eligible_fuel_l),
      'Fuel Apportioned (%)': safePercent(d.auxiliary_fuel_l, d.total_fuel_l),
      'FTC Amount ($)': safeFixed(d.auxiliary_ftc),
    },
    {
      'Activity': 'Total',
      'Distance (KM)': safeFixed(d.total_distance_km),
      'Time (Mins)': safeFixed(d.total_duration_mins),
      'Fuel Amount (L)': safeFixed(d.total_fuel_l),
      'Eligible Fuel Amount (L)': safeFixed(d.total_eligible_fuel_l),
      'Fuel Apportioned (%)': safePercent(d.total_fuel_l, d.total_fuel_l),
      'FTC Amount ($)': safeFixed(d.total_ftc),
    },
  ];
};

export const transformRatePeriodSummary = (data) => {
  const subsets = data.map((d) => {
    return [
      {
        'Fuel Type': d.fuel_type,
        'Rate Period Start': d.ftc_period_start,
        'Rate Period End': d.ftc_period_end,
        'FTC Rate': d.public_road_rate,
        'Activity': 'Public Road Travel',
        'Distance (KM)': safeFixed(d.public_road_distance_km),
        'Time (Mins)': safeFixed(d.public_road_duration_mins),
        'Fuel Amount (L)': safeFixed(d.public_road_fuel_l),
        'Eligible Fuel Amount (L)': safeFixed(d.public_road_eligible_fuel_l),
        'Fuel Apportioned (%)': safePercent(d.public_road_fuel_l, d.total_fuel_l),
        'FTC Amount ($)': safeFixed(d.public_road_ftc),
      },
      {
        'Fuel Type': d.fuel_type,
        'Rate Period Start': d.ftc_period_start,
        'Rate Period End': d.ftc_period_end,
        'FTC Rate': d.non_public_road_rate,
        'Activity': 'Non-Public Road Travel',
        'Distance (KM)': safeFixed(d.non_public_road_distance_km),
        'Time (Mins)': safeFixed(d.non_public_road_duration_mins),
        'Fuel Amount (L)': safeFixed(d.non_public_road_fuel_l),
        'Eligible Fuel Amount (L)': safeFixed(d.non_public_road_eligible_fuel_l),
        'Fuel Apportioned (%)': safePercent(d.non_public_road_fuel_l, d.total_fuel_l),
        'FTC Amount ($)': safeFixed(d.non_public_road_ftc),
      },
      {
        'Fuel Type': d.fuel_type,
        'Rate Period Start': d.ftc_period_start,
        'Rate Period End': d.ftc_period_end,
        'FTC Rate': d.non_public_road_rate,
        'Activity': 'Auxiliary Operation',
        'Distance (KM)': '',
        'Time (Mins)': '',
        'Fuel Amount (L)': safeFixed(d.auxiliary_fuel_l),
        'Eligible Fuel Amount (L)': safeFixed(d.auxiliary_eligible_fuel_l),
        'Fuel Apportioned (%)': safePercent(d.auxiliary_fuel_l, d.total_fuel_l),
        'FTC Amount ($)': safeFixed(d.auxiliary_ftc),
      },
    ];
  });
  return subsets.flat();
};
