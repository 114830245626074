import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { ClientStore, LoadingStore } from '../../../ducks';
import EmailValidator from 'email-validator';
import SettingsPanel from '../components/SettingsPanel';

const DivRecipients = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
}));

const DivGroup = styled('div')(() => ({
  paddingLeft: 20,
  width: '30rem',
  display: 'flex',
  flexDirection: 'column',
}));

const DivFeatureSwitch = styled('div')(() => ({
  fontSize: '1.2rem',
}));

const DivRecipient = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const TextFieldEmailField = styled(TextField)(() => ({
  width: '20rem',
}));


const appendUnique = (list, item) => {
  return list.includes(item) ? list : list.concat([item]);//[item].concat(list);
};

class EmailSettingsPanel extends React.Component {

  static propTypes = {
    classes: PropTypes.object,
    client: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    ftcReportEmail: false,
    ftcReportRecipients: [],
    email: '',
    summaryText: '',
    expanded: false,
  }

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.loadState();
    }
  }

  loadState = () => {
    const { client } = this.props;
    this.setState({
      ftcReportEmail: client.ftc_report_email,
      ftcReportRecipients: client.ftc_report_recipients || [],
      summaryText: this.summaryText(client),
    });

  }

  deleteEmail = (index) => {
    this.setState(state => {
      const list = state.ftcReportRecipients;
      const recipients = list.slice(0, index).concat(list.slice(index+1));
      return {
        ftcReportRecipients: recipients,
        ftcReportEmail: state.ftcReportEmail && recipients.length > 0,
      };
    });
  }

  addEmail = () => {
    this.setState(state => {
      return {
        ftcReportRecipients: appendUnique(state.ftcReportRecipients, state.email.trim()),
        email: '',
      };
    });
  }

  onSave = async () => {
    const { client, dispatch } = this.props;
    const { ftcReportRecipients, ftcReportEmail} = this.state;
    const recipients = ftcReportRecipients.filter(r => r.trim().length > 0);
    const update = {
      ftc_report_recipients: recipients,
      ftc_report_email: ftcReportEmail,
    };
    dispatch(ClientStore.updateOne(client.client_id, update));
    this.props.dispatch(LoadingStore.loading(false));
    this.setState({
      summaryText: this.summaryText(update),
      expanded: false,
    });
  }

  onCancel = () => {
    this.loadState();
    this.setState({expanded: false});
  }

  renderRecipient = (email, index) => {
    return (
      <DivRecipient key={email}>
        <div >{email}</div>
        <IconButton
          aria-label="Delete"
          onClick={() => this.deleteEmail(index)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </DivRecipient>
    );
  }

  onKeyUp = (event) => {
    if (event.keyCode === 13 && EmailValidator.validate(this.state.email)) {
      this.addEmail();
    }
  }

  onEnableFtcReportEmail = (enabled) => {
    this.setState((state) => {
      return {ftcReportEmail: enabled && state.ftcReportRecipients.length > 0};
    });
  }

  onEnableClientFuelInput = (enabled) => {
    this.setState({
      clientFuelInput: enabled,
    });
  }

  renderEmailInput = () => {
    const { email } = this.state;
    const isValid = EmailValidator.validate(email);
    return (
      <DivRecipient>
        <TextFieldEmailField
          placeholder="add an email address"
          margin="normal"
          value={email}
          onChange={(event) => this.setState({ email: event.target.value })}
          onKeyUp={this.onKeyUp}
        />

        <Fab
          size="small"
          color="primary"
          aria-label="Add"
          onClick={this.addEmail}
          disabled={!isValid}
        >
          <AddIcon />
        </Fab>
      </DivRecipient>

    );
  }

  summaryText = ({ftc_report_recipients, ftc_report_email}) => {
    if (!ftc_report_email || ftc_report_recipients.length < 1) {
      return 'Disabled';
    }
    const firstRecipient = ftc_report_recipients[0];
    const otherCount = ftc_report_recipients.length - 1;
    if (otherCount === 0) {
      return `Send to ${firstRecipient}`;
    } else if (otherCount === 1) {
      return `Send to ${firstRecipient} and 1 other`;
    } else {
      return `Send to ${firstRecipient} and ${otherCount} others`;
    }
  }

  render() {
    const { ftcReportEmail, ftcReportRecipients, summaryText, expanded } = this.state;
    return (
      <SettingsPanel
        title="Report Delivery"
        summary={summaryText}
        onSave={this.onSave}
        onCancel={this.onCancel}
        expanded={expanded}
        onExpand={(expanded) => this.setState({ expanded })}
      >
        <DivGroup>
          <DivFeatureSwitch>
            <Switch
              checked={ftcReportEmail}
              color="primary"
              onChange={(event) => this.onEnableFtcReportEmail(event.target.checked)}
              value="ftcReportEmail"
            />
      Enable email delivery?
          </DivFeatureSwitch>
          {this.renderEmailInput()}
          <DivRecipients>
            {ftcReportRecipients.map(this.renderRecipient)}
          </DivRecipients>
        </DivGroup>
      </SettingsPanel>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.clients.selectedItem,
  };
};

export default connect(mapStateToProps)(
  (EmailSettingsPanel)
);
