import React from 'react';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import language from 'config/language';
import PropTypes from 'prop-types';
import config from 'config/config';

export default function VehicleDatagrid({ vehicles, openVehicleDetails }) {
  const COLUMN_SIZES = {
    sm: 100,
    md: 150,
    lg: 200,
  };

  const dateFormat = {
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    width: COLUMN_SIZES.md,
  };

  const columns = [
    {
      field: 'vehicle_id',
      headerName: 'Prism ID',
    },
    {
      field: 'client_vehicle_id',
      headerName: 'IVMS ID',
    },
    {
      field: 'category',
      headerName: language['en']['vehicle_category'],
      width: COLUMN_SIZES.lg,
    },
    {
      field: 'make',
      headerName: 'Make',
      width: COLUMN_SIZES.lg,
    },
    {
      field: 'model',
      headerName: 'Model',
      width: COLUMN_SIZES.lg,
    },
    {
      field: 'description',
      headerName: language['en']['vehicle_description'],
    },
    {
      field: 'registration',
      headerName: language['en']['vehicle_registration'],
    },
    {
      field: 'registration_state',
      headerName: 'State',
    },
    {
      field: 'ftc_on_road',
      headerName: language['en']['vehicle_ftc_on_road'],
      width: COLUMN_SIZES.md,
      type: 'boolean',
    },
    {
      field: 'fuel_rate_source',
      headerName: language['en']['vehicle_fuel_rate_source'],
      width: COLUMN_SIZES.md,
    },
    {
      field: 'disabled',
      headerName: 'Active',
      type: 'boolean',
      valueGetter: ({ value }) => !value,
    },
    ...(config.isBarton
      ? [
          {
            field: 'con_ops',
            headerName: language['en']['vehicle_con_ops'],
          },
          {
            field: 'ruc_vehicle_type',
            headerName: language['en']['vehicle_ruc_vehicle_type'],
            width: COLUMN_SIZES.md,
          },
          {
            field: 'registration_charge_code',
            headerName: language['en']['vehicle_registration_charge_code'],
          },
        ]
      : []),
    {
      field: 'acquired_date',
      headerName: language['en']['vehicle_acquired_date'],
      ...dateFormat,
    },
    {
      field: 'auxiliary_fuel_method',
      headerName: language['en']['vehicle_auxiliary_fuel_method'],
    },
    {
      field: 'auxiliary_fuel_rate_lhr',
      headerName: language['en']['vehicle_auxiliary_fuel_rate_lhr'],
      type: 'number',
    },
    {
      field: 'auxiliary_fuel_use',
      headerName: language['en']['vehicle_auxiliary_fuel_use'],
    },
    {
      field: 'auxiliary_safe_harbour_override',
      headerName: language['en']['vehicle_auxiliary_safe_harbour_override'],
      type: 'boolean',
    },
    {
      field: 'disposed_date',
      headerName: language['en']['vehicle_disposed_date'],
      ...dateFormat,
    },
    {
      field: 'division',
      headerName: language['en']['vehicle_division'],
    },
    {
      field: 'engine_cylinders',
      headerName: language['en']['vehicle_engine_cylinders'],
      type: 'number',
    },
    {
      field: 'engine_displacement_l',
      headerName: language['en']['vehicle_engine_displacement_l'],
      type: 'number',
    },
    {
      field: 'fuel_type',
      headerName: language['en']['vehicle_fuel_type'],
    },
    {
      field: 'gcm_tonnes',
      headerName: language['en']['vehicle_gcm_tonnes'],
      type: 'number',
    },
    {
      field: 'gvm_tonnes',
      headerName: language['en']['vehicle_gvm_tonnes'],
      type: 'number',
    },
    {
      field: 'load_type',
      headerName: language['en']['vehicle_load_type'],
    },
    {
      field: 'max_weight_kg',
      headerName: language['en']['vehicle_max_weight_kg'],
      type: 'number',
    },
    {
      field: 'min_weight_kg',
      headerName: language['en']['vehicle_min_weight_kg'],
      type: 'number',
    },
    {
      field: 'model_year',
      headerName: language['en']['vehicle_model_year'],
      type: 'number',
    },
    {
      field: 'number_of_axles',
      headerName: language['en']['vehicle_number_of_axles'],
      type: 'number',
    },
    {
      field: 'ref_fuel_rate_driving_l_100km',
      headerName: language['en']['vehicle_ref_fuel_rate_driving_l_100km'],
      type: 'number',
    },
    {
      field: 'ref_fuel_rate_idling_l_hr',
      headerName: language['en']['vehicle_ref_fuel_rate_idling_l_hr'],
      type: 'number',
    },
    {
      field: 'registration_end_date',
      headerName: language['en']['vehicle_registration_end_date'],
      ...dateFormat,
    },
    {
      field: 'seats',
      headerName: language['en']['vehicle_seats'],
    },
    {
      field: 'transmission_type',
      headerName: language['en']['vehicle_transmission_type'],
    },
    {
      field: 'variant',
      headerName: language['en']['vehicle_variant'],
    },
    {
      field: 'vin',
      headerName: language['en']['vehicle_vin'],
    },
    {
      field: 'pbs_approved',
      headerName: language['en']['vehicle_pbs_approved'],
    },
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', padding: '5px' }}>
      <DataGridPro
        columns={columns}
        rows={vehicles}
        getRowId={(row) => row.vehicle_id}
        density="compact"
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
        slots={{ toolbar: Toolbar }}
        onRowClick={(params) => openVehicleDetails(params.row)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              acquired_date: false,
              auxiliary_fuel_method: false,
              auxiliary_fuel_rate_lhr: false,
              auxiliary_fuel_use: false,
              auxiliary_safe_harbour_override: false,
              disposed_date: false,
              division: false,
              engine_cylinders: false,
              engine_displacement_l: false,
              fuel_type: false,
              gcm_tonnes: false,
              gvm_tonnes: false,
              load_type: false,
              max_weight_kg: false,
              min_weight_kg: false,
              model_year: false,
              number_of_axles: false,
              ref_fuel_rate_driving_l_100km: false,
              ref_fuel_rate_idling_l_hr: false,
              registration_end_date: false,
              seats: false,
              transmission_type: false,
              variant: false,
              vin: false,
              pbs_approved: false,
            },
          },
        }}
      />
    </div>
  );
}
VehicleDatagrid.propTypes = {
  vehicles: PropTypes.array,
  openVehicleDetails: PropTypes.func,
};
