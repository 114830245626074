import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PdfIcon from '../../../assets/icons/icon_pdf.svg';
import XlsIcon from '../../../assets/icons/icon_xls.svg';
import FileIcon from '../../../assets/icons/icon_file.svg';
import ZipIcon from '../../../assets/icons/icon_zip.svg';
import CsvIcon from '../../../assets/icons/icon_csv.svg';
import moment from 'moment';

const PREFIX = 'ReportFileCard';

const classes = {
  card: `${PREFIX}-card`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  desc: `${PREFIX}-desc`,
  qualifier: `${PREFIX}-qualifier`,
  row: `${PREFIX}-row`,
  createdAt: `${PREFIX}-createdAt`
};

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    width:300,
    maxHeight: 140,
    margin: 10,
  },

  [`& .${classes.image}`]: {
    height: 60,
    marginLeft: 25,
    flex: '0 0 auto',  /* IE */
    objectFit: 'scale-down', /* FF */
  },

  [`& .${classes.title}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '5rem',
    color: '#333333',
    padding: '0.5rem',
  },

  [`& .${classes.desc}`]: {
    width: '100%',
    fontSize: 14,
    textAlign: 'left',
  },

  [`& .${classes.qualifier}`]: {
    fontSize: 12,
  },

  [`& .${classes.row}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  [`& .${classes.createdAt}`]: {
    fontSize: 12,
    padding: 10,
    color: '#333333',
  }
}));

const icons = {
  pdf: PdfIcon,
  excel: XlsIcon,
  zip: ZipIcon,
  csv: CsvIcon,
};

const month_abbr = (month) => moment().month(month-1).format('MMM');

class ReportFileCard extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    regenerate: PropTypes.func,
    openReport: PropTypes.func.isRequired,
    sendReport: PropTypes.func,
  }

  state = {
    anchorEl: null,
  };

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  renderMenu() {
    const { report, regenerate, sendReport } = this.props;
    const { anchorEl } = this.state;

    if (!regenerate && !sendReport) return null;
    return (
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleMenuClose}
      >
        {regenerate &&
          <MenuItem onClick={() => {this.handleMenuClose(); regenerate(report);}}>
            Regenerate
          </MenuItem>
        }
        {sendReport &&
          <MenuItem onClick={() => {this.handleMenuClose(); sendReport(report);}}>
            Send by Email
          </MenuItem>
        }
      </Menu>
    );
  }

  qualifier = (report) => {
    const month = month_abbr(report.month);
    if (!report.retro) return `${month} ${report.year}`;
    if (!report.start_year) return 'Retrospective';  // old style
    const start_month = month_abbr(report.start_month);
    return `${start_month} ${report.start_year} to ${month} ${report.year}`;
  }

  render() {
    const {  report, openReport } = this.props;
    const { anchorEl } = this.state;
    const fileFormat = report.format;
    const qualifier = this.qualifier(report);
    const fileDesc = report.description;
    const generatedTime = 'generated ' + moment.utc(report.created).fromNow();
    const icon = icons[fileFormat] || FileIcon;
    const menu = this.renderMenu();

    return (
      <StyledCard className={classes.card}>
        <div className={classes.row}>
          <div className={classes.createdAt}> {generatedTime} </div>
          <div className={classes.row}>
            <IconButton aria-label='Download' onClick={() => openReport(report)} size="large">
              <DownloadIcon />
            </IconButton>
            {menu &&
              <IconButton
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup='true'
                onClick={this.handleMenuClick}
                size="large">
                <MoreVertIcon />
              </IconButton>
            }
          </div>
        </div>
        <div className={classes.row}>
          <img src={icon} alt="report" className={classes.image}/>
          <div className={classes.title}>
            <div className={classes.desc}> {fileDesc} </div>
            <div className={classes.qualifier}> {qualifier} </div>
          </div>
        </div>
        {menu}
      </StyledCard>
    );
  }
}

export default (ReportFileCard);
