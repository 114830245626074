import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

const TextFieldSelect = styled(TextField)(() => ({
  margin: 10,
  width: 220,
}));

const DivGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const DivRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const sortReferrers = (referrers) => {
  const last = ['Test', 'Other'];  // put these values last.
  const first = referrers.filter(r => !last.includes(r));
  return first.sort().concat(last);
};

class ServiceTypeSettingsPanel extends React.Component {

  static propTypes = {
    client: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    referrers: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
  }

  state = {
    relationshipOwner: undefined,
    referredBy: undefined,
    serviceType: undefined,
    expanded: false,
    isActive: false,
  }

  componentDidMount() {
    this.loadState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.loadState();
    }
  }

  loadState = () => {
    const { client } = this.props;
    this.setState({
      relationshipOwner: client.relationship_owner,
      referredBy: client.referred_by,
      serviceType: client.service_type,
      isActive: client.active,
    });
  }

  summary = (client) => {
    const silence = ['Test', 'Other', null];
    const parts = [];
    const { relationship_owner, referred_by, service_type, active } = client;
    if (service_type) parts.push(service_type);
    if (!silence.includes(relationship_owner)) parts.push('through ' + relationship_owner);
    if (!silence.includes(referred_by)) parts.push('referred by ' + referred_by);
    if (!active) parts.push('INACTIVE');
    return parts.length ? parts.join(', ') : 'Unknown';
  }

  render() {
    const {  referrers, serviceTypes, client, onSave } = this.props;
    const { relationshipOwner, referredBy, serviceType, expanded, isActive } = this.state;
    const referrerList = sortReferrers(referrers);

    return (
      <SettingsPanel
        title="Service Type"
        summary={this.summary(client)}
        onSave={() => {
          onSave({
            relationship_owner: relationshipOwner,
            referred_by: referredBy,
            service_type: serviceType,
            active: isActive,
          });
          this.setState({ expanded: false });
        }}
        onCancel={() => {
          this.loadState();
          this.setState({ expanded: false });
        }}
        onExpand={(expanded) => this.setState({ expanded })}
        expanded={expanded}
      >
        <DivGroup>
          <DivRow>
            <Switch
              checked={isActive}
              color="primary"
              onChange={(event) => this.setState({ isActive: event.target.checked })}
              value="isActive"
            />
      Client is Active?
          </DivRow>
          <DivRow>
            <TextFieldSelect
              select
              label="Service Type"
              value={serviceType || ''}
              onChange={(event) =>
                this.setState({ serviceType: event.target.value, modified: true })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {serviceTypes.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextFieldSelect>
            <TextFieldSelect
              select
              label="Relationship Owner"
              value={relationshipOwner || ''}
              onChange={(event) =>
                this.setState({
                  relationshipOwner: event.target.value,
                  modified: true,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {referrerList.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextFieldSelect>
            <TextFieldSelect
              select
              label="Referred By"
              value={referredBy || ''}
              onChange={(event) =>
                this.setState({ referredBy: event.target.value, modified: true })
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {referrerList.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextFieldSelect>
          </DivRow>
        </DivGroup>
      </SettingsPanel>
    );
  }
}

export default (ServiceTypeSettingsPanel);
