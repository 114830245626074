import React ,{useEffect,useState}from 'react';
import {useRef} from 'react';
import Map, {Source, Layer} from 'react-map-gl';
import bbox from '@turf/bbox';
import PropTypes from 'prop-types';
import MapViewToggle from './MapViewToggle';
import config from '../config/config';


//Initial values are set to view Australia
const initialMapLatitude = -23.6980;
const initialMapLongitude = 133.8807;
const initialZoom = 4;
const mapMaxBounds=[[72.2460938,-55.3228175 ],[168.2249543,-9.0882278]];

const availableMapStyles = {
  'street': 'mapbox://styles/mapbox/streets-v11',
  'satellite': 'mapbox://styles/mapbox/satellite-streets-v11'
};

const MapView =({geojson, layerStyle, style ={}}) =>{
  const mapRef = useRef();
  const [mapView, setMapView] = useState('street');
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [mapLoaded,setMapLoaded] = useState(false);


  const toggleMapStyle = () => {
    if (mapView==='street'){
      setMapStyle(availableMapStyles['street']);
    }
    else{setMapStyle(availableMapStyles['satellite']);}
  };


  useEffect(() => {
    toggleMapStyle();
  }, [mapView]);// eslint-disable-line


  useEffect(() => {
    if(geojson && mapRef.current){
      const [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {padding: 100, duration: 1000}
      );
  }}, [geojson, mapLoaded]);// eslint-disable-line

  const onMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={config.settings.MAPBOX_KEY}
      onLoad={onMapLoad}
      initialViewState={{
        latitude: initialMapLatitude,
        longitude: initialMapLongitude,
        zoom: initialZoom
      }}
      mapStyle={mapStyle}
      maxBounds={mapMaxBounds}
      style={style}
      interactiveLayerIds={['data']}
    >
      <MapViewToggle mapView={mapView} setMapView={setMapView}/>
      {mapLoaded && <Source id="my-data" type="geojson" data={geojson}>
        <Layer {...layerStyle} />
      </Source>}
    </Map>
  );
};

MapView.propTypes = {
  geojson :  PropTypes.object.isRequired,
  layerStyle: PropTypes.object.isRequired,
  style: PropTypes.object
};

export default MapView;