import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import config from '../config/config';

ReactGA.initialize(config.settings.GA_KEY);

class Analytics extends React.Component
{
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object.isRequired,
    clientId: PropTypes.string,
    userId: PropTypes.string,
  }

  unlisten = undefined;

  componentDidMount() {
    this.unlisten = this.props.history.listen(location =>
      ReactGA.pageview(location.pathname)
    );
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId) {
      ReactGA.set({
        dimension1: this.props.clientId
      });
    }
    if (prevProps.userId !== this.props.userId) {
      ReactGA.set({
        userId: this.props.userId,
        dimension2: this.props.userId
      });
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    roles: state.refs.roles.items,
    userId: state.auth.userId,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)(Analytics);
