import React from 'react';
import PropTypes from 'prop-types';
import { AuthFormGroup, AuthForm, AuthHeader } from '../components';
import { Link, Redirect } from 'react-router-dom';
import { AuthStore, ClientStore } from '../../../ducks';
import { AuthAPI } from '../../../api';
import { connect } from 'react-redux';
import PublicPage from '../components/PublicPage';

class Login extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    tokenExpiresAt: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    auth_username: '',
    auth_password: '',
    focus: 'auth_username',
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      alertText: '',
    };
  }

  onLogin = () => {
    this.setState({
      loading: true,
      alertText: '',
    });

    if (!this.state.auth_username || !this.state.auth_password) {
      setTimeout(() => {
        this.setState({
          alertText: 'Please enter a valid username and password',
          loading: false,
        });
      }, 400);
    } else {
      AuthAPI.login(this.state.auth_username, this.state.auth_password)
        .then((response) => {
          const { profile, token, expires_in, refresh_token } =
            response.payload;
          this.props.dispatch(
            AuthStore.setToken(
              profile,
              token,
              Date.now() + 1000 * expires_in,
              refresh_token
            )
          );
          if (profile.sysadmin) {
            this.props.dispatch(ClientStore.listAll(true));
          }
        })
        .catch((err) => {
          let alertText = 'Login failed.  Please try again.';
          if (err && err.body && err.body.message === 'Invalid credentials.') {
            alertText = 'Email or password is invalid.';
          }
          this.setState({
            alertText,
            loading: false,
          });
        });
    }
  };

  onFormFieldChange = (name, value) => this.setState({ [name]: value });

  passwordResetLink = () => (
    <Link to="/forgot" style={{ textDecoration: 'none', color: 'inherit' }}>
      Forgot Password?
    </Link>
  );

  render() {
    const isAuth =
      this.props.isAuthenticated && this.props.tokenExpiresAt > Date.now();
    const title = <AuthHeader title="auth_login_title" />;
    if (isAuth) return <Redirect to="/prism" />;

    return (
      <PublicPage>
        <AuthForm
          loading={this.state.loading}
          title={title}
          buttonText="auth_login"
          buttonAction={this.onLogin}
          alertText={this.state.alertText}
          homeLink={false}
        >
          <AuthFormGroup
            label="Email"
            autoComplete="username"
            type="email"
            name="auth_username"
            value={this.state.auth_username}
            onChange={this.onFormFieldChange}
            focus={this.state.focus === 'auth_username'}
            onEnter={() => this.setState({ focus: 'auth_password' })}
          />

          <AuthFormGroup
            label="Password"
            autoComplete="current-password"
            type="password"
            name="auth_password"
            glyph="password"
            value={this.state.auth_password}
            onChange={this.onFormFieldChange}
            helperText={this.passwordResetLink()}
            focus={this.state.focus === 'auth_password'}
            onEnter={this.onLogin}
          />
        </AuthForm>
      </PublicPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.auth.profile,
    tokenExpiresAt: state.auth.tokenExpiresAt,
  };
};

export default connect(mapStateToProps)(Login);
