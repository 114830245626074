import ClientAdmin from './containers/ClientAdmin';
import withRole from '../../hocs/withRole';

export default [
  {
    path: '/clientadmin',
    exact: false,
    component: withRole(['Client Admin'], ClientAdmin),
    module: 'clientadmin'
  }
];
