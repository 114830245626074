import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'ErrorToast';

const classes = {
  close: `${PREFIX}-close`,
  content: `${PREFIX}-content`
};

const StyledIconButton = styled(IconButton)((
  {
    theme
  }
) => ({
  [`& .${classes.close}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  [`& .${classes.content}`]: {
    backgroundColor: theme.palette.error.dark,
  }
}));

class ErrorToast extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string,
    duration: PropTypes.number,
    key: PropTypes.any,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    this.setState({open: this.props.open});
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.key !== this.props.key || prevProps.message !== this.props.message || prevProps.open !== this.props.open) {
      this.setState({open: this.props.open});
    }
  }

  onClose = () => {
    this.setState({open: false});
  }

  render = () => {
    return (
      <Snackbar
        key={this.props.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.open}
        autoHideDuration={this.props.duration || 6000}
        onClose={this.onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.content,
        }}
        message={<span id="message-id">{this.props.message}</span>}
        action={[
          <StyledIconButton
            key="close"
            aria-label="Close"
            color="inherit"
            //className={classes.close}
            onClick={this.onClose}
            size="large">
            <CloseIcon />
          </StyledIconButton>,
        ]}
      />
    );
  }
}

export default (ErrorToast);
