import { request, makePath } from './http';
const API_NAME = 'vehicles';

export function list(clientId, params={}) {
  return request('GET', makePath(API_NAME), {clientId, params});
}

export function update(clientId, vehicleId, data) {
  return request('PATCH', makePath(API_NAME, vehicleId), {clientId, body:data});
}

export function regoLookup(clientId, rego) {
  const params = {rego: rego};
  return request('GET', makePath(API_NAME, 'rego-lookup'), {clientId, params});
}

export function create(clientId, data) {
  return request('POST', makePath(API_NAME), {clientId, body:data});
}

export function remove(clientId, vehicleId) {
  return request('DELETE', makePath(API_NAME, vehicleId), {clientId});
}

export function categories(clientId) {
  return request('GET', makePath(API_NAME, 'categories'), {clientId});
}

export function matchVehicle(clientId, params) {
  return request('GET', makePath(API_NAME, 'match'), {clientId, params});
}

export function search(clientId, params) {
  return request('GET', makePath(API_NAME, 'search'), {clientId, params});
}

export function getTrailerConfiguration(clientId, params) {
  return request('GET', makePath(API_NAME, 'trailer-configuration'), {clientId, params});
}

export function updateTrailerConfiguration(clientId, data) {
  return request('PUT', makePath(API_NAME, 'trailer-configuration'), {clientId, body: data});
}

export function copyPreviousTrailerConfiguration(clientId, data) {
  return request('PUT', makePath(API_NAME, 'copy-configuration'), {clientId, body: data});
}


export function regoCostDetails(clientId, state, charge_code) {
  const params = {state: state, charge_code: charge_code};
  return request('GET', makePath(API_NAME, 'rego-cost-details'), {clientId, params});
}

export function isConfigSelectorEnabled(clientId) {
  return request('GET', makePath(API_NAME, 'configuration-selector', 'enabled'), {clientId});
}