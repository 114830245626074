import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import { themeConfig } from '../../../ThemeProvider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import ReportAction from './ReportAction';


const PREFIX = 'HealthReportTableView';

const classes = {
  root: `${PREFIX}-root`,
  headingBox: `${PREFIX}-headingBox`,
  pageHeading: `${PREFIX}-pageHeading`,
  subText: `${PREFIX}-subText`,
  tableContainer: `${PREFIX}-tableContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  headingSection: `${PREFIX}-headingSection`,
  numberOfIssuesSection: `${PREFIX}-numberOfIssuesSection`,
  tableCell: `${PREFIX}-tableCell`,

};

const StyledPaper = styled(Paper)({
  [`&.${classes.root}`]: {
    width: '100%',
    margin: '0 auto 1rem auto',
    paddingLeft:'8rem',
    paddingRight:'8rem',
    height:'100%',
    overflow: 'auto',
  },

  [`& .${classes.headingBox}`]: {
    paddingTop: '1rem',
    paddingBottom: '0.4rem',
    marginBottom: '0',
    border: 'none',
  },
  [`& .${classes.headingSection}`]: {
    display:'flex'
  },

  [`& .${classes.pageHeading}`]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: themeConfig.palette.primary.main,
    marginBottom: '0.1rem',
    paddingRight:'1rem'
  },

  [`& .${classes.subText}`]: {
    color: '#374151',
    fontSize: '0.9rem',
    marginTop: '5px',
  },

  [`& .${classes.tableContainer}`]: {
    marginBottom: '1rem'
  },
  [`& .${classes.tableCell}`]: {
    padding:'unset',
    paddingLeft:'16px',
    paddingRight:'16px',
  },

  [`& .${classes.numberOfIssuesSection}`]: {
    width:'10rem',
    textAlign: 'center',
    borderRadius: '25px',
    fontSize: '0.9rem',paddingTop:'1px'
  },
});

const actionColumn =(row,refreshHealthDetails, reportName) => {
  return <ReportAction name ={reportName}  refreshHealthDetails={refreshHealthDetails} selectedVehicleId={row.vehicle_id}/>;
};

const columns = [
  {
    id: 'vehicle_id',
    label: 'Prism ID',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'client_vehicle_id',
    label: 'Device/Tracking System ID',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'registration',
    label: 'Registration',
    minWidth: 100,
    align: 'center'
  },
  {
    label: 'Action',
    minWidth: 100,
    align: 'center',
    component: actionColumn
  },

];

const generateIssueColor = (issueColor) => {
  if (issueColor === 'red'){
    return  'rgba(220, 38, 38, .25)';
  }
  if (issueColor === 'amber')
    return 'rgba(175, 171, 59, .25)';
  return  'rgba(5, 150, 105, .25)';
};


const HealthReportTableView = ({
  reportName,
  vehicleInformation,
  title,
  description,
  helpfulText,
  helpfulLink,
  refreshHealthDetails,
  numberOfIssues,
  issuesColor}) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // TODO add a column for editing the content and then automatically updating the table
  return (
    <StyledPaper className={classes.root}>
      <div className={classes.headingBox}>
        <div className={classes.headingSection}>
          <h2 className={classes.pageHeading}>{title}</h2>
          <p style={{backgroundColor:generateIssueColor(issuesColor)}} className={classes.numberOfIssuesSection}>{numberOfIssues} issues</p>

          <HelpIcon style ={{height:'unset', marginLeft:'1rem'}} onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}/>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'right',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{helpfulText}</Typography>
          </Popover>
        </div>
        <div className={classes.subText}>
          <p>{description} <a href={helpfulLink}>read the user guide</a>.</p>
        </div>
      </div>
      <Paper className={classes.tableContainer}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicleInformation.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {column.component ? column.component(row,refreshHealthDetails, reportName) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {vehicleInformation.length>0 && <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={vehicleInformation.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
      </Paper>
    </StyledPaper>
  );
};


HealthReportTableView.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  helpfulText: PropTypes.string.isRequired,
  helpfulLink: PropTypes.string.isRequired,
  numberOfIssues: PropTypes.number.isRequired,
  issuesColor:PropTypes.string.isRequired,
  vehicleInformation: PropTypes.array.isRequired,
  reportName: PropTypes.string.isRequired,
  refreshHealthDetails: PropTypes.func.isRequired
};

export default HealthReportTableView;
