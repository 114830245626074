import { JobsAPI } from '../api';

const JOBS_LOADING   = 'jobs/LOADING';
const JOBS_UPDATED   = 'jobs/UPDATED';
const JOBS_REFRESHED = 'jobs/REFRESHED';
const JOB_DELETED    = 'jobs/DELETED';
const ERROR          = 'jobs/ERROR';
const PARAMS_CHANGED = 'jobs/PARAMS_CHANGED';

const CACHE_TTL = 300000;

const initialState = {
  params: undefined,
  items: [],
  loading: false,
  loadTime: undefined,
};

let lastRequestId = undefined;

const objSignature = obj => obj ? JSON.stringify(obj, Object.keys(obj).sort()) : String(obj);

// Action Creators

export function jobsLoading() {
  return {
    type: JOBS_LOADING,
  };
}

export function jobsUpdated(jobs, offset, complete) {
  return {
    type: JOBS_UPDATED,
    items: jobs,
    offset,
    complete,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

export function paramsChanged(params) {
  return {
    type: PARAMS_CHANGED,
    params,
  };
}

export function jobsRefreshed(jobs) {
  return {
    type: JOBS_REFRESHED,
    items: jobs,
  };
}

export function jobDeleted(jobId) {
  return {
    type: JOB_DELETED,
    jobId: jobId,
  };
}

// Async Action Creators

async function chunkedFetch(params, dispatch, requestId, offset, limit) {
  let done = false;
  while (!done) {
    const response = await JobsAPI.getJobs({...params, offset});
    done = response.jobs.length < response.pagesize;
    done = done || ( (response.offset + response.jobs.length) >= limit );
    const action = jobsUpdated(response.jobs, response.offset, done);
    if (requestId !== lastRequestId) break;
    dispatch(action);
    offset += response.jobs.length;
  }
}

export function getJobs(params, refresh=false, offset=0, limit=100) {
  return (dispatch, getState) => {
    const state = getState().jobs;
    const age = Date.now() - state.loadTime;
    const sameParams = objSignature(params) === objSignature(state.params);
    if (!refresh && sameParams && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      if (!sameParams) dispatch(paramsChanged(params));
      const requestId = Math.random();
      lastRequestId = requestId;
      dispatch(jobsLoading());
      chunkedFetch(
        params,
        dispatch,
        requestId,
        offset,
        limit,
      );
    }
  };
}

export function refreshJob(clientId, jobId) {
  return async (dispatch) => {
    const response = await JobsAPI.getJobs({client_id: clientId, job_id: jobId});
    const action = jobsRefreshed(response.jobs);
    dispatch(action);
  };
}

export function deleteJob(clientId, jobId) {
  return async (dispatch) => {
    await JobsAPI.deleteJob(clientId, jobId);
    const action = jobDeleted(jobId);
    dispatch(action);
  };
}

export function deleteReQueueJob(clientId, jobId) {
  return async (dispatch) => {
    await JobsAPI.deleteReQueueJob(clientId, jobId);
    const action = jobDeleted(jobId);
    dispatch(action);
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case JOBS_LOADING:
    return {
      ...state,
      isLoading: true,
    };

  case JOBS_UPDATED:
  // merge the new data into the list at the specified offset.
  {
    const tailOffset = action.complete ? 0 : undefined;
    const items = [
      ...state.items.slice(0, action.offset),
      ...action.items,
      ...state.items.slice(action.offset + action.items.length, tailOffset)];
    return {
      ...state,
      isLoading: !action.complete,
      loadTime: Date.now(),
      items: items,
    };
  }

  case JOBS_REFRESHED:
  // replace jobs in the current job list
  {
    const map = action.items.reduce((o, j) => {o[j.job_id]=j; return o;}, {});
    const items = state.items.map(j => map[j.job_id] || j);
    return {
      ...state,
      items: items,
    };
  }

  case JOB_DELETED:
  {
    const items = state.items.filter(j => j.job_id !== action.jobId);
    return {
      ...state,
      items: items,
    };
  }

  case PARAMS_CHANGED:
    return {
      ...state,
      params: action.params,
      items: [],
      loadTime: undefined,
      total: undefined,
    };

  default:
    return state;
  }
}
