import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ResponsiveSankey } from '@nivo/sankey';

const PREFIX = 'SankeyChart';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '80rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  }
}));

const SankeyChart = ({ data }) => {
  if (data === null || data === undefined) return null;
  if (data['links'].length ===0 || data['nodes'].length === 0 ) return null;
  return (
    <Root className={classes.root}>
      <ResponsiveSankey
        data={data}
        margin={{ top: 40, right: 200, bottom: 40, left: 200 }}
        align="justify"
        colors={{ scheme: 'category10' }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
        nodeBorderRadius={3}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="horizontal"
        labelPadding={16}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
      />
    </Root>
  );
};

SankeyChart.propTypes = {
  data: PropTypes.object,
};

export default SankeyChart;
