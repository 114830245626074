import React from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EmailSettingsPanel from '../components/EmailSettingsPanel';
import FeaturesSettingsPanel from '../components/FeaturesSettingsPanel';
import FTCReportSettingsPanel from '../components/FTCReportSettingsPanel';
import SchemaSettingsPanel from '../components/SchemaSettingsPanel';
import ServiceTypeSettingsPanel from '../components/ServiceTypeSettingsPanel';
import SourceSettingsPanel from '../components/SourceSettingsPanel';
import BillingSettingsPanel from '../components/BillingSettingsPanel';
import TripSplitSettingsPanel from '../components/TripSplitSettingsPanel';
import TripInclusionSettingsPanel from '../components/TripInclusionSettingsPanel';
import { ClientStore, RefsStore } from '../../../ducks';
import ConOpsSettingsPanel from '../components/ConOpsSettingsPanel';
import config from '../../../config/config';

const PREFIX = 'ClientSettings';

const classes = {
  container: `${PREFIX}-container`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#fafafa',
  }
}));

class ClientSettings extends React.Component {

  static propTypes = {
    client: PropTypes.object,
    isLoading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    schemas: PropTypes.array.isRequired,
    referrers: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    servicePlans: PropTypes.array.isRequired,
    dataPlans: PropTypes.array.isRequired,
    isSuperUser: PropTypes.bool,
  }

  componentDidMount() {
    this.props.dispatch(RefsStore.getSchemas(true));
    this.props.dispatch(RefsStore.getReferrers(true));
    this.props.dispatch(RefsStore.getServiceTypes(true));
    this.props.dispatch(RefsStore.getBillingServicePlans(true));
    this.props.dispatch(RefsStore.getBillingDataPlans(true));
  }

  patchClient = (update) => {
    const { client, dispatch } = this.props;
    dispatch(ClientStore.updateOne(client.client_id, update));
  }

  updateConops = (update) => {
    const { client, dispatch } = this.props;
    dispatch(ClientStore.updateConops(client.client_id, update));
  }

  render() {
    const { schemas, referrers, serviceTypes, servicePlans, dataPlans,  client, isSuperUser } = this.props;
    return (
      <Root className={classes.container}>
        {isSuperUser &&
          <React.Fragment>
            <ServiceTypeSettingsPanel
              client={client}
              referrers={referrers}
              serviceTypes={serviceTypes}
              onSave={this.patchClient}
            />
            <BillingSettingsPanel
              client={client}
              dataProviders={referrers}
              servicePlans={servicePlans}
              dataPlans={dataPlans}
              onSave={this.patchClient}
            />
            <SourceSettingsPanel/>
            <SchemaSettingsPanel
              client={client}
              schemas={schemas}
              onSave={this.patchClient}
            />
            <FeaturesSettingsPanel
              client={client}
              onSave={this.patchClient}
            />
            <FTCReportSettingsPanel
              client={client}
              onSave={this.patchClient}
            />
            {config.isBarton && <ConOpsSettingsPanel
              client={client}
              onSave={this.updateConops}
            />}
          </React.Fragment>
        }
        <EmailSettingsPanel/>
        <TripSplitSettingsPanel
          client={client}
          onSave={this.patchClient}
        />
        <TripInclusionSettingsPanel
          client={client}
          onSave={this.patchClient}
        />
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    client: state.clients.selectedItem,
    isLoading: state.loading.isLoading,
    schemas: state.refs.schemas.items,
    referrers: state.refs.referrers.items,
    serviceTypes: state.refs.serviceTypes.items,
    servicePlans: state.refs.billingServicePlans.items,
    dataPlans: state.refs.billingDataPlans.items,
    isSuperUser: state.auth.isSuperUser, // sysadmin mode on
  };
};

export default connect(mapStateToProps)(
  (ClientSettings)
);
