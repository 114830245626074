import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconCheckCircle from '@mui/icons-material/CheckCircle';
import IconError from '@mui/icons-material/Error';
import IconLoop from '@mui/icons-material/Loop';
import { green, red, blue } from '@mui/material/colors';

const PREFIX = 'StatusSignal';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  loading: `${PREFIX}-loading`,
  hidden: `${PREFIX}-hidden`
};

const Root = styled('div')({
  [`& .${classes.success}`]: {
    color: green[500],
    margin: '15px'
  },
  [`& .${classes.error}`]: {
    color: red[500],
    margin: '15px'
  },
  [`& .${classes.loading}`]: {
    color: blue[500],
    margin: '15px',
    '-webkit-animation': 'icon-spin 2s infinite linear',
    animation: 'icon-spin 2s infinite linear,'
  },
  [`&.${classes.hidden}`]: {
    display: 'inline-block',
    margin: '15px',
  }
});

class StatusSignal extends Component {

  static HIDDEN =  'hidden';
  static LOADING = 'loading';
  static SUCCESS = 'success';
  static ERROR = 'error';

  static propTypes = {
    state: PropTypes.string.isRequired,
    fontSize: PropTypes.number,
    style: PropTypes.object,
  };

  render = () => {
    const style = this.props.style || {};
    const fontSize = this.props.fontSize || '32pt';
    switch (this.props.state) {
    case StatusSignal.LOADING:
      return (<IconLoop className={classes.loading} style={{fontSize, ...style}}/>);
    case StatusSignal.SUCCESS:
      return (<IconCheckCircle className={classes.success} style={{fontSize, ...style}}/>);
    case StatusSignal.ERROR:
      return (<IconError className={classes.error} style={{fontSize, ...style}}/>);
    case StatusSignal.HIDDEN:
    default:
      return <Root className={classes.hidden} style={{width:fontSize, height:fontSize, ...style}}/>;
    }
  }
}

export default (StatusSignal);
