import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ClientTable from '../../../components/admin/ClientTable';
import { connect } from 'react-redux';
import { ClientStore } from '../../../ducks';


const StyledClientTable
 = styled(ClientTable
 )(() => ({}));

class ClientManagement extends React.Component {

  static propTypes = {
    clients: PropTypes.array,
    total: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    return (
      <StyledClientTable
        clients={this.props.clients}
        isLoading={this.props.isLoading}
        total={this.props.total}
        get={ClientStore.list}
        adminMode={false}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    clients: state.clients.items, // client admin list
    total: state.clients.total,
    isLoading: state.clients.isLoading,
  };
};

export default connect(mapStateToProps)(
  (ClientManagement)
);
