import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import { themeConfig } from 'ThemeProvider';

const PREFIX = 'ToolItem';

const classes = {
  item: `${PREFIX}-item`,
  card: `${PREFIX}-card`,
  link: `${PREFIX}-link`,
  disabledCard: `${PREFIX}-disabledCard`,
  image: `${PREFIX}-image`,
  label: `${PREFIX}-label`
};

const Root = styled('div')(() => ({
  [`&.${classes.item}`]: {
    justifyContent: 'center',
    marginTop: '0.5rem',
    marginRight: '1.5rem',
    marginBottom: '0.5rem',
    width: '100px',
  },

  [`& .${classes.card}`]: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    height: '60px',
    width: '100px',
    boxShadow: '0 0 8px 0 rgba(149,163,176,0.25)',
    padding: 0,
    border: '1px solid #cccccc',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    '&:hover': {
      border: `1px solid ${themeConfig.palette.primary.main}`,
      boxShadow: `0 0 5px 0 ${themeConfig.palette.primary.main}`,
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },

  [`& .${classes.disabledCard}`]: {
    background: '#cccccc',
  },

  [`& .${classes.image}`]: {
    height: '2rem',
    display: 'block',
    margin: '0 auto',
  },

  [`& .${classes.label}`]: {
    paddingTop: '0.5rem',
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '#374151',
    width: '100%',
    textAlign: 'center',
    margin: 0,
  }
}));

class ToolItem extends React.Component {

  static propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }

  render = () => {
    const { image, to, label} = this.props;
    return (
      <Root className={classes.item}>
        <Link className={classes.link} to={to}>
          <Card className={classes.card}>
            <img src={image} alt="tool-img" className={classes.image}/>
          </Card>
          <p className={classes.label}>
            {label}
          </p>
        </Link>
      </Root>
    );
  };
}

export default (ToolItem);
