import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class FileDownload extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    onDownloadComplete: PropTypes.func.isRequired,
    method: PropTypes.string,
  }

  constructor() {
    super();
    this.formRef= React.createRef();
  }

  componentDidMount() {
    this.formRef.current.submit();
    this.props.onDownloadComplete();
  }

  render() {
    const { url, method='GET' } = this.props;
    const query = url.substring(url.indexOf('?')+1);
    const vars = queryString.parse(query);
    return (
      <form
        ref={this.formRef}
        action={url}
        method={method}
      >
        {Object.keys(vars).map((name, index) => (
          <input
            key={index}
            name={name}
            type="hidden"
            value={vars[name]}
          />
        ))}
      </form>
    );
  }
}


export default FileDownload;
