import React from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { dateToShortMonth } from 'helpers/date';

const PREFIX = 'MonthlySelectionDrawer';
const drawerOpenWidth = 300;
const drawerClosedWidth = 50;

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  menu: `${PREFIX}-menu`,
  menuClosed: `${PREFIX}-menuClosed`,
};

const StyledContainer = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    paddingTop: '1px',
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#333333',
    height: '100%',
  },

  [`& .${classes.title}`]: {
    marginLeft: '1rem',
    fontSize: '1rem',
  },

  [`& .${classes.drawerPaper}`]: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 'auto',
  },

  [`& .${classes.drawerPaperClose}`]: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerClosedWidth - drawerOpenWidth,
  },

  [`& .${classes.menu}`]: {
    padding: 0,
    marginLeft: 0,
    maxHeight: '600px',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuClosed}`]: {
    marginLeft: drawerClosedWidth - drawerOpenWidth,
    padding: '50px',
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`&.${classes.content}`]: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: 0,
  },
}));

export default function MonthlySelectionDrawer({ setSelectedMonth }) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const getMonths = () => {
    const currentDate = new Date();
    // Start of RUC trial
    const targetDate = new Date('April 1, 2023');
    const dates = [];

    while (currentDate >= targetDate) {
      const month = dateToShortMonth(currentDate);
      const year = currentDate.getFullYear();
      const formattedDate = `${month} ${year}`;

      dates.push(formattedDate);
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
    return dates;
  };
  const months = getMonths();

  return (
    <StyledContainer>
      <Paper>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !drawerOpen && classes.drawerPaperClose
            ),
          }}
        >
          <div className={classes.toolbar}>
            <span className={classes.title}>Monthly vehicle configurations</span>

            {drawerOpen ? (
              <IconButton onClick={() => setDrawerOpen(false)} size="large">
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => setDrawerOpen(true)} size="large">
                <ChevronRightIcon />
              </IconButton>
            )}
          </div>

          <List
            component="nav"
            alignitems="flex-start"
            disablePadding={true}
            className={classNames(
              classes.menu,
              !drawerOpen && classes.menuClosed
            )}
          >
            <Divider />
            {months.map((item, index) => (
              <ListItemButton
                alignItems="flex-start"
                divider={true}
                key={index}
                onClick={() => setSelectedMonth(months[index])}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      </Paper>
    </StyledContainer>
  );
}

MonthlySelectionDrawer.propTypes = {
  setSelectedMonth: PropTypes.func,
};
