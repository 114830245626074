import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ClientAPI } from '../../../api';
import {LoadingStore} from '../../../ducks';
import Report from '../containers/Report';
import HealthReportTableView from '../components/HealthReportTableView';
import LeftNav from '../../../components/LeftNav';



const AccountHealth = ({...props}) => {
  // const client = useSelector((state) => state.clients.selectedItem);
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const dispatch = useDispatch();

  const makeHealthReportList = (healthReports) =>{
    return healthReports.map(r=> {
      return {
        path:'/account_health/' + r.key,
        label:  r.title,
        content: function renderReport(props) {
          return (
            <Report
              report={r}
              ViewComponent={HealthReportTableView}
              {...props}
            />
          );
        },
      };
    });
  };

  const [healthReports, setHealthReports] = useState([]);

  const getHealthReports = async () =>{
    dispatch(LoadingStore.loading(true    ));
    try {
      const response = await ClientAPI.healthDetailsOverview(clientId);
      const healthReportList = makeHealthReportList(response.message.data);
      setHealthReports(healthReportList);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  useEffect(() => {
    getHealthReports();
  },[clientId]);// eslint-disable-line

  return (<div style={{ height: '100%' }}>
    <LeftNav
      title='Account Health Report'
      pages={healthReports}
      {...props}
    />
  </div>);
};


export default AccountHealth;

