import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

const PREFIX = 'CategoryFilter';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  select: `${PREFIX}-select`,
  item: `${PREFIX}-item`,
  rightMargin: `${PREFIX}-rightMargin`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    padding: 0,
  },
  [`& .${classes.label}`]: {
    marginRight: '0.5rem',
    fontSize: '0.7rem',
    fontWeight: 500,
  },
  [`& .${classes.select}`]: {
    minWidth: '7rem',
    fontSize: '0.7rem',
    height: '2rem',
  },
  [`& .${classes.item}`]: {
    fontSize: '0.7rem',
  },
  [`& .${classes.rightMargin}`]: {
    marginRight: '1rem',
  },
});

// Produce nice display names from raw api fields
export const formatFieldName = (value) => {
  let formatted = value.replace(/_/g, ' ');
  formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
  return formatted;
};

const CategoryFilter = ({ value, valuesList, onFilterChanged, label=null, rightMargin=false }) => {

  if (valuesList === undefined || valuesList.length === 0) return null;
  return (
    <Root className={classes.root}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <FormControl>
        <Select
          variant="outlined"
          className={classes.select}
          value={value}
          onChange={(e) => onFilterChanged(e.target.value)}
        >
          {valuesList.map(item => (
            <MenuItem key={item} value={item} className={classes.item}>{formatFieldName(item)}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {rightMargin && <div className={classes.rightMargin} />}
    </Root>
  );
};

CategoryFilter.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  valuesList: PropTypes.array,
  onFilterChanged: PropTypes.func.isRequired,
  label: PropTypes.string,
  rightMargin: PropTypes.bool,
};

CategoryFilter.displayName = 'CategoryFilter';

export default CategoryFilter;
