import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { prettyLocalDatetime } from '../helpers/date';

const PREFIX = 'DateRangeControl';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  picker: `${PREFIX}-picker`,
  dateRangePicker: `${PREFIX}-dateRangePicker`,
  text: `${PREFIX}-text`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    margin: 0,
    backgroundColor: 'white'
  },

  [`& .${classes.button}`]: {
    color: theme.palette.text.primary,
    borderColor:'#cccccc',
    height: '36px',
    padding: '0.4rem 0.5rem',
    '&:focus': {
      border: '2px solid #4f45e4',
    },
    '&:focus-within': {
      border: '2px solid #4f45e4',
    },
  },

  [`& .${classes.picker}`]: {
    position: 'absolute',
    top: 38,
    left: 0,
  },

  [`& .${classes.dateRangePicker}`]: {
    zIndex: 101,
    justifyContent: 'center',
  },

  [`& .${classes.text}`]: {
    margin: '0 0 0 0.3rem',
    fontSize: '0.7rem',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
  }
}));

const DateRangeControl = ({ onDateRangeChanged, selectedDateRange }) => {

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  let buttonText = 'No date range selected';
  if (selectedDateRange) {
    buttonText = `${prettyLocalDatetime(selectedDateRange.startDate, 'll')} to ${prettyLocalDatetime(selectedDateRange.endDate, 'll')}`;
  }

  return (
    <Root className={classes.root}>
      <Button className={classes.button} variant="outlined" disableRipple onClick={toggleOpen}>
        <DateRangeIcon fontSize="small" />
        <p className={classes.text}>{buttonText}</p>
      </Button>
      <div className={classes.picker}>
        <DateRangePicker
          open={open}
          toggle={toggleOpen}
          onChange={(range) => onDateRangeChanged(range)}
          wrapperClassName={classes.dateRangePicker}
        />
      </div>
    </Root>
  );
};

DateRangeControl.propTypes = {
  onDateRangeChanged: PropTypes.func,
  selectedDateRange: PropTypes.object,
};

export default DateRangeControl;
