import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/CloudUpload';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchBar from './SearchBar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewCardIcon from '@mui/icons-material/ViewModule';
import { CSVLink } from 'react-csv';
import ActionButton, { FabActionButton } from '../../../components/ActionButton';
import config from '../../../config/config';

const PREFIX = 'Toolbar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarGroup: `${PREFIX}-toolbarGroup`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  button: `${PREFIX}-button`,
  buttonIcon: `${PREFIX}-buttonIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.toolbar}`]: {
    flex: '0 0 80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
    // marginLeft: '40px',
    // marginRight: '40px',
    color: '#333333',
  },

  [`& .${classes.toolbarGroup}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.title}`]: {
    display: 'inline-block',
    margin: 0,
    marginRight: '0.5rem',
    fontSize: '1.2rem',
  },

  [`& .${classes.subtitle}`]: {
    margin: 0,
    display: 'inline-block',
    fontSize: '0.8rem',
  },

  [`& .${classes.button}`]: {
    float: 'right',
    marginLeft: '5px',
    fontWeight: 900,
  },

  [`& .${classes.buttonIcon}`]: {
    marginLeft: theme.spacing(),
  }
}));

const CheckboxFilter = ({label, checked, onChange}) =>
  (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>
        }
        label={label}
      />
    </FormGroup>
  );

CheckboxFilter.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const subtitleText = (showCount, totalCount) => {
  return `${showCount} active of ${totalCount}`;
};

const SortSelect = ({ options, selected, onChange }) => (
  <FormControl
    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    size="small"
  >
    <span>Sort by</span>
    <Select
      value={selected}
      onChange={(event) => onChange(event.target.value)}
      sx={{ backgroundColor: 'white', marginLeft: '10px' }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SortSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

class Toolbar extends React.PureComponent {

  static propTypes = {
    showCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortOptions: PropTypes.array.isRequired,
    sortSelected: PropTypes.string.isRequired,
    sortEnabled: PropTypes.bool.isRequired,
    onSortChanged: PropTypes.func.isRequired,
    incompleteChecked: PropTypes.bool.isRequired,
    onIncompleteChanged: PropTypes.func.isRequired,
    disabledChecked: PropTypes.bool.isRequired,
    onDisabledChanged: PropTypes.func.isRequired,
    onSearchChanged: PropTypes.func.isRequired,
    onNewVehicle: PropTypes.func.isRequired,
    onUploadVehicle: PropTypes.func.isRequired,
    onViewChanged: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    clientId: PropTypes.string,
    profile: PropTypes.object,
    isSuperUser: PropTypes.bool,
  };

  isClientAdmin = () => {
    const { profile, clientId, isSuperUser } = this.props;
    if (isSuperUser) return true;
    return (
      profile &&
      clientId &&
      profile.roles[clientId] &&
      profile.roles[clientId].includes('Client Admin')
    );
  }

  renderToggleGroup = () => {
    const { view, onViewChanged } = this.props;
    return (
      <ToggleButtonGroup value={view} exclusive onChange={(e,v) => v && onViewChanged(v)}>
        <ToggleButton value="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value="card">
          <ViewCardIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

 csvFormat = (data, fields = ['client_vehicle_id'], json_fields=['registration_cost_details']) => {
   return data.map(row => {
     row = Object.assign({}, row);

     if (!config.isBarton) {
       const fieldsToIgnore = ['con_ops', 'ruc_vehicle_type'];
       fieldsToIgnore.forEach(field => delete row[field]);
     }
     fields.forEach(field => row[field] = 'id::' + row[field]);
     json_fields.forEach(field => {
       if(row[field]) {
         row[field] = JSON.stringify(row[field], function(key, value) {
           //   Ignore charge_code as it has its own field and total can mess with the calculation
           if (key === 'charge_code' || key === 'total') return undefined;
           return value;
         }).replaceAll('"', '""');
       }
     });

     return row;
   });
 };

  renderActions = () => {
    const {
      data,
      fileName,
    } = this.props;

    return (
      <React.Fragment>

        <CSVLink data={this.csvFormat(data)} filename={`${fileName}.csv`}>
          <ActionButton
            variant="contained"
            tooltip="Download complete vehicle register"
            className={classes.button}
            color="primary"
            Icon={DownloadIcon}
            label="Download"
          />
        </CSVLink>

      </React.Fragment>
    );
  }
  renderAdminActions = () => {
    const {
      onNewVehicle,
      onUploadVehicle,
    } = this.props;

    return (
      <React.Fragment>

        <ActionButton
          variant="contained"
          onClick={onUploadVehicle}
          tooltip="Upload new vehicles"
          className={classes.button}
          color="primary"
          Icon={UploadIcon}
          label="Upload"
        />

        <FabActionButton
          size="small"
          onClick={onNewVehicle}
          tooltip="Add new vehicle"
          className={classes.button}
          color="secondary"
          Icon={AddIcon}
        />

      </React.Fragment>
    );
  }

  render() {
    const {
      showCount,
      totalCount,
      sortOptions,
      sortSelected,
      sortEnabled,
      onSortChanged,
      incompleteChecked,
      onIncompleteChanged,
      disabledChecked,
      onDisabledChanged,
      onSearchChanged,
    } = this.props;
    const isClientAdmin = this.isClientAdmin();

    return (
      <Root className={classes.toolbar}>
        <div className={classes.toolbarGroup}>
          <h1 className={classes.title}>Vehicles</h1>
          <h2 className={classes.subtitle}>
            {subtitleText(showCount, totalCount)}
          </h2>
        </div>
        <div className={classes.toolbarGroup}>
          <CheckboxFilter label="Incomplete data" checked={incompleteChecked} onChange={onIncompleteChanged} />
          <CheckboxFilter label="Show inactive" checked={disabledChecked} onChange={onDisabledChanged} />
          <SearchBar placeholder="Find vehicles..." onChange={onSearchChanged} />
          {sortEnabled &&
            <SortSelect options={sortOptions} selected={sortSelected} onChange={onSortChanged}/>
          }
        </div>
        {/* Don't give grid view as an option for long register to stop lag */}
        {totalCount < 1000 && (
          <div className={classes.toolbarGroup}>{this.renderToggleGroup()}</div>
        )}
        <div className={classes.toolbarGroup}>
          {this.renderActions()}
          {isClientAdmin && this.renderAdminActions()}
        </div>
      </Root>
    );
  }
}

export default (Toolbar);
