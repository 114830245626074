import { ReportFilesAPI } from '../api';

const QUERY_REPORTS_LOADING = 'reportFiles/queryReports/LOADING';
const QUERY_REPORTS_UPDATED = 'reportFiles/queryReports/UPDATED';
const QUERY_REPORTS_ERROR   = 'reportFiles/queryReports/ERROR';

const CACHE_TTL = 300000;

const initialState = {
  queryReports: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
};

// Action Creators

export function queryReportsLoading() {
  return {
    type: QUERY_REPORTS_LOADING,
  };
}

export function queryReportsUpdated(response) {
  const items = response.payload;
  return {
    type: QUERY_REPORTS_UPDATED,
    items,
  };
}

export function error(error) {
  return {
    type: QUERY_REPORTS_ERROR,
    error,
  };
}

// Async Action Creators

export function getQueryReports(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().reportFiles.queryReports;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(queryReportsLoading());
      return ReportFilesAPI.getQueryReports()
        .then(response => dispatch(queryReportsUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case QUERY_REPORTS_LOADING:
    return {
      ...state,
      queryReports: {
        ...state.queryReports,
        isLoading: true,
      }
    };

  case QUERY_REPORTS_UPDATED:
    return {
      ...state,
      queryReports: {
        ...state.queryReports,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  default:
    return state;
  }
}
