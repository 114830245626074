import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TsAndCs from '../../../components/TsAndCs';
import { currentTsAndCs } from '../../../components/TsAndCs';
import Button from '@mui/material/Button';
import { AuthStore } from '../../../ducks';
import { withRouter } from 'react-router';
import ScrollDownArrow from '../../../components/ScrollDownArrow';

const PREFIX = 'TermsAndConditions';

const classes = {
  container: `${PREFIX}-container`,
  frame: `${PREFIX}-frame`,
  arrow: `${PREFIX}-arrow`,
  button: `${PREFIX}-button`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    background: '#2b247e',
    height: '100%',
    width: '100vw',
  },

  [`& .${classes.frame}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 100px 100px 100px',
    overflowY: 'scroll',
    background: '#ffffff',
    margin: 40,
    alignSelf: 'center',
    maxWidth: 1000,
  },

  [`& .${classes.arrow}`]: {
    position: 'relative',
    top: 150,
    right: 50,
  },

  [`& .${classes.button}`]: {
    marginTop: 40,
    alignSelf: 'center',
  }
}));

class TermsAndConditions extends React.Component {

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    acceptedTsAndCs: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  }

  /*
   * we want the user to be able to return here after accepting,
   * to re-read the document, so we only redirect if we have recorded
   * their accept action in state.accepted.  We also don't
   * redirect them until the acceptedTsAndCs prop updates via the store, Otherwise
   * they will be redirected back here by the withRole hoc.
   */

  state = {
    declined: false,
    accepted: false,
  }

  onAccept = () => {
    this.props.dispatch(AuthStore.acceptTsAndCs(currentTsAndCs));
    this.setState({accepted: true});
  }

  onDecline = () => {
    this.setState({declined: true});
  }

  render() {
    const accepted = this.props.acceptedTsAndCs === currentTsAndCs;
    if (!this.props.isAuthenticated) return (<Redirect to="/login"/>);
    if (this.state.declined) return (<Redirect to="/logout"/>);
    if (this.state.accepted && accepted) return (<Redirect to="/prism"/>);
    return (
      <Root className={classes.container}>
        <div className={classes.frame}>
          <div className={classes.arrow}>
            <ScrollDownArrow/>
          </div>
          <TsAndCs/>
          {!accepted && [
            <Button key="accept" variant="contained" color="primary" size="large" onClick={this.onAccept} className={classes.button}>Accept and Continue</Button>,
            <Button
              key="decline"
              variant="contained"
              size="large"
              onClick={this.onDecline}
              className={classes.button}>Decline</Button>
          ]}
        </div>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    acceptedTsAndCs: state.auth.acceptedTsAndCs,
  };
};

export default withRouter(connect(mapStateToProps)((TermsAndConditions)));
