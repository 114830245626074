import { request, makePath } from './http';
const API_NAME = 'clients';

export function list(params={}) {
  return request('GET', makePath(API_NAME), {params});
}

export function listAll(params={}) {
  return request('GET', makePath(API_NAME, 'all'), {params});
}

export function update(clientId, data) {
  return request('PATCH', makePath(API_NAME), {clientId, body:data});
}

export function create(clientId, data) {
  return request('POST', makePath(API_NAME), {clientId, body:data});
}

export function signup(data) {
  return request('POST', makePath(API_NAME, 'signup'), {body: data});
}

export function signupConfirmation(token, data={}) {
  return request('POST', makePath(API_NAME, 'signup', token), {body: data});
}

export function health(clientId){
  return request('GET', makePath(API_NAME,'health'), {clientId});
}

export function healthDetailsOverview(clientId){
  return request('GET', makePath(API_NAME,'health/overview'), {clientId});
}

export function healthDetails(clientId, health_option){
  return request('GET', makePath(API_NAME,'health/details'), {clientId, params: {health_option}});
}

export function updateConops(clientId, data) {
  return request('PATCH', makePath(API_NAME, 'conops'), {clientId, body:data});
}