import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  formatValue,
  fieldNameToLabel
} from './helpers.js';


const PREFIX = 'ChartTooltip';

const classes = {
  holder: `${PREFIX}-holder`,
  header: `${PREFIX}-header`,
  text: `${PREFIX}-text`
};

const Root = styled('div')(() => ({
  [`&.${classes.holder}`]: {
    margin: '0',
    padding: '5px',
    backgroundColor: '#fff',
    border: '1px solid #e2e2e2',
  },

  [`& .${classes.header}`]: {
    width: '100%',
    paddingTop: '0',
    marginTop: '0',
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#374151',
    textAlign: 'left',
  },

  [`& .${classes.text}`]: {
    width: '100%',
    padding: '0',
    marginBottom: '0px',
    textAlign: 'left',
    fontSize: '0.7rem',
    fontWeight: 600,
    color: '#374151',
  }
}));


// This is a generic tooltip component. It requires explicit passing of the label and data and
// is for presentation only. Most the time it will be used as a base for a tooltip design for
// the specifc chart which will require additional data structuring.

export const ChartTooltip = ({heading, data, units}) => {
  return (
    <Root className={classes.holder}>
      <h2 className={classes.header}>{formatValue(heading)}</h2>
      {data.map((d, i) => {
        const unitsText = typeof(units) === 'undefined' ? '' : units;
        const itemText = `${fieldNameToLabel(d.name)}: ${formatValue(d.value)}${unitsText}`;
        return (
          <p className={classes.text} key={i}>{itemText}</p>
        );
      })}
    </Root>
  );
};

ChartTooltip.propTypes = {
  units: PropTypes.string,
  heading: PropTypes.string,
  data: PropTypes.array,
};

export default (ChartTooltip);
