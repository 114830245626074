import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CreateToolbar from 'modules/ftcreports/components/CreateToolbar';
import EmptyBoxIcon from '../../../assets/icons/empty-box-icon.svg';
import FileDownload from 'modules/ftcreports/components/FileDownload';
import NewFileReportDialog from 'modules/ftcreports/components/NewFileReportDialog';
import ReportFileCard from 'modules/ftcreports/components/ReportFileCard';
import { RucReports } from 'api';
import moment from 'moment';
import { months } from '../../../helpers/date';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import StatusSnackbar from '../components/StatusSnackbar';

export default function Invoices() {
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const [reports, setReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState();
  const [emailSnackbarOpen, setEmailSnackbarOpen] = useState(false);
  const [genSuccessSnackbarOpen, setGenSuccessSnackbarOpen] = useState(false);
  const [genFailedSnackbarOpen, setGenFailedSnackbarOpen] = useState(false);
  const [noReportsFound, setNoReportsFound] = useState(false);
  const [showNewFileReportDialog, setShowNewFileReportDialog] = useState(false);
  const REPORTING_DATE = 5;
  const fileFormats = [
    { format: 'pdf', label: 'PDF' },
    { format: 'excel', label: 'Excel' },
  ];

  const setReportLoading = (report, loading) => {
    if (!report.name) return;
    if (loading) {
      setLoadingReports((loadingReports) => [...loadingReports, report.name]);
    } else {
      setLoadingReports((loadingReports) =>
        loadingReports.filter((name) => name !== report.name)
      );
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onRegenerate = async (report) => {
    setReportLoading(report, true);
    const { month, year, format } = report;
    await RucReports.generateReport(clientId, {
      month,
      year,
      doc_format: format,
    });
    let oldReport = {
      year: report.year,
      month: report.month,
      format: report.format,
      created: report.created,
    };
    let reports;
    let retryCount = 0;
    const maxRetryCount = 60;
    do {
      await delay(1000);
      reports = (await RucReports.getReports(clientId)).payload;
      retryCount++;
    } while (
      reports.filter(
        (report) =>
          report.year === oldReport.year &&
          report.month === oldReport.month &&
          report.format === oldReport.format &&
          report.created !== oldReport.created
      ).length === 0 &&
      retryCount < maxRetryCount
    );
    setNoReportsFound(reports.length === 0);
    setReports(reports);
    setReportLoading(report, false);
    if (retryCount >= maxRetryCount) {
      setGenFailedSnackbarOpen(true);
    } else {
      setGenSuccessSnackbarOpen(true);
    }
  };

  const onDownload = (report) => {
    const params = {
      content_disposition: `attachment; filename="${report.name}"`,
      content_type: report.content_type,
    };
    setReportLoading(report, true);
    RucReports.downloadReport(clientId, report.name, params).then(
      (response) => {
        setReportLoading(report, false);
        setDownloadUrl(response.payload.location);
      }
    );
  };

  const onEmail = (report) => {
    setReportLoading(report, true);
    RucReports.sendReport(clientId, {
      month: report.month,
      year: report.year,
      name: report.name,
      doc_format: report.format,
    }).then(() => {
      setReportLoading(report, false);
      setEmailSnackbarOpen(true);
    });
  };

  const group_reports_by_month = () => {
    const grouped = {};
    reports.forEach((report) => {
      const key = `${report.month}-${report.year}`;
      if (!(key in grouped)) {
        grouped[key] = {};
      }
      grouped[key][report.format] = report;
    });
    return grouped;
  };

  const load_reports = () =>
    RucReports.getReports(clientId)
      .then((reports) => {
        setNoReportsFound(reports.payload.length === 0);
        setReports(reports.payload);
      })
      .catch((err) => console.log(err));

  useEffect(() => {
    RucReports.getReports(clientId).then((reports) => {
      setNoReportsFound(reports.payload.length === 0);
      setReports(reports.payload);
    });
  }, [setReports, setNoReportsFound, clientId]);

  const EmptyStateDiv = styled('div')({
    width: '100%',
    paddingTop: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  });

  const nextReportingDate = () => {
    let reportDate = moment();
    if (!isSuperUser && reportDate.date() < REPORTING_DATE) {
      reportDate = reportDate.subtract({ months: 1 });
    }
    return reportDate.endOf('month');
  };

  const reportPendingMessage = () => {
    let reportDate = nextReportingDate();
    const availableDate = reportDate
      .clone()
      .add({ months: 1 })
      .date(REPORTING_DATE);
    return `Reports for ${reportDate.format(
      'MMMM YYYY'
    )} will be available from ${availableDate.format('D MMM YYYY')}.`;
  };

  const previousMonth = (year, month) => {
    month -= 1;
    if (!month) {
      year -= 1;
      month = 12;
    }
    return [year, month];
  };

  const getAllReportingMonths = (dateRange) => {
    if (dateRange.min === null || dateRange.max === null) return [];
    const lastAvailableReportDate = nextReportingDate().subtract({ months: 1 });
    const lastYear = lastAvailableReportDate.year();
    const lastMonth = lastAvailableReportDate.month() + 1;
    const [year0, month0] = dateRange.min.split('-').map((v) => Number(v));
    const [year1, month1] = dateRange.max.split('-').map((v) => Number(v));
    let year = year1;
    let month = month1;
    // don't offer to create reports for the incomplete reporting period.
    if (year > lastYear || (year === lastYear && month > lastMonth)) {
      year = lastYear;
      month = lastMonth;
    }
    let dates = [];

    while (year > year0 || (year === year0 && month >= month0)) {
      dates.push({
        month: month,
        year: year,
        value: `${year}-${month}`,
        label: `${months[month - 1]} ${year}`,
      });
      [year, month] = previousMonth(year, month);
    }
    return dates;
  };

  const createNewReport = (year, month, months, format = 'pdf') => {
    setShowNewFileReportDialog(false);
    const matched = reports.filter(
      (report) =>
        report.year === year &&
        report.month === month &&
        report.format === format
    );
    const created = matched.length > 0 ? matched[0].created : null;
    const report = { year, month, format, created };
    onRegenerate(report);
  };

  const build_report = (report) => {
    return (
      <div>
        {loadingReports.includes(report.name) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px',
              width: '300px',
              height: '128px',
            }}
          >
            <CircularProgress />
            <p style={{ paddingLeft: '10px' }}>Processing...</p>
          </div>
        ) : (
          <ReportFileCard
            report={report}
            regenerate={() => onRegenerate(report)}
            openReport={() => onDownload(report)}
            sendReport={onEmail}
          />
        )}
      </div>
    );
  };

  const ReportsView = () => {
    const month_abbr = (month) =>
      moment()
        .month(month - 1)
        .format('MMMM');
    const grouped_reports = group_reports_by_month();

    return Object.keys(grouped_reports).map((month_and_year, index) => {
      const month = month_and_year.split('-')[0];
      const year = month_and_year.split('-')[1];
      const reports = grouped_reports[month_and_year];
      const pdf = reports.pdf;
      const excel = reports.excel;
      return (
        <div key={index}>
          <h1>
            {month_abbr(month)} {year}
          </h1>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {pdf && build_report(pdf)}
            {excel && build_report(excel)}
          </div>
        </div>
      );
    });
  };

  return (
    <Container>
      <CreateToolbar
        message={reportPendingMessage()}
        toolTip="Create new report"
        onNew={() => setShowNewFileReportDialog(true)}
        onRefresh={load_reports}
      />
      {noReportsFound ? (
        <EmptyStateDiv>
          <img src={EmptyBoxIcon} alt="Empty box icon" width={200} />
          <p>No reports found</p>
        </EmptyStateDiv>
      ) : (
        <div style={{ paddingBottom: '20px' }}>
          <ReportsView></ReportsView>
        </div>
      )}
      {downloadUrl && (
        <FileDownload url={downloadUrl} onDownloadComplete={setDownloadUrl} />
      )}
      <StatusSnackbar
        message="Report sent!"
        severity="success"
        open={emailSnackbarOpen}
        onClose={() => setEmailSnackbarOpen(false)}
      />
      <StatusSnackbar
        message="Report regenerated!"
        severity="success"
        open={genSuccessSnackbarOpen}
        onClose={() => setGenSuccessSnackbarOpen(false)}
      />
      <StatusSnackbar
        message="Report failed to generate!"
        severity="error"
        open={genFailedSnackbarOpen}
        onClose={() => setGenFailedSnackbarOpen(false)}
      />
      <NewFileReportDialog
        show={showNewFileReportDialog}
        onAction={createNewReport}
        onCancel={() => setShowNewFileReportDialog(false)}
        reportingMonths={getAllReportingMonths({
          min: '2023-5',
          max: '2024-12',
        })}
        formats={fileFormats}
        hideAdvanced={true}
      />
    </Container>
  );
}
