import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CopyIcon from '@mui/icons-material/FileCopy';

const PREFIX = 'ClientIdControl';

const classes = {
  clientId: `${PREFIX}-clientId`,
  reveal: `${PREFIX}-reveal`
};

const Root = styled('div')(() => ({
  [`&.${classes.clientId}`]: {
    display: 'flex',
    alignItems: 'flex-begin',
  },

  [`& .${classes.reveal}`]: {
    transition: 'all .2s ease-in-out',
    transform: 'scale(0.6)',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  }
}));

function selectText(node) {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}


class ClientIdControl extends React.PureComponent {

  static propTypes = {
    clientId: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  onCopy = () => {
    selectText(this.ref.current);
    document.execCommand('copy');
    clearSelection();
  }

  render() {
    const {  clientId } = this.props;
    return (
      <Root className={classes.clientId}>
        Client ID: <div ref={this.ref}>{clientId}</div>
        <div className={classes.reveal} onClick={this.onCopy}>
          <CopyIcon fontSize="small"/>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)(
  (ClientIdControl)
);
