import React from 'react';
import { styled } from '@mui/material/styles'; import PropTypes from 'prop-types';
const PREFIX = 'NumberedSection';

const classes = {
  indent: `${PREFIX}-indent`,
  section: `${PREFIX}-section`,
  number: `${PREFIX}-number`,
  content: `${PREFIX}-content`
};

const Root = styled('section')(() => ({
  [`& .${classes.indent}`]: {
    paddingLeft: 40,
  },

  [`&.${classes.section}`]: {
    display: 'flex',
    marginTop: '1em',
  },

  [`& .${classes.number}`]: {
    flex: '0 0 60px',
  },

  [`& .${classes.content}`]: {
    flex: 1,
  }
}));

/*
 * Supports 3 types of sections:
 * major : label is 1. << this is the default if minor or para is not specified
 * minor : label is 1.1
 * para : label is (a)
 */
const NumberedSection = (({ children, counter, minor, para}) => {
  let label;
  if (minor) {
    label = <span>{counter.major}.{++counter.minor}</span>;
    counter.para = 0;
  } else if (para) {
    label = <span>({String.fromCharCode(96 + ++counter.para)})</span>;
  } else {
    label = <span><b>{++counter.major}.</b></span>;
    counter.minor = 0;
    counter.para = 0;
  }
  return (
    <Root className={classes.section}>
      <span className={classes.number}>{label}</span>
      <span className={classes.content}>{children}</span>
    </Root>
  );
});

NumberedSection.propTypes = {
  children: PropTypes.node,
  counter: PropTypes.number,
  minor: PropTypes.number,
  para: PropTypes.number

};

export default NumberedSection;
