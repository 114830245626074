import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
const PREFIX = 'Section';

const classes = {
  root: `${PREFIX}-root`,
  fullHeight: `${PREFIX}-fullHeight`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    padding: '1rem',
    marginBottom: '1rem',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.gray['300']}`,
    borderRadius: '3px',
    boxShadow: `2px 2px 5px 1px ${theme.palette.gray['100']}`,
    width: '100%',
    overflowX:'auto',
    position:'relative',
  },

  [`& .${classes.fullHeight}`]: {
    minHeight: 'calc(100vh - 20rem)',
  }
}));

const Section = ({ fullHeight=false, children }) => {


  if (fullHeight === true) {
    return (
      <Root className={classes.root}>
        <div className={classes.fullHeight}>
          {children}
        </div>
      </Root>
    );
  }

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

Section.propTypes = {
  fullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
};

export default Section;
