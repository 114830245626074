import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthStore } from '../../../ducks';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Logout extends Component
{
  static propTypes = {
    history: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.dispatch(AuthStore.logoutUser());
    }
  }

  render() {
    if (!this.props.isAuthenticated) return (<Redirect to="/login"/>);
    return null;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Logout);
