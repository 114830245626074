import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { formatValue, formatNumber } from '../../../components/analytics/helpers.js';
import { prettyLocalDatetime } from '../../../helpers/date';

const PREFIX = 'ProfileTooltip';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  text: `${PREFIX}-text`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    margin: '0',
    padding: '8px',
    backgroundColor: '#fff',
    border: '1px solid #e2e2e2',
    zIndex: '999',
  },
  [`& .${classes.header}`]: {
    width: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: '4px',
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: '700',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
    color: '#3c424a',
  },
  [`& .${classes.text}`]: {
    width: '100%',
    fontSize: '12px',
    padding: 0,
    margin: 0,
    color: '#3c424a',
    textAlign: 'left',
  },
});

const ProfileTooltip = ({ payload, active }) => {

  if (!active || payload === null) {
    return null;
  }
  const data = payload[0].payload;
  const heading = 'Point ' + data.point_number;
  return (
    <Root className={classes.root}>
      <h2 className={classes.header}>{formatValue(heading)}</h2>
      <p className={classes.text}>Timestamp: {prettyLocalDatetime(data.timestamp)}</p>
      <p className={classes.text}>Interval distance: {formatNumber(parseFloat(data.distance_m).toFixed(1))} meters</p>
      <p className={classes.text}>Travel time: {formatNumber(parseFloat(data.duration_s).toFixed(0))} seconds</p>
      <p className={classes.text}>Speed over interval: {formatNumber(parseFloat(data.speed_section_kmh).toFixed(0))} km/h</p>
    </Root>
  );
};

ProfileTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
};

export default ProfileTooltip;
