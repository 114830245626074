import { ARTICULATED_TRUCK, RIGID_TRUCK, articulatedTypes, rigidTypes } from '../models/VehicleTypes';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  styled,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import { VehicleAPI } from 'api';
import classes from '../../../assets/trailers/classes.jpg';
import { useSelector } from 'react-redux';
import Confirmation from 'components/Confirmation';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export default function VehicleList({ selectedMonth }) {
  const clientId = useSelector((state) => state.clients.selectedItemId);
  /*
  Vehicle
  { 
    id: 1, 
    client_id: 'x1', 
    client_vehicle_id: 'v1', 
    month_year: Date,
    asset_charge_codes: ['TD2'], 
    ruc_vehicle_type: 'RIGID_TRUCK', 
    registration: 'ABC123' 
  }
  */
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setLoading(true);
    const params = { month: selectedMonth };
    VehicleAPI.getTrailerConfiguration(clientId, params).then((result) => {
      setVehicles(result.payload);
      setLoading(false);
    });
  }, [setVehicles, setLoading, clientId, selectedMonth]);

  const handleChange = (vehicle, event) => {
    console.log(event.target.value);
    const chargeCodes =
      event.target.value === '[]' ? [] : String(event.target.value).replace('[', '').replace(']', '').split(',');
    console.log(chargeCodes);
    const data = {
      id: vehicle.id,
      asset_charge_codes: chargeCodes,
    };
    VehicleAPI.updateTrailerConfiguration(clientId, data);

    // Update local data structure
    setVehicles((vehicles) => {
      const idx = vehicles.indexOf(vehicle);
      vehicles[idx].asset_charge_codes = String(event.target.value).replace('[', '').replace(']', '').split(',');
      return [...vehicles];
    });
  };

  const buildVehicle = (vehicle) => {
    // Show select for articulated trucks with no charge codes (trailers)
    const showSelect =
      vehicle.ruc_vehicle_type === ARTICULATED_TRUCK &&
      (vehicle.asset_charge_codes === null || vehicle.asset_charge_codes.length === 0);

    const types = vehicle.ruc_vehicle_type === RIGID_TRUCK ? rigidTypes : articulatedTypes;

    const vehicleTypesDropdown = (vehicle) => (
      <FormControl>
        <InputLabel id="demo-simple-select-label">Select configuration</InputLabel>
        <Select
          label="Select configuration"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width: '300px' }}
          value={`[${vehicle.asset_charge_codes}]`}
          onChange={(event) => handleChange(vehicle, event)}
        >
          {types.map((type, index) => (
            <MenuItem key={index} value={`[${type.value}]`}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    const rigidTruckNote = (
      <p style={{ color: 'red' }}>
        Note: the default configuration for rigid trucks is set to no trailer. If this is not your configuration, please
        change it below.
      </p>
    );

    return (
      <Item key={vehicle.client_vehicle_id}>
        <p>
          <strong>Registration number: </strong>
          {vehicle.registration}
        </p>
        {showSelect ? (
          <div>
            <p style={{ color: 'red' }}>Select type</p>
            {vehicleTypesDropdown(vehicle)}
          </div>
        ) : (
          <div>
            <p>Change type</p>
            {vehicle.ruc_vehicle_type === RIGID_TRUCK &&
              vehicle.asset_charge_codes &&
              vehicle.asset_charge_codes.length === 0 &&
              rigidTruckNote}
            {vehicleTypesDropdown(vehicle)}
          </div>
        )}
      </Item>
    );
  };

  const getIncompleteVehicles = () =>
    vehicles.filter(
      (v) => v.ruc_vehicle_type === ARTICULATED_TRUCK && v.asset_charge_codes && v.asset_charge_codes.length === 0
    );

  // Check if any vehicles are incomplete. If so, then notify the user
  const NavigationChecker = () => (
    <Prompt
      when={getIncompleteVehicles().length > 0}
      message={`You have unfinished configurations for articulated trucks:\n ${getIncompleteVehicles().map(
        (v) => v.registration
      )}`}
    />
  );

  const LoadingSkeleton = () => {
    const loadingItem = (
      <Item>
        <Skeleton variant="text" width={'50%'} height={50} />
        <div style={{ height: 10 }}></div>
        <Skeleton variant="text" width={'25%'} height={50} />
        <div style={{ height: 10 }}></div>
        <Skeleton variant="rounded" width={'100%'} height={75} />
      </Item>
    );
    return (
      <>
        {loadingItem}
        {loadingItem}
      </>
    );
  };

  const copyFromPreviousMonthClicked = () => {
    setShowConfirmation(false);
    setLoading(true);
    const params = { month: selectedMonth };
    VehicleAPI.copyPreviousTrailerConfiguration(clientId, params).then(
      () => {
        VehicleAPI.getTrailerConfiguration(clientId, params).then((result) => {
          setVehicles(result.payload);
          setLoading(false);
        });
      },
      () => {
        console.log('Error copying configurations');
      }
    );
  };
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '50%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '20px',
            }}
          >
            <h3>{selectedMonth}</h3>
            <Button variant="contained" style={{ height: '40px' }} onClick={() => setShowConfirmation(true)}>
              Copy from previous month
            </Button>
          </div>
          <p>
            Note: Please make sure to update the configuration initially; this update will be automatically applied
            every month unless there is a manual configuration modification.
          </p>
        </div>
        <h3 style={{ width: '50%' }}>Vehicle reference</h3>
      </div>
      <div style={{ display: 'flex' }}>
        <Stack
          sx={{
            width: '50%',
            marginRight: '5px',
            marginBottom: '30px',
            overflow: 'auto',
            maxHeight: '75vh',
            paddingRight: '5px',
            paddingBottom: '5px',
          }}
        >
          <h3>Rigid trucks</h3>
          {loading ? (
            <LoadingSkeleton />
          ) : vehicles.length > 0 ? (
            vehicles
              .filter((vehicle) => vehicle.ruc_vehicle_type === RIGID_TRUCK)
              .map((vehicle) => buildVehicle(vehicle))
          ) : (
            <p>No rigid trucks found</p>
          )}
          <h3>Articulated trucks</h3>
          {loading ? (
            <LoadingSkeleton />
          ) : vehicles.length > 0 ? (
            vehicles
              .filter((vehicle) => vehicle.ruc_vehicle_type === ARTICULATED_TRUCK)
              .map((vehicle) => buildVehicle(vehicle))
          ) : (
            <p>No articulated trucks found</p>
          )}
        </Stack>
        <div style={{ width: '50%', height: '75vh', overflowY: 'auto' }}>
          <img src={classes} alt="Vehicle reference sheet" width="100%" />
        </div>
      </div>
      <NavigationChecker />
      <Confirmation
        show={showConfirmation}
        color="secondary"
        title="Copy configuration from last month?"
        text="This is a permanent operation and it cannot be undone! All configurations from the current month will be replaced."
        onCancel={() => setShowConfirmation(false)}
        onAction={copyFromPreviousMonthClicked}
      />
    </Container>
  );
}

VehicleList.propTypes = {
  selectedMonth: PropTypes.string,
};
