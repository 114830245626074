import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
const PREFIX = 'ContentTitle';

const classes = {
  title: `${PREFIX}-title`
};

const Root = styled('h1')(() => ({
  [`&.${classes.title}`]: {
    width: '100%',
    fontSize: '0.7rem',
    fontWeight: 600,
    color: '#374151',
    textAlign: 'left',
    marginTop: '0',
    paddingBottom: '8px',
  }
}));

class ContentTitle extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string,
  }

  render() {
    const { title, } = this.props;
    if (!title) return null;
    return <Root className={classes.title}>{title}</Root>;
  }
}

export default (ContentTitle);
