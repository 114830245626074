import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';

const PREFIX = 'FuelByVehicleForm';

const classes = {
  container: `${PREFIX}-container`,
  scroller: `${PREFIX}-scroller`,
  tableHolder: `${PREFIX}-tableHolder`,
  tableCell: `${PREFIX}-tableCell`,
  noActivity: `${PREFIX}-noActivity`
};

const StyledPaper = styled(Paper)(() => ({
  [`&.${classes.container}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 20,
  },

  [`&.${classes.scroller}`]: {
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  [`& .${classes.tableHolder}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.tableCell}`]: {
    paddingRight: '5px',
    paddingLeft: '5px',
  },

  [`& .${classes.noActivity}`]: {
    color: '#888',
  }
}));

const columns = [
  {
    name: 'client_vehicle_id',
    label: 'IVMS ID',
    field: 'client_vehicle_id',
  },
  {
    name: 'make',
    label: 'Make',
    field: 'make',
  },
  {
    name: 'model',
    label: 'Model',
    field: 'model',
  },
  {
    name: 'registration',
    label: 'Registration',
    field: 'registration',
  },
  {
    name: 'description',
    label: 'Description',
    field: 'description',
  },
  {
    name: 'activity',
    label: 'Activity',
    field: (v => (v.duration_mins === null && v.distance_km === null) ? 'No activity recorded' : `${Math.round(v.distance_km)} km / ${Math.round(v.duration_mins)} mins`),
  }
];

const cmp = (a, b) => {
  // null is treated as zero for comparisons, so let's not interleave null and zero
  if (a === null || b === null) {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      return -1;
    }
    return 1;
  }
  return (a < b) ? -1 : (a > b ? 1 : 0);
};

const alphaSort = (field) => {
  return (a, b) => {
    const fieldA = (a[field] || '').toLowerCase();
    const fieldB = (b[field] || '').toLowerCase();
    // use client_vehicle_id as tie-breaker for stable sorting.
    return cmp(fieldA, fieldB) || cmp(a.client_vehicle_id, b.client_vehicle_id);
  };
};

const parseFuel = (str) => {
  const v = parseFloat(str);
  return isNaN(v) ? 0 : v;
};

const fuelSort = (field) => {
  return (a, b) => {
    const fuelA = parseFuel(a[field]);
    const fuelB = parseFuel(b[field]);
    // use distance_km as tie-breaker for stable sorting.
    return cmp(fuelA, fuelB) || cmp(a.distance_km, b.distance_km);
  };
};

const makeSort = (orderBy, order) => {
  let fn = undefined;
  switch (orderBy) {
  case 'fuel':
    fn = fuelSort('fuel_amount_l');
    break;
  case 'activity':
    fn = fuelSort('distance_km');
    break;
  default:
    fn = alphaSort(orderBy);
  }
  if (order > 0) {
    return fn;
  } else {
    return (a, b) => fn(b, a);
  }
};

const filterFuel = (str) => {
  str = str + ''; // force everything to string
  const m = str.match(/[0-9]*\.?[0-9]*/);
  return m ? m[0] : '';
};

class VehicleRow extends React.PureComponent {

  static propTypes = {
    vehicle: PropTypes.object.isRequired,
    onFuelChanged: PropTypes.func.isRequired,
  };

  render() {
    const {  vehicle, onFuelChanged } = this.props;
    const enabled = vehicle.duration_mins !== null;
    return (
      <TableRow key={vehicle.clientVehicleId}>
        {columns.map(c =>
          <TableCell key={c.name} className={classes.tableCell}>
            {typeof c.field === 'function' ? c.field(vehicle) : (vehicle[c.field] || '')}
          </TableCell>)}
        <TableCell className={classes.tableCell}>
          {enabled &&
            <TextField
              label="Litres"
              className={classes.textField}
              value={vehicle.fuel_amount_l === null ? '' : vehicle.fuel_amount_l}
              onChange={event => onFuelChanged({...vehicle, fuel_amount_l:filterFuel(event.target.value)})}
            />
          }
        </TableCell>
      </TableRow>
    );
  }
}

class FuelByVehicleForm extends React.PureComponent {
  static propTypes = {
    vehicles: PropTypes.array.isRequired,
    onFuelChanged: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired, // toggle visibility without unmounting
  };

  state = {
    orderBy: 'model',
    order: 1,
  };

  componentDidMount() {
    const { orderBy, order } = this.state;
    this.setSort(orderBy, order);
  }

  onFuelChanged = (vehicle) => {
    const { vehicles } = this.props;
    const updatedVehicles = vehicles.map(v => v.vehicle_id === vehicle.vehicle_id ? vehicle : v);
    this.props.onFuelChanged(updatedVehicles);
  };

  setSort = (orderBy, order) => {
    const sortFn = makeSort(orderBy, order);
    this.setState({
      order: order,
      orderBy: orderBy,
    });
    this.props.onFuelChanged(this.props.vehicles.sort(sortFn));
  };

  sortHeader = (column) => {
    const { orderBy, order } = this.state;
    return (
      <TableCell key={column.field} className={classes.tableCell}>
        <Tooltip
          title="Sort"
          placement={column.numeric ? 'bottom-end' : 'bottom-start'}
          enterDelay={300}
        >
          <TableSortLabel
            active={orderBy === column.name}
            direction={order < 0 ? 'asc' : 'desc'}
            onClick={() => this.setSort(column.name, column.name === orderBy ? -order : 1)}
          >
            {column.label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    );
  };

  render() {
    const {  show, vehicles } = this.props;
    if (!show) return null;
    return (
      <StyledPaper className={classNames(classes.container, classes.scroller)}>
        <div className={classes.container}>
          <div className={classes.tableHolder}>
            <Table padding="dense" className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map(c => this.sortHeader(c))}
                  {this.sortHeader({
                    name: 'fuel',
                    label: 'Fuel amount (L)',
                    field: 'fuel',
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.map(vehicle =>
                  <VehicleRow
                    key={vehicle.vehicle_id}
                    vehicle={vehicle}
                    classes={classes}
                    onFuelChanged={this.onFuelChanged}/>
                )}
              </TableBody>
            </Table>

          </div>
        </div>
      </StyledPaper>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isSuperUser: state.auth.isSuperUser,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)((FuelByVehicleForm));
