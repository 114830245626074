import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Typography,
} from '@mui/material';
import PercentageTicker from './PercentageTicker';

const PREFIX = 'IdlingTable';

const classes = {
  headerCell: `${PREFIX}-headerCell`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellRightPad: `${PREFIX}-tableCellRightPad`,
  group: `${PREFIX}-group`,
  progress: `${PREFIX}-progress`,
  value: `${PREFIX}-value`
};

const StyledTableContainer = styled(TableContainer)(() => ({
  [`& .${classes.headerCell}`]: {
    padding: '0.1rem 0.2rem',
    fontSize: '0.7rem',
    textAlign: 'left',
    fontWeight: 600,
  },

  [`& .${classes.tableCell}`]: {
    padding: '0.1rem 0.2rem',
    fontSize: '0.7rem',
    textAlign: 'left',
  },

  [`& .${classes.tableCellRightPad}`]: {
    padding: '0.1rem 4rem 0.1rem 0.2rem',
    fontSize: '0.7rem',
    textAlign: 'left',
  },

  [`& .${classes.group}`]: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  [`& .${classes.progress}`]: {
    height: '1rem',
    width: '12rem',
  },

  [`& .${classes.value}`]: {
    paddingLeft: '0.5rem',
    fontSize: '0.7rem',
  }
}));

const IdlingTable = ({ data }) => {

  if (data === null || data === undefined) return null;
  const maxIdlingMins = Math.max(...data.map(o => o.idling_mins), 0);
  return (
    <StyledTableContainer component={'div'}>
      <Table sx={{ minWidth: '100%' }} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>Vehicle</TableCell>
            <TableCell className={classes.headerCell}>Idling Time (Hours:Mins)</TableCell>
            <TableCell className={classes.headerCell}>MoM Change</TableCell>
            <TableCell className={classes.headerCell}>Idling Proportion (% of total time)</TableCell>
            <TableCell className={classes.headerCell}>MoM Change</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const scaledIdlingMins = (row.idling_mins / maxIdlingMins) * 100;
            return (
              <TableRow
                key={row.client_vehicle_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className={classes.tableCell} scope="row">
                  {`${row.client_vehicle_id} / ${row.registration} / ${row.make} ${row.model}`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className={classes.group}>
                    <LinearProgress className={classes.progress} variant="determinate" value={scaledIdlingMins} />
                    <Typography className={classes.value}>{row.idling_time}</Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.tableCellRightPad}>
                  <PercentageTicker value={row.time_difference_percent} invert={true} />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className={classes.group}>
                    <LinearProgress className={classes.progress} variant="determinate" value={row.idling_proportion * 100} />
                    <Typography className={classes.value}>{`${(row.idling_proportion * 100).toFixed(1)}%`}</Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <PercentageTicker value={row.proportion_difference_percent} invert={true} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

IdlingTable.propTypes = {
  data: PropTypes.array,
};

export default IdlingTable;
