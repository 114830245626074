import { request, makePath } from './http';
const API_NAME = 'refs';

export function roles() {
  return request('GET', makePath(API_NAME, 'roles'));
}

export function auxiliaryFuelUses() {
  return request('GET', makePath(API_NAME, 'auxiliary-fuel-uses'));
}

export function auxiliaryFuelMethods() {
  return request('GET', makePath(API_NAME, 'auxiliary-fuel-methods'));
}

export function schemas() {
  return request('GET', makePath(API_NAME, 'schemas'));
}

export function referrers() {
  return request('GET', makePath(API_NAME, 'referrers'));
}

export function serviceTypes() {
  return request('GET', makePath(API_NAME, 'service-types'));
}

export function billingServicePlans() {
  return request('GET', makePath(API_NAME, 'billing-service-plans'));
}

export function billingDataPlans() {
  return request('GET', makePath(API_NAME, 'billing-data-plans'));
}
