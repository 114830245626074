export default {
  'en': {
    'prism': 'Prism',
    'logo_alt': 'Prism',
    'placeholder': 'placeholder...',

    'admin_title': 'Administration',
    'api_generated_key_title': 'Copy your API Key',
    'api_generated_key_description': 'This is the last time you\'ll see your API key, please copy it and keep it in a safe place',
    'api_generate_key_title': 'Generating API Key...',

    'analytics_daily_activity': 'Daily Activity Dashboard',
    'analytics_trip_explorer': 'Trip Explorer',
    'analytics_fleet_performance_map': 'Fleet Performance Map',
    'analytics_list_search_placeholder': 'Search content',
    'analytics_label': 'Analytics',

    'auth_about_version': 'v2.0',
    'auth_back': 'Back',
    'auth_brand_label': 'PRISM',
    'auth_forgot_password': 'Forgot Password',
    'auth_login': 'Sign In',
    'auth_login_title': 'Welcome to Prism',
    'auth_logout_title': 'Signing out, please wait...',
    'auth_password': 'Password',
    'auth_reset_password': 'Reset Password',
    'auth_reset_password_title': 'RESET YOUR PASSWORD',
    'auth_reset_password_complete_description': 'We just sent you an email to reset your password.',
    'auth_start': 'Start',
    'auth_username': 'Username',

    'activate_button': 'Activate Account',
    'activate_title': 'Activate Your Account',

    'password_reset_title': 'Password Reset',
    'password_reset_button': 'Reset Password',

    'back': 'Back',
    'contact': 'Contact Prism',

    'error_not_allowed_title': 'This feature is restricted.',
    'error_not_allowed_description': 'You don\'t have the permissions required to use this feature I\'m afraid.',
    'error_not_found_title': 'Resource not found',
    'error_not_found_description': 'We couldn\'t find what you\'re looking for I\'m afraid.',
    'error_oops_title': 'Uh oh...',
    'error_oops_description': 'Something\'s broken this page. We\'ll fix it as soon as we can.',

    'filters': 'Filters',
    'loading': 'Please wait...',

    'menu_vehicles': 'Vehicles',
    'menu_reports': 'Reports',
    'menu_analytics': 'Analytics',
    'menu_upload': 'Upload',
    'menu_clients': 'Clients',
    'menu_details': 'Details',
    'menu_settings': 'Settings',
    'menu_users': 'Users',
    'menu_api_keys': 'API Keys',
    'menu_api_keys_add': 'Add Key',
    'menu_loading': 'Please wait...',

    'module_register_title': 'Vehicle Register',
    'module_register_description': 'Check back later...',
    'module_analytics_title': 'Analytics',
    'module_analytics_description': 'Check back later...',
    'module_reports_title': 'Reports',
    'module_reports_description': 'Check back later...',
    'module_uploads_title': 'Uploads',
    'module_uploads_description': 'Check back later...',

    'client_launch': 'Launch as Client',
    'client_label': 'Client',
    'client_settings_title': 'Client Settings',
    'client_full_name': 'Full Name*',
    'client_clientid': 'Client ID*',
    'client_nickname': 'Nickname*',
    'client_abn': 'ABN*',
    'client_abn_help': 'An 11-digit number',
    'client_state': 'State*',
    'client_industry': 'Industry*',
    'client_api_keys': 'API Keys',
    'client_api_keys_empty': 'This client does not have any API keys...',
    'client_api_keys_add' : 'Create an API Key for this client',
    'client_delete_modal_title': 'Delete Client',
    'client_delete_context_message': 'You are about to delete the selected client!',
    'client_delete_warn_message': 'Deleting a client is irreversible, are you sure you want to permanently delete this client?',
    'client_delete_button': 'Delete Client',

    'client_parent': 'New Client\'s Parent*',

    'clients_label': 'Clients',
    'clients_add': 'Add Client',
    'clients_update': 'Update Client',
    'clients_delete': 'Delete Client',
    'clients_filter_industry': 'Filter Industry',
    'clients_filter_state': 'Filter State',
    'clients_add_title': 'Add New Client',
    'clients_update_title': 'Client Details',
    'clients_settings_title': 'Client Settings',
    'clients_settings_update': 'Update Settings',
    'clients_empty': 'There are no clients in this list.',
    'clients_matched': 'There are {length} clients matching your criteria...',
    'clients_unmatched': 'There are no clients matching your search criteria.',
    'clients_initializing': 'Initializing Clients...',
    'clients_list_search_placeholder': 'Find Nickname...',

    'report_ftc_rate_period': 'FTC per Rate Period',
    'report_ftc_vehicle': 'FTC Per Vehicle',
    'report_ftc_prop_vehicle': 'Activity Proportions per Vehicle',
    'report_ftc_prop_month': 'Activity Proportions Month Summary',

    'register_label': 'Vehicles',
    'register_add': 'Create Vehicle',
    'register_download_csv': 'Download CSV',
    'register_add_title': 'Add Vehicle to Register',
    'register_dispose': 'Dispose Vehicle',
    'register_filter_category': 'Category Filter',
    'register_filter_active_status': 'Vehicle Active/Inactive',
    'register_filter_make': 'Make',
    'register_filter_model': 'Model',
    'register_filter_year': 'Year',
    'register_list_search_placeholder': 'Find Registration Number...',
    'register_initializing': 'Initializing Vehicle Register...',
    'register_empty': 'There are no vehicles in this register.',
    'register_matched': 'There are {length} vehicles matching your criteria...',
    'register_unmatched': 'There are no vehicles matching your search criteria.',
    'register_update': 'Submit Changes',
    'register_update_title': 'Update Registered Vehicle',

    'reports': 'Reports',
    'reports_download_csv': 'Download CSV',
    'reports_initializing': 'Initializing Reports...',
    'reports_list_search_placeholder': 'Find report...',
    'reports_label': 'Reports',
    'reports_matched': 'There are {length} reports matching your criteria...',

    'report_monthly_activity_summary': 'Monthly Activity Summary',
    'report_monthly_ftc_summary': 'Monthly FTC Summary',
    'report_vehicle_activity_summary': 'Vehicle Activity Summary',
    'report_vehicle_ftc_summary': 'Vehicle FTC Summary',
    'report_vehicle_daily_activity_table': 'Vehicle Daily Activity',
    'report_active_duration_mins': 'Active Duration (mins)',
    'report_client_id': 'Client ID',
    'report_daily_totals_per_vehicle': 'Daily Totals Per Vehicle',
    'report_date': 'Date',
    'report_month_end_date': 'Month End Date',
    'report_ftc_rate_period_end': 'Rate Period End Date',
    'report_ftc_rate_public_road': 'Public Road Rate',
    'report_ftc_rate_non_public_road': 'Non-Public Road Rate',
    'report_public_road_driving_km': 'Public Road KM',
    'report_non_public_road_driving_km': 'Non-Public Road KM',
    'report_total_duration_mins': 'Operating Time Mins',
    'report_idling_mins': 'Idle Time Mins',
    'report_ivms_id': 'IVMS ID',
    'report_public_road_driving_duration_mins': 'Public Road Driving Duration Mins',
    'report_non_public_road_driving_duration_mins': 'Non-Public Road Driving Duration Mins',
    'report_public_road_idling_duration_mins': 'Public Road Idle Duration Mins',
    'report_non_public_road_idling_duration_mins': 'Non-Public Road Idle Duration Mins',
    'report_public_road_idling_mins': 'Public Road Idle Time Mins',
    'report_public_road_distance_km': 'Public Road Distance KM',
    'report_public_road_duration_mins': 'Public Road Duration Mins',
    'report_auxiliary_fuel_l': 'Auxiliary Fuel L',
    'report_auxiliary_eligible_fuel_l': 'Auxiliary Eligible Fuel L',
    'report_auxiliary_fuel_use': 'Auxiliary Fuel Use',
    'report_auxiliary_ftc': 'Auxiliary FTC',
    'report_total_fuel_l': 'Total Fuel L',
    'report_public_road_fuel_l': 'Public Road Fuel L',
    'report_non_public_road_fuel_l': 'Non-Public Road Fuel L',
    'report_total_eligible_fuel_l': 'Total Eligible Fuel L',
    'report_public_road_eligible_fuel_l': 'Public Road Eligible Fuel L',
    'report_non_public_road_eligible_fuel_l': 'Non-Public Road Eligible Fuel L',
    'report_total_ftc': 'Total FTC',
    'report_public_road_ftc': 'Public Road FTC',
    'report_non_public_road_ftc': 'Non-Public Road FTC',
    'report_non_public_road_duration_mins': 'Non-Public Road Duration Mins',
    'report_vehicles_active': 'Vehicles Active',
    'report_active_vehicles': 'Active Vehicles',
    'report_public_distance_percent': 'Public Distance %',
    'report_non_public_distance_percent': 'Non-Public Distance %',
    'report_active_days': 'Active Days',
    'report_earliest_activity_date': 'Earliest Activity Date',
    'report_latest_activity_date': 'Latest Activity Date',
    'report_duration_driving_mins': 'Duration Driving Mins',
    'report_public_driving_mins_percent': 'Public Driving Time %',
    'report_non_public_driving_mins_percent': 'Non Public Driving Time %',
    'report_duration_idling_mins': 'Idle Mins',
    'report_public_idling_mins_percent': 'Public Idle %',
    'report_non_public_idling_mins_percent': 'Non-Public Idle %',
    'report_ftc_period_start': 'FTC Period Start',
    'report_ftc_period_end': 'FTC Period End',
    'report_public_road_rate': 'Public Road Rate',
    'report_non_public_road_rate': 'Non-Public Road Rate',
    'report_vehicles_claiming_public_road_ftc': 'Vehicles Claiming Public Road FTC',
    'report_non_public_road_distance_km': 'Non-Public Road Distance KM',
    'report_non_public_road_idling_mins': 'Non-Public Road Idle Time Mins',
    'report_ancillary_operation_mins': 'Ancillary Operation Time Mins',
    'report_public_road_operation_fuel': 'Public Road',
    'report_non_public_road_operation_fuel': 'Non-Public Road',
    'report_ancillary_operation_fuel': 'Ancillary Operation',
    'report_total_fuel': 'Total Fuel',
    'report_ftc_public_road_operation': 'Public Road FTC',
    'report_ftc_non_public_road_operation': 'Non-Public Road FTC',
    'report_ftc_ancillary_operation': 'Ancillary Operation FTC',
    'report_ftc_total': 'Total FTC',


    'report_current_month_values': 'Current Month Values',
    'report_month_1': 'Month 1',
    'report_month_2': 'Month 2',
    'report_change_percent': 'Change (%)',
    'report_distance_km': 'Distance (KM)',
    'report_driving_time': 'Driving Time (HH:MM:SS)',
    'report_idle_time': 'Idle Time (HH:MM:SS)',
    'report_end_date': 'To',
    'report_ftc_amount': 'FTC Amount ($)',
    'report_ftc_ancilliary_use': 'FTC Ancilliary Use',
    'report_ftc_apportioned_fuel_l': 'Apportioned Fuel (L)',
    'report_ftc_apportioned_fuel_per_cent': 'Apportioned Fuel (%)',
    'report_ftc_off_public_road_use': 'FTC Off-road Use',
    'report_ftc_off_road_rate': 'FTC Off-Road Rate',
    'report_ftc_on_road_rate': 'FTC On-Road Rate',
    'report_ftc_rate_ancilliary_use_avg': 'Avg. FTC Rate Ancilliary Use',
    'report_ftc_rate_avg': 'FTC rate (avg $/L)',
    'report_ftc_rate_off_public_road_use_avg': 'Avg. FTC Rate Off-road Use',
    'report_ftc_rate_ancillary': 'Ancillary Operation Rate',
    'report_ftc_report': 'FTC Summary Report',
    'report_fuel_consumed_l': 'Fuel consumed (L)',
    'report_fuel_consumed_off_public_road_l': 'Fuel consumed off public road (L)',
    'report_fuel_consumed_ancilliary_use_l': 'Fuel consumed ancilliary use (L)',
    'report_fuel_ftc_claimed_total_l': 'Fuel FTC Claimed Total (L)',
    'report_fuel_ftc_claimed_l': 'Fuel FTC Claimed (L)',
    'report_fuel_ftc_on_road_l': 'Fuel FTC On-Road (L)',
    'report_fuel_ftc_off_road_l': 'Fuel FTC Off-Road (L)',
    'report_fuel_ftc_total_l': 'Fuel FTC Total (L)',
    'report_fuel_type': 'Fuel Type',
    'report_gps_records': 'GPS Records',
    'report_make': 'Make',
    'report_model': 'Model',
    'report_month': 'Month',
    'report_monthly_totals_per_vehicle': 'Monthly Totals Per Vehicle',
    'report_monthly_totals_per_fuel_type': 'Monthly Totals Per Fuel Type',
    'report_monthly_totals_per_client': 'Monthly Totals Per Client',
    'report_monthly_totals': 'Monthly Totals Report',
    'report_monthly_vehicle': 'Monthly Vehicles Report',
    'report_off_public_road_km': 'Off Public Road KM',
    'report_operating_time_hrs': 'Operating Time (Hrs)',
    'report_previous_month': 'Previous Month',
    'report_previous_month_values': 'Previous Month Values',
    'report_quarter': 'Quarter',
    'report_quarterly_totals': 'Quarterly Totals Report',
    'report_registration': 'Registration',
    'report_registration_state': 'Registration State',
    'report_description': 'Desc',
    'report_start_date': 'From',
    'report_total_distance_km': 'Total Distance KM',
    'report_total_driving_mins': 'Total Driving Mins',
    'report_total_idling_mins': 'Total Idling Mins',
    'report_total_fuel_consumed_l': 'Total Fuel Consumed (L)',
    'report_total_km': 'Total KM',
    'report_measurement_units': 'Measurement Units',
    'report_unknown': 'Unknown Report',
    'report_vehicle_id': 'Vehicle ID',
    'report_vehicle_count': 'Vehicle Count',
    'report_vehicles': 'Vehicles',
    'report_year': 'Year',
    'report_device_id': 'Device ID',
    'report_category': 'Category',
    'report_vehicle_active': 'Active',
    'report_trip_count': 'Trip Count',
    'report_public_road_distance_percent': 'Public Road %',
    'report_operating_mins': 'Operating Mins',
    'report_idle_mins': 'Idling Mins',
    'report_operating_mins_percent': 'Operating %',
    'report_trip_id': 'Trip ID',
    'report_start_time': 'Start time',
    'report_end_time': 'End time',
    'report_distance_source': 'Distance Source',
    'report_min_speed_kmh': 'Min Speed (km/h)',
    'report_max_speed_kmh': 'Max Speed (km/h)',
    'report_start_location': 'Start Location',
    'report_end_location': 'End Location',
    'report_start_longitude': 'Start Longitude',
    'report_start_latitude': 'Start Latitude',
    'report_end_longitude': 'End Longitude',
    'report_end_latitude': 'End Latitude',

    'industry_resources': 'Resources',
    'industry_space': 'Space',
    'industry_taxi': 'Taxi',
    'industry_technology': 'Technology',
    'industry_transport': 'Transport',

    'settings_default_schema': 'Default Schema',
    'settings_use_reallocation': 'Use Reallocation',
    'settings_ivms_min_records': 'IVMS Min. Records',
    'settings_idling_claimable_cutoff': 'Idling Claimable Cut-off',
    'settings_default_vehicle': 'Default Vehicle',
    'settings_geo_feature_type': 'Geo. Feature Type',
    'settings_on_road_consumption_modifier': 'On-road Consumption Modifier',
    'settings_lpg_proportion': 'LPG Proportion',
    'settings_gps_max_wobble': 'GPS Max. Wobble',
    'settings_off_road_consumption_modifier': 'Off-road Consumption Modifier',
    'settings_geo_ref_table': 'Geo. Reference Table',
    'settings_ancilliary_claim': 'Ancilliary Claim',
    'settings_active_conditional_polling_interval': 'Active Cond. Polling Int.',
    'settings_active_max_polling_interval': 'Active Max. Polling Int.',
    'settings_block_allocation_cutoff': 'Block Allocation Cut-off',
    'settings_low_polling_accuracy': 'Low Polling Accuracy',

    'user_add': 'Add User',
    'user_add_title': 'Add a New User',
    'user_delete': 'Delete User',
    'user_delete_button': 'Delete User',
    'user_delete_modal_title': 'Delete User',
    'user_delete_context_message': 'You are about to delete the selected user!',
    'user_delete_warn_message': 'Deleting a user is irreversible, are you sure you want to permanently delete this user?',
    'user_email': 'Email Address*',
    'user_roles': 'Roles*',
    'user_password': 'Password*',
    'user_update': 'Update User',
    'user_update_title': 'Update User',
    'users_delete': 'Delete User',
    'users_empty': 'There are no users for this client.',
    'users_filter_role': 'User Role Filter',
    'users_initializing': 'Please wait...',
    'users_label': 'Users',
    'users_search_placeholder': 'Find Email...',
    'users_unmatched': 'There are no users matching your search criteria.',
    'users_matched': 'There are {length} users matching your criteria...',

    'validation_boolean': 'This field must be True or False',
    'validation_required': 'This field is required',
    'validation_whole_number': 'This field requires a whole number',
    'validation_number': 'This field requires a number',
    'validation_string': 'This field requires text',
    'validation_length_eq': 'This field must be {len} characters long',
    'validation_hexadecimal': 'This field requires a record identifier',
    'validation_vin': 'This field must be a valid 17 digit VIN',

    'vehicle_delete_button': 'Delete Vehicle',
    'vehicle_upload_submit': 'Submit',
    'vehicle_acquired_date': 'Date of Acquisition',
    'vehicle_disposed_date': 'Date of Disposal',
    'vehicle_ftc_on_road': 'FTC On-Road claim',
    'vehicle_ref_fuel_rate_idling_l_hr': 'Idle Fuel Consumption Rate (L/hr)',
    'vehicle_ref_fuel_rate_driving_l_100km': 'Driving Fuel Consumption Rate (L/100km)',
    'vehicle_min_weight_kg': 'Minimum Weight (Kg)',
    'vehicle_max_weight_kg': 'Maximum Weight (Kg)',
    'vehicle_make': 'Make*',
    'vehicle_model': 'Model*',
    'vehicle_model_year': 'Build Year',
    'vehicle_variant': 'Variant',
    'vehicle_registration': 'Rego',
    'vehicle_registration_state': 'Registration State',
    'vehicle_vin': 'VIN',
    'vehicle_vehicle_id': 'Vehicle ID',
    'vehicle_client_vehicle_id': 'IVMS System ID',
    'vehicle_ref_full_name': 'Full Name (Will be populated with reference data if left blank)',
    'vehicle_ref_description': 'Description (Will be populated with reference data if left blank)',
    'vehicle_engine_cylinders': 'Engine Cylinders',
    'vehicle_engine_displacement_l': 'Engine Displacement (L)',
    'vehicle_transmission_type': 'Transmission',
    'vehicle_load_type': 'Load Type',
    'vehicle_seats': 'Seats',
    'vehicle_fuel_type': 'Fuel Type',
    'vehicle_fuel_consumption_idling_l_hr': 'Fuel Consumption - Idle (1h)',
    'vehicle_fuel_consumption_driving_1_100km': 'Fuel Consumption - Driving (100km)',
    'vehicle_fuel_consumption_urban_1_100km': 'Fuel Consumption - Urban (100km)',
    'vehicle_fuel_consumption_extra_urban_l_100km': 'Fuel Consumption - Extra Urban (100km)',
    'vehicle_fuel_consumption_combined_l_100km': 'Fuel Consumption - Combined (100km)',
    'vehicle_category': 'Category',
    'vehicle_dispose_button': 'Dispose Vehicle',
    'vehicle_dispose_modal_title': 'Dispose Vehicle',
    'vehicle_dispose_context_message': 'You are about to dispose of the selected vehicle!',
    'vehicle_dispose_warn_message': 'Are you sure you want to permanently dispose of this vehicle?',
    'vehicle_fuel_rate_source': 'Fuel Rate',
    'vehicle_con_ops': 'ConOps',
    'vehicle_ruc_vehicle_type': 'RUC Vehicle Type',
    'vehicle_registration_charge_code': 'Charge code',
    'vehicle_description': 'Description',
    'vehicle_auxiliary_fuel_method': 'Auxiliary Fuel Method',
    'vehicle_auxiliary_fuel_rate_lhr': 'Auxiliary Fuel Rate (L/hr)',
    'vehicle_auxiliary_fuel_use': 'Auxiliary Fuel Use',
    'vehicle_auxiliary_safe_harbour_override': 'Auxiliary Safe Harbour',
    'vehicle_division': 'Division',
    'vehicle_gcm_tonnes': 'GCM (t)',
    'vehicle_gvm_tonnes': 'GVM (t)',
    'vehicle_number_of_axles': 'Number of Axles',
    'vehicle_registration_end_date': 'Registration End Date',
    'vehicle_pbs_approved': 'PBS Approved',
  }
};
