import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const PREFIX = 'Metric';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  value: `${PREFIX}-value`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '10rem',
  },

  [`& .${classes.label}`]: {
    fontSize: '0.8rem',
    width: '100%',
    color: theme.palette.gray['700'],
  },

  [`& .${classes.value}`]: {
    padding: '0.3rem 0',
    fontSize: '1.3rem',
    color: theme.palette.gray['800'],
    fontWeight: 600,
  }
}));

const Metric = ({ label, value, format='number', units='', dp=0 }) => {

  if (value === null || value === undefined) return null;

  let displayValue = `${value} ${units}`;
  if (format === 'percentage') {
    displayValue = `${(value * 100).toFixed(1)}%`;
  }
  if (format === 'number') {
    displayValue = `${(value * 1).toLocaleString(undefined, {minimumFractionDigits: dp, maximumFractionDigits: dp})} ${units}`;
  }

  return (
    <Root className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{displayValue}</Typography>
    </Root>
  );
};

Metric.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  format: PropTypes.string,
  units: PropTypes.string,
  dp: PropTypes.number,
};

export default Metric;
