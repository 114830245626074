import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import ClientSelect from '../ClientSelect';
import SessionControl from './SessionControl';
import ClientAdminControl from './ClientAdminControl';
import logo from '../../assets/logo-mark-negative.svg';
import nationalPilotLogo from '../../assets/national-pilot-logo.png';
import { currentTsAndCs } from '../TsAndCs';
import SessionRefresh from './SessionRefresh';
import { connect } from 'react-redux';
import { ClientStore } from '../../ducks';
import config from 'config/config';

const PREFIX = 'index';

const classes = {
  appbar: `${PREFIX}-appbar`,
  logo: `${PREFIX}-logo`,
  logoImage: `${PREFIX}-logoImage`,
  group: `${PREFIX}-group`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarClientSelect: `${PREFIX}-toolbarClientSelect`,
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.appbar}`]: {
    zIndex: 1002,
    backgroundColor: config.isBarton
      ? '#053573'
      : '#202938',
    color: '#333333',
    position: 'fixed',
    top: 0,
    left: 0,
    borderBottom: '1px solid #999999',
    boxShadow: '0px 1px 5px 0px #D1D5DB',
  },
  [`& .${classes.logo}`]: {
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '48px',
  },

  [`& .${classes.logoImage}`]: {
    height: '2rem',
  },

  [`& .${classes.group}`]: {
    display: 'flex',
    justifySelf: 'center',
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '52px',
    justifyContent: 'space-between',
    padding: '0 0 0 1rem',
  },

  [`& .${classes.toolbarClientSelect}`]: {
    marginLeft: '5px',
    paddingLeft: '5px',
    paddingTop: '6px',
  },
}));

class Header extends React.PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    acceptedTsAndCs: PropTypes.string,
    tokenExpiresAt: PropTypes.number,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    isSuperUser: PropTypes.bool,
    clients: PropTypes.array.isRequired,
    clientId: PropTypes.string.isRequired,
  };

  onClientIdChanged = (clientId) => {
    this.setState({ clientId });
    if (clientId != null) {
      this.props.dispatch(ClientStore.loadAdditionalClient(clientId));
    }
    this.props.history.push('/prism');
  };

  renderBack() {
    return (
      <IconButton
        style={{ color: 'white' }}
        aria-label="Back"
        component={Link}
        to={'/prism'}
      >
        <BackIcon />
      </IconButton>
    );
  }

  render() {
    const {
      isAuthenticated,
      acceptedTsAndCs,
      tokenExpiresAt,
      history,
      location,
      clients,
      clientId,
    } = this.props;
    const isAuth =
      isAuthenticated &&
      acceptedTsAndCs === currentTsAndCs &&
      tokenExpiresAt > Date.now();
    const showBackLink = location.pathname !== '/prism';

    if (!isAuth) return null;
    return (
      <StyledAppBar position="static" classes={{ root: classes.appbar }}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.group}>
            {showBackLink ? (
              this.renderBack()
            ) : (
              <div className={classes.logo}>
                <img
                  src={
                    config.isBarton
                      ? nationalPilotLogo
                      : logo
                  }
                  alt="Prism Logo"
                  className={classes.logoImage}
                />
              </div>
            )}
            <div className={classes.toolbarClientSelect}>
              <ClientSelect
                value={clientId}
                clients={clients}
                onChange={this.onClientIdChanged}
                allowEmpty={false}
              />
            </div>
            <ClientAdminControl history={history} />
          </div>
          <div className={classes.group}>
            <SessionControl history={history} />
          </div>
        </Toolbar>
        <SessionRefresh history={history} />
      </StyledAppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isSuperUser: state.auth.isSuperUser,
    acceptedTsAndCs: state.auth.acceptedTsAndCs,
    tokenExpiresAt: state.auth.tokenExpiresAt,
    clients: state.clients.all.items,
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)(Header);
