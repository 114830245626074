import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const PREFIX = 'DateFilter';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  dateSelect: `${PREFIX}-dateSelect`,
  item: `${PREFIX}-item`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
    padding: 0,
  },
  [`& .${classes.label}`]: {
    marginRight: '0.5rem',
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  [`& .${classes.dateSelect}`]: {
    minWidth: '7rem',
    fontSize: '0.9rem',
    height: '2rem',
  },
  [`& .${classes.item}`]: {
    fontSize: '0.9rem',
  },
});

const formatMonthYear = (date) => moment(date).format('MMM YYYY');

const DateFilter = ({ filter, onFilterChanged, dateList, label=null }) => {


  if (dateList === undefined) return null;

  const renderDateSelector = () => {
    return (
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <FormControl>
          <Select
            variant="outlined"
            className={classes.dateSelect}
            value={filter.date}
            onChange={(e) => onFilterChanged(e.target.value)}
          >
            {dateList && dateList.map(date => (
              <MenuItem key={date} value={date} className={classes.item}>{formatMonthYear(date)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <Root>
      {dateList && dateList.length > 0 ? renderDateSelector() : null}
    </Root>
  );
};

DateFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
  dateList: PropTypes.array,
  label: PropTypes.string,
};

DateFilter.displayName = 'DateFilter';

export default DateFilter;
