import { request, makePath } from './http';
const API_NAME = 'fuel';

export function summary(clientId, params) {
  return request('GET', makePath(API_NAME, 'summary'), {clientId, params});
}

export function get(clientId, month_start_date) {
  const params = { month_start_date };
  return request('GET', makePath(API_NAME), {clientId, params});
}

export function create(clientId, data, params) {
  return request('POST', makePath(API_NAME), {clientId, params, body: data});
}

export function remove(clientId, month_start_date) {
  const params = { month_start_date };
  return request('DELETE', makePath(API_NAME), {clientId, params});
}
