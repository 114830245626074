import React ,{useEffect,useState}from 'react';
import {AreasAPI} from '../../../api';
import {useDispatch,useSelector} from 'react-redux';
import {LoadingStore} from '../../../ducks';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import MapView from '../../../components/MapView';


const layerStyle = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#4E3FC8',
    'fill-opacity': 0.6
  }
};

const ViewArea =({match}) =>{
  const dispatch = useDispatch();
  const [geojson,setGeojson] = useState(null);
  const clientId = useSelector((state) => state.clients.selectedItemId);

  const getCustomArea = async (id) => {
    dispatch(LoadingStore.loading(true    ));
    try {
      const response = await AreasAPI.getCustomArea(clientId, id);
      setGeojson(response);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(LoadingStore.loading(false));
    }
  };

  useEffect(() => {
    getCustomArea(match.params.id);
  }, []);// eslint-disable-line

  return (
    <MapView geojson={geojson} layerStyle={layerStyle}/>
  );
};

ViewArea.propTypes = {
  match :  PropTypes.object
};

export default ViewArea;