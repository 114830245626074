import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SettingsPanel from './SettingsPanel';
import TextField from '@mui/material/TextField';

const PREFIX = 'TripSplitSettingsPanel';

const classes = {
  root: `${PREFIX}-root`,
  block: `${PREFIX}-block`,
  subtitle: `${PREFIX}-subtitle`,
  row: `${PREFIX}-row`,
  label: `${PREFIX}-label`,
  select: `${PREFIX}-select`
};

const StyledSettingsPanel = styled(SettingsPanel)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    padding: '1rem',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  [`& .${classes.block}`]: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.subtitle}`]: {
    margin: '-0.5rem 0 1rem 0',
    padding: 0,
    fontSize: '0.9rem',
    fontWeight: 600,
  },

  [`& .${classes.row}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1rem',
    borderTop: `1px solid ${theme.palette.gray[100]}`,
  },

  [`& .${classes.label}`]: {
    fontSize: '0.9rem',
  },

  [`& .${classes.sublabel}`]: {
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },

  [`& .${classes.select}`]: {
    minWidth: '6rem',
  }
}));

const TripSplitSettingsPanel = ({ client, onSave }) => {


  const initialData = {
    'trip_split_max_idle_mins_before_movement': client.trip_split_max_idle_mins_before_movement,
    'trip_split_max_idle_mins_between_movement': client.trip_split_max_idle_mins_between_movement,
    'trip_split_max_idle_mins_after_movement': client.trip_split_max_idle_mins_after_movement,
    'trip_split_max_interval_mins': client.trip_split_max_interval_mins,
    'trip_split_max_interval_km': client.trip_split_max_interval_km,
  };
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState(initialData);

  const toggleExpanded = () => setExpanded(!expanded);

  const onValueChange = (field, value) => {
    const newValue = value === '' ? null : value;
    setData({...data, [field]: newValue});
  };

  const summary = () => {
    const totalItems = Object.keys(data).length;
    const setItems = Object.values(data).filter(value => value !== null).length;
    return setItems + ' items set of ' + totalItems + ' available';
  };

  return (
    <StyledSettingsPanel
      className={classes.root}
      title="Trip Split Threshold Settings"
      summary={summary()}
      onSave={() => {
        onSave(data);
        setExpanded(false);
      }}
      onCancel={() => {
        setData(initialData);
        setExpanded(false);
      }}
      onExpand={toggleExpanded}
      expanded={expanded}
    >
      <div className={classes.block}>
        <h2 className={classes.subtitle}>
          These constaints control how trips are defined and where sequences of activity are split into smaller sections. If left blank, no contraint is applied.
        </h2>
        <div className={classes.row}>
          <p className={classes.label}>1. Maximum idle time, in minutes, allowed at the start of a trip, before the first instance of movement.</p>
          <p className={classes.sublabel}>This only works if there is movement in the trip. Use the Minimum Speed Trip Exclusion setting to exclude trips that have only idling and no movement.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_split_max_idle_mins_before_movement || ''}
            onChange={(e)=>onValueChange('trip_split_max_idle_mins_before_movement', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>2. Maximum idle time, in minutes, allowed between sections of movement that occur within a trip.</p>
          <p className={classes.sublabel}>This only works if there is movement in the trip. Use the Minimum Speed Trip Exclusion setting to exclude trips that have only idling and no movement.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_split_max_idle_mins_between_movement || ''}
            onChange={(e)=>onValueChange('trip_split_max_idle_mins_between_movement', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>3. Maximum idle time, in minutes, allowed at the end of a trip, after the last instance of movement.</p>
          <p className={classes.sublabel}>This only works if there is movement in the trip. Use the Minimum Speed Trip Exclusion setting to exclude trips that have only idling and no movement.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_split_max_idle_mins_after_movement || ''}
            onChange={(e)=>onValueChange('trip_split_max_idle_mins_after_movement', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>4. Maximum time, in minutes, allowed between two sequential position readings.</p>
          <p className={classes.sublabel}>Use this to prevent excessive time gaps between two points.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_split_max_interval_mins || ''}
            onChange={(e)=>onValueChange('trip_split_max_interval_mins', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className={classes.row}>
          <p className={classes.label}>5. Maximum distance, in KM, allowed between two sequential position readings.</p>
          <p className={classes.sublabel}>Use this to prevent excessive distance gaps between two points.</p>
          <TextField
            variant="outlined"
            size="small"
            className={classes.select}
            type="number"
            helperText="Any numeric value"
            value={data.trip_split_max_interval_km || ''}
            onChange={(e)=>onValueChange('trip_split_max_interval_km', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
    </StyledSettingsPanel>
  );
};

TripSplitSettingsPanel.propTypes = {
  client: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TripSplitSettingsPanel;
