import React from 'react';

import { styled } from '@mui/material/styles';

import 'mapbox-gl/dist/mapbox-gl.css';
import NewAreaForm from '../components/NewAreaForm';
import MapView from '../../../components/MapView';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import {LoadingStore} from '../../../ducks';
import {useDispatch,useSelector} from 'react-redux';
import {AreasAPI} from '../../../api';
import PropTypes from 'prop-types';
import { themeConfig } from 'ThemeProvider';


const layerStyle = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#4E3FC8',
    'fill-opacity': 0.6
  }
};

const DivFormContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

const DivForm = styled('div')(() => ({
  width: '90%',
}));

const StyledLinkContainer = styled(Link)(() => ({
  textDecoration: 'none',
}));

const DivPage = styled('div')(({theme}) => ({
  margin: '0 auto 1rem auto',
  maxWidth: '90%',
  paddingLeft: '1rem',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    marginBottom: '3rem',
  },
}));

const DivHeadingBox = styled('div')(() => ({
  paddingTop: '1rem',
  paddingBottom: '0.4rem',
  marginBottom: '0',
  border: 'none',
}));

const H1PageHeading = styled('h1')(() => ({
  fontSize: '1.2rem',
  fontWeight: 600,
  color: themeConfig.palette.primary.main,
  marginBottom: '0.1rem',
}));


const StyledButton = styled(Button)(() => ({
  float: 'center',
  fontSize: '14px',
  opacity: 0.9,
  lineHeight: '18px',
  width: '284px',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.15)',
  margin: '24px',
  padding: '12px 24px',

  // right: 0,
  outline: 'none',

  cursor: 'auto',
}));

const DivButtonContainer = styled('div')(() => ({
  width: '100%',
  textAlign: 'center',
}));

const DivMap = styled('div')(() => ({
  height: '400px',
  width: '95%',
}));

const NewAreaReview =({location, history}) =>{
  const geojson = location.state.geojson;
  const newArea = location.state.newArea;

  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.clients.selectedItemId);

  const onConfirmClicked = async () =>{
    let geoJsonWithProperties = {'type':'FeatureCollection','features':[]};
    geojson['features'].forEach(function (arrayItem) {
      geoJsonWithProperties['features'].push({...arrayItem,'properties': newArea });
    });
    dispatch(LoadingStore.loading(true    ));
    try {
      const response = await AreasAPI.createCustomArea(clientId, geoJsonWithProperties);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(LoadingStore.loading(false));
      history.push('/areas');
    }
  };

  return (
    <DivPage>
      <DivHeadingBox>
        <H1PageHeading>New Custom Area Review</H1PageHeading>
      </DivHeadingBox>
      <DivFormContainer>
        <FormGroup row={true}>
          <DivForm>
            <NewAreaForm disabled={true} newArea={newArea} readOnly={true} />
          </DivForm>
        </FormGroup>
      </DivFormContainer>
      <DivMap>
        <MapView geojson={geojson} layerStyle={layerStyle}/>
      </DivMap>

      <DivButtonContainer>
        <StyledLinkContainer to={{pathname:'/areas/new_area/map' , state: { newArea: location.state.newArea, geojson: location.state.geojson }}}>
          <StyledButton
            variant="contained"
            color="secondary">
            Back
          </StyledButton>
        </StyledLinkContainer>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={onConfirmClicked}
        >
          Confirm
        </StyledButton>
      </DivButtonContainer>
    </DivPage>
  );
};

NewAreaReview.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default NewAreaReview;