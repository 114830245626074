import React ,{useEffect, useState}from 'react';
import { styled } from '@mui/material/styles';
import Form, {  Section } from '../../../components/Form';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import NewAreaForm from '../components/NewAreaForm';
import PropTypes from 'prop-types';
import { themeConfig } from 'ThemeProvider';


const PREFIX = 'AreasNew';

const classes = {
  page: `${PREFIX}-page`,
  headingBox: `${PREFIX}-headingBox`,
  pageHeading: `${PREFIX}-pageHeading`,
  subText: `${PREFIX}-subText`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.page}`]: {
    margin: '0 auto 1rem auto',
    maxWidth: '70%',
    paddingLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginBottom: '3rem',
    },
  },

  [`& .${classes.headingBox}`]: {
    paddingTop: '1rem',
    paddingBottom: '0.4rem',
    marginBottom: '0',
    border: 'none',
  },

  [`& .${classes.pageHeading}`]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: themeConfig.palette.primary.main,
    marginBottom: '0.1rem',
  },

  [`& .${classes.subText}`]: {
    color: '#374151',
    fontSize: '0.9rem',
    marginTop: '5px',
  },

  [`& .${classes.buttonContainer}`]: {
    margin:'auto',
    textDecoration: 'none'
  },

  [`& .${classes.button}`]: {
    textDecoration: 'none',
    display:'flex',
    justifyContent:'flex-center',
    marginBottom: 10,
    marginTop: 10,
    width: '284px',
    margin: '24px',
    padding: '12px 24px',
  }
}));


const AreasNew =({history , location})=> {

  const [newArea,setNewArea] = useState((location.state!== undefined) ? location.state.setArea : {});
  const [isValid,setIsValid] = useState(false);

  const formatObject = (obj) => {
    Object.entries(obj).forEach(
      ([key, value]) => {
        if (value === '' || value.length <1)  {
          delete obj[key];
        }
        obj[key]=obj[key].trim();
      }
    );};

  const onNextClick =() =>{
    formatObject(newArea);
    history.push('/areas/new_area/map' , {newArea: newArea});
  };

  const checkIsValid = ()=>{
    if ('name' in newArea && 'ftc_road_classification' in newArea){
      if (newArea['name'].length>1){
        setIsValid(true);
      }
    }
  };

  useEffect(() => {
    checkIsValid();
  }, [newArea]);// eslint-disable-line

  return (
    <Root className={classes.page}>
      <div className={classes.headingBox}>
        <h1 className={classes.pageHeading}>New Custom Area</h1>
        <p className={classes.subText}>Enter the below details to create a new custom area</p>
      </div>
      <div>
        <Form>
          <NewAreaForm newArea={newArea} disabled={false} readOnly={false} setNewArea={setNewArea}/>
          <Section>
            <FormGroup row sx ={{justifyContent:'center'}} >
              <Link className={classes.buttonContainer}  to={'/areas'}>
                <Button className={classes.button} variant="contained" color="secondary">
                Cancel
                </Button>
              </Link>
              <Button disabled={!isValid} className={classes.button} variant="contained" color="primary" onClick={onNextClick}>
                    Next
              </Button>
            </FormGroup>
          </Section>
        </Form>
      </div>
    </Root>
  );
};


AreasNew.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default AreasNew;
