import React, { Component } from 'react';

import Button from '@mui/material/Button';
import CustomField from './CustomField';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorToast from 'components/ErrorToast';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import IconClose from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { SourcesAPI } from '../../../api';
import StatusSignal from './StatusSignal';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(() => ({
  margin: 20,
  marginTop: '100px',
}));


const ButtonSaveButton = styled(Button)(() => ({
  marginRight: 20,
  alignSelf: 'center',
}));

const IconButtonDeleteButton = styled(IconButton)(( ) => ({
  marginLeft: 20,
}));

const DivSplitter = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const DivSpaced = styled('div')(( ) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const DivRight = styled('div')(( ) => ({
  display: 'flex',
}));

const DivModalClose = styled('div')(( ) => ({
  float: 'right',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing.unit,
  minWidth: 300,
}));

const StyledTextField = styled(TextField)(( ) => ({}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing.unit,
  minWidth: 300,
}));



const timeZoneName = moment.tz(moment.tz.guess()).zoneAbbr();
const utcTime = (localTime) => localTime ? moment(localTime).utc().format('YYYY-MM-DDTHH:mm:ss') : null;
const localTime = (utc) => utc ? moment.utc(utc).local().format('YYYY-MM-DDTHH:mm:ss') : null;
const startTime = () => moment().set({h: 0, m: 0, s:0}).format('YYYY-MM-DDTHH:mm:ss');
const compareSystems = (a, b) => (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
const sortSystems = (systems) => Object.values(systems).sort(compareSystems);
class SubscriptionConfig extends Component {

  static propTypes = {
    subscription: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    systems: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func,
  };

  state = {
    systemId: '',
    startTime: startTime(),
    endTime: '',
    schedule: '1:00:00:00',
    active: false,
    saveBusy: false,
    testBusy: false,
    testStatus: StatusSignal.HIDDEN,
    credentials: {},
    args: {},
    isDirty: false,
    errorOpen: false,
    errorMessage: undefined,
    deleteDialogOpen: false,
  };

  getCredentials = async () => {
    if (this.props.subscription !== undefined
      && this.props.subscription.id !== undefined) {
      const response = await SourcesAPI.getSubscription(this.props.clientId, this.props.subscription.id, {decrypt: true});
      this.copySubscriptionToState(response.payload);
    }
  }

  copySubscriptionToState = (subscription) => {
    this.setState({
      systemId: '' + subscription.system_id,
      startTime: localTime(subscription.start_time) || '',
      endTime: localTime(subscription.end_time) || '',
      schedule: subscription.schedule || '',
      active: !!subscription.active,
      credentials: subscription.credentials,
      args: subscription.args,
    });
  }

  copySubscriptionFromState = () => {
    return {
      system_id: parseInt(this.state.systemId, 10),
      start_time: utcTime(this.state.startTime),
      end_time: utcTime(this.state.endTime),
      schedule: this.state.schedule,
      active: this.state.active,
      credentials: this.state.credentials,
      args: this.state.args,
    };
  }

  componentDidMount() {
    this.getCredentials();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.subscription !== prevProps.subscription) {
      this.getCredentials();
    }
  }

  onError = async error => {
    if (error.body) {
      this.setState({errorOpen: true, errorMessage: error.body.error, errorKey: new Date()});
    } else {
      this.setState({errorOpen: true, errorMessage: error.message, errorKey: new Date()});
    }
  }

  onSaveSubscription = async () => {
    this.setState({saveBusy: true});
    const subscription = this.copySubscriptionFromState();
    try {
      if (this.props.subscription.id === undefined) {
        const s = await SourcesAPI.postSubscription(this.props.clientId, subscription);
        this.props.onCreate(s.payload);
      } else {
        await SourcesAPI.patchSubscription(this.props.clientId, this.props.subscription.id, subscription);
      }
      this.setState({saveBusy: false, isDirty: false});
    } catch (error) {
      this.onError(error);
      this.setState({saveBusy: false});
    }
    if (this.props.onSave) this.props.onSave();
  }

  onTestSubscription = async () => {
    this.setState({testBusy: true, testStatus: StatusSignal.LOADING});
    try {
      await SourcesAPI.testSubscription(this.props.clientId, this.props.subscription.id);
      this.setState({testBusy: false, testStatus: StatusSignal.SUCCESS});
    } catch(error) {
      this.onError(error);
      this.setState({testBusy: false, testStatus: StatusSignal.ERROR});
    }
  }

  onDeleteSubscription = async () => {
    try {
      await SourcesAPI.deleteSubscription(this.props.clientId, this.props.subscription.id);
      this.props.onDelete(this.props.subscription);
      this.setState({deleteDialogOpen: false});
    } catch(error) {
      this.onError(error);
    }
  }

  renderActiveField = () => {
    const onChange = this.onCustomFieldChange('active');
    return (
      <StyledFormControlLabel
        label="Enabled"
        control={
          <Switch
            checked={this.state.active}
            onChange={(event) => onChange(event.target.checked)}
            color="primary"
            value="active"
          />
        }
      />
    );
  }

  onSystemChange = (systemId) => {
    this.onCustomFieldChange('systemId')(systemId);
    const system = this.props.systems[systemId];
    if (system) {
      // eslint-disable-next-line
      for (let idx in system.args) {
        const arg = system.args[idx];
        if (arg.default_value) {
          this.setState(state => ({
            args : {[arg.field]: arg.default_value, ...state.args}
          }));
        }
      }
    }
  }

  renderSystemField = () => {
    const systems = this.props.systems || {};
    return (
      <StyledFormControl>
        <InputLabel htmlFor="system-helper" required={true}>
    Source System
        </InputLabel>
        <Select
          value={this.state.systemId}
          onChange={(event) => this.onSystemChange(event.target.value)}
          input={<Input name="system" id="system-helper" />}
        >
          {sortSystems(systems).map((system) => (
            <MenuItem key={system.id} value={system.id}>
              {system.name}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    );
  }

  renderStartTimeField = () => {
    const onChange = this.onCustomFieldChange('startTime');
    return (
      <StyledFormControl>
        <StyledTextField
          label={`Start Time (${timeZoneName})`}
          type="datetime-local"
          value={this.state.startTime}
          onChange={(event) => onChange(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required={true}
        />
      </StyledFormControl>
    );
  }

  renderEndTimeField = () => {
    const onChange = this.onCustomFieldChange('endTime');
    return (
      <StyledFormControl>
        <StyledTextField
          label={`End Time (${timeZoneName})`}
          type="datetime-local"
          value={this.state.endTime}
          onChange={(event) => onChange(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </StyledFormControl>
    );
  }

  renderScheduleField = () => {
    return (
      <CustomField
        field="schedule"
        label="Frequency"
        type="duration"
        value={this.state.schedule}
        onChange={this.onCustomFieldChange('schedule')}
      />
    );
  }

  onCustomFieldChange = (name, stateField) => value => {
    this.setState((state) => {
      if (stateField !== undefined) {
        state[stateField][name] = value;
      } else {
        state[name] = value;
      }
      state.isDirty = true;
      return state;
    });
  };

  renderCredentials = () => {
    if (!this.props.systems || !this.state.systemId) return null;
    const system = this.props.systems[this.state.systemId];
    if (!system) return null;
    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Credentials" />
          </ListItem>
          {system.credentials.map(spec =>
            <ListItem key={spec.field}>
              <CustomField
                value={this.state.credentials[spec.field]}
                onChange={this.onCustomFieldChange(spec.field, 'credentials')}
                {...spec}
              />
            </ListItem>)}
        </List>
      </div>
    );
  }

  renderArgs = () => {
    if (!this.props.systems || !this.state.systemId) return null;
    const system = this.props.systems[this.state.systemId];
    if (!system) return null;
    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Parameters" />
          </ListItem>
          {system.args.map(spec =>
            <ListItem key={spec.field}>
              <CustomField
                value={this.state.args[spec.field]}
                onChange={this.onCustomFieldChange(spec.field, 'args')}
                {...spec}
              />
            </ListItem>)}
        </List>
      </div>
    );
  }

  renderConfiguration = () => {
    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Configuration" />
          </ListItem>
          <ListItem>
            {this.renderSystemField()}
          </ListItem>
          <ListItem>
            {this.renderStartTimeField()}
          </ListItem>
          <ListItem>
            {this.renderEndTimeField()}
          </ListItem>
          <ListItem>
            {this.renderScheduleField()}
          </ListItem>
          <ListItem>
            {this.renderActiveField()}
          </ListItem>
        </List>

      </div>
    );
  }

  renderDeleteDialog() {
    return (
      <Dialog
        open={this.state.deleteDialogOpen}
        onClose={() => this.setState({deleteDialogOpen: false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete this subscription?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This subscription will be deleted.  This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({deleteDialogOpen: false})} color="primary" autoFocus>
            Cancel
          </Button>
          <Button variant="contained" onClick={this.onDeleteSubscription} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <StyledPaper>
        <DivModalClose>
          <IconButton onClick={this.props.onClose}>
            <IconClose />
          </IconButton>
        </DivModalClose>
        <DivSplitter>
          <div>{this.renderConfiguration()}</div>
          <div>
            {this.renderCredentials()}
            {this.renderArgs()}
          </div>
        </DivSplitter>
        <DivSpaced>
          <IconButtonDeleteButton
            onClick={() => this.setState({ deleteDialogOpen: true })}
            disabled={this.props.subscription.id === undefined}
          >
            <DeleteIcon />
          </IconButtonDeleteButton>
          <DivRight>
            <ButtonSaveButton
              variant="contained"
              color="primary"
              onClick={this.onSaveSubscription}
              disabled={this.state.saveBusy || !this.state.isDirty}
            >
        Save
            </ButtonSaveButton>
            <ButtonSaveButton
              variant="contained"
              color="primary"
              onClick={this.onTestSubscription}
              disabled={
                this.state.testBusy ||
                this.state.isDirty ||
                this.props.subscription.id === undefined
              }>
              Test
            </ButtonSaveButton>
            <StatusSignal state={this.state.testStatus} style={{ float: 'right' }} />
          </DivRight>
          <ErrorToast
            open={this.state.errorOpen}
            message={this.state.errorMessage}
            key={this.state.errorKey}
          />
          {this.renderDeleteDialog()}
        </DivSpaced>
      </StyledPaper>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItem.client_id,
    systems: state.sources.systems.items,
  };
};

export default connect(mapStateToProps)(
  (SubscriptionConfig)
);
