import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingStore } from 'ducks';
import { AnalyticsAPI } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { makeDateList, makeMonthFilter } from 'lib/date';
import {
  Container,
  Section,
  PageHeader,
  IdlingTable,
  MonthFilter,
} from '../components';
import Toolkit from '../../../components/Toolkit';

const IdlingDashboard = ({ dateRange }) => {
  const dispatch = useDispatch();
  const { min: minDate, max: maxDate } = dateRange;
  const [data, setData] = useState(null);
  const dateList = makeDateList(minDate, maxDate);
  const [date, setDate] = useState(dateList[0]);

  const clientId = useSelector((state) => state.clients.selectedItemId);

  React.useEffect(() => {
    const fetchData = async (date) => {
      dispatch(LoadingStore.loading(true));
      try {
        const queryParams = makeMonthFilter(date);
        const response = await AnalyticsAPI.getMonthlyIdling(clientId, queryParams);
        setData(response.payload);
      } catch (err) {
        console.error(err);
        setData(null);
      } finally {
        dispatch(LoadingStore.loading(false));
      }
    };
    fetchData(date);
  }, [date, clientId, dispatch]);

  return (
    <Container>
      <PageHeader
        title="Idling dashboard"
        subtitle="Compare idling performance for each vehicle in absolute and relative amounts"
      />
      <Toolkit>
        <MonthFilter
          label="Select month:"
          filter={{ date: date }}
          onFilterChanged={setDate}
          dateList={dateList}
        />
      </Toolkit>
      <Section fullHeight>
        <IdlingTable data={data}/>
      </Section>
    </Container>
  );
};

IdlingDashboard.propTypes = {
  dateRange: PropTypes.object.isRequired,
};

export default IdlingDashboard;
