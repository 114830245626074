import Analytics from './Analytics';
import withRole from '../../hocs/withRole';

export default [
  {
    key: 1,
    path: '/analytics',
    exact: false,
    component: withRole(['Vehicle Admin', 'Client Admin', 'Client View'], Analytics),
    module: 'analytics',
  }
];
