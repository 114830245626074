import React, { useState, useEffect } from 'react';
import {
  Container,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from '@mui/material';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { ReportsAPI } from 'api';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import StatusSnackbar from 'modules/invoices/components/StatusSnackbar';
import language from 'config/language';
import { dateToShortMonthAndYear } from 'helpers/date';

const INITIAL_PAGE = 0;
const PAGE_SIZE = 500;
const INITIAL_PAGINATION_MODEL = {
  page: INITIAL_PAGE,
  pageSize: PAGE_SIZE,
};

export default function ReportPage({ reportId, name }) {
  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState(new Map());
  const [result, setResult] = useState();
  const [error, setError] = useState(false);
  const clientId = useSelector((state) => state.clients.selectedItemId);
  const [paginationModel, setPaginationModel] = React.useState(
    INITIAL_PAGINATION_MODEL
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    ReportsAPI.getReportFilters(clientId, reportId).then((res) => {
      const filters = res.payload;
      filters.forEach((f) => {
        f.values.sort();
        // For month, we want to reverse the sort so we have newest dates first
        if (f.name.toLowerCase().includes('month')) {
          f.values.reverse();
        }
      });
      setFilters(filters);
    });
  }, [setFilters, clientId, reportId]);

  const handleSelectChange = (event, filter) => {
    const value = event.target.value;
    const newValues = new Map(filterValues);
    if (Array.isArray(value) && value[value.length - 1] === 'all') {
      newValues.set(
        filter.name,
        (filterValues.get(filter.name)?.length ?? 0) === filter.values.length
          ? []
          : filter.values
      );
    } else {
      newValues.set(filter.name, value);
    }
    setFilterValues(newValues);
  };

  const getResult = (paginationModel, sortModel) => {
    setIsLoading(true);
    const params = {
      filter_values: Object.fromEntries(filterValues),
      pagination_model: paginationModel,
      sort_model: sortModel,
    };
    ReportsAPI.getReportResults(clientId, reportId, params)
      .then((res) => {
        return setResult(res.payload);
      })
      .finally(() => setIsLoading(false));
  };

  const handleRunQueryClick = () => {
    const isComplete = filters.every(
      (f) => filterValues.has(f.name) && filterValues.get(f.name).length > 0
    );
    if (isComplete) {
      // Reset pagination model and sort model
      setPaginationModel(INITIAL_PAGINATION_MODEL);
      setSortModel([]);
      setError(false);
      getResult(INITIAL_PAGINATION_MODEL, []);
    } else {
      setError(true);
    }
  };

  const SingleSelect = (filter) => {
    return (
      <Select
        labelId={`${filter.name}-label`}
        id={filter.name}
        label={filter.name}
        value={filterValues.get(filter.name) || ''}
        onChange={(event) => handleSelectChange(event, filter)}
        required
      >
        {filter.values.map((v, i) => (
          <MenuItem key={i} value={v}>
            {filterValueToFriendlyName(filter.name, v)}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const filterValueToFriendlyName = (name, value) => {
    if (name.toLowerCase().includes('month')) {
      return dateToShortMonthAndYear(new Date(value));
    }
    return value;
  };

  const MultipleSelect = (filter) => {
    return (
      <Select
        labelId={`${filter.name}-label`}
        id={filter.name}
        label={filter.name}
        value={filterValues.get(filter.name) || []}
        renderValue={(selected) =>
          selected.length > 1 ? 'Multiple' : selected[0]
        }
        onChange={(event) => handleSelectChange(event, filter)}
        multiple
        required
      >
        <MenuItem value="all">
          <Checkbox
            checked={
              filterValues.get(filter.name)?.length === filter.values.length
            }
            indeterminate={
              (filterValues.get(filter.name)?.length ?? 0) > 0 &&
              (filterValues.get(filter.name)?.length ?? 0) <
                filter.values.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {filter.values.map((v, i) => (
          <MenuItem key={i} value={v}>
            <Checkbox
              checked={filterValues.get(filter.name)?.includes(v) || false}
            />
            <ListItemText primary={filterValueToFriendlyName(filter.name, v)} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const onPaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);
    getResult(paginationModel, sortModel);
  };

  const onSortModelChange = (sortModel) => {
    setSortModel(sortModel);
    getResult(paginationModel, sortModel);
  };

  return (
    <Container sx={{ marginBottom: '50px' }} maxWidth={false}>
      <h1>{name}</h1>
      <p>Filters</p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {filters.map((f) => (
          <FormControl
            key={f.name}
            sx={{
              minWidth: `${f.name.length * 8 + 64}px`,
              maxWidth: `${f.name.length * 16 + 64}px`,
              marginRight: '8px',
            }}
            size="small"
          >
            <InputLabel id={`${f.name}-label`}>{f.name}</InputLabel>
            {f.allow_multiple_select ? MultipleSelect(f) : SingleSelect(f)}
          </FormControl>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleRunQueryClick}
          size="medium"
        >
          Run query
        </Button>
      </div>
      <div style={{ height: '50px' }}></div>
      {result && (
        <DataGridPro
          columns={result.columns.map((column) => ({
            ...column,
            headerName: language['en'][`report_${column.field}`],
          }))}
          rows={result.rows}
          rowCount={result.row_count}
          sx={{
            height: '60vh',
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          slots={{ toolbar: CustomToolbar }}
          density="compact"
          checkboxSelection
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[PAGE_SIZE]}
          loading={isLoading}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          disableColumnFilter
        />
      )}
      <StatusSnackbar
        message={'Please select the filters'}
        severity={'error'}
        open={error}
        onClose={() => setError(false)}
      />
    </Container>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

ReportPage.propTypes = {
  reportId: PropTypes.number,
  name: PropTypes.string,
};
