import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import language from '../config/language';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

const getMuiTheme = () => createTheme({
  components: {
    MUIDataTableToolbar: {
      styleOverrides:{
        root: {
          minHeight: '20px',
          padding: '0.5rem',
          borderBottom: '1px solid #ccc',
        },
        titleText: {
          fontSize: '0.8rem',
          fontWeight: 600,
        },
        icon: {
          fontSize: '0.8rem',
          padding: '0px',
          width: '24px',
          height: '24px',
          marginLeft: '1rem',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },
        iconActive: {
          fontSize: '0.8rem',
          padding: '0px',
          width: '24px',
          height: '24px',
          marginLeft: '1rem',
          '&:hover': {
            backgroundColor: '#fff',
          },
        }
      }
    },
    MUIDataTableSearch: {
      styleOverrides:{
        searchText: {
          height: '20px',
        },
        searchIcon: {
          margin: 0,
        },
        clearIcon: {
          padding: 0,
          '&:hover': {
            backgroundColor: '#fff',
          },
        },
      }},
    MUIDataTable: {
      styleOverrides:{
        tableRoot: {
          borderBottom: '1px solid #ccc',
        },
        paper: {
          borderRadius: 0,
          boxShadow: 'none',
          backgroundColor: '#fff',
        },
        responsiveBase: {
          padding: 0,
        },
      }},
    MUIDataTableBody: {
      styleOverrides:{
        emptyTitle: {
          padding: '1rem',
          fontSize: '0.9rem',
        },
      }},
    MUIDataTableHeadCell: {
      styleOverrides:{
        root: {
          width: '100%',
          padding: 0,
          verticalAlign: 'bottom',
        },
        fixedHeader: {
          backgroundColor: '#E5E7EB',
        },
        toolButton: {
          width: '100%',
          justifyContent: 'flex-start',
          borderRadius: 0,
          padding: 0,
        },
        sortAction: {
          width: '100%',
          backgroundColor: 'transparent',
        },
        data: {
          width: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          backgroundColor: 'transparent',
          fontSize: '0.7rem',
          fontWeight: 600,
          padding: '8px 16px',
        },
      }},
    MuiTableRow: {
      styleOverrides:{
        root: {
          cursor: 'pointer',
          backgroundColor: '#fff',
          '&$hover:hover': {
            backgroundColor: '#E5E7EB',
          },
        },
        footer: {
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        },
      }},
    MUIDataTableBodyCell: {
      styleOverrides:{
        root: {
          fontSize: '0.7rem',
          padding: '3px 4px',
        }
      }},
  },
});

const ReportTable = ({ name, title, data, options, onRowClick }) => {
  const [columns, setColumns] = useState([]);

  const defaultOptions = {
    downloadOptions: {filename: `${name}.csv`, separator: ','},
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 100,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelect: true,
    tableBodyHeight: '75vh',
  };

  let tableOptions = defaultOptions;
  if (options !== undefined) {
    tableOptions = {...defaultOptions, ...options};
  }

  const transformColumns = () => {
    setColumns(Object.keys(data[0]).map((k) => ({
      name: k,
      label: language['en'][`report_${k}`],
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: 'nowrap' }}),
        setCellHeaderProps: () => ({ style: { whiteSpace: 'nowrap' } }),
      },
    })));
  };

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      transformColumns();
    } else {
      setColumns([]);
    }
  }, [data]); // eslint-disable-line

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={tableOptions}
          onRowClick={onRowClick}
          rowsSelected={[3]}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReportTable.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  options: PropTypes.object,
  onRowClick: PropTypes.func,
};

export default ReportTable;
