import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import persistReducer from './persistReducer';
import clients from './clients';
import users from './users';
import auth from './auth';
import refs from './refs';
import vehicles from './vehicles';
import loading from './loading';
import sources from './sources';
import jobs from './jobs';
import reportFiles from './reportFiles';
import { AuthAPI } from '../api';
import * as ClientStore from './clients';
import * as UserStore from './users';
import * as AuthStore from './auth';
import * as RefsStore from './refs';
import * as VehicleStore from './vehicles';
import * as LoadingStore from './loading';
import * as SourcesStore from './sources';
import * as JobsStore from './jobs';
import * as ReportFilesStore from './reportFiles';

import thunk from 'redux-thunk';
const reducers = combineReducers({
  auth,
  clients,
  users,
  refs,
  vehicles,
  loading,
  sources,
  jobs,
  reportFiles,
});

const rootReducer = (state, action) => {
  // drop user-specific saved state if user changes.
  if (action.type === 'auth/LOGIN' &&
      state.auth &&
      state.auth.userId !== action.profile.user_id) {
    state = {
      ...state,
      clients: undefined,
      auth: undefined,
      users: undefined,
      vehicles: undefined,
    };
  }
  return reducers(state, action);
};

const tokenHydrate = () => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    if (action.payload && action.payload.auth && action.payload.auth.token) {
      AuthAPI.setBearerToken(action.payload.auth.token);
    }
  }
  return next(action);
};

const persistedReducer = persistReducer('root', rootReducer, {stateReconciler: autoMergeLevel2});
const store = createStore(persistedReducer, applyMiddleware(thunk, tokenHydrate));
const persistor = persistStore(store);

export {
  persistor,
  AuthStore,
  ClientStore,
  UserStore,
  RefsStore,
  VehicleStore,
  LoadingStore,
  SourcesStore,
  JobsStore,
  ReportFilesStore,
};
export default store;
