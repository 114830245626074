import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Confirmation from './Confirmation';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import RegistrationAccordion from './Registration';
import Fab from '@mui/material/Fab';
import { isEqual } from 'lodash';
import Form, {
  Section,
  SelectInput,
  DateInput,
  TextInput,
  ComboInput,
  spanTextFieldStyle,
} from '../../../components/Form';
import config from '../../../config/config';
import { VehicleAPI } from '../../../api';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import {
  fuelOpt,
  regoStateOpt,
  yesNoOpt,
  transmissionOpt,
  rucVehicleType,
} from '../models/DropdownOptions';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Alert,
} from '@mui/material';

const PREFIX = 'EditForm';

const classes = {
  formControl: `${PREFIX}-formControl`,
  toolbar: `${PREFIX}-toolbar`,
  closeButton: `${PREFIX}-closeButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  saveButton: `${PREFIX}-saveButton`,
  updateText: `${PREFIX}-updateText`,
  infoButton: `${PREFIX}-infoButton`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(),
    minWidth: 120,
    maxWidth: 300,
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  [`& .${classes.closeButton}`]: {},

  [`& .${classes.deleteButton}`]: {
    float: 'right',
  },

  [`& .${classes.updateText}`]: {
    color: theme.palette.common.danger,
    marginTop: 10,
    fontWeight: 'bold',
  },

  [`& .${classes.saveButton}`]: {
    marginTop: '1rem',
    fontWeight: 900,
    alignSelf: 'center',
    width: '10rem',
  },

  // Removing the hover effect because it is being smudged and is displaying as an ellipse
  [`& .${classes.infoButton}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

const boolTransform = {
  toString: (nativeValue) =>
    nativeValue === null || nativeValue === undefined
      ? ''
      : nativeValue.toString(),
  fromString: (strValue) =>
    strValue === 'true' ? true : strValue === 'false' ? false : undefined,
};

const EditForm = ({
  selectedVehicle,
  onSave,
  onMatchFuel,
  onDelete,
  onClose,
}) => {
  const isSuperUser = useSelector((state) => state.auth.isSuperUser);
  const client = useSelector((state) => state.clients.selectedItem);
  const auxFuelUses = useSelector((state) => state.refs.auxFuelUses.items);
  const auxFuelMethods = useSelector(
    (state) => state.refs.auxFuelMethods.items
  );
  const vehicles = useSelector((state) => state.vehicles.items);
  const categories = useSelector((state) => state.vehicles.categories.items);
  const version = client && client.features.includes('ftc_reports_v2') ? 2 : 1;
  const [vehicle, setVehicle] = useState(selectedVehicle);
  const [hasUpdateOccurred, setHasUpdateOccurred] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmEnable, setConfirmEnable] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [formattedAuxUses, setFormattedAuxUses] = useState([]);
  const [formattedAuxMethods, setFormattedAuxMethods] = useState([]);
  const [formattedCategories, setFormattedCategories] = useState([]);
  const [nhvrResults, setNhvrResults] = useState(null);
  const [nhvrSelectedIndex, setNhvrSelectedIndex] = useState(null);
  const [chargeCodeLookupError, setChargeCodeLookupError] = useState('');

  const transformRegistrationDetails = (registrationDetailsValue) => {
    //   The registration cost breakdown is in an Object structure,
    //   this should be changed to a Map with an index for each key pair value,
    //   in order to maintain the position of component after editing.
    const transformedRegistrationDetails = new Map();
    Object.entries(registrationDetailsValue || {}).forEach(
      ([key, value], index) => {
        transformedRegistrationDetails.set(index, { key, value });
      }
    );
    return Object.fromEntries(transformedRegistrationDetails);
  };

  const setVehicleAndUpdateOccurred = (value) => {
    if (!isEqual(value, vehicle)) {
      setVehicle(value);
      setHasUpdateOccurred(true);
    }
  };

  const updateRegoCostDetailsFromNhvrResponse = (result) => {
    let vin;
    // Only update the current VIN if the last four digits do not match the result from the NHVR
    if (
      vehicle.vin !== null &&
      vehicle.vin !== undefined &&
      vehicle.vin.length >= 1 &&
      vehicle.vin.slice(-4) === result['vehicle_vin'].slice(-4)
    ) {
      vin = vehicle.vin;
    } else if (result['vehicle_vin'].length > 1) {
      vin = result['vehicle_vin'];
    } else {
      vin = null;
    }
    const updatedVehicle = {
      ...vehicle,
      ...('registration_cost_details' in result && {
        registration_cost_details: result['registration_cost_details'],
      }),
      ...(config.isBarton &&
        'asset_type' in result && {
          ruc_vehicle_type: result['asset_type'],
        }),
      vin: vin,
      registration_state: result['registration_jurisdiction'],
      gvm_tonnes: result['vehicle_gvm'],
      gcm_tonnes: result['vehicle_gcm'],
      registration_charge_code: result['registration_charge_code'],
      registration_end_date: result['registration_end_date'],
      make: result['vehicle_make']?.toUpperCase(),
      model: result['vehicle_model']?.toUpperCase(),
      model_year: result['vehicle_year_manufactured']?.toString(),
      variant: result['vehicle_body_type']?.toUpperCase(),
      ...(result['asset_type'] === 'TRAILER' && {
        category: 'NO FUEL USE',
      }),
      pbs_approved: result['is_pbs_vehicle_approved'],
    };
    setVehicleAndUpdateOccurred(updatedVehicle);
  };

  const loadFuelRates = async () => {
    const fields = [
      'make',
      'model',
      'category',
      'fuel_type',
      'variant',
      'drive_type',
      'transmission_type',
      'engine_displacement_l',
      'engine_cylinders',
    ];
    const specs = fields.reduce((obj, key) => {
      if (key in vehicle) obj[key] = vehicle[key];
      return obj;
    }, {});
    const data = await onMatchFuel(specs);
    if (data) {
      const updatedVehicle = {
        ...vehicle,
        ref_fuel_rate_idling_l_hr: data.ref_fuel_rate_idling_l_hr,
        ref_fuel_rate_driving_l_100km: data.ref_fuel_rate_driving_l_100km,
        fuel_rate_source: data.fuel_rate_source,
      };
      setVehicleAndUpdateOccurred(updatedVehicle);
    }
  };

  const setFuelRateSource = (prismFuel) => {
    if (prismFuel) {
      loadFuelRates();
    } else {
      const updatedVehicle = {
        ...vehicle,
        ref_fuel_rate_idling_l_hr: '',
        ref_fuel_rate_driving_l_100km: '',
        fuel_rate_source: 'DIRECT ENTRY',
      };
      setVehicleAndUpdateOccurred(updatedVehicle);
    }
  };

  const onChange = (field, value) => {
    const updatedVehicle = { ...vehicle, [field]: value };
    setVehicleAndUpdateOccurred(updatedVehicle);
  };

  const onSelectAuxFuelUse = (value) => {
    // UPDATE: Below comments apply to v1 only
    // we could set the % automatically with
    // auxiliary_fuel_percentage: value.rate,
    // but because using the safe harbour precludes the use of
    // off-road calculation, we don't think this is always a good choice.
    let updatedVehicle = {
      ...vehicle,
      auxiliary_fuel_use: value.label,
      auxiliary_safe_harbour_overide: false,
    };
    if (updatedVehicle.auxiliary_fuel_method === 'SAFE HARBOUR') {
      updatedVehicle['auxiliary_fuel_percentage'] = value.rate;
    }
    if (updatedVehicle.auxiliary_fuel_method === 'PERCENTAGE') {
      updatedVehicle['auxiliary_safe_harbour_overide'] = true;
    }
    setVehicleAndUpdateOccurred(updatedVehicle);
  };

  const onSelectAuxFuelMethod = (value) => {
    let updatedVehicle = {
      ...vehicle,
      auxiliary_fuel_method: value.label,
    };
    if (updatedVehicle.auxiliary_fuel_method === 'SAFE HARBOUR') {
      updatedVehicle['auxiliary_fuel_use'] = 'NONE';
      updatedVehicle['auxiliary_fuel_percentage'] = 0;
    }
    setVehicleAndUpdateOccurred(updatedVehicle);
  };

  const findSafeHarbour = (vehicle) => {
    const uses = formattedAuxUses.filter(
      (u) => u.label === vehicle.auxiliary_fuel_use
    );
    if (uses.length === 0) return false;
    if (uses[0].rate === Number(vehicle.auxiliary_fuel_percentage)) return true;
    return false;
  };

  useEffect(() => {
    const noUse = { label: 'NONE', rate: 0 };
    const stdUses = auxFuelUses.map((afu) => ({
      label: afu.name,
      rate: afu.safe_harbour_percentage,
    }));
    const uses = [noUse, ...stdUses];
    setFormattedAuxUses(uses);
  }, [auxFuelUses, vehicles]); // eslint-disable-line

  useEffect(() => {
    const methods = auxFuelMethods.map((i) => ({ label: i }));
    setFormattedAuxMethods(methods);
  }, [auxFuelMethods]); // eslint-disable-line

  useEffect(() => {
    const cats = categories.map((category) => ({
      label: category,
      value: category,
    }));
    setFormattedCategories(cats);
  }, [categories]); // eslint-disable-line

  const prismFuelRates = vehicle.fuel_rate_source !== 'DIRECT ENTRY';
  const prismFuelRatesLabel = 'Prism Fuel Rates';
  const userFuelRatesLabel = 'Customer Entered Fuel Rates';

  const renderV1AuxSection = () => {
    const isSafeHarbour = findSafeHarbour(vehicle);
    const auxFuelAnnotation =
      vehicle.auxiliary_fuel_percentage > 0
        ? isSafeHarbour
          ? 'Safe harbour rate'
          : 'Custom rate'
        : '';
    return (
      <Section title="Auxiliary Fuel Use">
        <FormGroup row>
          <ComboInput
            label="Auxiliary Fuel Use"
            value={vehicle}
            values={formattedAuxUses}
            field="auxiliary_fuel_use"
            onChange={onChange}
            onSelect={onSelectAuxFuelUse}
          />
          <TextInput
            label="Auxiliary Fuel Rate"
            value={vehicle}
            field="auxiliary_fuel_percentage"
            onChange={onChange}
            helperText={auxFuelAnnotation}
            units="%"
          />
        </FormGroup>
      </Section>
    );
  };

  const renderV2AuxSection = () => (
    <Section title="Auxiliary Fuel Use">
      <FormGroup row>
        <ComboInput
          label="Calculation Method"
          value={vehicle}
          values={formattedAuxMethods}
          field="auxiliary_fuel_method"
          onChange={onChange}
          onSelect={onSelectAuxFuelMethod}
        />
        <ComboInput
          label="Fuel Use"
          value={vehicle}
          values={formattedAuxUses}
          field="auxiliary_fuel_use"
          onChange={onChange}
          onSelect={onSelectAuxFuelUse}
        />
      </FormGroup>
      <FormGroup row>
        {vehicle.auxiliary_fuel_method === 'CALCULATED' && (
          <TextInput
            label="Fuel Consumption Rate"
            value={vehicle}
            field="auxiliary_fuel_rate_lhr"
            units="l/hr"
            onChange={onChange}
            helperText=""
          />
        )}
        {vehicle.auxiliary_fuel_method === 'PERCENTAGE' && (
          <TextInput
            label="Percentage Apportionment"
            value={vehicle}
            field="auxiliary_fuel_percentage"
            units="%"
            onChange={onChange}
            helperText="WARNING: Applied in addition to off-road FTC allocation!!"
          />
        )}
        {vehicle.auxiliary_fuel_method === 'SAFE HARBOUR' && (
          <TextInput
            label="Safe harbour percentage"
            disabled
            value={vehicle}
            field="auxiliary_fuel_percentage"
            units="%"
            onChange={onChange}
            helperText="Includes off-road FTC allocation"
          />
        )}
      </FormGroup>
    </Section>
  );

  const NhvrResultList = () => (
    <List
      subheader={
        <ListSubheader>Lookup results (choose the correct one)</ListSubheader>
      }
    >
      {nhvrResults.map((result, index) => (
        <ListItem disablePadding key={index}>
          <ListItemButton
            onClick={() => {
              setNhvrSelectedIndex(index);
              updateRegoCostDetailsFromNhvrResponse(result);
            }}
            selected={index === nhvrSelectedIndex}
          >
            <ListItemText
              primary={`State: ${result.registration_jurisdiction} | Make: ${
                result.vehicle_make
              } | Model: ${result.vehicle_model} | GVM: ${
                result.vehicle_gvm
              }t${result.vehicle_gcm ? ` | GCM: ${result.vehicle_gcm}t` : ''}`}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  const handleRegoLookup = () => {
    setNhvrSelectedIndex(null);
    VehicleAPI.regoLookup(client.client_id, vehicle.registration).then(
      (response) => {
        if (response && response.status_code === 200) {
          const results = response.payload;
          setNhvrResults(results);
        }
      }
    );
  };

  const handleChargeCodeLookup = () => {
    if (!vehicle.registration_state) {
      setChargeCodeLookupError('Please fill in the state');
    } else if (!vehicle.registration_charge_code) {
      setChargeCodeLookupError('Please fill in the charge code');
    } else {
      setChargeCodeLookupError('');
      VehicleAPI.regoCostDetails(
        client.client_id,
        vehicle.registration_state,
        vehicle.registration_charge_code
      ).then((response) => {
        // Only update if there is a response that contains rego cost details
        if (
          response &&
          response.status_code === 200 &&
          Object.keys(response.payload).length >= 1
        ) {
          const updatedVehicle = {
            ...vehicle,
            registration_cost_details:
              response.payload['registration_cost_details'],
            ruc_vehicle_type: response.payload['asset_type'],
          };
          setVehicleAndUpdateOccurred(updatedVehicle);
        } else {
          setChargeCodeLookupError('Invalid charge code');
        }
      });
    }
  };

  const LookupButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      variant="contained"
      color="secondary"
      sx={{ height: '40px', alignSelf: 'center', fontWeight: 900 }}
    >
      Lookup
    </Button>
  );
  LookupButton.propTypes = { onClick: PropTypes.func };

  const onPbsApprovedChange = (event) => {
    const updatedVehicle = {
      ...vehicle,
      pbs_approved: event.target.checked,
    };
    setVehicleAndUpdateOccurred(updatedVehicle);
  };

  /*
   * Note: outer div is required for vertical scrolling to work correctly
   */
  return (
    <Root>
      <Form>
        <div className={classes.toolbar}>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
          <Fab
            size="small"
            className={classes.deleteButton}
            onClick={() => setShowConfirmation(true)}
          >
            <DeleteIcon />
          </Fab>
        </div>
        <Section title="Data Collection and Reporting">
          <FormControlLabel
            control={
              <Switch
                checked={!vehicle.disabled}
                onChange={(event) => {
                  event.target.checked
                    ? setConfirmEnable(true)
                    : setConfirmDisable(true);
                }}
                color="primary"
              />
            }
            label={
              vehicle.disabled
                ? 'Inactive - Data Collection and Reporting is disabled.'
                : 'Active - Data Collection and Reporting is enabled.'
            }
          />
        </Section>

        {hasUpdateOccurred && (
          <Typography component={'span'} className={classes.updateText}>
            <p>Vehicle details have been changed.</p>
            <p>Please press the save button to ensure your changes are Saved</p>
          </Typography>
        )}

        <Section title="Identification">
          <FormGroup row>
            <TextInput
              label="Prism ID"
              value={vehicle}
              field="vehicle_id"
              readOnly
            />
            <TextInput
              label="Rego"
              value={vehicle}
              field="registration"
              onChange={onChange}
            />
            {vehicle.registration && (
              <LookupButton onClick={handleRegoLookup} />
            )}
            <SelectInput
              label="Registration State"
              value={vehicle}
              values={regoStateOpt}
              field="registration_state"
              onChange={onChange}
              className={classes.formControl}
            />
          </FormGroup>
          {nhvrResults && nhvrResults.length === 0 && (
            <Alert severity="error">Could not find vehicle in the NHVR</Alert>
          )}
          {nhvrResults && nhvrResults.length > 0 && <NhvrResultList />}
          <FormGroup row>
            <TextInput
              label="Device ID"
              value={vehicle}
              field="client_vehicle_id"
              onChange={onChange}
              readOnly={!isSuperUser}
            />
            <TextInput
              label="VIN"
              value={vehicle}
              field="vin"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <TextInput
              label="Description"
              value={vehicle}
              field="description"
              onChange={onChange}
              span={2}
            />
          </FormGroup>
        </Section>

        <Section title="Primary Details">
          <FormGroup row>
            <TextInput
              label="Make"
              value={vehicle}
              field="make"
              required
              onChange={onChange}
            />
            <TextInput
              label="Model"
              value={vehicle}
              field="model"
              required
              onChange={onChange}
            />
            <TextInput
              label="Variant"
              value={vehicle}
              field="variant"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <SelectInput
              label="Category"
              value={vehicle}
              values={formattedCategories}
              field="category"
              onChange={onChange}
              className={classes.formControl}
            />
            <SelectInput
              label="Fuel Type"
              value={vehicle}
              values={fuelOpt}
              field="fuel_type"
              onChange={onChange}
              className={classes.formControl}
            />
            <SelectInput
              label="FTC On-Road Claim"
              value={vehicle}
              values={yesNoOpt}
              transform={boolTransform}
              field="ftc_on_road"
              onChange={onChange}
              className={classes.formControl}
            />
          </FormGroup>
          <FormGroup row>
            <DateInput
              label="Date of Acquisition"
              value={vehicle}
              field="acquired_date"
              onChange={onChange}
            />
            <DateInput
              label="Date of Disposal"
              value={vehicle}
              field="disposed_date"
              onChange={onChange}
            />
          </FormGroup>
        </Section>

        <Section title="Specification Detail">
          <FormGroup row>
            <TextInput
              label="Build Year"
              value={vehicle}
              field="model_year"
              onChange={onChange}
            />
            <SelectInput
              label="Transmission"
              value={vehicle}
              values={transmissionOpt}
              field="transmission_type"
              onChange={onChange}
            />
            <TextInput
              label="Engine Displacement"
              value={vehicle}
              field="engine_displacement_l"
              units="L"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <TextInput
              label="Engine Cylinders"
              value={vehicle}
              field="engine_cylinders"
              onChange={onChange}
            />
            <TextInput
              label="Seats"
              value={vehicle}
              field="seats"
              onChange={onChange}
            />
            <TextInput
              label="Load Type"
              value={vehicle}
              field="load_type"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <TextInput
              label="Gross Vehicle Mass"
              value={vehicle}
              field="gvm_tonnes"
              units="tonnes"
              onChange={onChange}
            />
            <TextInput
              label="Gross Combination Mass"
              value={vehicle}
              field="gcm_tonnes"
              units="tonnes"
              onChange={onChange}
            />
            <FormControlLabel
              style={spanTextFieldStyle(1)}
              control={
                <Switch
                  color="primary"
                  checked={vehicle.pbs_approved || false}
                  onChange={onPbsApprovedChange}
                />
              }
              label="PBS Approved"
            />
          </FormGroup>
        </Section>

        <Section title="Registration Details">
          <FormGroup row>
            <TextInput
              label="Registration Charge Code"
              value={vehicle}
              field="registration_charge_code"
              onChange={onChange}
            />
            <LookupButton onClick={handleChargeCodeLookup} />
            <TextInput
              label="Registration End Date"
              value={vehicle}
              field="registration_end_date"
              onChange={onChange}
            />
          </FormGroup>
          {chargeCodeLookupError && (
            <Alert severity="error">{chargeCodeLookupError}</Alert>
          )}
          <FormGroup row>
            <RegistrationAccordion
              registrationDetailsValue={transformRegistrationDetails(
                vehicle.registration_cost_details
              )}
              registrationDetailsField="registration_cost_details"
              onChange={onChange}
            />
          </FormGroup>
        </Section>

        {config.isBarton && (
          <Section title="Road User Charging Settings">
            <FormGroup row>
              <TextInput
                label="RUC Con Ops"
                value={vehicle}
                field="con_ops"
                onChange={onChange}
              />
              <SelectInput
                label="RUC Vehicle Type"
                value={vehicle}
                values={rucVehicleType}
                field="ruc_vehicle_type"
                onChange={onChange}
              />
            </FormGroup>
          </Section>
        )}

        <Section title="Fuel Consumption">
          <FormGroup row>
            <TextInput
              label="Idling Fuel Consumption Rate"
              value={vehicle}
              field="ref_fuel_rate_idling_l_hr"
              units="l/hr"
              onChange={onChange}
              readOnly={prismFuelRates}
            />
            <TextInput
              label="Driving Fuel Consumption Rate"
              value={vehicle}
              field="ref_fuel_rate_driving_l_100km"
              units="l/100km"
              onChange={onChange}
              readOnly={prismFuelRates}
            />
            <FormControlLabel
              style={spanTextFieldStyle(1)}
              control={
                <Switch
                  color="primary"
                  checked={prismFuelRates}
                  onChange={(event) => setFuelRateSource(event.target.checked)}
                />
              }
              label={prismFuelRates ? prismFuelRatesLabel : userFuelRatesLabel}
            />
            <Tooltip leaveDelay={1000}  title={
              <>
                <a style={{color: 'inherit', textDecoration: 'inherit'}} target="_blank" rel="noopener noreferrer" href={config.links.FUEL_CONSUMPTION_RATES_HELP}>
                  <em>{'For more information on fuel consumption rates,'}</em> <b>{' Please click here '}</b>
                </a>
              </>
            }>
              <IconButton className={classes.infoButton}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </FormGroup>
        </Section>

        {version === 1 && renderV1AuxSection()}
        {version === 2 && renderV2AuxSection()}

        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => onSave(vehicle)}
        >
          Save
          <DoneIcon />
        </Button>
        <Confirmation
          show={showConfirmation}
          title="Delete this vehicle?"
          text="Deleting a vehicle is a permanent operation and it cannot be undone!"
          onCancel={() => setShowConfirmation(false)}
          onAction={() => onDelete(vehicle)}
        />
        <Confirmation
          show={confirmDisable}
          title="Disable data collection and reporting for this vehicle?"
          text="If you continue this vehicle will not appear in future reports."
          onCancel={() => setConfirmDisable(false)}
          onAction={() => {
            setConfirmDisable(false);
            onChange('disabled', true);
          }}
        />
        <Confirmation
          show={confirmEnable}
          title="Enable data collection and reporting for this vehicle?"
          text="If you continue this vehicle will appear in future reports and will be counted for billing purposes."
          onCancel={() => setConfirmEnable(false)}
          onAction={() => {
            setConfirmEnable(false);
            onChange('disabled', false);
          }}
        />
      </Form>
    </Root>
  );
};

EditForm.propTypes = {
  selectedVehicle: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMatchFuel: PropTypes.func.isRequired,
};

export default EditForm;
