import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {MoonLoader} from 'react-spinners';

const PREFIX = 'Loading';

const classes = {
  overlay: `${PREFIX}-overlay`
};

const Root = styled('div')(() => ({
  [`& .${classes.overlay}`]: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    pointerEvents: 'none',
    transition: 'all 400ms',
  }
}));

class Loading extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    force: PropTypes.bool,
  }

  render() {
    if (!this.props.isLoading && !this.props.force) return null;
    return (
      <Root>
        <div className={classes.overlay}>
          <MoonLoader size="32px" color="#8088f9" />
        </div>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps)((Loading));
