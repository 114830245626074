import { UserAPI, AuthAPI } from '../api';

const LOGOUT         = 'auth/LOGOUT';
const LOGIN          = 'auth/LOGIN';
const SET_PROFILE    = 'auth/SET_PROFILE';
const SET_SUPERUSER  = 'auth/SET_SUPERUSER';
const SET_ACCEPTED_TS_AND_CS = 'auth/SET_ACCEPTED_TS_AND_CS';
const ERROR          = 'auth/ERROR';

const initialState = {
  loadTime: undefined,
  isAuthenticated: false,
  acceptedTsAndCs: undefined,
  isSuperUser: false,
  token: undefined,
  tokenExpiresAt: undefined,
  profile: undefined,
  userId: undefined,
};

const CACHE_TTL = 60 * 1000;  // 1 minute cache on profile

// Action Creators

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function setToken(profile, token, expiresAt, refreshToken) {
  AuthAPI.setBearerToken(token);
  return {
    type: LOGIN,
    profile,
    token,
    expiresAt,
    refreshToken,
  };
}

export function setSuperUser(isSuperUser=true) {
  return {
    type: SET_SUPERUSER,
    isSuperUser,
  };
}

export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile,
  };
}

export function setAcceptedTsAndCs(acceptedTsAndCs) {
  return {
    type: SET_ACCEPTED_TS_AND_CS,
    acceptedTsAndCs,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

// Async Action Creators

export function reloadProfile(refresh=false) {
  return (dispatch, getState) => {
    const state = getState();
    const age = Date.now() - state.auth.loadTime;
    if (refresh || age > CACHE_TTL) {
      const clientId = state.clients.selectedItemId;
      return UserAPI.profile(clientId, state.auth.userId)
        .then(response => dispatch(setProfile(response.payload)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function acceptTsAndCs(version) {
  return (dispatch, getState) => {
    const state = getState();
    const clientId = state.clients.selectedItemId;
    UserAPI.acceptTsAndCs(clientId, version)
      .then(() => dispatch(setAcceptedTsAndCs(version)));
  };
}

export function logoutUser() {
  return (dispatch) => {
    AuthAPI.logout()
      .then(() => dispatch(logout()));
  };
}
// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case LOGIN:
    return {
      ...state,
      isAuthenticated: true,
      isSuperUser: false,
      token: action.token,
      tokenExpiresAt: action.expiresAt,
      refreshToken: action.refreshToken,
      profile: action.profile,
      acceptedTsAndCs: action.profile.ts_and_cs_version,
      userId: action.profile.user_id, // used to detect user-change on login
      loadTime: Date.now(),
    };

  case LOGOUT:
    return {
      ...state,
      isAuthenticated: false,
      isSuperUser: false,
      token: undefined,
      tokenExpiresAt: undefined,
      refreshToken: undefined,
      profile: undefined,
      // leave user_id unchanged
    };

  case SET_PROFILE:
    return {
      ...state,
      loadTime: Date.now(),
      profile: action.profile,
    };

  case SET_SUPERUSER:
    return {
      ...state,
      isSuperUser: action.isSuperUser,
    };

  case SET_ACCEPTED_TS_AND_CS:
    return {
      ...state,
      acceptedTsAndCs: action.acceptedTsAndCs,
    };

  default:
    return state;
  }
}
