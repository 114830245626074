import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NoData from '../NoData';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import StarIcon from  '@mui/icons-material/Star';
import StarBorderIcon from  '@mui/icons-material/StarBorder';
import Toolbar from './Toolbar';
import Drawer from '@mui/material/Drawer';
import ClientForm from './ClientForm';
import ErrorDialog from '../ErrorDialog';
import { ClientAPI, UserAPI } from '../../api';
import { ClientStore, AuthStore, RefsStore } from '../../ducks';
import moment from 'moment';
import { industryMap } from 'components/Industries';

const PREFIX = 'ClientTable';

const classes = {
  container: `${PREFIX}-container`,
  scroller: `${PREFIX}-scroller`,
  smallIcon: `${PREFIX}-smallIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.scroller}`]: {
    overflowY: 'scroll',
  },

  [`& .${classes.smallIcon}`]: {
    fontSize: 18,
  }
}));

const sameRoles = (array1, array2) => {
  if (array1.length === array2.length) {
    const a1 = array1.slice().sort();
    const a2 = array1.slice().sort();
    return a1.every((value, index) => value === a2[index]);
  }
  return false;
};

class ClientTable extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    clients: PropTypes.array,
    isLoading: PropTypes.bool,
    total: PropTypes.number,
    get: PropTypes.func,
    roles: PropTypes.array,
    profile: PropTypes.object,
    clientId: PropTypes.string,
    adminMode: PropTypes.bool.isRequired,
    schemas: PropTypes.array.isRequired,
  }

  state = {
    drawerOpen: false,
    selectedClient: undefined,
    error: undefined,
  }

  loadMoreFrom = false;

  componentDidMount() {
    this.props.dispatch(RefsStore.getSchemas());
    this.props.dispatch(RefsStore.getRoles());
    this.reloadClients();
  }

  onNewClient = () => {
    this.setState({
      drawerOpen: true,
      selectedClient: {},
    });
  }

  onRefresh = () => {
    this.reloadClients(true);
  }

  reloadClients = (refresh=false) => {
    this.props.dispatch(this.props.get(refresh));
  }

  reloadClient = (client) => {
    this.props.dispatch(ClientStore.updatedOne(client));
  }

  onClientSelect = (client) => {
    this.setState({
      selectedClient: Object.assign({}, client),
      drawerOpen: true
    });
  }

  onDrawerClose = () => {
    this.setState({drawerOpen: false});
  }

  setError = (title, err) => {
    const text = (err.body && err.body.message) || 'An error occurred processing this request.';
    this.setState({ error: {title, text} });
  }

  onSave = async (client, userRoles) => {
    if (client.client_id === undefined) {
      const newClient = await this.createClient(client);
      this.updateUserRoles(newClient.client_id, userRoles);
    } else {
      this.updateClient(client);
      this.updateUserRoles(client.client_id, userRoles);
    }
  }

  createClient = async (client) => {
    const client_id = this.props.clientId;
    let newClient = undefined;
    try {
      const response = await ClientAPI.create(client_id, client);
      newClient = response.payload;
      this.setState({selectedClient: undefined, drawerOpen: false});
      this.reloadClients(true);
    } catch (err) {
      this.setError('Error creating client', err);
    }
    return newClient;
  }

  updateClient = async (client) => {
    try {
      await ClientAPI.update(client.client_id, client);
      this.setState({selectedClient: undefined, drawerOpen: false});
      this.reloadClient(client);
    } catch (err) {
      this.setError('Error updating client', err);
    }
  }

  updateUserRoles = (clientId, newRoles) => {
    const { profile, dispatch, adminMode } = this.props;
    const roles = profile.roles[clientId] || [];
    if (!sameRoles(roles, newRoles)) {
      const patch = {
        roles: Object.assign({}, profile.roles, {[clientId]: newRoles})
      };
      const update = adminMode ? UserAPI.updateAdmin : UserAPI.update;
      update(clientId, profile.user_id, patch)
        .then(() => {
          dispatch(AuthStore.reloadProfile(true));
          dispatch(ClientStore.list(true));
        });
    }
  }

  schemaName = (schemaId) => {
    const schema = this.props.schemas.find(s => s.id === schemaId);
    return schema ? schema.name : schemaId;
  }

  renderPermissions = (client) => {
    const { profile, } = this.props;
    const roles = profile.roles[client.client_id] || [];
    let Component;
    if (roles.includes('Client Admin')) {
      Component = StarIcon;
    } else if (roles.length > 0) {
      Component = StarBorderIcon;
    } else {
      return null;
    }
    return <Component className={classes.smallIcon}/>;
  }

  render() {
    const { clients, isLoading, total,  roles, profile, adminMode } = this.props;
    const { drawerOpen, selectedClient, error } = this.state;

    if (!clients) return (clients===undefined ? <NoData message="Loading"/> : <NoData/>);
    // if the client doesn't exist, default role is Client Admin
    // if the client exists and the user has roles, show those.
    // otherwise userRoles is empty.
    const selectedClientId = selectedClient && selectedClient.client_id;
    const currentRoles = selectedClientId && profile && profile.roles[selectedClientId];
    const defaultRoles = selectedClientId ? [] : ['Client Admin'];
    const userRoles = currentRoles || defaultRoles;
    const readOnlyRoles = adminMode ? [] : ['Client Admin'];

    // get list of clients the user has ClientAdmin for
    const adminClients = adminMode ? clients : clients.filter(client =>
      profile &&
      profile.roles[client.client_id] &&
      profile.roles[client.client_id].includes('Client Admin')
    );
    const count = adminMode ? total : adminClients.length;
    return (
      <Root className={classes.container}>
        <Toolbar
          className={classes.toolbar}
          itemName="client"
          onNew={adminMode ? this.onNewClient : undefined}
          onRefresh={this.onRefresh}
          refreshDisabled={isLoading}
          showing={count}
          total={count}
        />
        <div className={classes.scroller}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell padding="none"></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Schema</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminClients.map(client => (
                <TableRow key={client.client_id} onClick={() => this.onClientSelect(client)}>
                  <TableCell padding="none">
                    {adminMode ? this.renderPermissions(client) : null}
                  </TableCell>
                  <TableCell>
                    {client.client_id}
                  </TableCell>
                  <TableCell>
                    {client.nickname}
                  </TableCell>
                  <TableCell>
                    {client.full_name}
                  </TableCell>
                  <TableCell>
                    {this.schemaName(client.client_schema)}
                  </TableCell>
                  <TableCell>
                    {this.renderIndustry(client)}
                  </TableCell>
                  <TableCell>
                    {client.state}
                  </TableCell>
                  <TableCell>
                    {moment(client.created).format('D MMM YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={this.onDrawerClose}>
          <ClientForm
            client={selectedClient}
            onClose={this.onDrawerClose}
            onSave={this.onSave}
            roles={roles}
            userRoles={userRoles}
            adminMode={adminMode}
            readOnlyRoles={readOnlyRoles}
          />
        </Drawer>
        {error && <ErrorDialog
          show={true}
          title={error.title}
          text={error.text}
          onClose={() => this.setState({error: undefined})}/>
        }
      </Root>
    );
  }

  renderIndustry(client) {
    const industry = client.industry?.anzsic?.level_1;
    if (industry == null || industry in industryMap) {
      return industry;
    }
    return 'Invalid';
  }
}

const mapStateToProps = state => {
  return {
    roles: state.refs.roles.items,
    profile: state.auth.profile,
    clientId: state.clients.selectedItemId,
    schemas: state.refs.schemas.items,
  };
};

export default connect(mapStateToProps)(
  (ClientTable)
);
