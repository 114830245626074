import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Roles from './Roles';

import Form, {
  Section,
  TextInput
} from '../Form';

const PREFIX = 'GroupAddForm';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  closeButton: `${PREFIX}-closeButton`,
  deleteButton: `${PREFIX}-deleteButton`,
  saveButton: `${PREFIX}-saveButton`
};

const Root = styled('div')(() => ({
  [`& .${classes.toolbar}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  [`& .${classes.closeButton}`]: {
  },

  [`& .${classes.deleteButton}`]: {
    float: 'right',
  },

  [`& .${classes.saveButton}`]: {
    marginTop: '1rem',
    fontWeight: 900,
    alignSelf: 'center',
    width: '10rem',
  }
}));

class GroupAddForm extends React.Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.array,
  }

  state = {
    newRoles: ['Client Admin'],
    emailList: '',
  }

  onChange = (field, emailList) => {
    emailList = emailList.replace(/[,; \n]+/g, '\n');
    this.setState({emailList});
  }

  onChangeRole = (value, checked) => {
    this.setState((state) => {
      if (checked) {
        state.newRoles.push(value);
      } else {
        state.newRoles = state.newRoles.filter(role => role !== value);
      }
      return state;
    });
  }

  onSave = () => {
    const cleanList = this.state.emailList
      .replace(/[,; \n]+/g, '\n')
      .split('\n')
      .filter(line => line !== '');
    this.props.onSave(cleanList, this.state.newRoles);
  }

  render() {
    const {  onClose, roles } = this.props;
    const { newRoles, emailList } = this.state;
    const title = 'Add Users';
    const valid = newRoles.length > 0 && emailList.length > 1;

    return (
      <Root>
        <Form>
          <div className={classes.toolbar}>
            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>

          </div>
          <h1>{title}</h1>

          <Section title="Identification">
            <FormGroup row>
              <TextInput label="Email Addresses" value={{emailList}} field="emailList" onChange={this.onChange} rows={4} span={2}/>
            </FormGroup>
          </Section>

          <Section title="Permissions">
            <Roles
              roles={roles}
              userRoles={newRoles}
              onChange={(roles) => this.setState({newRoles: roles})}
            />
          </Section>

          <Button variant="contained" color="primary" className={classes.saveButton} onClick={this.onSave} disabled={!valid}>
            Save
            <DoneIcon/>
          </Button>
        </Form>
      </Root>
    );
  }
}

export default (GroupAddForm);
