import { request, makePath, setHeaders, clearHeader, rawRequest } from './http';
const API_NAME = 'auth';
let authToken = undefined;

export function setBearerToken(token) {
  authToken = token;
  setHeaders({authorization: 'Bearer ' + token});
}

export function clearBearerToken() {
  authToken = undefined;
  clearHeader('authorization');
}

export function isAuthenticated() {
  return authToken !== undefined;
}

export function login(email, password) {
  return request('POST', makePath(API_NAME, 'login'), {body: {email, password}})
    .then(response => {
      setBearerToken(response.payload.token);
      return response;
    });
}

export function refresh(refresh_token) {
  return request('POST', makePath(API_NAME, 'refresh'), {body: {refresh_token}})
    .then(response => {
      setBearerToken(response.payload.token);
      return response;
    });
}

export function logout() {
  // Use rawRequest to not double up on 403 error handling
  return rawRequest('POST', makePath(API_NAME, 'logout'))
    .then(response => {
      clearBearerToken();
      return response;
    })
    .catch(() => {
      // if the token has expired, logout will return a 403 error, but we are still logged out.
      clearBearerToken();
    });
}
