const isBarton = process.env.REACT_APP_PRODUCT === 'barton';

export default {
  services: {
    HOST:  process.env.REACT_APP_SERVICES_HOST,
    VERSION:  process.env.REACT_APP_SERVICES_DEFAULT_VERSION
  },
  settings: {
    STAGE: process.env.REACT_APP_STAGE,
    GA_KEY: process.env.REACT_APP_GA_KEY,
    MAPS_KEY: process.env.REACT_APP_GMAPS_KEY,
    MAPBOX_KEY: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    SENTRY_DNS_KEY: process.env.REACT_APP_SENTRY_DSN,
    SENTRY_TRACES_SAMPLE_RATE: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    MUI_LICENCE_KEY: process.env.REACT_APP_MUI_LICENCE_KEY,
    PRODUCT: process.env.REACT_APP_PRODUCT,
  },
  links :{
    FUEL_CONSUMPTION_RATES_HELP: isBarton
      ? 'https://help.nationalpilot.com.au/Home/using-the-vehicle-register#UsingtheVehicleRegister-FuelConsumption'
      : 'https://help.prismapp.com.au/Home/vehicle-registration-and-fuel-consumption',
    HELP_CENTRE: isBarton
      ? 'https://help.nationalpilot.com.au/Home/'
      : 'https://help.prismapp.com.au/Home/',
  },
  isBarton: isBarton,
};
