import moment from 'moment';
import 'moment/locale/en-au';

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const dateToDateString = (date) => {
  if (date === undefined) return null;
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString().split('T')[0];
};

export const prettyLocalDatetime = (value, format = 'llll') => {
  return moment.utc(value).local().format(format);
};

// use en-US to ensure month is 3 characters long. for default locale and en-GB September -> Sept, not Sep
export const dateToShortMonth = (date) => date.toLocaleString('en-US', { month: 'short' });

export const dateToShortMonthAndYear = (date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
