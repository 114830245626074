import { SourcesAPI } from '../api';

const SYSTEMS_LOADING        = 'sources/systems/LOADING';
const SYSTEMS_UPDATED        = 'sources/systems/UPDATED';
const JOBS_LOADING           = 'sources/jobs/LOADING';
const JOBS_UPDATED           = 'sources/jobs/UPDATED';
const JOBS_PARAMS_CHANGED    = 'sources/jobs/PARAMS_CHANGED';
const EXTERNAL_FILES_LOADING = 'sources/externalFiles/LOADING';
const EXTERNAL_FILES_UPDATED = 'sources/externalFiles/UPDATED';
const ERROR                  = 'sources/ERROR';

const CACHE_TTL = 300000;

const initialState = {
  systems: {
    items: {},
    isLoading: false,
    loadTime: undefined,
  },
  jobs: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
  externalFiles: {
    items: [],
    isLoading: false,
    loadTime: undefined,
  },
};

const objSignature = obj => obj ? JSON.stringify(obj, Object.keys(obj).sort()) : String(obj);

let lastRequestId = undefined;

// Action Creators

export function systemsLoading() {
  return {
    type: SYSTEMS_LOADING,
  };
}

export function systemsUpdated(items) {
  return {
    type: SYSTEMS_UPDATED,
    items,
  };
}

export function jobsLoading() {
  return {
    type: JOBS_LOADING,
  };
}

export function jobsUpdated(items, offset, complete) {
  return {
    type: JOBS_UPDATED,
    items,
    offset,
    complete
  };
}

export function paramsChanged(params) {
  return {
    type: JOBS_PARAMS_CHANGED,
    params,
  };
}

export function externalFilesLoading() {
  return {
    type: EXTERNAL_FILES_LOADING,
  };
}

export function externalFilesUpdated(response) {
  const items = response.payload;
  return {
    type: EXTERNAL_FILES_UPDATED,
    items,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}

// Async Action Creators

async function chunkedFetch(params, dispatch, requestId, offset, limit) {
  let done = false;
  while (!done) {
    const response = await SourcesAPI.findJobs({...params, offset});
    done = response.jobs.length < response.pagesize;
    done = done || ( (response.offset + response.jobs.length) >= limit );
    const action = jobsUpdated(response.jobs, response.offset, done);
    if (requestId !== lastRequestId) break;
    dispatch(action);
    offset += response.jobs.length;
  }
}

export function getJobs(params, refresh=false, offset=0, limit=100) {
  return (dispatch, getState) => {
    const state = getState().sources.jobs;
    const age = Date.now() - state.loadTime;
    const sameParams = objSignature(params) === objSignature(state.params);
    if (!refresh && sameParams && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      if (!sameParams) dispatch(paramsChanged(params));
      const requestId = Math.random();
      lastRequestId = requestId;
      dispatch(jobsLoading());
      chunkedFetch(
        params,
        dispatch,
        requestId,
        offset,
        limit,
      );
    }
  };
}

export function getExternalFiles(refresh=false, params={}) {
  return (dispatch, getState) => {
    const { loadTime } = getState().sources.externalFiles;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(externalFilesLoading());
      return SourcesAPI.getExternalFiles(params)
        .then(response => dispatch(externalFilesUpdated(response)))
        .catch(e => dispatch(error(e)));
    }
  };
}

export function getSystems(refresh=false) {
  return (dispatch, getState) => {
    const { loadTime } = getState().sources.systems;
    const age = Date.now() - loadTime;
    if (!refresh && age < CACHE_TTL) { // age might be NaN, which compares false
      // nothing to do, already cached.
    } else {
      dispatch(systemsLoading());
      return SourcesAPI.getSystems()
        .then(response => dispatch(systemsUpdated(response.payload)))
        .catch(e => dispatch(error(e)));
    }
  };
}

// Reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

  case SYSTEMS_LOADING:
    return {
      ...state,
      systems: {
        ...state.systems,
        isLoading: true,
      }
    };

  case SYSTEMS_UPDATED:
    return {
      ...state,
      systems: {
        ...state.systems,
        items: action.items,
        isLoading: false,
      }
    };

  case JOBS_LOADING:
    return {
      ...state,
      jobs: {
        ...state.jobs,
        isLoading: true,
      }
    };

  case JOBS_UPDATED:
  // merge the new data into the list at the specified offset.
  {
    const tailOffset = action.complete ? 0 : undefined;
    const items = [
      ...state.jobs.items.slice(0, action.offset),
      ...action.items,
      ...state.jobs.items.slice(action.offset + action.items.length, tailOffset)];
    return {
      ...state,
      jobs: {
        ...state.jobs,
        isLoading: !action.complete,
        loadTime: Date.now(),
        items: items,
      }
    };
  }

  case JOBS_PARAMS_CHANGED:
    return {
      ...state,
      jobs: {
        ...state.jobs,
        params: action.params,
        items: [],
        loadTime: undefined,
        total: undefined,
      }
    };

  case EXTERNAL_FILES_LOADING:
    return {
      ...state,
      externalFiles: {
        ...state.externalFiles,
        isLoading: true,
      }
    };

  case EXTERNAL_FILES_UPDATED:
    return {
      ...state,
      externalFiles: {
        ...state.externalFiles,
        isLoading: false,
        loadTime: Date.now(),
        items: action.items,
      }
    };

  default:
    return state;
  }
}
